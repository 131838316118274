// Third party imports
import {
    Validator,
    RequiredRule,
    CompareRule,
    EmailRule,
    PatternRule,
    StringLengthRule,
    RangeRule,
    AsyncRule,
    CustomRule,
  } from 'devextreme-react/validator';

const _ = require("lodash");
const s = require("underscore.string");

const validatorTypes = {
    requiredRule: 0,
    compareRule: 1,
    emailRule: 2,
    patternRule: 3,
    stringLengthRule: 4,
    rangeRule: 5,
    asyncRule: 6,
    customRule: 7
};
  
export const validation = {
    validatorTypes: validatorTypes,
    getValidator: (validator) =>{

        if (_.isObject(validator))
        {
            var rules = validator.rules?.map((rule,i) => {
                rule.message = s.stripTags(rule.message);

                switch(rule.type)
                {
                    case validatorTypes.requiredRule:
                        return <RequiredRule type='required' key={i} message={rule.message} trim={rule.trim ?? true} />
                    case validatorTypes.compareRule:
                        return <CompareRule type='compare' key={i} message={rule.message} comparisonTarget={rule.comparisonTarget} comparisonType={rule.comparisonType ?? '==' } ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.emailRule:
                        return <EmailRule type='email' key={i} message={rule.message} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.patternRule:
                        return <PatternRule type='pattern' key={i} message={rule.message} pattern={rule.pattern} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.stringLengthRule:
                        return <StringLengthRule type='stringLength' key={i} message={rule.message} min={rule.min} max={rule.max} trim={rule.trim ?? true} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} />
                    case validatorTypes.rangeRule:
                        return <RangeRule type='range' key={i} message={rule.message} min={rule.min} max={rule.max} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} reevaluate={rule.reevaluate ?? false} />
                    case validatorTypes.asyncRule:
                        return <AsyncRule type='async' key={i} message={rule.message} validationCallback={rule.callback} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} reevaluate={rule.reevaluate ?? false} />
                    case validatorTypes.customRule:
                        return <CustomRule type='custom' key={i} message={rule.message} validationCallback={rule.callback} ignoreEmptyValue={rule.ignoreEmptyValue ?? false} reevaluate={rule.reevaluate ?? false} />
                    default:
                        break;
                }
            });
    
            if (rules)
                return <Validator ref={validator.ref} onValidated={validator.onValidated}>{rules}</Validator>;

        };
    }
};
