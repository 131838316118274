// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { FilterBuilder } from '../../../base/filterBuilder/filterBuilder.js';
import { Bar } from '../../../base/bar/bar';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { TextArea } from '../../../base/textArea/textArea';
import { Button } from '../../../base/button/button.js';
import { helpers } from '../../../../utils/helpers';
import { DataList } from '../../../base/dataList/dataList.js';
import { cosmetic } from '../../../../modules/cosmetic.js';
import { map } from '../../map/map.js';
import { Autocomplete } from 'devextreme-react';
import { constants } from '../../../../utils/constants';

const _ = require("lodash");

export function SelectionCriteria() {

    var categories = ['Expand', 'Category 2', 'Category 3', 'Category 4'];
    var groupOperations = ['and', 'or'];
    var fieldsDef = [
        {
            dataField: 'hh_income',
            dataType: 'number',
            format: 'currency',
            caption: 'HH Income',
        },
        {
            dataField: 'Strategy',
            filterOperations: ['=', 'anyof'],
            lookup: {
                dataSource: categories,
            },
        },
    ];

    var filterDef = [[
        "hh_income",
        "=",
        60
    ]];

    const [filter, setFilter] = useState([]);
    const [fields, setFields] = useState(fieldsDef);
    const [points, setPoints] = useState([]);
    const [zoomToResult, setZoomToResult] = useState(true);
    const [locationSearch, setLocationSearch] = useState(null);
    const [locations, setLocations] = useState([]);
    const [mapLocationListener, setMapLocationListener] = useState(null);
    const [locationResult, setLocationResult] = useState(null);
    const [currentPushpin, setCurrentPushpin] = useState(null);

    useEffect(() => {
        setFilter(filterDef);
        setCurrentPushpin(cosmetic.createGeocodePushpin({ location: null, temporary: true }));
    }, []);

    const onValueChange = useCallback(
        (e) => {
            console.log(e);
            setFilter(e)
        },
        [setFilter],
    );

    const getSeedPointUniverse = () => {
        return "0,000";
    };

    const addPoint = useCallback(() => {
        setPoints([...points, { id: helpers.newGuid(), geocode: _.cloneDeep(locationResult), pushpinEntity: currentPushpin }]);

        setLocationSearch(null);
        resetMapLocationListener();
        setLocationResult(null);
        setCurrentPushpin(cosmetic.createGeocodePushpin({ location: null, temporary: true }));
        
    }, [points,locationResult]);

    function removePoint(pointId) {

        const pushpin = points.find(a => a.id === pointId).pushpinEntity;
        if (pushpin) {
            pushpin.dispose();
        }

        setPoints(points.filter(a => a.id !== pointId));
    }

    const dataListRow = (point) =>{

        return <div key={point.id}>
            <span className='app-market-opt-forced-seed-point-address-text'>{point.geocode.address.full}</span>
            <Button className='app-market-opt-forced-seed-point-list-button' theme='simple' icon={icons.trash} tooltip={translate('remove')} 
                                onClick={() =>{ 
                                        removePoint(point.id);
                                }} />
        </div>      
    };    

    const updateLocation = (o)=>{

        if (!_.isString(o.query) || o.query.length === 0)
            return;        

        map.geocode({
            query: o.query,
            callback: (result) => {   
                setLocationResult(result);

                if (o.updateSearchWithGeocode)
                    setLocationSearch(result.address.full);
                else if (o.updateSearchWithQuery)
                    setLocationSearch(o.query);

                if (o.location == null)
                    currentPushpin.location = result.location;
                else
                    currentPushpin.location = o.location;

                if (zoomToResult === true && o.zoom !== false)
                    map.locate({ location: result.location });
            }
        });
    };

    const searchLocation = (e) => {

        if (!e.value)
            return;

        map.addressSearch({
            search: e.value,
            callback: (results) =>{
                if (!_.isNull(results) && results.length > 0)
                    setLocations(results.map(result => { return result.description;  }));
            }
        });

        if (e.value.length > 0)
            e.component.open();
        else
            e.component.close();
	};

    const toggleMapLocator = () =>{
        if (mapLocationListener === null)
        {
            map.setCursor({ cursor: 'crosshair' });
            map.disableEntityClicking();
            setMapLocationListener(
                map.addListener({
                    type: constants.listeners.click,
                    action: (e) => {                    
                        updateLocation({
                            query: `${e.location.lat} ${e.location.lon}`,
                            updateSearchWithGeocode: true,
                            zoom: false,
                            location: e.location
                        });
                    }
                })
            );
        }
        else
        {
            resetMapLocationListener();
        }
    };   

    const resetMapLocationListener = () =>{
        map.enableEntityClicking();
        map.setCursor({ cursor: '' });
        map.removeListener(mapLocationListener);
        setMapLocationListener(null);
    }

    return <>
        <div className='app-market-opt-wizard-body'>
            <Bar icon={icons.locationDot} text={translate('forced_seed_points')} opened={true}>
                <div className='app-market-opt-forced-seed-points'>
                    <div className='app-text-box'>
                        <TextArea
                            minHeight='75px'
                            maxHeight='75px'
                            height='75px'
                            value={locationSearch}
                            label={translate('new_location')}
                            placeholder={translate('new_location_place_holder')}
                            onEnter={()=>{}}
                            onKeyUp={(e)=>{
                                setLocationSearch(e.component.option('text'));

                                if (e.event.originalEvent.key !== 'Enter')
                                    return;

                                updateLocation({ query: e.component.option('text') });
                            }}
                            onChange={(e) =>{
                                if (e.event === undefined)
                                    return;

                                setLocationSearch(e.value);
                                updateLocation(e.value);
                            }}
                        />
                    </div>
                    <Autocomplete
                        items={locations}
                        value={locationSearch}
                        onValueChanged={searchLocation}
                        minSearchLength={2}
                        searchTimeout={500}
                        height='0px'
                        placeholder=''
                        onSelectionChanged={(e) =>{
                            updateLocation({ query: e.selectedItem, updateSearchWithQuery: true });
                        }}
                    />
                    <div className='app-market-opt-forced-seed-points-buttons-container'>
                        <Button className='app-market-opt-forced-seed-points-button' theme='secondary' icon={icons.crosshair} text={translate('place_site')} active={mapLocationListener !== null} onClick={() => {toggleMapLocator()}} />
                        <Button className='app-market-opt-forced-seed-points-button' theme='primary' icon={icons.plus} text={translate('add')} 
                            disabled={locationResult === null}
                            onClick={() => { addPoint(); }} />
                    </div>
                <div className='app-market-opt-forced-seed-point-list-container'>
                    <div className='app-market-opt-forced-seed-point-label'>{translate('seed_points')}</div>
                    <div className='app-market-opt-forced-seed-point-list'>
                        <DataList
                            keyExpr={'id'}
                            dataSource={points}
                            height={125}
                            selectionMode={'single'}
                            selectByClick={true}
                            searchEnabled={false}
                            showSelectionControls={false}
                            itemRender={dataListRow}
                        />                        
                    </div>
                </div>
                </div>

            </Bar>            
            <Bar icon={icons.locationDot} text={translate('optional_filters')} opened={true}>
                <div className='app-market-opt-optional-filters'>
                    <div className='app-market-opt-optional-filters-actions'>
                        <div className=''>
                            {`${translate('seed_point_universe')}: ${getSeedPointUniverse()}`}
                        </div>
                        <div className='right-aligned-button'>
                            <Button size='tiny' theme='primary' icon={icons.check} />
                        </div>
                    </div>
                    <div>
                        <FilterBuilder
                            fields={fields}
                            filter={filter}
                            onValueChange={onValueChange}>
                        </FilterBuilder>                        
                    </div>
                </div>
            </Bar>
        </div>
    </>
}
