// App imports
import { map } from '../components/app/map/map';
import { layerActions } from '../components/app/layers/layer/layer';
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
import { icons } from '../components/base/icon/icon';
import { layers } from '../components/app/layers/layers';
import { ciDashboards } from '../components/app/mobilityData/competitiveInsights/dashboards/ciDashboards';
import { sources } from './sources';
import { navigation } from '../components/app/navigation/navigation';
import { rightPanel } from '../components/base/rightPanel/rightPanel';
import { customerMaps } from './customerMaps';
import { selections } from './selections';
import { errorToast } from '../components/base/toast/toast';
import { CIDashboardLazyLoader } from '../components/app/mobilityData/competitiveInsights/dashboards/ciDashboardLazyLoader';

const _ = require("lodash");

export const competitiveInsights = {
    open: () => {
        sources.refresh({
            layers: [{
                data: { isCompetitiveInsights: true },
                id: helpers.emptyGuid(),
                subType: 0,
                text: "Competitive Insights"
            }],
            onRefresh: (o) =>{															
                layers.refreshDataLayers(o);
            }
        });

        navigation.forceExpand({ section: constants.navigation.sections.layers });
    },
    getLayer: () =>{
        return map.layers.find(x => x.id === helpers.emptyGuid() && x.metaData.isCompetitiveInsights === true);
    },
	createHalo: (o) =>{

        var halo = competitiveInsights.getHalo(o);
        var entity = o.layer.addEntity({
			type: constants.entities.genericPoint,
			location: o.location,
            anchor: { x: halo.container / 2, y: halo.container / 2 },
			image: halo.image,
			visible: o.visible,
            zIndex: o.zIndex,
            groupId: o.groupId,
            metaData: o.metaData
        });

        return entity;
    },
    getHalo: function(o){

        var padding = o.size / 3;
        var stroke = 3;
        var diameter = o.size + padding;
        var container = diameter + stroke;

        var haloColor = competitiveInsights.getGrade({ grade: o.grade });

        return {
            container: container,
            image: `<svg xmlns="http://www.w3.org/2000/svg" height="${container}" width="${container}"> \
                <circle cx="${container / 2}" cy="${container / 2}" r="${diameter / 2}" stroke="${haloColor.stroke}" stroke-width="${stroke}" fill="${haloColor.fill}" /> \
            </svg>`
        } 
    },
    getGradeByIndexGrade: function (index,competitiveInsights) {
        switch(index)
        {
            default:
            case constants.competitiveInsights.indexGrade.chainIndexGrade:
                return competitiveInsights.chainIndexGrade;
            case constants.competitiveInsights.indexGrade.chainMarketIndexGrade:
                return competitiveInsights.chainMarketIndexGrade;
            case constants.competitiveInsights.indexGrade.channelIndexGrade:
                return competitiveInsights.channelIndexGrade;
            case constants.competitiveInsights.indexGrade.channelMarketIndexGrade:
                return competitiveInsights.channelMarketIndexGrade;
        }        
    },
    getGrade: function (options) {
        switch (options.grade) {
            default:
                return "";
            case "A":
                return { stroke: "rgb(r: 6, g: 130, b: 45)", fill: "rgb(26,150,65)", style: "compInsightGreen" };
            case "B":
                return { stroke: "rgb(146,197,86)", fill: "rgb(166,217,106)", style: "compInsightLightGreen" };
            case "C":
                return { stroke: "rgb(229,192,25)", fill: "rgb(249,212,45)", style: "compInsightYellow" };
            case "D":
                return { stroke: "rgb(222,89,12)", fill: "rgb(242,109,32)", style: "compInsightOrange" };
            case "F":
                return { stroke: "rgb(182,0,12)", fill: "rgb(202,20,32)", style: "compInsightRed" };
        }
    },
    getSubType: function (item) {
        switch (item.type) {
            default:
                return "";
            case constants.competitiveInsights.tradeAreas.visitsCount:
            case constants.competitiveInsights.tradeAreas.visitsHousehold:
            case constants.competitiveInsights.tradeAreas.visitsPercent:
                return  `${item.pointIds[0]}_visitsTradeArea`;
            case constants.competitiveInsights.tradeAreas.polygonPercent:
                return `${item.pointIds[0]}_polygonTradeArea`;
            case constants.competitiveInsights.tradeAreas.totalVisitsByGeography:
            case constants.competitiveInsights.tradeAreas.householdPenetrationByGeography:
                return "multiTradeArea";
        }
    },    
    getHeaderInfo: function (item) {
        var zoomToLayer = _.isBoolean(item.zoomToLayer) ? item.zoomToLayer : true;
        switch (item.type) {
            default:
                return "";
            case constants.competitiveInsights.tradeAreas.visitsCount:
                return {title: item.text, subText: translate("by_visit_count"), zoomToLayer: zoomToLayer}
            case constants.competitiveInsights.tradeAreas.visitsHousehold:
                return {title: item.text, subText: translate("by_household_penetration"), zoomToLayer: zoomToLayer}
            case constants.competitiveInsights.tradeAreas.visitsPercent:
                return {title: item.text, subText: translate("percent_trade_area"), zoomToLayer: zoomToLayer}
            case constants.competitiveInsights.tradeAreas.polygonPercent:
                return {title: item.text, subText: `${(item.percent * 100)}${translate('percent_polygon')}`, zoomToLayer: zoomToLayer}
            case constants.competitiveInsights.tradeAreas.totalVisitsByGeography:
                return {title: translate("range_map_of_total_visits"), subText: '', zoomToLayer:false}
            case constants.competitiveInsights.tradeAreas.householdPenetrationByGeography:
                return {title: translate("range_map_of_hh_pen"), subText: '', zoomToLayer:false}
        }  
    },      
    getCompetitiveInsightsVisitsOptions: function (o) {

        var instance = this;

        //todo modify for grouping output : Visits and Visits Trade Area
        var percentItems =  [{ name: translate("visits_count"), type: constants.competitiveInsights.tradeAreas.visitsCount },
                             { name: translate("visits_per_hh"), type: constants.competitiveInsights.tradeAreas.visitsHousehold },
                            ].map((v) => {

                    var item = {
                        setDefault: false,
                        text: v.name,
                        percent: 0,
                        type: v.type,
                        entity: o.entity,
                        pointIds: [o.entity.id],
                        icon: icons.grid,
                        await: true
                    };

                    item.onClick = async () => {
                        await instance.showLayer(item);
                    };

                    return item;
                }
            );

        percentItems.push(
            ...[.8, .75, .7, .65, .6].map((percent) => {

                var item = {
                    setDefault: false,
                    text: `${(percent * 100)}${translate('percent_visits_trade_area')}`,
                    percent: percent,
                    type: constants.competitiveInsights.tradeAreas.visitsPercent,
                    entity: o.entity,
                    pointIds: [o.entity.id],
                    icon: icons.grid,
                    await: true
                };

                item.onClick = async () => {
                    await instance.showLayer(item);
                }

                return item;
            })
        );

        return percentItems;
    },
    getCompetitiveInsightsPolygonTradeAreaOptions: function (o) {

        var instance = this;

        return [.8, .75, .7, .65, .6].map((percent) => {

            var item = {
                setDefault: false,
                text: `${(percent * 100)}${translate('percent_trade_area')}`,
                percent: percent,
                type: constants.competitiveInsights.tradeAreas.polygonPercent,
                entity: o.entity,
                pointIds: [o.entity.id],
                icon: icons.hexagon,
                await: true               
            }

            item.onClick = async () => { 
                await instance.showLayer(item);
            };

            return item;
        });
    },    
    getCompetitiveInsightsTradeArea: async function (o) {      
        
        var tradeAreas = await legacyEndpoints.service({
            name: 'GetCompetitiveInsightsTradeArea',
            parameters: {
                pointIds: o.pointIds,
                percent: o.percent,
                type: o.type                
            }
        });

        return tradeAreas;
    },           
    getCompetitiveInsightsInformation: async function (o) {      
        
        var result = await legacyEndpoints.service({
            name:'GetCompetitiveInsightsInformation',
            parameters: {
                channelId: o.entity.metaData.competitiveInsights.channelId,
                pointId:  o.entity.id,
                type: o.entity.metaData.competitiveInsights.type
            }
        });

        result.Form.Tabs.push({
            icon: icons.pieChart,
            Id: helpers.newGuid(),
            Pages: [{
                Sections: [{                
                    isCustom: true, 
                    customComponent: ()=>{                        
                        return <CIDashboardLazyLoader entity={o.entity} />
                    }
                }]
            }],
            Sequence: 1,
            Title: translate("charts"),
            Visible: false
        });

        result.Form.Tabs.forEach(tab => {
            tab.Id = helpers.newGuid();
            tab.Pages.forEach(page => {
                page.Sections.forEach(section => {
                    section.Id = helpers.newGuid();
                });
            });
        });

        return result;
    },   
    getLayers: function() {
        return map.layers.filter(layer => 
            layer.group === constants.layers.groups.competitiveInsights )
    },
    getTooltipCSS: function(shape) {
        var tooltipClass;
        switch (shape.fillColor.r) {
            default:
            case 252:
                tooltipClass = "compInsightVeryLow";
                break;
            case 251:
                tooltipClass = "compInsightLow";
                break;
            case 239:
                tooltipClass = "compInsightMedium";
                break;
            case 203:
                tooltipClass = "compInsightHigh";
                break;
            case 103:
                tooltipClass = "compInsightVeryHigh";
                break;
        };
        return tooltipClass;
    },    
    showLayer: async function(item) {     

        var data = await this.getCompetitiveInsightsTradeArea(item);

        var getEntityVisibility = (layer, groupId) =>{
            return layer.oppositeVisibilityEntities.indexOf(groupId) > -1 ? !layer.visible : layer.visible;
        };

        const subType = this.getSubType(item);          
        const headerInfo = this.getHeaderInfo(item);
        var existingLayer = this.getLayers(subType).find(layer => layer.metaData.layerTypeId === subType);

        if (_.isObject(existingLayer)) {
            if (_.isArray(existingLayer._listeners)) {
                existingLayer._listeners.forEach(listener => {
                    existingLayer.removeListener(listener.id);
                });
            }

            existingLayer.dispose();
        }
  
        var layer = map.addLayer({
            id: `${data.id}_${item.type}`,
            group: constants.layers.groups.competitiveInsights,
            type: constants.layers.types.competitiveInsightsTradeArea,
            metaData: {layerTypeId: subType, points: item.pointIds, tradeAreaType: item.type, percentage: item.percent, center: _.isObject(item.entity?.location) ? item.entity.location : null},
            text: headerInfo.title,
            subText: headerInfo.subText,
            visible: !_.isUndefined(item.visible) ? item.visible : true,
            actions: [{
                id: layerActions.visible,
                getActive: () => { return layer.visible; },
                getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
                onClick: () =>{                        
                    layer.visible = !layer.visible;
                }
            },
            {
                id: layerActions.delete,
                onClick: () =>{
                    layer.dispose();
                }
            }],
            onChange: (c)=>{
                layers.update();
            }
        });

        data.shapes.forEach(shape => {            
            layer.addEntity({
                id: shape.id,
                type: constants.entities.polygon,
                paths: helpers.parseWkt(shape.polygon.polygonString),
                groupId: data.ranges.find(x => x.Red === shape.fillColor.r && x.Green === shape.fillColor.g && x.Blue === shape.fillColor.b)?.ID,
                fillColor: shape.fillColor,
                strokeColor: shape.strokeColor,
                strokeWidth: parseInt(shape.strokeThickness),
                listeners: [{
                    type: constants.listeners.click,
                    action: (e) =>{
                        
                        const information = data.information.find(x => x.Key === shape.id)

                        if (_.isObject(information))
                            map.showTooltip({
                                title: translate('information'),
                                className: this.getTooltipCSS(shape),
                                pixel: map.latLonToXY(e.location),
                                sections: [information.Value.map(x => { return <div>{`${x.Label} ${x.Value.Text}`}</div> } )]
                            });
                    }
                }]
            });                
        });
          
        layer.legend = data.ranges.map(legend =>{
            legend.groupId = legend.ID;            
            return {
                groupId: legend.groupId,
                text: legend.LegendText,
                subText: headerInfo.subText,
                color: {r: legend.Red,g: legend.Green,b: legend.Blue,a: legend.Alpha},
                actions: [{
                    id: layerActions.visible,
                    getActive: () => { return getEntityVisibility(layer, legend.groupId); },
                    onClick: () =>{

                        if (layer.oppositeVisibilityEntities.indexOf(legend.groupId) > -1)
                            layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== legend.groupId);
                        else
                            layer.oppositeVisibilityEntities.push(legend.groupId);

                        layer.entities.filter(x => _.isString(x.groupId)).forEach(x => {
                            x.visible = layer.oppositeVisibilityEntities.indexOf(x.groupId) > -1 ? !layer.visible : layer.visible;
                        });
                    }
                }]
            };
        })
        
        layers.update();
    },     
    getDashboard: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetDashboard',
            parameters: {
                id: o.dashboardId,
                siteId: o.siteId,
                settings: o.settings
            }
        }));     

    },
    getDashboardDefaultSettings: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetDashboardDefaultSettings',
            parameters: {
                siteId: o.siteId,
                channelId: o.channelId,
                channel:  o.channel
            }
        }));     

    },
    processDashboardChartData: function (chart) {      
        var dataSource = [];
        var series = [];
        var hasNegatives = false;

        chart.dataSource.forEach(row => {
            var source = _.find(dataSource, function (o) { return o[chart.series.ArgumentField] === row[chart.series.ArgumentField]; });

            if (!_.isObject(source)) {
                source = {};
                source[chart.series.ArgumentField] = row[chart.series.ArgumentField];
            }

            if (_.isArray(chart.series.ValueFields)) {
                chart.series.ValueFields.forEach(value => {
                    var key = (chart.type === constants.competitiveInsights.chartTypes.pie) ? value : `${row[chart.series.IdField]}_${value}`;

                    source[key] = row[value];

                    if (_.isNumber(row[value]) && row[value] < 0)
                        hasNegatives = true;
                });

                chart.series.ValueFields.forEach(value => {
                    var key = (chart.type === constants.competitiveInsights.chartTypes.pie) ? value : `${row[chart.series.IdField]}_${value}`;
                    var seriesItem = _.find(series, function(o) { return o.valueField === key; });
                    var nameField = _.find(chart.series.NameFields, function(o) { return o.ValueField === value; });

                    if (!_.isObject(seriesItem)) {
                        seriesItem = {
                            valueField: key,
                            aggregation: {
                                enabled: true,
                                method: "sum"
                            }
                        };

                        if (chart.type === constants.competitiveInsights.chartTypes.pie) {
                            seriesItem.argumentField = chart.series.ArgumentField;
                            seriesItem.label = {
                                visible: true,
                                connector: {
                                    visible: true,
                                    width: 0.5
                                },
                                format: {
                                    type: "percent",
                                    precision: 1
                                },
                                customizeText: function(point) {
                                    return `${point.argumentText}: ${point.valueText}`;
                                }
                            };
                        } else if (chart.type === constants.competitiveInsights.chartTypes.line) {
                            seriesItem.name = _.isObject(nameField)
                                ? nameField.Text.replaceAll("[Field]", row[nameField.Field])
                                : "N/A";
                        }

                        series.push(seriesItem);
                    }
                });
            }

            dataSource.push(source);
        });

        return {
            dataSource: dataSource,
            series: series,
            hasNegatives: hasNegatives,
            exportOptions: {
                enabled: true,
                printingEnabled: true,
                formats: ["PNG", "JPEG", "PDF", "SVG"]
            }
        }
    },
    getDemographicReports: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetCompetitiveInsightsDemographicReports',
            parameters: {
                pointId: o.pointId
            }
        }));     

    },
    getDemographicData: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetCompetitiveInsightsDemographicData',
            parameters: {
                pointId: o.pointId,
                percent: o.percent,
                demographicId: o.demographicId,
                comparePointIds: o.comparePointIds,
                isDefault: o.isDefault
            }
        }));     

    },
    createCompetitiveInsightsCache: async function (o) {      
    
        // TBD: this should be a one way service call (KLI 1.0 had a isOneWay flag)
        legacyEndpoints.service({
            name: 'CreateCompetitiveInsightsCache',
            parameters: {
                pointId: o.pointId,
                channelId: o.channelId,
                channel: o.channel,
                tradeAreaTypes: o.tradeAreaTypes,
                dashboardIds: o.dashboardIds,
                token: legacyEndpoints.authenticationToken
            }
        });

    },
    createDashboardReportJob: async function (o) {      
    
        // TBD: this should be a one way service call (KLI 1.0 had a isOneWay flag)
        legacyEndpoints.service({
            name: 'CreateDashboardReportJob',
            parameters: {
                siteId: o.siteId,
                sessionId: o.sessionId,
                settings: o.settings,
                title: o.title
            }
        });

    },
    getDashboards: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetDashboards',
            parameters: {
                idList: o.dashboards,
                siteId: o.siteId,
                settings: o.settings
            }
        }));     

    },
    saveDashboardChartImage: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'SaveDashboardChartImage',
            parameters: {
                siteId: o.siteId,
                chartId: o.chartId,
                sessionId: o.sessionId,
                base64: o.base64,
                format: o.format
            }
        }));     

    },
    validateSelections: () => {
            var competitiveInsightsPoints = _.filter(selections.getEntities(), function(item) { return item.metaData.competitiveInsights != null; });

            if (competitiveInsightsPoints.length === 0) {
                errorToast(translate('selections_error_no_point_selection'));
                return [];
            }

            if (rightPanel.getContent()?.key !== 'CIDashboards') {
                errorToast(translate('selections_error_no_active_dashboard'));
                return [];
            }  

            return competitiveInsightsPoints.map(selection => {
                return {id: selection.id, name: selection.text};
            });
    },    
    marketShareDashboard: () => {
        var list = competitiveInsights.validateSelections();
        ciDashboards?.showMarketShare(list);
	},
	customerProfileDashboard: () => {
        var list = competitiveInsights.validateSelections();
        ciDashboards?.showCustomerProfile(list);       
	},
	multiSiteDashboard: () => {
        var list = competitiveInsights.validateSelections();
        ciDashboards?.showMultiSite(list);	  
	},
	totalVisitsGeography: (entities) => {
        if (entities.length === 0) {
            errorToast(translate('selections_error_no_point_selection'));
            return;
        }

        var points = entities.map(x => x.id);
		competitiveInsights.showByGeography({ pointIds: points, percent: 0, type: constants.competitiveInsights.tradeAreas.totalVisitsByGeography});
	},
	householdPenetration: (entities) => {
        if (entities.length === 0) {
            errorToast(translate('selections_error_no_point_selection'));
            return;
        }

		var points = entities.map(x => x.id);
		competitiveInsights.showByGeography({ pointIds: points, percent: 0, type: constants.competitiveInsights.tradeAreas.householdPenetrationByGeography});
	},
	totalVisitsDesireLine: async (entities) => {        
        if (entities.length === 0) {
            errorToast(translate('selections_error_no_point_selection'));
            return;
        }

        var pointIds = entities.map(x => x.id);

        customerMaps.refresh({ 
            id: -1, 
            type: constants.customerDataRenderers.desireLine,
            name: translate('desire_line_of_visits'),
            pointId: pointIds, 
            isUber: false, 
            isDynamic: false,
            isTile: false,
            timeType: -1, 
            dataType: -1, 
            themeId: -1,
            outOfRange: false,
            isCompetitiveInsights: true
        });        
	},		
	showByGeography: async (o) => {
        var item = {
            setDefault: false,
            percent: 0,
            type: o.type,
            pointIds: o.pointIds,
            icon: icons.hexagon,
            await: true               
        }

        await competitiveInsights.showLayer(item);	
	},    
    getCompetitiveInsightsChannelsForUser: async function (o) {      
    
        return(await legacyEndpoints.service({
            name: 'GetCompetitiveInsightsChannelsForUser',
            parameters: {
                id: o.id
            }
        }));     

    }, 
    updateCompetitiveInsightsCustomChannel: async function(o) {
		var data = legacyEndpoints.service({
			name: o.id === helpers.emptyGuid() ? 'CreateCompetitiveInsightsCustomChannel' : 'UpdateCompetitiveInsightsCustomChannel', 
            parameters: {channel:o}});

        return data;
    },  
    getNewCompetitiveInsightsCustomChannel: () => {
        return {
            id: null,
            name: null,
            description: null
        }
    },         
};