// React imports
import { useCallback, useEffect, useState, useMemo } from 'react';

// App imports
import { app, userPreferences } from '../app';
import { Button } from '../../base/button/button';
import { Notifications } from '../notifications/notifications';
import { UserBadge } from '../../app/userBadge/userBadge';
import { constants } from '../../../utils/constants'
import { icons } from '../../base/icon/icon';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { translate } from '../../../utils/translation';
import { AutoCloser } from '../../base/autoCloser/autoCloser';
import { helpers } from '../../../utils/helpers';
import { map } from '../../app/map/map';
import { cosmetic } from '../../../modules/cosmetic';
import { sources } from '../../../modules/sources';
import { layers } from '../layers/layers';
import { Popover } from '../../base/popover/popover';
import { navigation } from '../navigation/navigation';

import { Autocomplete } from 'devextreme-react/autocomplete';
import { DataList } from '../../base/dataList/dataList';
import CustomStore from 'devextreme/data/custom_store';

var _ = require("lodash");

export var header;

export function Header({context, hideSearch, navigationLoaded}) {
	var userName = legacyEndpoints.authenticationToken.UserInformation.Name;
	var userEmail = legacyEndpoints.authenticationToken.UserInformation.Email;

	const userImage = legacyEndpoints.handlers.getPhotoUrl({ isUserPhoto: true, width: 150, height: 150 });
	const [activeTab, setActiveTab] = useState(0);
	const [activeUserBadge, setActiveUserBadge] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchLoading, setSearchLoading] = useState(false);
	const [availableSearches, setAvailableSearches] = useState(false);
	const [searchFilters, setSearchFilters] = useState([helpers.emptyGuid()]);
	const [activeNotifications, setActiveNotifications] = useState(false);
	const [activeAnnouncements, setActiveAnnouncements] = useState(false);
	const [notificationsNotViewed, setNotificationsNotViewed] = useState([]);
	const [announcementsNotViewed, setAnnouncementsNotViewed] = useState([]);
	const [notificationCategories, setNotificationCategories] = useState([]);
    const [activeNotificationCategories, setActiveNotificationCategories] = useState([]);
	const [lastSearchResults, setLastSearchResults] = useState({data: [], totalCount: 0 });

	const defaultIcons = useMemo(() => [
		{ icon: icons.message, type: constants.notifications.types.message },
		{ icon: icons.gear, type: constants.notifications.types.job },
		{ icon: icons.map, type: constants.notifications.types.map },
        { icon: icons.fileBarChart, type: constants.notifications.types.savedReport },
		{ icon: icons.task, type: constants.notifications.types.task },
		{ icon: icons.wifiSlash, type: constants.notifications.types.offlinePackage },
		{ icon: icons.braille, type: constants.notifications.types.geoFeeds },
		{ icon: icons.car, type: constants.notifications.types.siteTours },
		{ icon: icons.suitcase, type: constants.notifications.types.trip2Trade },
		{ icon: icons.folderArrowUp, type: constants.notifications.types.adHocDataSource }
	], []);

	header = {
        toggleMap: () => {
            setActiveTab(0);
            app.toggleMap();
        },
        toggleTasks: () => {
            setActiveTab(1);
            app.toggleTasks();
        },
        toggleDashboards: () =>{
            setActiveTab(2);
            app.toggleDashboards();
        }
	};

	useEffect(()=>{

		if (helpers.isViewer())
			return;

		legacyEndpoints.service({
			name: 'GetAvailableSearches',
			success: (result) =>{
				
				var poiSearch = result.find(x => x.Id === helpers.emptyGuid());
				var resultWithoutPoi = result.filter(x => x !== poiSearch);

				var grouped = [
				{
					key: `${translate("address_place_results")} (Google)`,
					items:[{ Id: helpers.emptyGuid(), Name: translate('poi'), Selected: poiSearch?.Selected }]
				},
				{
					key: translate('point_searches'),
					items: resultWithoutPoi.filter(x => x.Type === 0 && x.Category !== 'Ad Hoc Imports')
				},
				{
					key: translate('ad_hoc_point_searches'),
					items: resultWithoutPoi.filter(x => x.Type === 0 && x.Category === 'Ad Hoc Imports')
				},
				{
					key: translate('shape_searches'),
					items: resultWithoutPoi.filter(x => x.Type === 2 && x.Category !== 'Ad Hoc Imports')
				},
				{
					key: translate('ad_hoc_shape_searches'),
					items: resultWithoutPoi.filter(x => x.Type === 2 && x.Category === 'Ad Hoc Imports')
				}];

				var geographyCategories = [];

				resultWithoutPoi.filter(x => x.Type === 1).forEach(item =>{
					
					var geographyCategory = geographyCategories.find(x => x.key === item.Category);
					if (_.isUndefined(geographyCategory))
					{
						geographyCategory = {
							key: item.Category,
							items: []
						};
						geographyCategories.push(geographyCategory);	
					}

					geographyCategory.items.push(item);

				});

				grouped = grouped.concat(geographyCategories);

				grouped.forEach(group => { 
					group.items.forEach(item =>{
						item.searchText = `${item.Name} ${(item.HintText ? `${item.HintText}` : '')}` 
					});
				});

				setAvailableSearches(grouped);
				setSearchFilters(result.filter(x => x.Selected).map(item => {return item.Id; }));
			}
		});
	}, []);

	const getNotificationCategories = useCallback(
        async () => {

			if (helpers.isViewer())
				return;

			// TBD: create a simple service call to just get notification categories
            var result = await legacyEndpoints.service({
                name: 'GetAllFilteredNotificationsForSession',
                parameters: {
                    SessionKey: legacyEndpoints.authenticationToken.TokenInformation.SessionKey,
                    skip: 0,
                    take: 10,
                    isGlobal: true,
                    notificationTypes: []
                }
            });		

			// lookup the FA icon, if it doesn't exist, use the default icon
			var cats = result.categories.map(item => { 
				if (icons.hasOwnProperty(item.icon))
					return { ...item, icon: icons[item.icon] };
				else {
					const icon = defaultIcons.find(x => x.type === item.type)
					if (_.isObject(icon))
						return { ...item, icon: icon.icon };
					else 
						return { ...item, icon: icons.check };
				}
			});

			// TBD: not all notification types are supported yet, so filter by the ones that are
			// note: messages will only be displayed in announcements, so they should always be filtered out
			cats = _.sortBy(cats, "type").filter(x => x.type === constants.notifications.types.job 
													|| x.type === constants.notifications.types.map 
													|| x.type === constants.notifications.types.task 
													|| x.type === constants.notifications.types.adHocDataSource 
													|| x.type === constants.notifications.types.savedReport);
			setNotificationCategories(cats);
	        setActiveNotificationCategories(cats.map(item => item.type));

	    }, [defaultIcons]
    );

	useEffect(()=>{
		getNotificationCategories();
	}, [getNotificationCategories]);

	var updateNotifications = (result) =>{

		var newNotifications = [];
		var newAnnouncements = [];

		result.categories.forEach(category => { 
			// TBD: not all notification types are supported yet, so only count the ones that are
			if (category.type === constants.notifications.types.message 
				|| category.type === constants.notifications.types.job 
				|| category.type === constants.notifications.types.map 
				|| category.type === constants.notifications.types.task
				|| category.type === constants.notifications.types.adHocDataSource
				|| category.type === constants.notifications.types.savedReport) {

				if (_.isArray(category.items)) {
					var items = category.items.filter(item => !item.isViewed && !item.isGlobal);
					if (items.length > 0) {
						newNotifications = [ ...newNotifications, ...items.map(item => { return { 
							id: item.id,
							type: category.type
						}}) ];
					}

					items = category.items.filter(item => !item.isViewed && item.isGlobal);
					if (items.length > 0) {
						newAnnouncements = [ ...newAnnouncements, ...items.map(item => { return { 
							id: item.id,
							type: category.type
						}}) ];
					}
				}
			}
		});

		setNotificationsNotViewed(newNotifications);
		setAnnouncementsNotViewed(newAnnouncements);
    };

	useEffect(()=>{

		// can't subscribe until navigation component is loaded
		if (!navigationLoaded)
			return;

		// prevent updates while notifications are open so that the list doesn't suddenly update while scrolling
		if (activeNotifications || activeAnnouncements) {
			navigation.unsubscribeFromNotifications({id: '657e0996-d6ab-48fe-a89f-506b69c3582b'});
		}
		else {
			navigation.subscribeToNotifications({
				id: '657e0996-d6ab-48fe-a89f-506b69c3582b',
				action: async (result) =>{
					updateNotifications(result);
				}	
			});
		}

	}, [navigationLoaded, activeNotifications, activeAnnouncements]);

	var refreshNewNotifications = () =>{

        legacyEndpoints.service({
            name: 'GetNewNotificationsForSession',
            parameters: {
				SessionKey: legacyEndpoints.authenticationToken.TokenInformation.SessionKey
			},
			success: (result) => {
				updateNotifications(result);
				navigation.updateJobs();
			}
        });
    };
	
	const handleSearchQuery = useCallback(async (e) => {
		setSearchQuery(e.value);
	}, []);

	const zoomMap = useCallback((location, zoom) =>{		
		map.locate({ location: location, zoom: zoom});
	}, []);

	const store = new CustomStore({		
		load: async (o) => {

			if (o.searchExpr === undefined)
				return lastSearchResults;

			var addressResults = [];
			var queryResults = [];
			var shapeQueryResults = [];
			var geographyResults = [];

			var poiPromise = new Promise(async (resolve) => {
				if (searchFilters.filter(x => x === helpers.emptyGuid()).length > 0)
				{
					var addressData = await map.placeSearch({
						search: o.searchValue
					});				
					
					if (_.isArray(addressData) && addressData.length > 0)
					{
						addressResults = addressData.map(result => { return { text: result.description, type: 0, zoom: 16 } });	
						addressResults = addressResults.length > 0 ? [{ text: `${translate("address_place_results")} (Google)`, isGroup: true }, ...addressResults] : [];
					}
				}

				resolve();
			});			

			var dataPromise =new Promise(async (resolve) => {
				var data = {
					customQuerySearchResults: [],
					geographySearchResults: []	
				};
	
				if (searchFilters.filter(x => x !== helpers.emptyGuid()).length > 0)
					var data = await legacyEndpoints.service({
						name: 'UnifiedSearch',
						parameters: { 
							query: o.searchValue,
							searchFilters: searchFilters.filter(x => x !== helpers.emptyGuid()),
							skip: 0,
							take: 5
						}
					});
	
				queryResults = data.customQuerySearchResults.filter(x => x.QueryType == 0).map(result => { return { pointId: result.PointID, queryId: result.QueryID, text: result.Name, subText: result.SearchName, type: 1, zoom: result.ZoomLvl, location: { lat: result.Latitude, lon: result.Longitude } } });
				queryResults = queryResults.length > 0 ? [{ text: translate("point_results"), isGroup: true }, ...queryResults] : queryResults;
		
				shapeQueryResults = data.customQuerySearchResults.filter(x => x.QueryType == 1).map(result => { return { pointId: result.PointID, queryId: result.QueryID, text: result.Name, subText: result.SearchName, type: 2, zoom: result.ZoomLvl, location: { lat: result.Latitude, lon: result.Longitude } } });
				shapeQueryResults = shapeQueryResults.length > 0 ? [{ text: translate("shape_results"), isGroup: true }, ...shapeQueryResults] : shapeQueryResults;
		
				geographyResults = data.geographySearchResults.map(result => { return { text: result.Name, subText: result.SearchName, type: 3, zoom: result.Zoom, location: { lat: result.Latitude, lon: result.Longitude } } });
				geographyResults = geographyResults.length > 0 ? [{ text: translate("geography_results"), isGroup: true }, ...geographyResults] : geographyResults;

				resolve();
			});

			await Promise.all([poiPromise, dataPromise]);

			setSearchLoading(false);

			var items = [...addressResults, ...queryResults, ...shapeQueryResults, ...geographyResults];

			var results = {
				data: items,
				totalCount: items.length
			};

			setLastSearchResults(results);

			return results;
		}
	});

	const search = useCallback(({ query }) =>{
		map.geocode({
			query: query,
			callback: (result) => {
				zoomMap(result.location, 16);
				cosmetic.createPushpin({
					location: result.location,
					symbol: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultGeocodeSymbol })
				});
			}
		});
	}, []);

	var headerItems = [
		{ text: translate('map'), onClick: () =>{app.toggleMap();} },
		{ text: translate('tasks'), onClick: () =>{app.toggleTasks();} },
		{ text: translate('dashboards'), onClick: () =>{app.toggleDashboards();}, disabled: !userPreferences.AllowDashboards}			
	];

	if (helpers.isViewer())
		headerItems = [];
	
	return <>		
		<div className='app-hdr'>
			<table className='app-hdr-table' >
				<tbody>
					<tr>
						<td style={{width: '120px'}}>
							<img src='https://cdn.tradeareasystems.net/Images/Rebrand/KalibrateHeader.png' alt={constants.company} /> {constants.company}	
						</td>
						{
							helpers.isViewer() ? null :
							<td style={{width: '300px'}}>
							{
								headerItems.map((tab, i) =>{
									return <Button key={i}
										theme='header'
										icon={tab.icon}
										text={tab.text} 
										onClick={()=>{ 
	
											setActiveTab(i);
	
											if (tab.onClick)
												tab.onClick();
	
										}} 
										active={activeTab===i} 
										disabled={tab.disabled} 
									/>
								})
							}
							</td>	
						}
											
						<td className='app-hdr-search-container-td'>
							{
								helpers.isViewer() ? null : 
								<>
									<Popover 
										parentId='#app-header-search-filter'
										title={translate('filter')}
										height={400}
										width={450}
									>
										<DataList
											keyExpr={'Id'}
											grouped={true}
											dataSource={availableSearches}
											searchExpr='searchText'
											height={'auto'}
											itemRender={(item) =>{
												return <>
													{item.Name} 
													<span className='app-header-search-subtext'>{(item.HintText ? ` (eg. ${item.HintText})` : '')}</span>
												</>
											}}
											selectedItemsChange={(o)=> {

												legacyEndpoints.service({
													name: 'SaveUnifiedSearchFilters',
													parameters: {
														ids: o
													}
												});

												setSearchFilters(o);
											}}
											selectByClick={true}
											selectedItems={searchFilters}
										/>
									</Popover>
									<Button id='app-header-search-filter' className='app-hdr-search-filter' theme='primary' size='small' icon={icons.filter} />
								</>
							}
							{
								hideSearch ? null : <>
									<Button className='app-hdr-search-button' theme='primary' size='small' icon={searchLoading ? icons.spinner : icons.magnifyingGlass} onClick={() =>{
										search({ query: searchQuery });
									}} />
									<div className='app-hdr-search-container'>
										<div className='app-text-box app-hdr-search-input'>
											<Autocomplete
												dataSource={store}
												value={searchQuery}
												valueExpr="text"
												minSearchLength={2}
												height='30px'
												placeholder={translate('unified_search_hint')}
												onValueChanged={handleSearchQuery}
												hoverStateEnabled={false}
												onEnterKey={(e)=>{
													search({ query: e.component.option('text') });											
												}}
												itemRender={(item) => {											
													return item.isGroup ? <b>{item.text}</b> :<>
														{item.text} 
														<span className='app-header-search-subtext'>{(item.subText? ` (${item.subText})` : '')}</span>
													</>
												}}
												onItemClick={async (o) => {
													if (_.isUndefined(o.itemData) || _.isNull(o.itemData))
														return;

													switch(o.itemData.type)
													{
														case 0:
															search({ query: o.itemData.text });
															break;
														case 1:
															zoomMap(o.itemData.location, o.itemData.zoom);
															
															sources.refresh({
																layers: [{
																	id: o.itemData.queryId,
																	text: translate('loading'),
																	subType: 0,
																	data: {isCompetitiveInsights: false}
																}],
																openActivityHub:{
																	layerId: o.itemData.queryId,
																	entityId: o.itemData.pointId
																},
																onRefresh: (o) =>{
																	layers.refreshDataLayers(o);
																}
															});
															
															break;
														case 2:
															zoomMap(o.itemData.location, o.itemData.zoom);
															
															sources.refresh({
																layers: [{
																	id: o.itemData.queryId,
																	text: translate('loading'),
																	subType: 1,
																	data: {isCompetitiveInsights: false}
																}],
																openActivityHub:{
																	layerId: o.itemData.queryId,
																	entityId: o.itemData.pointId
																},
																onRefresh: (o) =>{
																	layers.refreshDataLayers(o);
																}
															});
															
															break;
														case 3:
															zoomMap(o.itemData.location, o.itemData.zoom);
															break;
													}	
												}}
											/>
										</div>
									</div>	
								</>
							}													
						</td>
						{
							helpers.isViewer() ? null :
								<td style={{width: '285px'}}>
									<Button theme='header' icon={icons.bell} tooltip={translate('notifications')} 
										onClick={(e)=>{
											e.stopPropagation();
											setActiveNotifications(!activeNotifications);								
											if (!activeNotifications) {
												setActiveAnnouncements(false);
												setActiveUserBadge(false);
											}
										}}>
									{
										notificationsNotViewed.length > 0 ? <div className='app-notification-button-badge' tooltip={translate('notifications')}>{notificationsNotViewed.length}</div> : ''
									}
									</Button>
									<Button theme='header' icon={icons.announce} tooltip={translate('announcements')}
										onClick={(e)=>{
											e.stopPropagation();
											setActiveAnnouncements(!activeAnnouncements);								
											if (!activeAnnouncements) {
												setActiveNotifications(false);
												setActiveUserBadge(false);
											}
										}}>
									{
										announcementsNotViewed.length > 0 ? <div className='app-notification-button-badge' tooltip={translate('notifications')}>{announcementsNotViewed.length}</div> : ''
									}
									</Button>
									<Button theme='header' icon={icons.gear} tooltip={translate('management_studio')} onClick={ ()=>{helpers.navigateToUrl('https://admin.tasonline.com');}}/>
									<Button theme='header' icon={icons.headset} tooltip={translate('support')} onClick={ ()=>{
										helpers.navigateToZendeskSupport({
											userName: userName, 
											userEmail: userEmail,
											product: "default",
											supportPage: "categories/17541597732109-Online-2-0"
										});
									}}/>
									<Button theme='header' icon={icons.comments} tooltip={translate('feedback')} onClick={ ()=>{
										helpers.navigateToZendeskSupport({
											userName: userName, 
											userEmail: userEmail,
											product: "default",
											supportPage: "community/topics/19605713933965-KLI-2-0-Beta-Feedback"
										});
									}}/>
									<Button id='app-header-user-badge' imageClassName='app-hdr-account-image' theme='header' image={userImage} onClick={(e)=>{
										e.stopPropagation();
										setActiveUserBadge(!activeUserBadge);								
										if (!activeUserBadge) {
											setActiveNotifications(false);
											setActiveAnnouncements(false);
										}
									}} />
								</td>
						}
						
					</tr>
				</tbody>
			</table>
		</div>
		{activeNotifications ?
			<Notifications 
				title={translate("notifications")} 
				categories={notificationCategories} 
				activeCategories={activeNotificationCategories}
				updateActiveCategories={(cats)=>{ setActiveNotificationCategories(cats); }}
				noneAvailable={translate('no_notifications_available')} 
				isGlobal={false} 
				isNewExists={notificationsNotViewed.length > 0} 
				refreshNewNotifications={refreshNewNotifications} 
				onClose={()=>{ setActiveNotifications(false); }} 
			/> 
		: ''}
		{activeAnnouncements ?
			<Notifications 
				title={translate("announcements")} 
				categories={notificationCategories} 
				activeCategories={[constants.notifications.types.message]}
				noneAvailable={translate('no_announcements_available')} 
				isGlobal={true} 
				isNewExists={announcementsNotViewed.length > 0} 
				refreshNewNotifications={refreshNewNotifications} 
				onClose={()=>{ setActiveAnnouncements(false); }} 
			/> 
		: ''}
		{activeUserBadge ?
			<AutoCloser onClose={() => { setActiveUserBadge(false); }}>
				<UserBadge userName={userName} userEmail={userEmail} userImage={userImage} context={context} onClose={()=>{ setActiveUserBadge(false); }} /> 
			</AutoCloser>			
		: ''}
	</>;
}