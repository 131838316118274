// Third party imports
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';

// React imports
import React,{ useState, useEffect, useCallback, useRef, Fragment } from 'react';

// App imports
import { LargePanel } from '../../../base/largePanel/largePanel';
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { TextBox } from '../../../base/textBox/textBox';
import { icons, Icon } from '../../../base/icon/icon';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { translate } from "../../../../utils/translation";
import { Button } from '../../../base/button/button';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { Tabs } from '../../../base/tabs/tabs';
import { userPreferences } from '../../app';
import { projects as projectsModule } from '../../../../modules/projects';
import { ModalCard } from '../../../base/modalCard/modalCard';
import { MapUsage } from './mapUsage';
import { Popover } from '../../../base/popover/popover';
import { FolderEditForm } from './folderEditForm';
import { successToast, errorToast } from '../../../base/toast/toast';
import { helpers } from '../../../../utils/helpers';
import { DraggableItemBasic } from '../../../base/dragAndDrop/draggableItemBasic';
import { SharedUsers } from '../../sharedUsers/sharedUsers';
import { constants } from '../../../../utils/constants';
import { DropDown } from '../../../base/dropDown/dropDown';
import { MenuButton } from '../../../base/menuButton/menuButton';

const _ = require('lodash');

export var availableMaps = {};

export function AvailableMaps({ text, activeTab = 0, updateActiveTab, activeMap, onClose }) {

    const [myMaps, setMyMaps] = useState([]);
    const [sharedMaps, setSharedMaps] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [titleFilter, setTitleFilter] = useState('');
    const [defaultMap, setDefaultMap] = useState(null);
    const [showMapUsage, setShowMapUsage] = useState(false);
    const [showSharedUsers, setShowSharedUsers] = useState({show: false, mapId: null});
    const [mapId, setMapId] = useState(null);
    const [newFolder, setNewFolder] = useState({ id: helpers.emptyGuid(), text: '' });
    const [goToTab, setGoToTab] = useState(-1);
    const [sortType, setSortType] = useState(1);

    const newFolderWindow = useRef(null);
    const editFolderWindows = useRef([]); 

    const tabType = { savedMaps: 1, sharedMaps: 2 };
    const enumSortType = { date: 1, name: 2 };

    var getStructuredMapDataForDisplay = async (data) => {
        var sources = [];
        data.forEach((folder) => {
            var group = {
                id: folder.Id,
                text: folder.Name,
                isDefault: folder.IsDefault,
                sources: []
            };

            folder.Maps.forEach(map => {
                var child = {
                    id: map.Id,
                    parentId: folder.Id,
                    text: map.Name,
                    type: map.Type,
                    creator: map.Creator,
                    lastModified: map.LastModified,
                    isPublished: map.IsPublished
                };
                group.sources.push(child);
            });

            if (sortType === enumSortType.date)
                _.sortBy(group.sources, (source) => { return source.lastModified }).reverse();
            else if (sortType === enumSortType.name)
                _.sortBy(group.sources, (source) => { return source.text; });

            sources.push(group);
        });
        return sources;
    }

    var getUsersMaps = useCallback(
        async () => {

            if (activeTab === goToTab)
                return;

            setDefaultMap(userPreferences.DefaultMap);

            setLoaded(false);

            const maps = await projectsModule.getMaps();
            var mapData = await getStructuredMapDataForDisplay(maps);
            setMyMaps([...mapData]);

            editFolderWindows.current = Array(mapData?.length).fill().map((_, i) => editFolderWindows.current[i] || React.createRef())

            const sharedMaps = await projectsModule.getSharedMaps();
            var sharedMapData = await getStructuredMapDataForDisplay(sharedMaps);
            setSharedMaps([...sharedMapData]);

            setGoToTab(activeTab);
            setLoaded(true);
        },
        [activeTab]
    );

    useEffect(() => {
        getUsersMaps();
    }, [getUsersMaps]);

    useEffect(() => {
        let myMapsToSort = _.cloneDeep(myMaps);
        let sharedMapsToSort = _.cloneDeep(sharedMaps);
        //Sort Saved maps
        if (sortType === enumSortType.date){
            _.forEach(myMapsToSort, (group) => {
                group.sources = _.sortBy(group.sources, (source) => { return source.lastModified; }).reverse();
            });

            _.forEach(sharedMapsToSort, (group) => {
                group.sources = _.sortBy(group.sources, (source) => { return source.lastModified; }).reverse();
            });
        }           
        else if (sortType === enumSortType.name) {
            _.forEach(myMapsToSort, (group) => {
                group.sources = _.sortBy(group.sources, (source) => { return source.text; });
            });

            _.forEach(sharedMapsToSort, (group) => {
                group.sources = _.sortBy(group.sources, (source) => { return source.text; });
            });
        }

        setMyMaps(myMapsToSort);
        setSharedMaps(sharedMapsToSort);
    }
    , [sortType]);

    const close = () => {
        onClose();
    };

    const loadMap = async (id) => {
        if (id) {
            setGenerating(true);
            await projectsModule.loadMap({ mapId: id });
            close();
            setGenerating(false);
        }
    };

    const shareMap = (id) => {
        if (id) {
            setShowSharedUsers({show: true, mapId: id});
        }
    };

    availableMaps.publishMap = async ({id, publish}) => {

        myMaps.forEach(folder =>{
            folder.sources.forEach(source => {
                if (source.id === id)
                    source.isPublished = publish;
            });
        });

        setMyMaps([...myMaps]);

        projectsModule.publishMap({ id: id, publish: publish });
        
        if (publish)
            projectsModule.generatePublishMapLink(id);
    };

    const deleteMap = async (id) => {
        if (id) {
            const success = await projectsModule.deleteMap({ id: id });

            if (success) {
                setMyMaps(prevMaps => {
                    return prevMaps.map(parent => {
                        return {
                            ...parent,
                            sources: parent.sources.filter(child => child.id !== id)
                        };
                    });
                });    
            }
        }
    };

    const getMapElement = (item, selectedTab) => {        
        return <div key={item.id} className={`app-projects-available-maps-list-item`}>
            {item.id === activeMap ? 
            <div className='app-option-selector-check'>
                <Icon icon={icons.check} />
            </div> : ''}
            <div>
                <img className='app-projects-available-maps-map' src={legacyEndpoints.handlers.getPhotoUrl({ id: item.id, isMap: true, width: 200, height: 190, preventCache: true })} alt={translate('map_image')} />
                <div className='app-projects-available-maps-actions'>
                    {
                        selectedTab === tabType.sharedMaps ? '' :
                            <DraggableItemBasic key={item.id} id={item.id} />
                    }
                    <div className='app-projects-available-maps-actions-right-buttons'> 
                        <Button theme='simple' icon={icons.star}
                            active={defaultMap === item.id}
                            className={'app-map-control-current-map-default'} tooltip={translate('default_map')}
                            onClick={
                                async () => {
                                    const isDefault = !(item.id === userPreferences.DefaultMap)
                                    const result = projectsModule.updateDefaultMap({ mapId: item.id, isDefault: isDefault });
                                    if (result)
                                        setDefaultMap(isDefault ? item.id : null);
                                }
                            }
                        />
                        <Button theme='simple' icon={icons.folderOpen} tooltip={translate('open_map')} onClick={() => { loadMap(item.id); }} />
                        { 
                            selectedTab === tabType.sharedMaps ? '' : 
                            <div>
                                <Button theme='simple' icon={icons.shareAlt} tooltip={translate('share')} onClick={() => { shareMap(item.id); }} />
                                {userPreferences.HasPublisher ? 
                                    item.isPublished ? 
                                    <MenuButton active={item.isPublished} theme='simple' icon={icons.towerBroadcast} tooltip={translate('publish')} items={[
                                        {text: translate('unpublish'), onClick: () => { availableMaps.publishMap({ id: item.id, publish: false }); }},
                                        {text: translate('generate_link'), onClick: () => { projectsModule.generatePublishMapLink(item.id); }},
                                    ]} />
                                    :
                                    <Button active={item.isPublished} theme='simple' icon={icons.towerBroadcast} tooltip={translate('publish')} onClick={() => { 
                                            availableMaps.publishMap({ id: item.id, publish: true });                                             
                                        }} 
                                    /> 
                                : null}
                                <Button theme='simple' icon={icons.rectanglesMixed} tooltip={translate('map_usage')}
                                    onClick={async () => {
                                        setMapId(item.id);
                                        setShowMapUsage(true);
                                    }}
                                />
                                <ConfirmButton theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={() => { deleteMap(item.id); }} />
                            </div>
                        }
                    </div>                 
                </div>
            </div>
        </div>

    };

    const getDragMapElement = (item) => {
        return _.isObject(item) ? <div key={item.id} >
                <div className='app-projects-available-maps-drag-item'>
                    <img className='' src={legacyEndpoints.handlers.getPhotoUrl({ id: item.id, isMap: true, width: 200, height: 190, preventCache: true })} alt={translate('map_image')} />
                </div>
            </div>
        : '';
    };

    const getDeleteFolderAction = (o, index) => {
        return {
            id: index,
            icon: icons.trash,
            active: false,
            tooltip: translate('delete'),
            disabled: o.isDefault,
            onClick: async (e) => {
                e.stopPropagation();

                //todo replace with confirm dialog in CollapsibleSection component
                if (window.confirm(translate('are_you_sure_delete_map_folder'))) {
                    await projectsModule.deleteMapFolder({ folderId: o.id });

                    setMyMaps(prevMaps => {
                        const newMaps = prevMaps.filter(map => map.id !== o.id);
                        // Only update state if there is a change
                        if (newMaps.length !== prevMaps.length) {
                            return newMaps;
                        }
                        return prevMaps;
                    });

                    removeEditFolderWindowRef(index);                   
                }
            }
        }
    };

    const getEditFolderAction = (o, id) => {
        return {
            id: id,
            icon: icons.edit,
            active: false,
            tooltip: translate('edit'),
            disabled: o.isDefault,
            onClick: async (e) => {
                e.stopPropagation();
            }
        }
    };

    const addEditFolderWindowRef = () => {
        editFolderWindows.current.push(React.createRef());
    };

    const removeEditFolderWindowRef = (index) => {
        editFolderWindows.current.splice(index, 1);
    };    

    const updateFolder = async (o) => {
        var success = false;

        if (o.id === helpers.emptyGuid()) {
            const result = await projectsModule.createMapFolder({ name: o.text });

            if (result != null) {
                success = true;
                setMyMaps([...myMaps, { id: result, text: o.text, isDefault: false, sources: [] }]);
                addEditFolderWindowRef();
            }

        }
        else {
            const result = await projectsModule.updateMapFolder({ folderId: o.id, folderName: o.text });

            if (result) {
                success = true;
                setMyMaps(myMaps.map(folder => {
                    if (folder.id === o.id) {
                        return { ...folder, text: o.text };
                    } else {
                        return folder;
                    }
                }));
            }
        }

        if (success)
            successToast(translate('success'));
        else
            errorToast(translate('an_error_has_occurred'));
    };

    // Drag and drop
    const [activeItem, setActiveItem] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
    );

    const getDragItemObject = (id) => {
        return myMaps.flatMap(x => x.sources).find(i => i.id === id);
    };

    const handleDragStart = (event) => {
        const { active } = event;
        const dragItem = getDragItemObject(active.id);
        setActiveItem(_.cloneDeep(dragItem));
    }

    const handleDragEnd = (event) => {
        const { active, over } = event;

        const dragEndItem = myMaps.find((item) => item?.id === over?.id);

        if (dragEndItem != null) {
            const dragItem = getDragItemObject(active.id);
            
            if (dragItem.parentId == dragEndItem.id) {
                // ignore if dragging to same folder
            }
            else {            
                if (dragItem != null) {
                    setMyMaps(prev => prev.map(item => {
                        if (item.sources.some(child => child.id === dragItem.id)) {
                            return {
                                ...item,
                                sources: item.sources.filter(child => child.id !== dragItem.id)
                            };
                        }
                        return item;
                    }));

                    dragItem.parentId = dragEndItem.id;
                    setMyMaps(prev => prev.map(item => {
                        if (item.id === dragEndItem.id) {
                            return {
                                ...item,
                                sources: [...item.sources, dragItem]
                            };
                        }
                        return item;
                    }));

                    // save
                    const result = projectsModule.moveMapToFolder({ folderId: dragEndItem.id, mapId: dragItem.id });
                    if (result) 
                        successToast(translate('success'));
                }
            }

        }
        setActiveItem(null);
    }

    const getMapNameAndDate = (o) => {
        return <>
            <div>
                {o.text}
            </div>
            <div className='app-projects-available-maps-date'>
                {helpers.formatDate({ date: o.lastModified })}
            </div>
        </>        
    };    

    const getTabBodyElement = (mapData, selectedTab) => {        
        return <>
            {
                selectedTab === tabType.sharedMaps ? '' :
                    <div className={'app-projects-available-maps-add-folder'} >
                        <Button id='app-available-maps-add-folder' theme='primary' size='small' icon={icons.folderPlus} tooltip={translate('add_folder')}
                            onClick={() => { setNewFolder({ id: helpers.emptyGuid(), text: '' }); }}
                        />
                    </div>
            }
            <div className='app-projects-available-maps-body'>
                <SelectableItems
                    items={mapData}
                    showTags={false}
                    selectedItems={[]}
                    onRender={(o) => {
                        return <>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                            >
                                {
                                    o.items.map((group, i) => {
                                        return <div key={i}>
                                            <Popover
                                                ref={editFolderWindows.current[i]}
                                                parentId={`#app-available-maps-edit-folder_${group.id}`}
                                                title={translate('edit_folder')}
                                                height={210}
                                                width={450}
                                            >
                                                <FolderEditForm
                                                    group={group}
                                                    onUpdateGroup={(value) => { updateFolder(value); }}
                                                    onClose={() => {
                                                        editFolderWindows.current[i].current.instance.hide();
                                                    }}
                                                />
                                            </Popover>                                          
                                            <CollapsibleSection
                                                id={group.id}
                                                text={`${group.text} ${group.sources.length > 0 && selectedTab !== tabType.sharedMaps  ? group.sources[0].creator : ''}`}
                                                count={group.sources.length}
                                                expanded={group.isDefault}
                                                selectedCount={group.sources.filter(x => x.selected).length}
                                                actions={!group.isDefault ? [getDeleteFolderAction(group, i), getEditFolderAction(group, `app-available-maps-edit-folder_${group.id}`)] : ''}
                                            >
                                                {   
                                                    group.sources.filter(p => p.text.toLowerCase().includes(titleFilter.toLowerCase()) || titleFilter === '').map((item, j) => {
                                                        return <Fragment key={j}>
                                                                <OptionSelector
                                                                    text={getMapNameAndDate(item)}
                                                                    active={item.selected}
                                                                    onDoubleClick={() => { loadMap(item.id); }}
                                                                    content={getMapElement(item, selectedTab)}
                                                                />
                                                                </Fragment>
                                                    })
                                                }
                                            </CollapsibleSection>
                                        </div>

                                    })
                                }
                                <DragOverlay dropAnimation={null}>
                                    <OptionSelector
                                        text={activeItem?.text}
                                        content={getDragMapElement(activeItem)}
                                    />
                                </DragOverlay>
                            </DndContext>
                        </>
                    }}
                    hideFooter={true}
                />
            </div>
        </>
    };

    const setGoToTabByid = async (id) => {

        switch (id) {
            case tabType.savedMaps:
                setGoToTab(0);
                updateActiveTab({ tab: 0 });
                break;
            case tabType.sharedMaps:
                setGoToTab(1);
                updateActiveTab({ tab: 1 });
                break;
            default:
                break;
        }  
    }

    const tabs = [
        {
            id: tabType.savedMaps,
            text: translate('saved_maps'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.save.icon[0]} ${icons.save.icon[1]}"><path fill="currentColor" d="${icons.save.icon[4]}"></svg>`,
            component: getTabBodyElement(myMaps, tabType.savedMaps)
        },
        {
            id: tabType.sharedMaps,
            text: translate('shared_maps'),
            icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.share.icon[0]} ${icons.share.icon[1]}"><path fill="currentColor" d="${icons.share.icon[4]}"></svg>`,
            component: getTabBodyElement(sharedMaps, tabType.sharedMaps)
        },
    ];

    return <LargePanel text={text} loaded={loaded} generating={generating} onClose={() => { close(); }}>
        <Popover
            ref={newFolderWindow}
            parentId='#app-available-maps-add-folder'
            title={translate('add_folder')}
            height={210}
            width={450}
        >
            <FolderEditForm
                group={newFolder}
                onUpdateGroup={(value) => { updateFolder(value); }}
                onClose={() => {
                    newFolderWindow.current.instance.hide();
                }}
            />
        </Popover>
        <ModalCard className='app-projects-available-maps-modal-card-default' loaded={true} open={showMapUsage}>
            <MapUsage mapId={mapId} onClose={() => { setShowMapUsage(false); }} />
        </ModalCard>
        {
            !showSharedUsers.show ? '' :
            <ModalCard className='app-shared-users-modal-card' loaded={true} open={showSharedUsers.show}>
                <SharedUsers objectId={showSharedUsers.mapId} sharingObjectType={constants.sharingObjectType.map} onClose={() => { setShowSharedUsers({show: false, mapId: null}); }} />
            </ModalCard>        
        }
        <div className='app-projects-available-maps-header'>
            <div className='app-projects-available-maps-search'>
                <TextBox className='app-projects-available-maps-search-input' value={titleFilter} valueChangeEvent='keyup' onChange={e => setTitleFilter(e.value)}
                    actions={[{
                        icon: titleFilter.length > 0 ? '<svg viewBox="0 0 512 512"><path d="' + icons.circleX.icon[4] + '"/></svg>' :
                            '<svg viewBox="0 0 512 512"><path d="' + icons.magnifyingGlass.icon[4] + '"/></svg>',
                        onClick: () => { setTitleFilter(''); },
                    }]}
                />
            </div>
            <DropDown className='app-collapsible-section-sort-label' label={translate('sort_by')} items={[{key: enumSortType.date, name: translate("last_modified")},{key: enumSortType.name, name: translate("name")}]} selected={sortType} onSelect={(o) => {setSortType(o.value.key)}} valueProperty={"key"} display={"name"} />
        </div>
        <Tabs tabs={tabs} selectedTab={goToTab} onTabClick={id => setGoToTabByid(id)} />
    </LargePanel>
}