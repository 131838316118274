// App imports
import { map } from '../components/app/map/map';
import { constants } from '../utils/constants';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { layerActions } from '../components/app/layers/layer/layer';
import { translate } from '../utils/translation';
import { icons } from '../components/base/icon/icon';
import { layers } from '../components/app/layers/layers';
import { helpers } from '../utils/helpers';
import { inputBoxTypes, TextBox } from '../components/base/textBox/textBox';
import { Button } from '../components/base/button/button';

const _ = require("lodash");

export const customerMaps = {
    get: async () =>{
		
		const mapTypes = await legacyEndpoints.service({ name: 'GetCustomerMapsForUser' });

		const groupid = helpers.newGuid();

		var maps = mapTypes.map(mapType => {
			return {
				"Id": mapType.MapID,
				"Name": mapType.Name,
				"Type": mapType.Type,
				"SubType": 0,
				"Description": mapType.Name,
				"IsCustomerMap": true,
				"IsDynamic": true,
				"IsTile": mapType.IsTile,
				"ApplicationSymbology": {
					"Id": groupid,
					"Type": 2,
					"IsDefault": false,
					"Title": translate('bulk_customer_maps'),
					"Description": "",
					"TASOnline": "https://services.tradeareasystems.net/REST/v2.0/Handlers/Files.ashx?Id=e8b8e779-2cec-43e8-a1b7-1900be022105&IsFolderImage=true&Type=1",
					"TASMobile": "https://services.tradeareasystems.net/REST/v2.0/Handlers/Files.ashx?Id=e8b8e779-2cec-43e8-a1b7-1900be022105&IsFolderImage=true&Type=2",
					"TASAnalyst": ""
				}
			}
		});

        return maps;
    },
	getLayers: () =>{
		return map.layers.filter(layer => layer.group === constants.layers.groups.customerMap);
	},
    getSelectedIds: () =>{
        return customerMaps.getLayers().map(layer => {return layer.id;});
    },
	getActiveLayers: () =>{
		return customerMaps.getLayers().filter(layer => layer.active);
	},
	getMapType: (o) =>{
		switch (o.type)
		{
			default:
			case constants.customerDataRenderers.pin:
				return constants.layers.types.customerMaps.pin;
			case constants.customerDataRenderers.range:
				return constants.layers.types.customerMaps.marketShare;
			case constants.customerDataRenderers.desireLine:
				return constants.layers.types.customerMaps.desireLine;
			case constants.customerDataRenderers.heat:
				return constants.layers.types.customerMaps.heat;
		}
	},
	getRendererType: (o) =>{
		switch (o.type)
		{
			default:
			case constants.layers.types.customerMaps.pin:
				return constants.customerDataRenderers.pin;
			case constants.layers.types.customerMaps.marketShare:
				return constants.customerDataRenderers.range;
			case constants.layers.types.customerMaps.desireLine:
				return constants.customerDataRenderers.desireLine;
			case constants.layers.types.customerMaps.heat:
				return constants.customerDataRenderers.heat;
		}
	},
    getLayerName: (o) =>{

		// TBD: KLI 1.0 handles market share BG (100) and market share zip (101) - do we need them in 2.0?
        switch(o.type) {
            case constants.customerDataRenderers.pin:
                return `${translate('customer')} ${translate('pin_map')}`;
            case constants.customerDataRenderers.range:
            case 100:
            case 101:
                return `${translate('marketshare')}`;
            case constants.customerDataRenderers.desireLine:
                return `${translate('store_sales_volume')}`;
            case constants.customerDataRenderers.heat:
                return `${translate('customer')} ${translate('heat_map')}`;
            default:
                return null;
        }
    },
	getCustomerMapIcon: (type) =>{
		switch (type)
		{
			default:
				return icons.peopleGroup;
			case constants.layers.types.customerMaps.pin:
				return icons.braille;
			case constants.layers.types.customerMaps.marketShare:		
				return icons.grid;
			case constants.layers.types.customerMaps.desireLine:		
				return icons.bars;
			case constants.layers.types.customerMaps.heat:		
				return icons.fire;
		}
	},
    filterVisiblePoints: (o) =>{

        var type = customerMaps.getMapType({ type: o.type });
        var id = `${type}_${o.id}`;

        var layer = customerMaps.getLayers().find(layer => { return layer.id.toLowerCase() === id.toLowerCase() });
        
		var pointIds = [...o.pointIds];

		if (_.isObject(layer)) {
            layer.metaData.points = [];

			if (layer.oppositeVisibilityEntities.length > 0)			
				pointIds = pointIds.filter(id => !layer.oppositeVisibilityEntities.includes(id));
		}

		return pointIds;

    },
	getCustomerPinToolbar: (layer, id, newValue, ignoreOnChange) =>{
		return [{
			actions: [
				<TextBox 
					className={'app-legend-layer-toolbar-input'} 
					height={'24px'} 
					value={ignoreOnChange ? newValue : layer.metaData.pinPointCount}
					type={inputBoxTypes.numeric}
					allowNegative={false}
					allowDecimals={false}
					allowZero={false}
					allowLeadingZeros={false}
					onChange={(options) => { 
						if (options.userChanged && !ignoreOnChange) {

							// When processing a user entered value, replace the current toolbar so it doesn't fire an update
							// and revert the layer back to the old pin point count. We could just set toolbars to an empty array,
							// but by creating this "dummy" toolbar, the UI doesn't flicker and we display the new value.
							layer.toolbars = customerMaps.getCustomerPinToolbar(layer, id, options.value, true);
							
							customerMaps.refresh({ 
								id: id, 
								type: constants.customerDataRenderers.pin,
								name: layer.text,
								pointId: [], 
								isUber: layer.metaData.isUber, 
								isDynamic: layer.metaData.isDynamic,
								isTile: layer.metaData.isTile,
								timeType: layer.metaData.timeType, 
								dataType: layer.metaData.dataType, 
								themeId: layer.metaData.themeId,
								pinPointCount: options.value,
								outOfRange: map.zoom < 10
							});
						}
					}} 
				/>,
				<Button className='app-legend-layer-toolbar-action' theme='simple' icon={icons.clockRotateLeft} tooltip={translate('reset')} 
					onClick={()=>{
						customerMaps.refresh({ 
							id: id, 
							type: constants.customerDataRenderers.pin,
							name: layer.text,
							pointId: [], 
							isUber: layer.metaData.isUber, 
							isDynamic: layer.metaData.isDynamic,
							isTile: layer.metaData.isTile,
							timeType: layer.metaData.timeType, 
							dataType: layer.metaData.dataType, 
							themeId: layer.metaData.themeId,
							pinPointCount: layer.metaData.defaultPinPointCount,
							outOfRange: map.zoom < 10
						});
					}} 
				/>
			]
		}];
	},
    refreshDynamic: async (o) =>{

		var refreshLayers = o.layer ? [o.layer] : customerMaps.getActiveLayers().filter(activeLayer => activeLayer.metaData.isDynamic === true).map((layer) => {
            return{
                id: parseInt(layer.id.replace(`${layer.type}_`, '').replace('_bulk', '')),
				type: customerMaps.getRendererType({ type: layer.type }),
				data: {
					isDynamic: layer.metaData.isDynamic,
					isTile: layer.metaData.isTile,
					pinPointCount: layer.metaData.pinPointCount
				}
            };
        });

        if (refreshLayers.length === 0)
            return;

		refreshLayers.forEach(layer =>{

			legacyEndpoints.service({
				name: 'GetClosestPointsForCustomerMap',
				parameters: {
					aLeft: map.bounds.northWest.lon,
					aRight: map.bounds.southEast.lon,
					aTop: map.bounds.northWest.lat,
					aBottom: map.bounds.southEast.lat,
					aMapID: layer.id,
					aIsTile: layer.data.isTile,
					aMaxResults: 10
				},
				success: (results) => {
		
					const id = `${layer.id}_bulk`;

					// TBD: use layer text or set a name based upon layer type?
					//const name = customerMaps.getLayerName({ type: layer.type });
					const name = o.layer ? o.layer.text : '';

					var pointIds = results.map(point => {
						return point.ID;
					});

					pointIds = customerMaps.filterVisiblePoints({ id: id, type: layer.type, pointIds: pointIds });
		
					customerMaps.refresh({ 
						id: id, 
						type: layer.type,
						name: name,
						pointId: pointIds, 
						isUber: false, 
						isDynamic: layer.data.isDynamic,
						isTile: layer.data.isTile,
						timeType: -1, 
						dataType: -1, 
						themeId: layer.id,
						pinPointCount: layer.data.pinPointCount,
						outOfRange: layer.type !== constants.customerDataRenderers.heat && map.zoom < 10	// TBD: handle heat map out of range
					});
				}
			});
		});
	},
	refresh: async (o) =>{

		var type = customerMaps.getMapType({ type: o.type });
		var id = `${type}_${o.id}`;
		const isCompetitiveInsights = o.isCompetitiveInsights != null ? o.isCompetitiveInsights : false
		var pinPointCount = o.pinPointCount != null ? o.pinPointCount : -1

		var layer = customerMaps.getLayers().find(layer => { return layer.id.toLowerCase() === id.toLowerCase() });

		if (_.isObject(layer)) {
			if (_.isArray(o.pointId)) {
				layer.metaData.points = [...layer.metaData.points, ...o.pointId];
			}
			else {
				var index = layer.metaData.points.indexOf(o.pointId);
				if (index === -1) {
					layer.metaData.points.push(o.pointId);
				}
			}

			if (!_.isUndefined(o.customQueryId)) {
				index = layer.metaData.customQueryList.indexOf(o.customQueryId);
				if (index === -1) {
					layer.metaData.customQueryList.push(o.customQueryId);
				}
			}

			// if no pin point count override was provided, use the current count stored in the layer
			if (pinPointCount === -1)
				pinPointCount = layer.metaData.pinPointCount
			else
				layer.metaData.pinPointCount = pinPointCount;

			layer.outOfRange = o.outOfRange ? o.outOfRange : false;
		}
		else {		
			var points = _.isArray(o.pointId) ? o.pointId : [o.pointId];

			layer = map.addLayer({
				id: id,
				group: constants.layers.groups.customerMap,
				type: customerMaps.getMapType({ type: o.type }),
				text: o.name,
				subText: translate('loading'),
				active: !_.isUndefined(o.visible) ? o.visible : true,
				visible: !_.isUndefined(o.visible) ? o.visible : true,
				outOfRange: !_.isUndefined(o.outOfRange) ? o.outOfRange : false,
				metaData: { points: points, customQueryList: [o.customQueryId], isCompetitiveInsights: isCompetitiveInsights, isDynamic: o.isDynamic, isTile: o.isTile, isUber: o.isUber, timeType: o.timeType, themeId: o.themeId, defaultPinPointCount: pinPointCount, pinPointCount: pinPointCount },
				imageUrl: async (handler) =>{

					if (handler.layer.metaData.points.length === 0)
						return '';

					switch (type)
					{
						default:							
							if (layer?.outOfRange)
								return '';
							else
								return legacyEndpoints.handlers.getCustomerTilesBoundingBox({
									id: o.isUber || o.isDynamic ? o.themeId : o.id,
									upperLeftLat: handler.bounds.northWest.lat,
									upperLeftLon: handler.bounds.northWest.lon,
									lowerRightLat: handler.bounds.southEast.lat,
									lowerRightLon: handler.bounds.southEast.lon,
									zoom: handler.zoom,
									data: handler.layer.metaData.points,
									isChain: false,
									isUber: o.isUber,
									isDesireLine: type === constants.layers.types.customerMaps.desireLine,
									sourceId: handler.layer.metaData.points[0],
									timeType: o.timeType,
									dataType: o.dataType,
									customQueryId: o.customQueryId,
									isCompetitiveInsights: isCompetitiveInsights,
									pinPointCount: handler.layer.metaData.pinPointCount
								});
						case constants.layers.types.customerMaps.heat:
							var data = await legacyEndpoints.service({
								name: 'GenerateCustomerDataHeatMap',
								parameters: {
									aThemeID: o.isUber || o.isDynamic ? o.themeId : o.id,
									aLeft: handler.bounds.northWest.lon,
									aRight: handler.bounds.southEast.lon,
									aTop: handler.bounds.northWest.lat,
									aBottom: handler.bounds.southEast.lat,
									aZoom: handler.zoom,
									aData: handler.layer.metaData.points,
									aIsChain: false,
									aIsUber: o.isUber,
									aTimeType: o.timeType,
									aDataType: o.dataType
								}
							});

							return {
								locations: helpers.decodeLocations(data.EncodedPoints),
								gradient: data.ColorGradient.map(color => {
									return { r: color.R, g: color.G, b: color.B, a: 1}
								}),
								intensity: data.Intensity,
								radius: data.Radius,
								opacity: data.Opacity
							};
					}
				},
				actions: [{
					id: layerActions.active,
					getActive: () => { return layer.active; },
					onClick: () =>{
						
						layer.active = !layer.active;
						layer.visible = layer.active;
						layer.loading = false;

						if (layer.visible) {
							layer.legend = layer.metaData.savedLegend;
						}
						else {
							layer.legend = [];
						}

						if (layer.active) {
							if (o.isDynamic)
								customerMaps.refreshDynamic({ layer: {
									id: o.themeId,
									type: o.type,
									data: {
										isDynamic: o.isDynamic,
										isTile: o.isTile,
										pinPointCount: layer.metaData.pinPointCount
									}
								}});
							else if (type === constants.layers.types.customerMaps.pin && !o.isUber) {
								var visPoints = layer.metaData.savedLegend.map(item => { return item.legendId; });
								layer.metaData.points = [];

								customerMaps.refresh({ 
									id: o.id, 
									type: o.type,
									name: layer.text,
									pointId: visPoints, 
									isUber: false, 
									isDynamic: false,
									isTile: layer.metaData.isTile,
									timeType: -1, 
									dataType: -1, 
									themeId: layer.id,
									pinPointCount: layer.metaData.pinPointCount,
									outOfRange: map.zoom < 10
								});
							}
						}

						layers.update();
					}
				}],
				onChange: ()=>{
					layers.update();
				}
			});

			if (type === constants.layers.types.customerMaps.pin && !o.isUber) {
				layer.actions.push({
					id: layerActions.visible,
					getActive: () => { return layer.visible; },
					getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
					onClick: () =>{
						layer.visible = !layer.visible && layer.active;

						if (layer.active && !layer.outOfRange) {
							layer.oppositeVisibilityEntities = layer.visible ? [] : layer.metaData.savedLegend.map(item => { return item.legendId; });
							
							if (o.isDynamic) 
								customerMaps.refreshDynamic({ layer: {
									id: o.themeId,
									type: o.type,
									data: {
										isDynamic: o.isDynamic,
										isTile: o.isTile,
										pinPointCount: layer.metaData.pinPointCount
									}
								}});
							else {
								var visPoints = layer.visible ? layer.metaData.savedLegend.map(item => { return item.legendId; }) : [];
								layer.metaData.points = [];

								customerMaps.refresh({ 
									id: o.id, 
									type: o.type,
									name: layer.text,
									pointId: visPoints, 
									isUber: false, 
									isDynamic: false,
									isTile: layer.metaData.isTile,
									timeType: -1, 
									dataType: -1, 
									themeId: layer.id,
									pinPointCount: layer.metaData.pinPointCount,
									outOfRange: map.zoom < 10
								});
							}
						}
					}
				});
			}

			layer.actions.push({
				id: layerActions.delete,
				onClick: () =>{
					layer.dispose();
				}
			})
		}

		layer.loading = true;

		if (layer.outOfRange) {
			layer.metaData.savedLegend = [{
				icon: icons.warning,
				text: translate('out_of_range'),
				legendId: -1
			}];
		}
		else {
			switch (type){
				default:
					var data;

					if (o.isUber) {
						data = await legacyEndpoints.service({
							name: 'GetCustomerMapLegendForUber',
							parameters: {
								aThemeID: o.themeId,
								aData: layer.metaData.points,
								aCustomQueryList: layer.metaData.customQueryList,
								aTimeType: o.timeType,
								aDataType: o.dataType
							}
						});
					}
					else {
						data = await legacyEndpoints.service({
							name: 'GetCustomerMapLegend',
							parameters: {
								aThemeID: o.isDynamic ? o.themeId : o.id,
								aData: [...layer.metaData.points, ...layer.oppositeVisibilityEntities],
								aPinPointCount: pinPointCount
							}
						});

						// if we requested the default pin point count (-1), save the actual pin point count
						// so we can restore it when the user clicks the default button
						layer.metaData.pinPointCount = data.PinPointCount;
						if (pinPointCount === -1)
							layer.metaData.defaultPinPointCount = data.PinPointCount;
					}

					if (data.SubTitle)
						layer.subText = o.isDynamic ? `${translate('bulk')}: ${data.SubTitle}` : data.SubTitle;
					else
						layer.subText = o.isDynamic ? translate('bulk') : '';

					if (type === constants.layers.types.customerMaps.pin && !o.isUber) {
						
						layer.toolbars = customerMaps.getCustomerPinToolbar(layer, o.id, -1, false);

						layer.metaData.savedLegend = data.LegendItems.sort((a,b) => a.LegendId - b.LegendId).map(item => {
							var legend = {
								legendId: item.LegendId,
								text: item.LegendText,
								icon: layer.type === constants.layers.types.customerMaps.pin && layer.oppositeVisibilityEntities.indexOf(item.LegendId) === -1 ? 
									legacyEndpoints.handlers.getSymbolUrl({ imageUrl: item.LegendSymbol, symbolType: 0 }) : '',
								color: layer.type !== constants.layers.types.customerMaps.pin ? item.LegendSymbol : '',
								iconWidth: item.SymbolWidth,
								actions: [{
									id: layerActions.visible,
									getActive: () => { return layer.oppositeVisibilityEntities.indexOf(item.LegendId) === -1; },
									onClick: () =>{

										var visPoints = layer.visible ? [...layer.metaData.points] : [];

										if (layer.oppositeVisibilityEntities.indexOf(item.LegendId) > -1) {
											layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== item.LegendId);

											if (!layer.visible) {
												const ids = [...layer.oppositeVisibilityEntities];
												layer.visible = true;
												layer.oppositeVisibilityEntities = [...ids];
											}

											var index = visPoints.indexOf(item.LegendId);
											if (index === -1) {
												visPoints.push(item.LegendId);
											}

										}
										else {
											layer.oppositeVisibilityEntities.push(item.LegendId);
											visPoints = visPoints.filter(x => x !== item.LegendId);
										}

										if (o.isDynamic)
											customerMaps.refreshDynamic({ layer: {
												id: o.themeId,
												type: o.type,
												data: {
													isDynamic: o.isDynamic,
													isTile: o.isTile,
													pinPointCount: layer.metaData.pinPointCount
												}
											}});
										else {
											layer.metaData.points = [];

											customerMaps.refresh({ 
												id: o.id, 
												type: layer.type,
												name: layer.text,
												pointId: visPoints, 
												isUber: false, 
												isDynamic: false,
												isTile: layer.metaData.isTile,
												timeType: -1, 
												dataType: -1, 
												themeId: layer.id,
												pinPointCount: layer.metaData.pinPointCount,
												outOfRange: map.zoom < 10
											});
										}
									}
								}]
							};

							if (!o.isDynamic)
								legend.actions.push({
									id: layerActions.delete,
									onClick: () =>{
										var visPoints = layer.metaData.points.filter(x => x !== item.LegendId);
										layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== item.LegendId);
										layer.metaData.points = [];

										customerMaps.refresh({ 
											id: o.id, 
											type: layer.type,
											name: layer.text,
											pointId: visPoints, 
											isUber: false, 
											isDynamic: false,
											isTile: layer.metaData.isTile,
											timeType: -1, 
											dataType: -1, 
											themeId: layer.id,
											pinPointCount: layer.metaData.pinPointCount,
											outOfRange: map.zoom < 10
										});
								}
								})
				
							return legend;
						});
					}
					else
						layer.metaData.savedLegend = data.LegendItems.map(item => {
							return {
								text: item.LegendText,
								icon: layer.type === constants.layers.types.customerMaps.pin ? legacyEndpoints.handlers.getSymbolUrl({ imageUrl: item.LegendSymbol, symbolType: 0 }) : '',
								color: layer.type !== constants.layers.types.customerMaps.pin ? item.LegendSymbol : '',
								iconWidth: item.SymbolWidth
							};
						});

					break;
				case constants.layers.types.customerMaps.desireLine:
					var pointIds = [];

					if (o.isUber && !isCompetitiveInsights)
						pointIds.push({
							sourceId: layer.metaData.points[0],
							customQueryId: layer.metaData.customQueryList[0],
							timeType: o.timeType,
							dataType: o.dataType
						});
					else 
						pointIds = layer.metaData.points.map(pointId => {return { sourceId: pointId }});

					var mapId = o.isUber || o.isDynamic ? o.themeId : o.id;

					var stringData = await legacyEndpoints.service({
						name: 'GenerateBulkStringMapsWithUberSupport',
						parameters: {
							aMapID: mapId,
							aPointIDs: pointIds,
							isCompetitiveInsights: isCompetitiveInsights
						}
					});

					if (stringData.BulkLegend.SubTitle)
						layer.subText = o.isDynamic ? `${translate('bulk')}: ${stringData.BulkLegend.SubTitle}` : stringData.BulkLegend.SubTitle;
					else
						layer.subText = o.isDynamic ? translate('bulk') : '';

					var bulkLegend = stringData.BulkLegend.find(item => item.ID.toString() === mapId.toString());
					if (bulkLegend == null)
						bulkLegend = stringData.BulkLegend.find(item => item.UberRetailJobRenderId.toString() === mapId.toString());

					if (bulkLegend != null)
						layer.metaData.savedLegend = bulkLegend.LegendItems.map(item => {
							return {
								text: item.LegendText,
								color: item.HTMLColor,
								lineWidth: item.LineWidth
							}
						});
					break;
			}
		}

		layer.metaData.legendIcon = customerMaps.getCustomerMapIcon(type);

		layer.loading = false;

		layer.legend = layer.active ? layer.metaData.savedLegend : [];
		layer.refresh();
    }
};