// React imports
import { useState, useEffect, useCallback } from 'react'

// App imports
import { Loader } from '../../../base/loader/loader';
import { Button } from '../../../base/button/button';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { translate } from '../../../../utils/translation';
import { zIndexes } from '../../../../utils/zIndex';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { header } from '../../header/header';
import { map } from '../../map/map';
import { layers } from '../../layers/layers';
import { projects, MapCustomQuery, MapLayer } from '../../../../modules/projects';
import { sources } from '../../../../modules/sources';

const _ = require("lodash");

export function TaskActionCustomQuery({ task }) {

    const MAP_ZOOM_LEVEL = 15;

    const [generating, setGenerating] = useState(false);
    const [actionMapSrc, setActionMapSrc] = useState(helpers.getSmallTransparentImage());
    const [actionMapError, setActionMapError] = useState(false);

    const generateMapImage = useCallback( async () => {

        if (task?.sourceInformation?.parentObjectId == helpers.emptyGuid())
            return;

        setGenerating(true);
        setActionMapError(false);

        var metaData = projects.initMapMetadata();

        metaData.Information.Name = "Task Action Map";
        metaData.Information.Description = "Task Action Map";
        metaData.Information.ContactInformation = "Task Action Map";
        metaData.Information.IsFixed = false;
        metaData.Information.Type = constants.map.legacyTypes.road;

        metaData.Information.Zoom = MAP_ZOOM_LEVEL; // map.zoom;
        metaData.Information.Center =  _.isObject(task?.sourceInformation?.point) ?
            { lat: task.sourceInformation.point.lat, lon: task.sourceInformation.point.lon } :
            { lat: map.center.lat, lon: map.center.lon };

        var mapCustomQuery = new MapCustomQuery();
        mapCustomQuery.Id = task.sourceInformation.parentObjectId.toUpperCase();
        mapCustomQuery.LayerText = "";
        mapCustomQuery.MaxZoom = 6;
        mapCustomQuery.MaxLabelZoom = 6;
        mapCustomQuery.ZIndex = zIndexes({ type: constants.layers.types.point, subType: 0 });
        metaData.CustomQueries.push(mapCustomQuery);

        const mapId = await projects.printCustomMap({ metaData: metaData });

        if (mapId === helpers.emptyGuid()) {
            setActionMapError(true)
            setGenerating(false);
        }
        else
            setActionMapSrc(legacyEndpoints.handlers.getPrintUrl({ id: mapId, isPrintPreview: true, outputType: constants.print.outputTypes.png }));

    }, [task]);

    useEffect(() => {
        generateMapImage();
    }, [generateMapImage]);

    const viewMap = () => {

        if (!_.isObject(task?.sourceInformation?.point) || task?.sourceInformation?.parentObjectId == helpers.emptyGuid())
            return;

        map.center = { lat: task.sourceInformation.point.lat, lon: task.sourceInformation.point.lon };
        map.zoom = MAP_ZOOM_LEVEL;
        header.toggleMap();

        var newLayer = new MapLayer();
        newLayer.id = task.sourceInformation.parentObjectId;

        sources.refresh({
            layers: [newLayer],
            onRefresh: (o)=>{ 
                //TBD: this gets called in an infinite loop from sources.refresh onChange ?!?
                layers.refreshDataLayers(o); 
            },
            onComplete: async ()=>{ 

                const activeEntityLayer = map.layers.find(layer => layer.type === constants.layers.types.activeEntity);
                const customQueryLayer = sources.getLayers().find(layer => { return layer.id.toLowerCase() === task.sourceInformation.parentObjectId.toLowerCase() });
    
                if (_.isObject(activeEntityLayer) && _.isObject(customQueryLayer)) {
    
                    const entity = customQueryLayer.entities.find(x => x.id === task.sourceInformation.objectId);
    
                    if (_.isObject(entity)) {
                        activeEntityLayer.clear();
    
                        activeEntityLayer.addEntity({ 
                            type: constants.entities.activePoint,
                            location: { lat: task.sourceInformation.point.lat, lon: task.sourceInformation.point.lon },
                            anchor: entity.anchor,
                            width: entity.width,
                            height: entity.height
                        });
                    }
                }
            }
        });            
    };

    return <>
        <Loader theme={'generate'} loaded={!generating} />
        { actionMapError ?
            <div className='app-projects-print-error'>{translate('map_image_error')}</div>
        :
            <div className={'app-button-container'}>
                { actionMapSrc === helpers.getSmallTransparentImage() ? '' :
                    <Button 
                        theme='secondary' 
                        tooltip={translate('view_map')} 
                        onClick={()=>{ viewMap(); }} 
                    >
                        <img className='app-task-action-map' src={actionMapSrc} alt={translate('view_map')} 
                            onLoad={()=>{ setGenerating(false); }} 
                            onError={(o)=>{ setGenerating(false); setActionMapError(true); }} 
                        /> 
                    </Button>
                }
            </div>
        }
    </>
}