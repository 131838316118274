// React imports
import { useState, useEffect, useCallback, useMemo } from 'react';

// App imports
import { Loader } from '../../../base/loader/loader';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { Bar } from '../../../base/bar/bar';
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Popover } from '../../../base/popover/popover';
import { DataList } from '../../../base/dataList/dataList';
import { ModalCard } from '../../../base/modalCard/modalCard';
import { PrintMapAdornment } from './printMapAdornment';
import { QuickReportOptions } from './quickReportOptions';
import { PrintMapOptions } from './printMapOptions';
import { PrintMapContent } from './printMapContent';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { Icon, icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { projects } from '../../../../modules/projects';
import { map } from '../../map/map';

const _ = require("lodash");

export function PrintMap({ isMapBook = false, printSettings = null, adHocMap = null, points = [], thematics = [], sources = [], dataMaps = [], onSave, onClose }) {

    const [_adHocMap, setAdHocMap] = useState(null);
    
    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [fontFamilies, setFontFamilies] = useState([]);
    const [printTemplateSizes, setPrintTemplateSizes] = useState([]);
    const [printTemplates, setPrintTemplates] = useState([]);       // TBD: add support for user saved print templates
    const [previewPoint, setPreviewPoint] = useState(null);
    const [mapOptions, setMapOptions] = useState(null);
    const [mapOptionsExpanded, setMapOptionsExpanded] = useState(true);
    const [quickReportOptions, setQuickReportOptions] = useState(null);
    const [quickReportOptionsExpanded, setQuickReportOptionsExpanded] = useState(false);
    const [mapContentExpanded, setMapContentExpanded] = useState(false);
    const [mapTitles, setMapTitles] = useState(null);
    const [outputType, setOutputType] = useState(constants.print.outputTypes.pdf);
    const [mapPreviewSrc, setMapPreviewSrc] = useState(helpers.getSmallTransparentImage());
    const [printError, setPrintError] = useState(false);
    const [activeContent, setActiveContent] = useState(-1);
    const [adornmentData, setAdornmentData] = useState(null);
    const [contentButtons, setContentButtons] = useState([]);

    const outputTypes = useMemo(() => [
        { key: constants.print.outputTypes.pdf, text: translate('pdf_document') }, 
        { key: constants.print.outputTypes.png, text: translate('png_image') }
    ], []);

    useEffect(() =>{
        (async () => {

            setLoaded(false);

            const options = await projects.getPrintOptions();

            setFontFamilies(options.FontFamilies);
            setPrintTemplateSizes(options.PrintTemplateSizes);
            setPrintTemplates(options.PrintTemplates);
        
        })();
    }, []);

    const getContentButton = (o) => {
        var title;

        switch (o.text) {
            default:
                title = translate('custom_text');
                break;
            case constants.print.contentTypes.companyLogo:
                title = translate('company_logo');
                break;
            case constants.print.contentTypes.currentDate:
                title = translate('current_date');
                break;
            case constants.print.contentTypes.aerialDate:
                title = translate('aerial_date');
                break;
            case "":
                title = o.defaultText
                break;
        }

        return  { default: o.defaultText, text: title, class: o.class, active: title !== o.defaultText };
    };

    useEffect(() =>{

        if (printTemplateSizes.length === 0 || _.isObject(mapOptions) || _.isObject(mapTitles) || _.isObject(quickReportOptions))
            return;

        var newAdHocMap = null;
        var newPrintSettings;
        var newMapType;
        var newZoom = 15;
        var newZoomToTradeAreas = true;
        var newShowLabels = true;
        var newPreviewPoint = null;

        if (isMapBook) {
            newAdHocMap = adHocMap === null ? projects.getDefaultAdHocMap() : _.cloneDeep(adHocMap);
            newPrintSettings = projects.getAdHocMapPrintSettings(newAdHocMap);
            newMapType = projects.getMapTypeId(newAdHocMap.mapType);
            newZoom = newAdHocMap.zoom;
            newZoomToTradeAreas = newAdHocMap.zoomToTradeAreas;
            newShowLabels = _.isBoolean(newAdHocMap.showLabels) ? newAdHocMap.showLabels : true;
            newPreviewPoint = points.length > 0 ? points[0] : null;
        }
        else {
            if (printSettings === null) {
                newPrintSettings = projects.getDefaultPrintSettings();
                newPrintSettings.PrintTemplateSizeGUID = printTemplateSizes[0].PrintTemplateSizeGUID;
            } 
            else {
                newPrintSettings = _.cloneDeep(printSettings);
            }

            newMapType = map.type;
        }

        var newContentButtons = [];

        newContentButtons.push(getContentButton({ text: newPrintSettings.TopLeft, defaultText: translate('top_left_content'), class: 'app-projects-print-bar-left' }));
        newContentButtons.push(getContentButton({ text: newPrintSettings.TopRight, defaultText: translate('top_right_content'), class: 'app-projects-print-bar-right' }));
        newContentButtons.push(getContentButton({ text: newPrintSettings.BottomLeft, defaultText: translate('bottom_left_content'), class: 'app-projects-print-bar-left' }));
        newContentButtons.push(getContentButton({ text: newPrintSettings.BottomMiddle, defaultText: translate('bottom_middle_content'), class: 'app-projects-print-bar-center' }));
        newContentButtons.push(getContentButton({ text: newPrintSettings.BottomRight, defaultText: translate('bottom_right_content'), class: 'app-projects-print-bar-right' }));

        setMapOptions({
            mapType: newMapType,
            zoom: newZoom,
            zoomToTradeAreas: newZoomToTradeAreas,
            showLabels: newShowLabels,
            printTemplateGUID: newPrintSettings.PrintTemplateSizeGUID,
            printTemplateSizes: printTemplateSizes,
            orientation: newPrintSettings.Orientation,
            legendLocation: newPrintSettings.LegendLocation,
            legendBehavior: newPrintSettings.LegendBehavior
        });
        
        setQuickReportOptions({
            chartId: newPrintSettings.ChartId,
            chartLocation: newPrintSettings.ChartLocation,
        });        
        setMapTitles({
            topLeft: newPrintSettings.TopLeft,
            topLeftStyle: newPrintSettings.TopLeftStyle,
            topRight: newPrintSettings.TopRight,
            topRightStyle: newPrintSettings.TopRightStyle,
            bottomLeft: newPrintSettings.BottomLeft,
            bottomLeftStyle: newPrintSettings.BottomLeftStyle,
            bottomMiddle: newPrintSettings.BottomMiddle,
            bottomMiddleStyle: newPrintSettings.BottomMiddleStyle,
            bottomRight: newPrintSettings.BottomRight,
            bottomRightStyle: newPrintSettings.BottomRightStyle,
        });

        setAdHocMap(newAdHocMap);
        setPreviewPoint(newPreviewPoint);
        setContentButtons(newContentButtons);
        setPrintError(false);
        setLoaded(true);

    }, [printTemplateSizes, isMapBook, printSettings, adHocMap, points, mapOptions, mapTitles, quickReportOptions]);

    const printMap = useCallback(
        async (o) =>{

            if (!mapOptions || !mapTitles)
                return;

            o = _.isObject(o) ? o : {};

            if (!_.isBoolean(o.isPreview))
                o.isPreview = false;

            if (_.isUndefined(o.outputType))
                o.outputType = constants.print.outputTypes.png;

            if (o.isPreview && !autoRefresh)
                return;

            setGenerating(true);
            setPrintError(false);

            var mapId;
            
            if (isMapBook)
                mapId = await projects.getAdHocMapPreview({ adHocMap: _adHocMap, mapOptions: mapOptions, mapTitles: mapTitles, previewPoint: previewPoint });
            else
                mapId = await projects.printMap({ mapOptions: mapOptions, mapTitles: mapTitles, quickReportOptions: quickReportOptions});

            if (mapId === helpers.emptyGuid()) {
                setPrintError(true)
                setGenerating(false);
            }
            else {
                if (o.isPreview) {
                    setMapPreviewSrc(legacyEndpoints.handlers.getPrintUrl({ id: mapId, isPrintPreview: true, outputType: o.outputType }));
                }
                else {
                    helpers.navigateToUrl(legacyEndpoints.handlers.getPrintUrl({ id: mapId, outputType: o.outputType }));
                    setGenerating(false);
                }
            }

        },
        [isMapBook, _adHocMap, mapOptions, mapTitles, quickReportOptions, previewPoint, autoRefresh]
    );

    useEffect(() =>{
        printMap({ isPreview: true });
    }, [printMap]);
    
    useEffect(() =>{

        if (!mapTitles || activeContent < 0)
            return;

        var title = "";
        var text = "";
        var fontSizes = [6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28];
        var fontStyle = null;

        switch (activeContent) {
            default:
                break;
            case 0:
                title = translate('top_left_content');
                text = mapTitles.topLeft;
                fontSizes = [ ...fontSizes, 36, 48 ];
                fontStyle = mapTitles.topLeftStyle;
                break;
            case 1:
                title = translate('top_right_content');
                text = mapTitles.topRight;
                fontSizes = [ ...fontSizes, 36, 48 ];
                fontStyle = mapTitles.topRightStyle;
                break;
            case 2:
                title = translate('bottom_left_content');
                text = mapTitles.bottomLeft;
                fontStyle = mapTitles.bottomLeftStyle;
                break;
            case 3:
                title = translate('bottom_middle_content');
                text = mapTitles.bottomMiddle;
                fontStyle = mapTitles.bottomMiddleStyle;
                break;
            case 4:
                title = translate('bottom_right_content');
                text = mapTitles.bottomRight;
                fontStyle = mapTitles.bottomRightStyle;
                break;
        }

        switch (text) {
            default:
                setAdornmentData({ index: activeContent, title: title, type: constants.print.contentTypes.customText, text: text, fontSizes: fontSizes, fontStyle: fontStyle });
                break;
            case constants.print.contentTypes.companyLogo:
                setAdornmentData({ index: activeContent, title: title, type: constants.print.contentTypes.companyLogo, text: "", fontSizes: fontSizes, fontStyle: fontStyle });
                break;
            case constants.print.contentTypes.currentDate:
                setAdornmentData({ index: activeContent, title: title, type: constants.print.contentTypes.currentDate, text: "", fontSizes: fontSizes, fontStyle: fontStyle });
                break;
            case constants.print.contentTypes.aerialDate:
                setAdornmentData({ index: activeContent, title: title, type: constants.print.contentTypes.aerialDate, text: "", fontSizes: fontSizes, fontStyle: fontStyle });
                break;
        }

    }, [activeContent, mapTitles]);
    
    const saveAdornmentData = (o) => {

        var text = o.text;
        var title = "";

        switch (o.type) {
            default:
                title = translate('custom_text');
                break;
            case constants.print.contentTypes.companyLogo:
                text = constants.print.contentTypes.companyLogo;
                title = translate('company_logo');
                break;
            case constants.print.contentTypes.currentDate:
                text = constants.print.contentTypes.currentDate;
                title = translate('current_date');
                break;
            case constants.print.contentTypes.aerialDate:
                text = constants.print.contentTypes.aerialDate;
                title = translate('aerial_date');
                break;
        }

        const newButtons = contentButtons.map((button, i) => {
            if (i === o.index)
                return { ...button, text: o.isDelete === true ? button.default : title, active: o.isDelete === true ? false : true };
            else
                return button;
        });

        setContentButtons([ ...newButtons ]);

        switch (o.index) {
            default:
                break;
            case 0:
                setMapTitles({ ...mapTitles, topLeft: text, topLeftStyle: { ...mapTitles.topLeftStyle, FontFamily: o.fontFamily, FontSize: o.fontSize } });
                break;
            case 1:
                setMapTitles({ ...mapTitles, topRight: text, topRightStyle: { ...mapTitles.topRightStyle, FontFamily: o.fontFamily, FontSize: o.fontSize } });
                break;
            case 2:
                setMapTitles({ ...mapTitles, bottomLeft: text, bottomLeftStyle: { ...mapTitles.bottomLeftStyle, FontFamily: o.fontFamily, FontSize: o.fontSize } });
                break;
            case 3:
                setMapTitles({ ...mapTitles, bottomMiddle: text, bottomMiddleStyle: { ...mapTitles.bottomMiddleStyle, FontFamily: o.fontFamily, FontSize: o.fontSize } });
                break;
            case 4:
                setMapTitles({ ...mapTitles, bottomRight: text, bottomRightStyle: { ...mapTitles.bottomRightStyle, FontFamily: o.fontFamily, FontSize: o.fontSize } });
                break;
        }
    };

    const saveMap = () => {
        if (_.isFunction(onSave)) {
            var newAdHocMap = _.cloneDeep(_adHocMap);

            projects.setAdHocMapPrintSettings(newAdHocMap, mapOptions, mapTitles, quickReportOptions);

            onSave(newAdHocMap);
        }
    }

    const updatePreviewPoint = (o) => {
        if (o.length === 0) {
            setPreviewPoint(null);
        }
        else {
            const pointId = o[0];

            var point = _.find(points, function (pt) {
                return pt.PointId === pointId;
            });

            setPreviewPoint(_.isObject(point) ? point : null);
        }
    }

    // Note: using seperate Loader because map preview img needs to render in order for its onLoad event to fire (and set generating to false)
    return <>
        { !loaded && <Loader /> }
        <div className='app-projects-print-header'>
            { !loaded ? '' :
                <div className='app-projects-print-actions-rows'>
                    <div className='app-option-selector'>
                        {autoRefresh === true ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-projects-print-zoom-bar'
                            icon={icons.refresh}
                            tooltip={translate('refresh_map')}
                            active={autoRefresh === true} 
                            onClick={() => { setAutoRefresh(!autoRefresh); }} 
                        /> 
                    </div>
                    { isMapBook ? 
                    <>
                        { previewPoint === null ? '' :
                            <Popover 
                                parentId='#app-projects-print-preview-point'
                                title={translate('select_preview_point')}
                                height={400}
                                width={450}
                            >
                                <DataList
                                    keyExpr={'PointId'}
                                    dataSource={points}
                                    height={'auto'}
                                    selectionMode={'single'}
                                    searchExpr={'Name'}
                                    itemRender={(item) =>{
                                        return <>
                                            {item.Name} 
                                            <span className='app-projects-print-preview-point-subtext'>{` (${item.ServiceName})`}</span>
                                        </>
                                    }}
                                    selectedItemsChange={(o)=> {
                                        updatePreviewPoint(o);
                                    }}
                                    selectByClick={true}
                                    selectedItems={[previewPoint.PointId]}
                                />
                            </Popover>
                        }
                        <Button id='app-projects-print-preview-point' className='app-projects-print-preview-point' theme='secondary' tooltip={translate('preview_point')} 
                            size='small' icon={icons.pin} disabled={previewPoint === null} />
                        <Button className='app-projects-print-small-button app-projects-print-align-right' theme='secondary' tooltip={translate('cancel')} size='small' icon={icons.x}
                            onClick={()=>{ if (_.isFunction(onClose)) onClose(); }} 
                        />	
                        <Button className='app-projects-print-small-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.check} disabled={printError === true}
                            onClick={()=>{ saveMap(); }} 
                        />	
                    </>
                    :
                    <>
                        <DropDown className={'app-projects-print-align-right'} items={outputTypes} label={translate('output_type')} width={'170px'} height={'34px'} 
                            display='text' valueProperty='key' selected={outputType} 
                            onChange={(o) => { setOutputType(o.value); }} />
                        <Button className='app-projects-print-small-button' theme='primary' tooltip={translate('print_map')} size='small' icon={icons.print} disabled={printError === true}
                            onClick={()=>{ printMap({ outputType: outputType }); }} 
                        />	
                    </>
                    }
                </div>
            }
        </div>
        <div className='app-projects-print-body'>
            <div>
                <div className='app-projects-print-rows app-projects-print-centered-rows'>
                {
                    contentButtons.map((content, i) => {
                        var classes = ['app-projects-print-content-buttons', 'app-projects-print-bar-long'];
                        classes.push(content.class);

                        return i > 1 ? '' :
                            <Bar className={classes.join(' ')}
                                key={i}
                                text={content.text} 
                                onClick={()=>{ setActiveContent(i); }}
                                active={content.active}
                                actions={[
                                    { icon: icons.trash, tooltip: translate('delete'), disabled: !content.active, onClick: (e) => { 
                                        e.stopPropagation();
                                        saveAdornmentData({ isDelete: true, index: i, type: constants.print.contentTypes.customText, text: "", fontSize: 10, fontFamily: 'Arial' });
                                    } }
                                ]}
                            />
                    })
                }
                </div>
                <div className='app-projects-print-rows app-projects-print-centered-rows'>
                    <Loader theme={'generate'} loaded={!generating} />
                    { printError ?
                        <div className='app-projects-print-error'>{translate('print_image_error')}</div>
                    :
                        <img className='app-projects-print-preview' src={mapPreviewSrc} alt={translate('print_preview')} onLoad={()=>{ setGenerating(false); }} 
                            onError={(o)=>{ setGenerating(false); setPrintError(true); }} 
                        /> 
                    }
                </div>
                <div className='app-projects-print-rows app-projects-print-centered-rows'>
                {
                    contentButtons.map((content, i) => {
                        var classes = ['app-projects-print-content-buttons', 'app-projects-print-bar-short'];
                        classes.push(content.class);

                        return i < 2 ? '' :
                            <Bar className={classes.join(' ')}
                                key={i}
                                text={content.text} 
                                onClick={()=>{ setActiveContent(i); }}
                                active={content.active}
                                actions={[
                                    { icon: icons.trash, tooltip: translate('delete'), disabled: !content.active, onClick: (e) => { 
                                        e.stopPropagation();
                                        saveAdornmentData({ isDelete: true, index: i, type: constants.print.contentTypes.customText, text: "", fontSize: 10, fontFamily: 'Arial' });
                                    } }
                                ]}
                            />
                    })
                }
                </div>
            </div>
            <CollapsibleSection text={translate('print_options')} expanded={mapOptionsExpanded} onClick={() => { setMapOptionsExpanded(!mapOptionsExpanded); }} >
            { !loaded ? '' :
                <PrintMapOptions isMapBook={isMapBook} mapOptions={mapOptions} onApply={(o) => { setMapOptions(_.cloneDeep(o)); }} />
            }
            </CollapsibleSection>
            { isMapBook || !loaded ? '' :
                <CollapsibleSection text={translate('quick_report_options')} expanded={quickReportOptionsExpanded} onClick={() => { setQuickReportOptionsExpanded(!quickReportOptionsExpanded); }} >
                    <QuickReportOptions quickReportOptions={quickReportOptions} onApply={(o) => { setQuickReportOptions(_.cloneDeep(o)); }} />
                </CollapsibleSection>
            }                    
            { !isMapBook || !loaded ? '' :
                <CollapsibleSection text={translate('map_content')} expanded={mapContentExpanded} onClick={() => { setMapContentExpanded(!mapContentExpanded); }} >
                    <div className='app-projects-print-rows app-projects-print-centered-rows'>
                        <PrintMapContent adHocMap={_adHocMap} thematics={thematics} sources={sources} dataMaps={dataMaps} onSave={(o)=>{ setAdHocMap(o); }} />
                    </div>
                </CollapsibleSection>
            }
            <div className='app-projects-print-adornment-modal' style={{display: activeContent >= 0 ? 'flex' : 'none' }} >
                <ModalCard loaded={true} open={activeContent >= 0}>
                    <PrintMapAdornment active={activeContent >= 0} data={adornmentData} fontFamilies={fontFamilies} onClose={()=>{ setActiveContent(-1); }} 
                        onSave={(o)=>{ 
                            saveAdornmentData(o); 
                            setActiveContent(-1); 
                        }} 
                    />
                </ModalCard>	
            </div>
        </div>
    </>
}