// React imports  
import  React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';

// App imports
import { Button } from "../../../base/button/button";
import { MenuButton } from "../../../base/menuButton/menuButton";
import { ContextMenu } from "../../../base/contextMenu/contextMenu";
import { ConfirmButton } from "../../../base/confirmButton/confirmButton";
import { RadioButtonSet } from '../../../base/radioButtonSet/radioButtonSet';
import { navigation } from "../../navigation/navigation";
import { map, multiview } from "../map";
import { icons, Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';
import { cosmetic } from '../../../../modules/cosmetic';
import { trafficMetrix } from '../../../../modules/trafficMetrix';
import { parcel } from '../../../../modules/parcel';
import { saveMapContainer } from '../../saveMap/saveMapContainer';
import { projects } from '../../projects/projects';
import { projects as projectsModule } from '../../../../modules/projects';
import { selections } from '../../../../modules/selections';
import { userPreferences } from '../../app';
import { Popover } from '../../../base/popover/popover';
import { SharedUsers } from '../../sharedUsers/sharedUsers';
import { helpers } from '../../../../utils/helpers';
import { availableMaps } from '../../projects/availableMaps/availableMaps';
import { MapSearch } from '../mapSearch/mapSearch';

const _ = require("lodash");

export var mapControl;

export function MapControl() {
	
	const [zoom, setZoom] = useState(map.zoom);
	const [heading, setHeading] = useState(map.heading);
	const [tiltable, setTiltable] = useState(map.tiltable);
	const [rotatable, setRotatable] = useState(map.rotatable);
	const [mouseLocation, setMouseLocation] = useState(null);
	const [collapsed, setCollapsed] = useState(false);
	const [navigationCollapsed, setNavigationCollapsed] = useState(false);
	const [activeSection, setActiveSection] = useState(false);	
	const [streetSideActive, setStreetSideActive] = useState(false);
	const [lastMapRightClick, setLastMapRightClick] = useState(null);
	const [contextMenuVisible, setContextMenuVisible] = useState(false);
	const [lastMapType, setLastMapType] = useState(constants.map.types.road);
	const [thematics, setThematics] = useState([]);
	const [currentListener, setCurrentListener] = useState(null);
	const [currentMap, setCurrentMap] = useState(null);
    const [isDefaultMap, setIsDefaultMap] = useState(false);
	const [isPublished, setIsPublished] = useState(false);
	
	const shareWindow = useRef(null);
    const mapSearchWindow = useRef(null);

	navigation.onExpand({
		listener: (o)=>{
			setNavigationCollapsed(o.collapsed);
			setActiveSection(o.activeSection);
		}
	});

	mapControl = {
		setMap: (o)=>{			
			setCurrentMap(o.map);            
		},
		getCurrentMapInformation: ()=>{			
			return currentMap?.Information;
		},
		updateCurrentMapInformation: (o)=>{			
			if (_.isObject(currentMap))
				setCurrentMap({ ...currentMap, Information: { ...currentMap.Information, Id: o.id, Name: o.name, Description: o.description, IsFixed: o.fixed, ContactInformation: o.contact }});            
		},
		isCurrentMapFixed: () =>{
			return currentMap?.Information?.IsFixed === true;
		}		
	};

    useEffect(() =>{
        setIsDefaultMap(currentMap?.Information.Id === userPreferences.DefaultMap);
		setIsPublished(currentMap?.Information.IsPublished === true);
    },[currentMap]);

	const wrapListener = (item, persist) => {

		var clone = _.cloneDeep(item);	
		if (_.isFunction(clone.action))
			clone.onClick = () =>{		
				if (clone.needsListener)
				{
					map.setCursor({ cursor: 'crosshair' });
					if (currentListener !== null)
						map.removeListener(currentListener);

					map.disableEntityClicking();
					setCurrentListener(
						map.addListener({
							type: constants.listeners.click,
							executeOnce: !_.isBoolean(persist) || !persist,
							action: (e) => {
								item.action(e);

								if (persist)
									return;

								map.enableEntityClicking();
								map.setCursor({ cursor: ''});
							}
						})
					);
				}
				else {
					if (currentListener !== null) {
						map.removeListener(currentListener);
						setCurrentListener(null);
						map.enableEntityClicking();
					}
					clone.action();
				}
			};

		return clone;
	};

	const wrapContextMenuItem = (item) => {

		const wrapOnClick = (onClick) =>{
			return (e) =>{
				
				if (!_.isUndefined(e))
					return;

				onClick();
			};
		};

		if (_.isArray(item.items))
			item.items.forEach(x => x.onClick = wrapOnClick(x.onClick));
		else
			item.onClick = wrapOnClick(item.onClick);

		return item;
	};

	var tempTools = [
		{ allowInViewer: true, contextMenu: true, needsListener: false, header: true, text: translate('measuring_tools') },
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.ruler, text: translate('distance'), action: () => {cosmetic.measureDistance();} },
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.rulerTriangle, text: translate('area'), action: () => {cosmetic.measureArea();} },
		{ allowInViewer: true, contextMenu: false, needsListener: false, header: true, text: translate('location_tools') },
		{ allowInViewer: true, contextMenu: false, needsListener: true, icon: icons.locationDot, text: translate('geocode'), action: (e) => {cosmetic.geocode(e);} },
		{ allowInViewer: true, contextMenu: true, needsListener: false, header: true, text: translate('drawing_tools') },
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.text, text: translate('add_text'), disabled: true },
		{ allowInViewer: true, contextMenu: false, needsListener: true, icon: icons.thumbtack, text: translate('add_pushpin'), action: (e) => { cosmetic.addPushpin(e);}},
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.line, text: translate('draw_line'), action: () => {cosmetic.drawLine();} },
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.hexagon, text: translate('draw_polygon'), action: () => {cosmetic.drawPolygon();} },
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.circle, text: translate('draw_circle'), action: () => {cosmetic.drawCircle();} },
		{ allowInViewer: true, contextMenu: true, needsListener: false, icon: icons.rectangle, text: translate('draw_rectangle'), action: () => {cosmetic.drawRectangle();} },
		{ allowInViewer: false, contextMenu: true, needsListener: false, icon: icons.globe, text: translate('geography'), action: () => {cosmetic.drawGeography();} },		
		{ allowInViewer: true, contextMenu: false, needsListener: false, icon: icons.tag, text: translate('label_point'), }
	];
	
	if (userPreferences.UserGUID.toUpperCase() === 'C48636FE-3125-441B-9F8B-29685492D3D6' || userPreferences.UserGUID.toUpperCase() === 'CAD7F20B-8FDB-4B93-B983-EF8E42903150')
		tempTools.push({ contextMenu: true, needsListener: false, icon: icons.gear, text: translate('merge'), action: () => { cosmetic.mergeShapes(); } });
	
	const mapTools = useMemo(() => tempTools.filter(x => x.allowInViewer || !helpers.isViewer()), []);

	const selectionTools = useMemo(() =>[
		{ contextMenu: false, needsListener: false, header: true, text: translate('selections') },
		{ contextMenu: true, needsListener: false, icon: icons.lasso, text: translate('select'), action: () => {selections.select();} },
	], []);

	const multiviewAction = (isBiview) => {

		setLastMapType(map.type);
		map.setStreetViewPanorama({ default: false, panoMap: multiview.streetMap.getMap() });
		map.toggleStreetViewStreets();
		map.setCursor({ cursor: 'crosshair'});

		map.disableEntityClicking();
		setCurrentListener(
			map.addListener({
				type: constants.listeners.click,
				executeOnce: true,
				action: (e) => {

					map.enableEntityClicking();
					map.center = e.location;
					map.setCursor({ cursor: ''});
					map.toggleStreetViewStreets();
					map.setStreetViewControl({ visible: true });

					if (isBiview)
						multiview.showBiview();	
					else
						multiview.showTriview();	

					multiview.streetMap.setStreetViewPosition({ location: e.location });
					multiview.aerialMap.center = e.location;
					
					// Workaround for Google street view bug where it displays a mostly black screen after switching
					// from regular street view to multiview (and we toggle the map's street view from default to our external
					// multiview element). Adjust the multiview element to force a redraw.
					const elem = document.getElementById('street_map');
					const width = elem.style.width;
					elem.style.width = 99 + '%';
					setTimeout(() => {
						elem.style.width = width;
					}, 100);

					setStreetSideActive(true);
				}
			})
		);
	};

	const streetViews = useMemo(
		() => {
			return [
				{ header: true, text: translate('street_views') },
				{ icon: icons.person, text: translate('street_view'), action: () => {		
					
					setLastMapType(map.type);
					map.setStreetViewPanorama({ default: true });
					map.toggleStreetViewStreets();
					map.setCursor({ cursor: 'crosshair'});
					map.disableEntityClicking();

					if (currentListener !== null)
						map.removeListener(currentListener);

					setCurrentListener(
						map.addListener({
							type: constants.listeners.click,
							executeOnce: true,
							action: (e) => {
								
								map.setCursor({ cursor: ''});
								map.toggleStreetViewStreets();
								map.enableEntityClicking();
								map.setMapStreetViewPosition({ location: e.location });
	
								setStreetSideActive(true);
							}
						})
					);
				}},
				{ icon: icons.person, text: translate('street_bi_view'), action: () => {
					multiviewAction(true);
				}},
				{ icon: icons.person, text: translate('street_tri_view'), action: () => {
					multiviewAction(false);
				}}
			]
	},[ currentListener]);

	const getThematics = useCallback(
		() =>{

		var noThemes = [{
			contextMenu: true,
			icon: icons.infoCircle,
			text: translate('none'),
			disabled: true
		}];

		var filteredThemes = map.layers.filter(x => x.group === constants.layers.groups.thematic).map(thematic =>{
			return {
				contextMenu: true,
				needsListener: false,
				icon: icons.mapLocation,
				text: thematic.text,
				action: () => {

					map.setCursor({ cursor: 'crosshair'});

					if (currentListener !== null)
						map.removeListener(currentListener);

					setCurrentListener(
						map.addListener({
							type: constants.listeners.click,
							executeOnce: true,
							action: (e) => {
								
								map.setCursor({ cursor: ''});
								
								switch (thematic.id) {
									case constants.themes.parcel:
										parcel.parcelInformation({
											location: e.location
										});
										break;
									case constants.themes.tmxline:
										trafficMetrix.tmxInformation({
											location: e.location
										});
										break;
									default:
										cosmetic.thematicInformation({
											id: thematic.id,
											location: e.location
										});
										break;
								}
							}
						})
					);					
				}
			};
		});

		var thematicGrouping = [
			{contextMenu: true, needsListener: false, header: true, text: translate('thematic_information')},
			...(filteredThemes.length === 0 ? noThemes : filteredThemes)
		];

		setThematics(thematicGrouping);

	}, [currentListener]);

	const contextMenuItems = useMemo (() =>{
		return [
			{ allowInViewer: true, header: true, text: translate('actions') },
			{ allowInViewer: true, icon: icons.thumbtack, text: translate('add_pushpin'), onClick: () => { cosmetic.addPushpin(lastMapRightClick);  } },
			{ allowInViewer: true, icon: icons.locationDot, text: translate('geocode'), onClick: () => { cosmetic.geocode(lastMapRightClick); } },
			{ allowInViewer: false, icon: icons.lasso, text: translate('select'), onClick: () => { selections.select(); }},
			{ allowInViewer: true, icon: icons.toolbox, text: translate('tools'), items: mapTools.filter(x => x.contextMenu).map(item =>{
				
				var clone = _.cloneDeep(item);

				if (_.isFunction(clone.action))
					clone.onClick = () =>{ clone.action(lastMapRightClick); };

				return clone;

			}) },
			{ allowInViewer: true, icon: icons.person, text: translate('street_views'), disabled: streetSideActive, items: streetViews.map(item =>{
				
				var clone = _.cloneDeep(item);

				if (_.isFunction(clone.action))
					clone.onClick = () =>{ clone.action(); };

				return clone;

			}) },
			{ allowInViewer: false, icon: icons.infoCircle, text: translate('thematic_information'), items: thematics.map(item =>{
				
				var clone = _.cloneDeep(item);

				if (_.isFunction(clone.action))
					clone.onClick = () =>{ clone.action(); };

				return clone;

			}) },
			{ allowInViewer: false, icon: icons.eraser, text: translate('clear_map'), items: [{ icon: icons.check, text: translate('confirm'), onClick: ()=>{map.clear();}}] }
		].filter(x => x.allowInViewer || !helpers.isViewer()).map(item =>{ return wrapContextMenuItem(item); });
	}, [lastMapRightClick, mapTools, streetViews, thematics, streetSideActive]);

	useEffect(() =>{

		const setCompass = () =>{
			switch(map.heading){
				default:
					setHeading('N');
					break;
				case 45:
					setHeading('NW');
					break;
				case 90:
					setHeading('W');
					break;
				case 135:
					setHeading('SW');
					break;
				case 180:
					setHeading('S');
					break;
				case 225:
					setHeading('SE');
					break;
				case 270:
					setHeading('E');
					break;
				case 315:
					setHeading('NE');
					break;					
			}		
		};

		map.addListener({
			type: constants.listeners.mouseMove,
			action: (e) => {
				setMouseLocation(e.location);
			}
		});

		map.addListener({
			type: constants.listeners.mapTypeChange,
			action: () => {
				setTiltable(map.tiltable);
				
				setCompass();
			}
		});

		map.addListener({
			type: constants.listeners.viewChange,
			action: () => {				
				setZoom(map.zoom);
				setTiltable(map.tiltable);				
				setCompass();
				setRotatable(map.zoom >= 12 && map.type === constants.map.types.birdsEye);
			}
		});

		map.addListener({
			type: constants.listeners.layerAdd,
			action: () => {
				getThematics();
			}
		});

		map.addListener({
			type: constants.listeners.layerRemove,
			action: () => {
				getThematics();
			}
		});

		map.addListener({
			type: constants.listeners.rightClick,
			action: (e) => {
				setContextMenuVisible(true);
				setLastMapRightClick(e);
			}
		});

		multiview.streetMap.addListener({
			type: constants.listeners.panoPositionChange,
			action: () => {				
				map.center = multiview.streetMap.position;
				multiview.aerialMap.center = multiview.streetMap.position;
			}
		});

		getThematics();

	}, [getThematics]);

	var leftClasses = ['app-map-control-left'];
	var backgroundClasses = ['app-map-control-background'];

	if (navigationCollapsed){
		leftClasses.push('app-map-control-left-collapsed');
		backgroundClasses.push('app-map-control-left-collapsed');
	}		
	else if (activeSection)
	{
		leftClasses.push('app-map-control-left-active');
		backgroundClasses.push('app-map-control-left-active');
	}		

	if (rotatable)
		backgroundClasses.push('app-map-control-background-rotate');

	if (mapControl.isCurrentMapFixed()){
		leftClasses.push('app-map-control-background-fixed');
		backgroundClasses.push('app-map-control-background-fixed');
	}

    return <>
		{streetSideActive ? <Button className='app-map-control-street-view-close' theme='primary' icon={icons.x} onClick={()=>{
			map.type = lastMapType;
			multiview.hide();
			setStreetSideActive(false);
			map.setStreetViewControl({ visible: false });
		}} /> : ''}
		<ContextMenu target={"#map"} items={contextMenuItems} visible={contextMenuVisible} onHide={() => { setContextMenuVisible(false); }} />
		{collapsed ? '' : 
			<div className={backgroundClasses.join(' ')}>
				<div className='app-map-control-background-top' />
				{helpers.isViewer() && mapControl.isCurrentMapFixed() ? null : <div className='app-map-control-background-bottom' />}				
				{_.isObject(currentMap) ? 
					<div className='app-map-control-current-map'>
						{
							helpers.isViewer() ? null : <Button 
								className={'app-map-control-current-map-default'} tooltip={translate('default_map')}
								theme='map' 
								icon={icons.star} 
								active={isDefaultMap}
								onClick={()=>{ 
									const isDefault = !(currentMap.Information?.Id === userPreferences.DefaultMap)
									const result = projectsModule.updateDefaultMap({mapId: currentMap.Information.Id, isDefault: isDefault});
									if (result)
										setIsDefaultMap(isDefault);
								}} 
								disabled={!userPreferences.AllowSetDefaultMap} 
							/>
						}                    	
						{currentMap.Information.Name}
						<div className='app-map-control-current-map-actions'>
						{
							helpers.isViewer() ? null : 
							<>
								<Popover ref={mapSearchWindow}
									parentId={'#app-map-control-map-search-button'} 
									title={translate('map_search')}
									width={600}
									height={630}
								>		
									<MapSearch objectId={currentMap.Information.Id} 
										sharingObjectType={constants.sharingObjectType.map} 
										onClose={() => {mapSearchWindow.current.instance.hide();}}
										gridHeight='475px'
										showCancelButton={false}
									/>
								</Popover>                            
								{							
									!currentMap.Information.IsCreator ? null : <>
										<Popover ref={shareWindow}
											parentId={'#app-map-control-current-map-share-button'} 
											className={'app-map-control-current-map-share-popover'}
											showTitle={false}
											width={600}
											height={630}
										>		
											<SharedUsers objectId={currentMap.Information.Id} 
												sharingObjectType={constants.sharingObjectType.map} 
												onClose={() => {shareWindow.current.instance.hide();}}
												gridHeight='475px'
												showCancelButton={false}	
											/>
										</Popover>									
										<Button 
											id={'app-map-control-current-map-share-button'}
											className={'app-map-control-current-map-actions-share'}
											theme='map' 
											icon={icons.shareAlt} 
											tooltip={translate('share')} 
										/>			
										{userPreferences.HasPublisher ? 
											<>
											{
												isPublished ? 
												<MenuButton 
													className={'app-map-control-current-map-actions-publish'}
													active={isPublished} 
													theme='map' 
													icon={icons.towerBroadcast} 
													tooltip={translate('publish')} 
													items={[
														{text: translate('unpublish'), onClick: () => { 
															availableMaps.publishMap({ id: currentMap?.Information?.Id, publish: false }); 
															setIsPublished(false);
														}},
														{text: translate('generate_link'), onClick: () => { projectsModule.generatePublishMapLink(currentMap?.Information?.Id); }},
													]} 
												/>
												: 
												<Button 
													className={'app-map-control-current-map-actions-publish'}
													active={isPublished} 
													theme='map' 
													icon={icons.towerBroadcast} 
													tooltip={translate('publish')} 
													onClick={() => {
														availableMaps.publishMap({ id: currentMap?.Information?.Id, publish: true });
														setIsPublished(true);
													}}
												/>
											}
											</>
										: null}																			
									</>
								}
								<Button 
									id={'app-map-control-map-search-button'}
									className={'app-map-control-current-map-actions-search'}
									theme='map' 
									icon={icons.magnifyingGlass} 
									tooltip={translate('search')} 
								/>	                            
								<MenuButton 
									className={'app-map-control-current-map-actions-close'}
									icon={icons.map} 
									theme='map' 
									items={
									[
										{icon: icons.x, text: translate('close'), onClick: () =>{setCurrentMap(null)}},
										{icon: icons.x, text: translate('close_and_reset'), onClick: () =>{
											setCurrentMap(null);
											map.reset();
										}}
									]
								}/>
							</>
						}
						</div>
					</div> 
					: ''
				}
			</div>
		}
		<div className={leftClasses.join(' ')}>
			{collapsed ? <Button className='app-map-control-collapse-button' theme='map' icon={icons.rightArrow} tooltip={translate('expand')} onClick={() => {setCollapsed(!collapsed)}} /> 
			: ''
			}
			{collapsed || (helpers.isViewer() && mapControl.isCurrentMapFixed()) ? '' :
				<RadioButtonSet>
					{rotatable ? <Button icon={icons.rotateLeft} tooltip={translate('rotate_left')} theme='map' onClick={()=>{map.heading += 90;}}/> : ''}
					<Button theme='map' text={heading} onClick={()=>{map.heading = 0;}} />
					{rotatable ? <Button icon={icons.rotateRight} tooltip={translate('rotate_right')} theme='map' onClick={()=>{map.heading -= 90;}}/> : ''}				
				</RadioButtonSet>
			}					
			{collapsed ? '' :
				<div className={(rotatable ? 'app-map-control-actions' : 'app-map-control-actions no-rotate')}>
					<MenuButton className='app-map-control-button' icon={icons.map} theme='map' items={
						[{ header: true, text: translate('map_type') }].concat(
							map.getTypes().map(type=>{
								return { imageClassName: 'app-map-control-map-type', text: type.name, image: type.image, onClick: () =>{ map.type = type.id; }}
							})
						) 
					}/>
					<Button className='app-map-control-button' icon={icons.tag} tooltip={translate('toggle_labels')} theme='map' active={map.showAerialLabels} disabled={map.type != constants.map.types.aerial && map.type != constants.map.types.birdsEye } onClick={() => { 
						map.showAerialLabels = !map.showAerialLabels;
						map.type = map.type;
					}}/>
					<MenuButton className='app-map-control-button' tooltip={translate('street_view')} theme='map' disabled={streetSideActive} items={streetViews.map(item => { return wrapListener(item); })} />
					<RadioButtonSet className='app-map-control-button'>				
						<Button icon={icons.traffic} tooltip={translate('traffic')} theme='map' active={map.trafficVisible} onClick={() => {map.trafficVisible = !map.trafficVisible;}} />
						<Button icon={icons.trainSubway} tooltip={translate('transit')} theme='map' active={map.transitVisible} onClick={() => {map.transitVisible = !map.transitVisible;}} />
					</RadioButtonSet>
					{
						helpers.isViewer() ? 
							<Button className='app-map-control-button' icon={icons.print} tooltip={translate('print_map')} theme='map' disabled={false} onClick={() => { projects.openPrintMap(); }}/>
							:
							<RadioButtonSet className='app-map-control-button'>
								<Button icon={icons.save} tooltip={translate('save_map')} theme='map' disabled={streetSideActive} onClick={() => { projects.openSaveMap(); }}/>
								<Button icon={icons.print} tooltip={translate('print_map')} theme='map' disabled={false} onClick={() => { projects.openPrintMap(); }}/>
								<ConfirmButton icon={icons.eraser} tooltip={translate('clear_map')} theme='map' onConfirm={() => {map.clear();}} />
							</RadioButtonSet>
					}
					<RadioButtonSet className='app-map-control-button'>
						<MenuButton tooltip={translate('map_tools')} theme='map' items={mapTools.map(item => { return wrapListener(item); })}/>
						{helpers.isViewer() ? null : <MenuButton icon={icons.pointer} tooltip={translate('information')} theme='map' items={selectionTools.concat(thematics).map(item => { return wrapListener(item, true); })}/>}						
						<Button icon={icons.grab} tooltip={translate('pan')} theme='map' onClick={()=>{ 
							map.setCursor({ cursor: '' });
							map.removeListener(currentListener);
							map.stopDrawing(); 
							selections.active = false; 
							setCurrentListener(null); 
						}} />
					</RadioButtonSet>
					<Button className='app-map-control-button' icon={icons.crosshair} tooltip={translate('locate')} theme='map' onClick={() => {
						cosmetic.locate()
					}} />
					<Button className='app-map-control-button' icon={icons.addScreen} tooltip={translate('multi_view')} theme='map' disabled={true} />
					<Button className='app-map-control-button' icon={icons.leftArrow} tooltip={translate('collapse')} theme='map' onClick={() => {setCollapsed(!collapsed)}} />
				</div>
			}			
			{collapsed || (helpers.isViewer() && mapControl.isCurrentMapFixed()) ? '' :			
				<div className='app-map-control-vertical'>
					<RadioButtonSet vertical={true}>
						<Button className='app-map-control-button-zoom-in' icon={icons.plus} tooltip={translate('zoom_in')} theme='map' onClick={()=>{map.zoom++;}} />
						<Button className='app-map-control-button-zoom' text={zoom}  theme='map'/>
						<Button className='app-map-control-button-zoom-out' icon={icons.minus} tooltip={translate('zoom_out')} theme='map' onClick={()=>{map.zoom--;}} />
					</RadioButtonSet>
					{tiltable ? 
						<RadioButtonSet className='app-map-control-button' vertical={true}>
							<Button icon={icons.up} tooltip={translate('tilt_up')} theme='map' onClick={()=>{map.tilt += 20}}/>
							<Button icon={icons.separatedGrid} tooltip={translate('flatten')} theme='map' onClick={()=>{map.tilt = 0;}}/>
							<Button icon={icons.down}  tooltip={translate('tilt_down')}theme='map' onClick={()=>{map.tilt -= 20;}}/>
						</RadioButtonSet>
					: ''}				
				</div>
			}
							
		</div>
		<div className='app-map-control-location'>
			<Icon className='app-map-control-location-icon' icon={icons.crosshair} />
			<div className='app-map-control-location-text'>
				{mouseLocation ? `${mouseLocation.lat.toFixed(6)}, ${mouseLocation.lon.toFixed(6)}` : translate('no_location') }
			</div>			
		</div>
	</>
}