import { helpers } from '../utils/helpers';
import { translate } from '../utils/translation';
import { constants } from '../utils/constants';
import { map } from '../components/app/map/map';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { quickReports } from '../components/app/quickReports/quickReports';
import { layers } from '../components/app/layers/layers';
import { icons } from '../components/base/icon/icon';
import { layerActions } from '../components/app/layers/layer/layer';
import { standardGeographies } from './standardGeographies';
import { activityHub } from '../components/app/activityHub/activityHub';
import { cosmetic } from './cosmetic';
import { ShapeEdit } from '../components/app/shapeEdit/shapeEdit';
import { ShapeDownloader } from '../components/app/shapeDownloader/shapeDownloader';
import { InfoForm } from '../components/app/forms/infoForm/infoForm';

const _ = require("lodash");

export const tradeAreas = {
    getDefaults: (o) =>{
        o = o ?? {};
        return legacyEndpoints.service({
            name: 'GetTradeAreaModule',
            parameters: {
                dataSourceId: o.dataSourceId,
                customQueryId: o.customQueryId,
                pointId: o.pointId,
                latitude: o.latitude,
                longitude: o.longitude,
                filteredTypes: o.filteredTypes
            }
        });
    },
    getLibraries: function () {
        return map.layers.filter(layer => layer.type === constants.layers.types.tradeArea);
    },    
    getLibrary: function(o) {
        return map.layers.filter(layer => layer.type === constants.layers.types.tradeArea).find(layer => { return layer.id.toLowerCase() === (`${o.entity.id}_${o.entity.layer.id}_tal`).toLowerCase() });
    },
    createLabel: (layer, entity, label, id) =>{

		var svgLabel = helpers.createSvgLabel({
			text: entity.label,
			label: label,
			parentWidth: entity.width,
			parentHeight: entity.height
		});
		layer.addEntity({ 
			type: constants.entities.label, 
			location: helpers.getNorthernLocation(entity.paths),
			image: svgLabel.icon,
			anchor: svgLabel.anchor,
			visible: layer.visible,
			draggable: false,
            metaData: { parentId: id }
		});
	},
    updateTradeAreasLabels: (layer) =>{
        layer.tradeAreas.forEach((tradeArea) => {
            if (!tradeArea.autoLabel) return;

            const layerLabelForTradeArea = layer.labels.find(x => x.metaData.parentId === tradeArea.id);

            if (_.isObject(layerLabelForTradeArea)) {
                tradeArea.label.title = tradeArea.name;
                tradeArea.label.labelPoint = layerLabelForTradeArea.location;
                tradeArea.label.anchor = layerLabelForTradeArea.anchor;             
            }
        });
    },
    getUniqueLibraryName: function (name) {

        const isLibraryNameValid = (o) => {
            var layers = tradeAreas.getLibraries();
            var value = !_.isObject(layers.find(x => x.text === o.name));

            return value;
        }  

        var desiredName = name;
        var i = 1;

        while (!isLibraryNameValid({ name: desiredName }))
            desiredName = `${name} ${i++}`;        

        return desiredName;
    },    
    generate: async function (o) {        
        
        var userDrawnTradeAreas = o.tradeAreas.filter(x => x.generated === false && x.type === constants.tradeAreas.types.userDrawn);
        var geographyTradeAreas = o.tradeAreas.filter(x => x.generated === false && x.type === constants.tradeAreas.types.geography);
        var serviceTradeAreas = o.tradeAreas.filter(x => x.generated === false && x.type !== constants.tradeAreas.types.geography && x.type !== constants.tradeAreas.types.userDrawn);
        
        var layer = this.getLibrary({ entity: o.entity });
        
        if (layer)
            layer.clear();
        else
            layer = map.addLayer({
                id: `${o.entity.id}_${o.entity.layer.id}_tal`,
                group: constants.layers.groups.tradeArea,
                type: constants.layers.types.tradeArea,
                text: this.getUniqueLibraryName(o.entity.text),
                visible: !_.isUndefined(o.visible) ? o.visible : true,
                actions: [{
                    id: layerActions.visible,
                    getActive: () => { return layer.visible; },
                    getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
                    onClick: () =>{
                        layer.visible = !layer.visible;
                    }
                },
                {
                    id: layerActions.zoom,                                    
                    onClick: () =>{
                        map.locate({ location: o.entity.location });
                    }
                },
                {
                    id: layerActions.delete,
                    onClick: () =>{
                        layer.dispose();
                    }
                }],
                onChange: ()=>{
                    layers.update();
                    quickReports.update();
                }
            });

        const getTypeIcon = (type) =>{            
            switch(type){
                case constants.tradeAreas.types.ring:
                    return icons.circle;
                case constants.tradeAreas.types.driveTime:
                    return icons.car;
                case constants.tradeAreas.types.driveDistance:
                    return icons.ruler;
                case constants.tradeAreas.types.userDrawn:
                    return icons.hexagon;
                case constants.tradeAreas.types.geography:
                    return icons.globe;
            }
        };

        const addToMap = () =>{

            _.reverse(
                _.sortBy(o.tradeAreas.map((tradeArea) => {

                var paths = [];
                var metaData = {
                    tradeAreaType: tradeArea.type,
                    geographyIds: tradeArea.geographyIds,
                    vintageId: tradeArea.geographyVintage,
                    distance: tradeArea.distance,
                    lengthMeasurement: tradeArea.lengthMeasurement,
                    savedTradeAreaType: tradeArea.savedType                
                };

                switch(tradeArea.polygon.polygonType){                    
                    default:
                        paths = helpers.decodeLocations(tradeArea.polygon.polygonString);
                        break;
                    case constants.encodedString.wkt:
                        paths = helpers.parseWkt(tradeArea.polygon.polygonString);   
                        metaData.wkt = tradeArea.polygon.polygonString;
                        break;
                }
    
                layer.legend.push({
                    text: tradeArea.name,
                    icon: getTypeIcon(tradeArea.type === constants.tradeAreas.types.savedShape ? tradeArea.savedType : tradeArea.type),
                });
                
                var entityType;
                switch (tradeArea.type) 
                {
                    case constants.tradeAreas.types.savedShape:
                        switch(tradeArea.savedType)
                        {
                            case constants.tradeAreas.types.ring:
                                entityType = constants.entities.circle;
                                break;
                            case constants.tradeAreas.types.geography:
                                entityType = constants.entities.standardGeography;
                                break;
                            default:
                                entityType = constants.entities.polygon;
                                break;
                        }
                        break;
                    case constants.tradeAreas.types.ring:
                        entityType = constants.entities.circle
                        break;
                    case constants.tradeAreas.types.geography:
                        entityType = constants.entities.standardGeography;
                        break;
                    default:
                        entityType = constants.entities.polygon;
                        break;
                }

                var entity = layer.addEntity({
                    text: tradeArea.name,
                    label: tradeArea.label,
                    type: entityType,
                    paths: paths,
                    fillColor: tradeArea.fillColor,
                    strokeColor: tradeArea.lineColor,
                    metaData: metaData,
                    listeners: [{
                        type: constants.listeners.click,
                        action: (e) =>{
                            tradeAreas.showActivityHub({entity: e.entity, editStandardGeographyTradeArea: () => { layer.clear(); generateGeographyTradeArea(tradeArea);}});
                        }
                    }],
                    onEdit: (result) =>{
                        tradeArea.fillColor = result.fillColor;
                        tradeArea.lineColor = result.strokeColor;
                        tradeArea.lineWidth = result.strokeWidth;

                        if (tradeArea.Name !== result.text)
                        {
                            tradeArea.name = result.text;
                            tradeArea.dirtyName = true;
                            layer.clear();
                            nextStep();
                        }    
                    },
                    onReshape: (result) =>{ 
                        switch (tradeArea.type)
                        {
                            case constants.tradeAreas.types.ring:
                                tradeArea.polygon.polygonString = helpers.encodedLocations(result.locations);
                                tradeArea.distance = helpers.formatNumber(helpers.convertLength(result.radius, constants.lengthMeasurements.meters, tradeArea.lengthMeasurement).toFixed(2));
                                if (tradeArea.dirtyName !== true) 
                                {
                                    tradeArea.name = tradeAreas.calculateTradeAreaName(tradeArea);
                                    layer.clear();
                                    nextStep();
                                }
                                break;
                            default:
                                tradeArea.polygon.polygonString = helpers.encodedLocations(result.locations);
                                break;
                        }                     

                        quickReports.update({ id: entity.id });
                    }
                });

                if (tradeArea.autoLabel) 
                    tradeAreas.createLabel(layer, entity, tradeArea.label, tradeArea.id);

                return entity;

            }), 
            'area')).forEach((x, i) =>{ x.zIndex = x.zIndex + i; });

            layer.tradeAreas = o.tradeAreas;

            tradeAreas.updateTradeAreasLabels(layer);        

            if (o.entity.type === constants.entities.point)
                layer.metaData = {
                    allowShapeDelete: o.entity.layer.metaData.serviceAttributes.AllowShapeDelete,
                    allowShapeEdit: o.entity.layer.metaData.serviceAttributes.AllowShapeEdit,
                    allowShapeView: o.entity.layer.metaData.serviceAttributes.AllowShapeView,
                    center: o.entity.location,
                    customQueryId: o.entity.layer.metaData.serviceAttributes.ID,
                    dataSourceGUID: o.entity.layer.metaData.serviceAttributes.DataSourceGUID,
                    dataSourceId: o.entity.layer.metaData.serviceAttributes.DataSourceId,
                    pointId: o.entity.id
                };
            else if (o.entity.type === constants.entities.pushpin)
                layer.metaData = {
                    allowShapeDelete: false,
                    allowShapeEdit: false,
                    allowShapeView: false,
                    center: o.entity.location,
                    customQueryId: helpers.emptyGuid(), // indicate that this belongs to the cosmetic layer
                    dataSourceGUID: null,
                    dataSourceId: null,
                    pointId: o.entity.id
                };
                
            layers.update();
            quickReports.update();
        };

        const nextStep = ()=> {
            if (userDrawnTradeAreas.length > 0)
                drawPolygon(userDrawnTradeAreas[0]);
            else if (geographyTradeAreas.length > 0)
                generateGeographyTradeArea(geographyTradeAreas[0]);
            else if (serviceTradeAreas.length > 0)
                generateServiceTradeAreas(serviceTradeAreas);
            else
                addToMap(o.tradeAreas); 
        };

        const generateServiceTradeAreas = async (tradeAreas) =>{
            
            var result = await legacyEndpoints.service({
                name: 'GenerateTradeAreas',
                parameters: {
                    latitude: o.entity.location.lat,
                    longitude: o.entity.location.lon,
                    tradeAreas: tradeAreas
                }
            });

            serviceTradeAreas.forEach(tradeArea => tradeArea.polygon = result.find(x=> x.id === tradeArea.id).polygon);
            serviceTradeAreas = [];

            nextStep();
        };

        const generateGeographyTradeArea = (tradeArea) => {
            standardGeographies.open({
                title: tradeArea.name,
                tradeArea: tradeArea,
                vintages: o.standardGeographies,
                onSave: (o) =>{
                    geographyTradeAreas = _.without(geographyTradeAreas, tradeArea);                    
                    tradeArea.polygon.polygonString = o.wkt;
                    tradeArea.polygon.polygonType = constants.encodedString.wkt;
                    tradeArea.geographyIds = o.ids;
                    tradeArea.geographyVintage = o.vintageId;
                    tradeArea.geoLevel = o.geoLevelId;
                    tradeArea.demographicReport = o.reportId;
                },
                onClose: () => {
                    layer.clear();
                    nextStep();
                }
            });
        };

        const drawPolygon = (tradeArea) =>{

            map.startDrawing({
                type: constants.entities.polygon,
                onTooltipRender: (result) =>{
    
                    if (result.locations.length < 3)
                        return {
                            sections: [<div>{translate('add_at_least_3_vertices')}</div>]
                        };
    
                    return { sections: [] };
                },
                onFinish: async (shape) =>{

                    userDrawnTradeAreas = _.without(userDrawnTradeAreas, tradeArea);
                    
                    tradeArea.polygon.centroid = map.computeCenter({ locations: shape.locations });
                    tradeArea.polygon.polygonString = helpers.encodedLocations(shape.locations);
                    tradeArea.polygon.polygonType = constants.encodedString.google;

                    nextStep();                   
                }
            });
        };        

        nextStep();

    },
    calculateTradeAreaName: function(tradeArea){

        var type = '';
        var distance = `${tradeArea.distance} `;
        var lengthMeasurement = '';        

        switch(tradeArea.lengthMeasurement)
        {
            default:
                break;
            case constants.lengthMeasurements.feet:
                lengthMeasurement = `${translate('feet')} `;
                break;
            case constants.lengthMeasurements.miles:
                lengthMeasurement = `${translate('mile')} `;
                break;
            case constants.lengthMeasurements.meters:
                lengthMeasurement = `${translate('meter')} `;
                break;
            case constants.lengthMeasurements.kilometers:
                lengthMeasurement = `${translate('kilometer')} `;
                break;                
        }

        switch(tradeArea.type)
        {
            default:
                break;
            case constants.tradeAreas.types.ring: 
                type = translate('ring');
                break;
            case constants.tradeAreas.types.driveTime: 
                type = translate('drive_time');
                lengthMeasurement = `${translate('minute')} `;
                break;
            case constants.tradeAreas.types.driveDistance: 
                type = translate('drive_distance');
                break;
            case constants.tradeAreas.types.geography: 
                return translate('geography');
            case constants.tradeAreas.types.userDrawn: 
                return translate('user_drawn');
            case constants.tradeAreas.types.savedShape: 
                return tradeArea.name;
            case constants.tradeAreas.types.dataFit: 
                return translate('data_fit');
            case constants.tradeAreas.types.custom: 
                return translate('custom');
        }

        return `${distance}${lengthMeasurement}${type}`;
    },
    showActivityHub: (options)=>{
        activityHub.open({
            entity: options.entity,
            type: 'tradeArea',
            renderContent: async () =>{

                var tabs = [
                    { id: constants.activityHub.actions.information, component: <InfoForm form={cosmetic.getInformation(options.entity)} /> },
                ];

                switch(options.entity.type)
                {
                    default:
                        return []
                    case constants.entities.circle:
                    case constants.entities.polygon:
                    case constants.entities.rectangle:
                        return [...tabs,                            
                            { id: constants.activityHub.actions.edit, component: <ShapeEdit entity={options.entity} />},
                            { id: constants.activityHub.actions.reshape, onClick: ()=>{ options.entity.reshape(); activityHub.close();} },
                            { id: constants.activityHub.actions.select },
                            { id: constants.activityHub.actions.demographics },
                            { id: constants.activityHub.actions.download, component: <ShapeDownloader entity={options.entity} /> }
                        ];
                    case constants.entities.standardGeography:
                        return [...tabs,
                            { id: constants.activityHub.actions.edit, component: <ShapeEdit entity={options.entity} />},
                            { id: constants.activityHub.actions.reshape, onClick: ()=>{ options.editStandardGeographyTradeArea(); activityHub.close();} },
                            { id: constants.activityHub.actions.select },
                            { id: constants.activityHub.actions.download, component: <ShapeDownloader entity={options.entity} isGeometryCollection={true} /> }
                        ];
                }
            }
        });
    },
    setDefaultTemplate: async ({ id, location }) =>{
        return await legacyEndpoints.service({
            name: 'DefaultTradeAreaTemplate',
            parameters: {
                tradeAreaTemplateId: id,
                latitude: location.lat,
                longitude: location.lon
            }
        });
    },
    deleteTemplate: async ({ id, location }) =>{

        return await legacyEndpoints.service({
            name: 'RemoveTradeAreaTemplate',
            parameters: {
                tradeAreaTemplateId: id,
                latitude: location.lat,
                longitude: location.lon
            }
        });

    },
    saveTemplate: async ({ template, location}) =>{
        return await legacyEndpoints.service({
            name: 'SaveTradeAreaTemplate',
            parameters: {
                tradeAreaTemplate: template,
                latitude: location.lat,
                longitude: location.lon
            }
        });
    }
};