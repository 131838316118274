import { useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '../button/button';
import { translate } from '../../../utils/translation';
import { icons } from '../icon/icon';

import { Popup, Position } from 'devextreme-react/popup';

const _ = require("lodash");

export function Window({ id, children, className, visible=true, resizable=true, setVisible, width='300px', height='300px', maxWidth, maxHeight,
                        dragEnabled=true, allowMinimize, startMinimized=false, onClose, title, position, containerPosition, pixel, container, entity = null,
                        enableBodyScroll=false, modal=false, showCloseButton=true, hideOnOutsideClick=false }) {
    
    const ref = useRef(null);
    var [_id, setId] = useState(id);
    var [_width, setWidth] = useState(width);
    var [_height, setHeight] = useState(height);
    var [restoreWidth, setRestoreWidth] = useState(width);
    var [restoreHeight, setRestoreHeight] = useState(height);

    useEffect(() =>{
        setId(_.isString(id) ? id : _.uniqueId('app-window-'));
    }, [id]);

    useEffect(() =>{
        setWidth(width);
        setHeight(startMinimized ? 100 : height);
    }, [width, height]);

    const renderTitle = useCallback(() =>{
        return <>
            {title}
            {(showCloseButton ? <Button 
                className='app-window-close-button'
                theme='action' 
                icon={icons.x} 
                tooltip={translate('close')} 
                onClick={() => {
                    setVisible(false);

                    if (_.isFunction(onClose))
                        onClose(); 
                }} /> : '')}
            {(
                allowMinimize ? 
                <>
                    <Button
                        className='app-window-restore-button'
                        theme='action'
                        icon={icons.windowRestore}
                        tooltip={translate('restore')}
                        onClick={() =>{                            
                            setHeight(restoreHeight);
                            ref.current.instance.repaint();
                        }}
                    />
                    <Button
                        className='app-window-minimize-button'
                        theme='action'
                        icon={icons.windowMinimize}
                        tooltip={translate('minimize')}
                        onClick={() =>{
                            setHeight(100);
                            ref.current.instance.repaint();
                        }}
                    />                    
                </> : ''
                
            )}
            
        </>
    }, [title, restoreHeight, _height]);

    var classes = ['app-window'];

    if (className)
        classes.push(className);

    var wrapperAttr = {
        class: classes.join(' ')
    };

    const openLocation = ()=>{

        if (!_.isString(container))
            return null;

        var entityX = entity === null ? 0 : entity.width / 2;
        var entityY = entity === null ? 0 : entity.height * 2;
        var containerWidth = document.querySelector(container).offsetWidth;

        // take into account the KLI header and map tab bar (90px total height), which the devextreme
        // popup appears to do when applying its position offset
        var containerHeight = document.querySelector(container).offsetHeight + 90;
        
        var quadrantX = containerWidth * 0.5 < pixel.x ? 1 : 0;
        var quadrantY = containerHeight * 0.5 < pixel.y ? 1 : 0;

        var screenX = 0;
        var screenY = 0;

        var modifiedHeight = parseFloat(height);

        // determine max Y offset so that the top of the popup is always visible (and the user can access the close button)
        var maxOffsetY = -((containerHeight / 2) - (modifiedHeight / 2) - 90);

        switch (`${quadrantY}${quadrantX}`) {
            case '00':
            
                screenX = -(containerWidth / 2) + parseFloat(width) / 2 + pixel.x + entityX;
                screenY = -(containerHeight / 2) + modifiedHeight / 2 + pixel.y + entityY;
                
                if (screenY + containerHeight > containerHeight)
                    screenY -= ((screenY + containerHeight) - containerHeight) / 2;    

                if (screenY < maxOffsetY)
                    screenY = maxOffsetY;
                
                if (screenX + containerWidth > containerWidth)
                    screenX += (containerWidth - (screenX + containerWidth)) / 2;

                return `${screenX} ${screenY}`;
            case '01':
                
                screenX = -(containerWidth / 2) - parseFloat(width) / 2 + pixel.x + entityX;
                screenY = -(containerHeight / 2) + modifiedHeight / 2 + pixel.y + entityY;

                if (screenY + containerHeight > containerHeight)
                    screenY -= ((screenY + containerHeight) - containerHeight) / 2;   

                if (screenY < maxOffsetY)
                    screenY = maxOffsetY;
                
                if (screenX + containerWidth > containerWidth)
                    screenX -= (containerWidth - (screenX + containerWidth)) / 2;

                return `${screenX} ${screenY}`;
            case '10':

                screenX = -(containerWidth / 2) + parseFloat(width) / 2 + pixel.x + entityX;
                screenY = -(containerHeight / 2) - modifiedHeight / 2 + pixel.y + entityY;

                if (screenY + containerHeight < containerHeight)
                    screenY += (containerHeight - (screenY + containerHeight)) / 2;

                if (screenY < maxOffsetY)
                    screenY = maxOffsetY;

                if (screenX + containerWidth > containerWidth)
                    screenX += (containerWidth - (screenX + containerWidth)) / 2;

                return `${screenX} ${screenY}`;
            case '11':
                                
                screenX = -(containerWidth / 2) - parseFloat(width) / 2 + pixel.x + entityX;
                screenY = -(containerHeight / 2) - modifiedHeight / 2 + pixel.y + entityY;
                
                if (screenY + containerHeight < containerHeight)
                    screenY += (containerHeight - (screenY + containerHeight)) / 2;

                if (screenY < maxOffsetY)
                    screenY = maxOffsetY;

                if (screenX + containerWidth > containerWidth)
                    screenX -= (containerWidth - (screenX + containerWidth)) / 2;
                                
                return `${screenX} ${screenY}`;
        }
    };
    
    return <Popup 
        ref={ref}
        visible={visible}
        container={container}
        dragEnabled={dragEnabled}
        hideOnOutsideClick={hideOnOutsideClick}
        showCloseButton={false}
        enableBodyScroll={enableBodyScroll}
        showTitle={true}
        titleRender={renderTitle}
        width={_width}
        height={_height}
        maxWidth={maxWidth ? maxWidth : undefined}
        maxHeight={maxHeight ? maxHeight : undefined}
        resizeEnabled={resizable}
        shading={modal}
        wrapperAttr={wrapperAttr}
        onResizeEnd={(o)=>{
            
            setWidth(o.width);
            setHeight(o.height);
            setRestoreHeight(o.height);
        }}
        onHiding={()=>{

            setVisible(false);

            if (onClose) 
                onClose(); 
        }}
    >
        {pixel ? <Position offset={_.isString(pixel) ? pixel : openLocation()} /> : ''}
        {position ? <Position my={position} at={containerPosition ? containerPosition : position} of={containerPosition ? container : null} /> : ''}
        <div id={_id} className='app-window-content'>
            {children}
        </div>
    </Popup>
}      