// Third party imports
import { Loader } from '@googlemaps/js-api-loader'

// React imports
import { Map } from '../map';
import { GoogleLayer } from './googleLayer';
import { translate } from '../../utils/translation';
import { constants } from '../../utils/constants';
import { drivingDirections } from './googleDrivingDirections';
import { icons } from '../../components/base/icon/icon';
import { helpers } from '../../utils/helpers';

import * as baseStyle from './styles/base.json';
import * as darkStyle from './styles/dark.json';
import * as grayStyle from './styles/gray.json';
import * as lightStyle from './styles/light.json';

const _ = require("lodash");

export class GoogleMap extends Map {

    constructor(o) {
        super(o);        
        
        new Loader({
            apiKey: `${process.env.REACT_APP_MAPS_GOOGLE_API_KEY}`,
            version: 'weekly'
        })
        .load()
        .then(async () => {

            const { Map } = await window.google.maps.importLibrary("maps");
            const { StreetViewPanorama } = await window.google.maps.importLibrary("streetView");
            const { Geocoder } = await window.google.maps.importLibrary("geocoding");
            const { AutocompleteService } = await window.google.maps.importLibrary("places");

            await window.google.maps.importLibrary("geometry");
            await window.google.maps.importLibrary("visualization");

            if (o.mapType === "streetview") {
                this._private._map = new StreetViewPanorama(document.getElementById(o.mapElement), {
                    position: new window.google.maps.LatLng(this._private._center.lat, this._private._center.lon),
                    pov: {
                        heading: 265,
                        pitch: 0
                    },
                    addressControlOptions: {
                        position: window.google.maps.ControlPosition.BOTTOM_LEFT,
                    }
                });

                if (_.isObject(o.bindMap)) {
                    o.bindMap.getMap().setStreetView(this._private._map);
                    this._private._map.addListener("position_changed", () => this._executeListeners({ type: constants.listeners.panoPositionChange, properties: { } }) );
                }
            }
            else
            {
                this._private._map = new Map(document.getElementById(o.mapElement), {
                    gestureHandling: 'greedy',
                    center: new window.google.maps.LatLng(this._private._center.lat, this._private._center.lon),
                    zoom: this._private._zoom,
                    heading: this._private._heading,
                    scaleControl: true,
                    tilt: this._private._tilt,
                    disableDefaultUI: true,
                    minZoom: this._private._minZoom,
                    maxZoom: this._private._maxZoom,
                    noClear: true,
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    styles: baseStyle.default['style'],
                    clickableIcons: false,
                    streetViewControl: false,
                    streetViewControlOptions: {
                        position: window.google.maps.ControlPosition.BOTTOM_LEFT
                    }
                });

            switch (o.type){
                default:
                    var convertGoogleEvent = (e) =>{
                        return {
                            event: e.domEvent,
                            pixel: e.pixel,
                            location: { lat: e.latLng.lat(), lon: e.latLng.lng() }
                        };
                    };
                    
                    this._private._map.addListener("idle", (e) => this._executeListeners({ type: constants.listeners.viewChange, properties: { event: e } }));
                    this._private._map.addListener("maptypeid_changed", (e) => this._executeListeners({ type: constants.listeners.mapTypeChange, properties: { event: e } }));            
                    this._private._map.addListener("click", (e) => {
                        
                        if (!e.pixel || !e.latLng)
                            return;
                        
                        this._executeListeners({ type: constants.listeners.click, properties: convertGoogleEvent(e) });
                    });
                    this._private._map.addListener("contextmenu", (e) => {
    
                        if (!e.pixel || !e.latLng)
                            return;
    
                        this._executeListeners({ type: constants.listeners.rightClick, onlyDrawing: super.isDrawing, properties: convertGoogleEvent(e) });
                    });
                    this._private._map.addListener("mousemove", (e) => {
    
                        if (!e.pixel || !e.latLng)
                            return;
    
                        this._executeListeners({ type: constants.listeners.mouseMove, properties: convertGoogleEvent(e) });
                    });
                    
                    this._private._addressSearch =  new AutocompleteService();
                    this._private._geocoder = new Geocoder();
                    this._private._directionsRenderer = new window.google.maps.DirectionsRenderer();
                    this._private._streetViewLayer.layer = new window.google.maps.StreetViewCoverageLayer();
                    this._private._trafficLayer.layer = new window.google.maps.TrafficLayer();
                    this._private._transitLayer.layer = new window.google.maps.TransitLayer();
                    this._private._bikeLayer.layer = new window.google.maps.BicyclingLayer();                        
                    this._private._rotatable = false;

                    if (o.mapElement === 'map') {
                        this._private._streetViewPanorama = this._private._map.getStreetView();
                        this._private._streetViewPanorama.setOptions({addressControlOptions: { position: window.google.maps.ControlPosition.BOTTOM_LEFT }});
                    }
    
                    this._private._pois = {};
                    this._private._pois[constants.map.pois.airPorts] = { icon: icons.plane, text: translate('airports'), visible: true };
                    this._private._pois[constants.map.pois.attractions] = { icon: icons.masksTheater, text: translate('attractions'), visible: true };
                    this._private._pois[constants.map.pois.busTerminals] = { icon: icons.bus, text: translate('bus_terminals'), visible: true };
                    this._private._pois[constants.map.pois.carRentals] = { icon: icons.car, text: translate('car_rentals'), visible: true };
                    this._private._pois[constants.map.pois.foodAndDrink] = { icon: icons.burgerSoda, text: translate('food_and_drink'), visible: true };
                    this._private._pois[constants.map.pois.gasStations] = { icon: icons.gasPump, text: translate('gas_stations'), visible: true };
                    this._private._pois[constants.map.pois.government] = { icon: icons.scaleBalanced, text: translate('government'), visible: true };                
                    this._private._pois[constants.map.pois.lodging] = { icon: icons.hotel, text: translate('lodging'), visible: true };
                    this._private._pois[constants.map.pois.medical] = { icon: icons.suitcaseMedical, text: translate('medical'), visible: true };
                    this._private._pois[constants.map.pois.parks] = { icon: icons.benchTree, text: translate('parks'), visible: true };
                    this._private._pois[constants.map.pois.placeOfWorship] = { icon: icons.placeOfWorship, text: translate('places_of_worship'), visible: true };
                    this._private._pois[constants.map.pois.railStations] = { icon: icons.trainSubway, text: translate('rail_stations'), visible: true };
                    this._private._pois[constants.map.pois.schools] = { icon: icons.school, text: translate('schools'), visible: true };
                    this._private._pois[constants.map.pois.shopping] = { icon: icons.shoppingBag, text: translate('shopping'), visible: true };
                    this._private._pois[constants.map.pois.sportVenues] = { icon: icons.courtSport, text: translate('sport_venues'), visible: true };
                    this._private._pois[constants.map.pois.other] = { icon: icons.store, text: translate('other'), visible: true };

                    if (_.isFunction(o.onLoad))
                        o.onLoad();

                    break;
                }

                if (o.mapElement === 'aerial_map')
                {
                    this.zoom = 19;
                    this.type = constants.map.types.aerial;
                }
            }
        });

        return this;
    };

    get bounds() {
        var bounds = this._private._map.getBounds();

        if (!bounds)
            return null;

        var northEast = bounds.getNorthEast();
        var southWest = bounds.getSouthWest();

        super.bounds = {
            northEast: { lat: northEast.lat(), lon: northEast.lng() },
            northWest: { lat: northEast.lat(), lon: southWest.lng() },
            southEast: { lat: southWest.lat(), lon: northEast.lng() },
            southWest: { lat: southWest.lat(), lon: southWest.lng() }
        };

        return super.bounds; 
    }

    set bounds(value) {
        this._private._map.fitBounds(new window.google.maps.LatLngBounds(value.southWest, value.northEast));
        super.bounds = value;
    }

    get center() {
        var center = this._private._map.getCenter();        
        super.center = { lat: center.lat(), lon: center.lng() };
        return super.center;
    }
    set center(value) {
        this._private._map.setCenter(new window.google.maps.LatLng(value.lat, value.lon));
        super.center = value; 
    }

    get heading() {
        super.heading = this._private._map.getHeading();
        return super.heading; 
    }
    set heading(value) {
        
        this._private._map.setHeading(value);
        super.heading = value; 
    }

    get tilt() {
        super.tilt = this._private._map.getTilt();
        return super.tilt; 
    }
    set tilt(value) {
        this._private._map.setTilt(value);
        super.tilt = value; 
    }

    get trafficVisible(){ 
        return this._private._trafficLayer.visible; 
    }

    set trafficVisible(value){
        this._private._trafficLayer.visible = value;
                
        if (this._private._trafficLayer.visible)
            this._private._trafficLayer.layer.setMap(this._private._map);
        else
            this._private._trafficLayer.layer.setMap(null);
    }

    get transitVisible(){
        return this._private._transitLayer.visible; 
    }

    set transitVisible(value){
        this._private._transitLayer.visible = value;

        if (this._private._transitLayer.visible)
            this._private._transitLayer.layer.setMap(this._private._map);
        else
            this._private._transitLayer.layer.setMap(null);
    }

    getMapStyling(value){

        var result = {
            id: window.google.maps.MapTypeId.ROADMAP,
            style: baseStyle.default['style'],            
            tilt: 0,   
            heading: 0,
            rotatable: false
        };
        
        switch(value){
            default:
            case constants.map.types.road:
                result.id = window.google.maps.MapTypeId.ROADMAP;
                result.style = baseStyle.default['style'];
                break;
            case constants.map.types.terrain:
                result.id = window.google.maps.MapTypeId.TERRAIN;
                break;
            case constants.map.types.aerial:
                result.id = this._private._showAerialLabels ? window.google.maps.MapTypeId.HYBRID : window.google.maps.MapTypeId.SATELLITE;
                break;
            case constants.map.types.dark:
                result.id = window.google.maps.MapTypeId.ROADMAP;
                result.style = darkStyle.default['style'];
                break;
            case constants.map.types.gray:
                result.id = window.google.maps.MapTypeId.ROADMAP;
                result.style = grayStyle.default['style'];
                break;
            case constants.map.types.light:
                result.id = window.google.maps.MapTypeId.ROADMAP;
                result.style = lightStyle.default['style'];
                break;
            case constants.map.types.birdsEye:
                result.id = this._private._showAerialLabels ? window.google.maps.MapTypeId.HYBRID : window.google.maps.MapTypeId.SATELLITE;
                result.tilt = 45;
                break;
        }

        return result;
    }

    get type() { return this._private._type; }
    set type(value) {

        var styling = this.getMapStyling(value);

        this._private._rotatable = styling.rotatable;
        this._private._map.setTilt(styling.tilt);
        this._private._map.setMapTypeId(styling.id);

        if (styling.style != null)
            this._private._map.setOptions({ styles: styling.style });
        
        this._private._type = value; 
        this.togglePois([]);
    }

    get zoom() {

        if (!this._private._map)
            return super.zoom;

        super.zoom = this._private._map.getZoom();
        return super.zoom; 
    }
    set zoom(value) {
        
        if (!super.validZoom(value))
            return;

        super.zoom = value;
        this._private._map.setZoom(value);
    }

    get position() {
        var position = this._private._map.getPosition();        
        super.position = { lat: position.lat(), lon: position.lng() };
        return super.position;
    }
    set position(value) {
        this._private._map.setPosition(new window.google.maps.LatLng(value.lat, value.lon));
        super.center = value; 
    }
    

    getMap() {
        return this._private._map;
    }

    setStreetViewPanorama(o) {
        if (o.default && _.isObject(this._private._streetViewPanorama)) {
            this._private._map.setStreetView(this._private._streetViewPanorama);
        }
        else if (_.isObject(o.panoMap)) {
            this._private._map.setStreetView(o.panoMap);
        }
    }

    setStreetViewControl(o) {
        this._private._map.getStreetView().setVisible(o.visible);
        this._private._map.setOptions({streetViewControl: o.visible});
    }
    
    setStreetViewPosition(o){

        var streetview = this._private._map;

        streetview.setPosition(new window.google.maps.LatLng(o.location.lat, o.location.lon));

        streetview.setPov({
            heading: 265,
            pitch: 0
        });

        streetview.setVisible(true);
    }

    setMapStreetViewPosition(o) {

        var streetview = this._private._map.getStreetView();

        streetview.setPosition(new window.google.maps.LatLng(o.location.lat, o.location.lon));

        streetview.setPov({
            heading: 265,
            pitch: 0
        });

        streetview.setVisible(true);
    }

    toggleStreetViewStreets(){

        this._private._streetViewLayer.visible = !this._private._streetViewLayer.visible;

        if (this._private._streetViewLayer.visible)
            this._private._streetViewLayer.layer.setMap(this._private._map);
        else
            this._private._streetViewLayer.layer.setMap(null);
    }

    toggleBikeOverlay(){  

        this._private._bikeLayer.visible = !this._private._bikeLayer.visible;

        if (this._private._bikeLayer.visible)
            this._private._bikeLayer.layer.setMap(this._private._map);
        else
            this._private._bikeLayer.layer.setMap(null);
    }

    getPoiKeys(){
        return Object.keys(this.pois).map(poi => { return parseInt(poi); });
    }

    togglePois(pois){
                
        var style = this.getMapStyling(this.type).style;

        var fullPoiList = this.getPoiKeys();

        if (!_.isArray(pois))
            pois = fullPoiList.map(poi =>{
                this.pois[poi].visible = !this.pois[poi].visible;
                return { key: poi, visible: this.pois[poi].visible};
            });
        
        fullPoiList.forEach(poi =>{
            if (pois.find(x => parseInt(x.key) === poi))
                this.pois[poi].visible = pois.find(x => parseInt(x.key) === poi).visible;
        });

        fullPoiList.forEach(poiKey =>{

            var key = '';
            switch(poiKey){
                case constants.map.pois.other:
                    key = 'poi.business';
                    break;
                case constants.map.pois.attractions:
                    key = 'poi.attraction';
                    break;
                case constants.map.pois.shopping:
                    key = 'poi.business.shopping';
                    break;
                case constants.map.pois.foodAndDrink:
                    key = 'poi.business.food_and_drink';
                    break;
                case constants.map.pois.gasStations:
                    key = 'poi.business.gas_station';
                    break;
                case constants.map.pois.carRentals:
                    key = 'poi.business.car_rental';
                    break;
                case constants.map.pois.lodging:
                    key = 'poi.business.lodging';
                    break;
                case constants.map.pois.government:
                    key = 'poi.government';
                    break;
                case constants.map.pois.medical:
                    key = 'poi.medical';
                    break;
                case constants.map.pois.parks:
                    key = 'poi.park';
                    break;
                case constants.map.pois.placeOfWorship:
                    key = 'poi.place_of_worship';
                    break;
                case constants.map.pois.schools:
                    key = 'poi.school';
                    break;
                case constants.map.pois.sportVenues:
                    key = 'poi.sports_complex';
                    break;
                case constants.map.pois.airPorts:
                    key = 'transit.station.airport';
                    break;
                case constants.map.pois.busTerminals:
                    key = 'transit.station.bus';
                    break;
                case constants.map.pois.railStations:
                    key = 'transit.station.rail';
            }
            
            style.find(x => x.featureType === key).stylers[0].visibility = this.pois[poiKey].visible ? 'on' : 'off';

        });

        this._private._map.setOptions({ styles: style });
    }

    enableZooming(){
        this._private._map.setOptions({ minZoom: null, maxZoom: null });
    }

    disableZooming(){
        this._private._map.setOptions({ minZoom: this.zoom, maxZoom: this.zoom });
    }

    enablePanning(){
        this._private._map.setOptions({ restriction: null });
    }

    disablePanning(){        
        this._private._map.setOptions({ restriction: { 
            latLngBounds: {
                north: this.bounds.northEast.lat,
                south: this.bounds.southWest.lat,
                west: this.bounds.southWest.lon,
                east: this.bounds.northEast.lon
            },
            strictBounds: true 
        }});
    }

    getTypes(){
        return [
            { id: constants.map.types.road, name: translate('road'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Road.png' },
            { id: constants.map.types.terrain, name: translate('terrian'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Light.png' },
            { id: constants.map.types.aerial, name: translate('aerial'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Aerial.png' },
            { id: constants.map.types.dark, name: translate('dark'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Dark.png'},
            { id: constants.map.types.gray, name: translate('gray'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Gray.png'},
            { id: constants.map.types.light, name: translate('light'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_Light.png'},
            { id: constants.map.types.birdsEye, name: translate('birds_eye'), image: 'https://cdn.tradeareasystems.net/Images/TASOnline/Map/MapType_BirdsEye.png'}
        ];
    };    

    xyToLatLon(pixel){
        var topRight = this._private._map.getProjection().fromLatLngToPoint(this._private._map.getBounds().getNorthEast());
        var bottomLeft = this._private._map.getProjection().fromLatLngToPoint(this._private._map.getBounds().getSouthWest());
        var scale = Math.pow(2, this._private._map.getZoom());
        var worldPoint = new window.google.maps.Point(pixel.x / scale + bottomLeft.x, pixel.y / scale + topRight.y);
        var latLng = this._private._map.getProjection().fromPointToLatLng(worldPoint);

        return { lat: latLng.lat(), lon: latLng.lng() };
    };

    latLonToXY(location){
        var topRight = this._private._map.getProjection().fromLatLngToPoint(this._private._map.getBounds().getNorthEast());
        var bottomLeft = this._private._map.getProjection().fromLatLngToPoint(this._private._map.getBounds().getSouthWest());
        var scale = Math.pow(2, this._private._map.getZoom());
        var worldPoint = this._private._map.getProjection().fromLatLngToPoint(new window.google.maps.LatLng(location.lat, location.lon));

        return { x: (worldPoint.x - bottomLeft.x) * scale, y: (worldPoint.y - topRight.y) * scale };
    };

    async addressSearch(o){

        var results = await (() => {
            return new Promise((success, error) => {
                this._private._addressSearch.getQueryPredictions({ input: o.search,  locationBias: new window.google.maps.Circle({ center: this._private._map.getCenter(), radius: 100 }) }, success, error);
            });
        })();

        o.callback(results);
    };

    async placeSearch(o){

        var results = [];

        var promise = await new Promise(async (success, error) => {
            results = await this._private._addressSearch.getPlacePredictions({ input: o.search,  locationBias: new window.google.maps.Circle({ center: this._private._map.getCenter(), radius: 100 }) }, success, error);            
            results = results.predictions;
            success();
        })

        await Promise.all([promise]);
        
        return results;
    }

    async geocode(o){

        var findBestMatch = (results) =>{
            for (const type of ['ROOFTOP', 'RANGE_INTERPOLATED', 'GEOMETRIC_CENTER', 'APPROXIMATE']) {
                var result = results.find(x => x.geometry.location_type === type);
                if (_.isObject(result)) {
                    return result;
                }
            }
            return null;
        };
        
        var results = await (() => {
            return new Promise((success, error) => {
                if (helpers.isLatLon(o.query)) {
                    const latlngStr = o.query.split(",", 2);
                    const latlng = {
                        lat: parseFloat(latlngStr[0]),
                        lng: parseFloat(latlngStr[1]),
                    };
                    
                    this._private._geocoder.geocode({ location: latlng }, success, error);
                }
                else
                    this._private._geocoder.geocode({ address: o.query}, success, error);
            });
        })();
        
        if (!_.isArray(results) || results.length === 0)
            return;

        var result = findBestMatch(results);
        if (!_.isObject(result))
            result = results[0];

        var streetNumber = '';
        var route = '';
        var city = '';
        var state = '';
        var zip = '';
        var country = '';

        result.address_components.forEach(component =>{
            if (component.types.indexOf('street_number') !== -1)
                streetNumber = component.long_name;
            else if (component.types.indexOf('route') !== -1)
                route = component.long_name;
            else if (component.types.indexOf('locality') !== -1)
                city = component.long_name;
            else if (component.types.indexOf('administrative_area_level_1') !== -1)
                state = component.short_name;            
            else if (component.types.indexOf('postal_code') !== -1)
                zip = component.long_name;
            else if (component.types.indexOf('country') !== -1)
                country = component.short_name;
        });
        
        o.callback({
            location: ({ lat: result.geometry.location.lat(), lon: result.geometry.location.lng() }),     
            address: {
                full: result.formatted_address,
                street: `${streetNumber} ${route}`,
                city: city,
                state: state,
                zip: zip,
                country: country
            }            
        });
    };

    addLayer(o) {
        return super.addLayer({ layer: new GoogleLayer(this, o) });
    };    

    getDirections(props) {      
        props.map = this._private._map;
        drivingDirections.getDirections(props);
    }

    polygonContainsPolygon(innerPaths, outerPaths){
        return innerPaths.filter(x => this.polygonContainsPoint(outerPaths, x)).length === innerPaths.length;
    }

    polygonIntersectsPolygon(innerPaths, outerPaths){
        return innerPaths.filter(x => this.polygonContainsPoint(outerPaths, x)).length > 0;
    }
    
    polygonContainsPoint(paths, location){
        
        const getPaths = (paths)=>{
            return paths.map(location =>{
                return _.isArray(location) ? getPaths(location) : new window.google.maps.LatLng(location.lat, location.lon);
            });
        };
        
        return window.google.maps.geometry.poly.containsLocation(
            new window.google.maps.LatLng(location.lat, location.lon),
            new window.google.maps.Polygon({ paths: getPaths(paths) })
        );
    }

    computeCenter(o){

        if (o.locations.length >= 1 && _.isArray(o.locations[0]))
            o.locations = o.locations[0];

        var longitudes = _.sortBy(o.locations.map(location => location.lon));
        var latitudes = _.sortBy(o.locations.map(location => location.lat));
        
        var lowX = latitudes[0];
        var highX = latitudes[latitudes.length - 1];
        var lowy = longitudes[0];
        var highy = longitudes[longitudes.length - 1];
    
        var centerX = lowX + ((highX - lowX) / 2);
        var centerY = lowy + ((highy - lowy) / 2);
        
        return { lat: centerX, lon: centerY };
    }

    computeArea(o){
        return window.google.maps.geometry.spherical.computeArea(o.locations.map(location => new window.google.maps.LatLng(location.lat, location.lon)));
    }

    computeLength(o){
        return window.google.maps.geometry.spherical.computeLength(o.locations.map(location => new window.google.maps.LatLng(location.lat, location.lon)));
    }

    setCursor(o){
                
        if (!_.isObject(o))
            this._private._map.setOptions({ draggableCursor: '' });

        this._private._map.setOptions({ draggableCursor: o.cursor });
    }

    startDrawing(o){
        
        super.startDrawing(o);
    }

    finishDrawing(){
        
    }

    cancelDrawing(){
        
    }

    reset(){
        this.type = constants.map.types.road;
        this.zoom = 3;
        this.center = { lat: 30, lon: 0 };

        super.reset();
    }

    fitToView(entity) {

        var paths = entity.paths;
        if (entity.hasMultiDimensionalPaths)
            paths = _.flatten(paths);

        var bounds = new window.google.maps.LatLngBounds();

        for (var i = 0; i < paths.length; i++) {
            var myLatLng = new window.google.maps.LatLng(paths[i].lat, paths[i].lon);
            bounds.extend(myLatLng);
        }
        
        this._private._map.fitBounds(bounds);
        
        return this.bounds;
    }
};