// React imports
import { useState, useEffect } from 'react';

// 3rd party
import Linkify from 'react-linkify';

// App imports
import { Loader } from '../loader/loader';
import { Icon, icons } from '../../base/icon/icon';
import { helpers } from "../../../utils/helpers";
import { legacyEndpoints } from '../../../services/legacyEndpoints';

var _ = require("lodash");

export function Memo({className, memo}) {

    const [loaded, setLoaded] = useState(false);
    const [userImage, setUserImage] = useState(null);
    const [memoIcon, setMemoIcon] = useState(null);

    useEffect(() =>{

        if (!_.isObject(memo))
            return;

        if (_.isObject(memo.user)) {
            const userGUID = memo.user.GUID || memo.user.Id;
            setUserImage(legacyEndpoints.handlers.getPhotoUrl({ isUserPhoto: true, userId: userGUID, width: 50, height: 50 }));
        }

        if (_.isObject(memo.icon))
            setMemoIcon(memo.icon);
        else if (_.isString(memo.icon) && memo.icon.length > 0 && icons.hasOwnProperty(memo.icon))
            setMemoIcon(icons[memo.icon]);

        setLoaded(true);

    }, [memo]);

    const getLinkifyText = (text)=>{
        return <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}
            >
                {text}
            </Linkify>
    }

    var classes = ['app-memo-container'];
    if (className)
        classes.push(className);

    return <>
        <Loader theme={'generate'} loaded={loaded} />
        { !loaded ? '' :
            <div className={classes.join(' ')}>
                { _.isObject(memoIcon) ?
                    <div className='app-memo-icon-container' title={memo.iconTitle}>
                        <Icon className='app-memo-icon' icon={memoIcon} />
                    </div>
                : _.isString(memo.imageSrc) && memo.imageSrc.length > 0 ?
                    <div className='app-memo-icon-container'>
                        <img src={memo.imageSrc} alt={ _.isString(memo.imageAlt) ? memo.imageAlt : ''} />
                    </div>
                : '' }
                <div className='app-memo-body'>
                    { _.isString(memo.title) && memo.title.length > 0 ?
                        <div className='app-memo-title'>
                            {memo.title}
                        </div>
                    : '' }
                    { _.isString(memo.message) && memo.message.length > 0 ?
                        memo.isLinkify ? 
                            <div className='app-memo-message'>{getLinkifyText(memo.message)}</div>
                        :
                            <div className='app-memo-message' dangerouslySetInnerHTML={{__html: memo.message}} />
                    : ''}
                    { _.isString(memo.date) && memo.date.length > 0 ?
                        <div className='app-memo-date'>
                            {helpers.formatDate({ date: helpers.convertJSONDate(memo.date) })}
                        </div>
                    : ''}
                    { _.isObject(memo.user) ?
                        <div className='app-memo-user'>
                            <div className='app-memo-user-image-container'>
                                <img className='app-memo-user-image' src={userImage} alt={memo.user.Name} title={memo.user.Name} />
                            </div>
                            <div className='app-memo-user-name-container'>
                                {memo.user.Name}
                            </div>
                        </div>
                    : ''}
                </div>
            </div>
        }
    </>
}
