// React imports
import { useEffect, useState, useRef } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { TextBox, validatorTypes } from '../../../base/textBox/textBox';
import { TextEditor } from '../../../base/textEditor/textEditor';
import { icons, icon } from '../../../base/icon/icon';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { translate } from '../../../../utils/translation';
import { DropDown } from '../../../base/dropDown/dropDown';
import { TextArea } from '../../../base/textArea/textArea';
import { DateBox } from '../../../base/dateBox/dateBox';
import { tasks as tasksModule } from '../../../../modules/tasks';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { successToast, errorToast } from '../../../base/toast/toast';
import { Loader } from '../../../base/loader/loader';

const _ = require('lodash');

export function TaskDetail({task, showHeader, onSave, onCancel, resetOnSave = false}) {
    const titleValidatorRef = useRef(null);
    const dueDateValidatorRef = useRef(null);
    const assignedToValidatorRef = useRef(null);

    const [taskMetadata, setTaskMetadata] = useState(false);
    const [editTask, setEditTask] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setTaskMetadata(await tasksModule.getTaskMetadata());
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setGenerating(true);
            if(task == null) 
                return;

            if (task.entity != null) {
                setIsEdit(false);
                setEditTask(tasksModule.getDefaultTask({entity: task.entity}));
            }
            else if (task.id != null) {
                setIsEdit(true);
                setEditTask(await tasksModule.getTask(task));
            }
            else {
                setIsEdit(false);
                setEditTask(tasksModule.getDefaultTask({entity: null}));
            }
            setGenerating(false);
        };
        
        fetchData();
    }, [task]);
    
    const cancelTask = () => {
        setIsEdit(false);
        setEditTask(tasksModule.getDefaultTask({entity: null}));

        titleValidatorRef.current.instance.reset();
        dueDateValidatorRef.current.instance.reset();
        assignedToValidatorRef.current.instance.reset();

        if (onCancel)
            onCancel();
    }

    const saveTask = async () => {
        setGenerating(true);
        const updatedTask = await tasksModule.updateTask(editTask);

        if (updatedTask) {
            successToast(translate('success'));
            
            if (_.isFunction(onSave))
                onSave();
        }
        else
            errorToast(translate('an_error_has_occurred'));

        if (resetOnSave)
            setEditTask(tasksModule.getDefaultTask({entity: task.entity}));
        
        setGenerating(false);
    }

    const setNotesForNewTask = (editNoteText) => {
        const note = [{
            user: {
                GUID: legacyEndpoints.authenticationToken.TokenInformation.UserGUID
            },
            note: {
                Text: editNoteText
            },
            isEdited: false
        }];

        setEditTask(editTask => ({ ...editTask, notes: note }));
    };

    const setNotesForUpdateTask = (editNoteText) => {
        var notes = editTask.notes || [];

        if (_.isArray(editTask.notes) && editTask.notes.length > 0) {

            setEditTask(prevTask => {

                if (!prevTask || !Array.isArray(prevTask.notes)) {
                    return prevTask;
                }

                const updatedNotes = prevTask.notes?.map((note,i) => {
                    if (i === 0) { 
                        return {
                            ...note,
                            note: {
                                ...note.note,
                                Text: editNoteText
                            }
                        };
                    }
                    return note;
                });
                return {
                    ...prevTask,
                    notes: updatedNotes
                };
            });
        }    
        else {
            setNotesForNewTask(editNoteText);
        }        
    };

    const handleNoteTextChange = (newText) => {
        if(editTask.id) {
            setNotesForUpdateTask(newText);
        } 
        else {
            setNotesForNewTask(newText);
        }
    };

    const notesData = (task) => {
        var mentions = _.isArray(task.sharedPeers) ? task.sharedPeers : [];
        var value = null;

        if (_.isArray(task.notes)) {
            _.each(task.notes, function (note, i) {
                if (note != null && i === 0) {                    
                    value = note.note.Text;
                }
            });
        }

        if (_.isObject(task.createdBy) && _.isString(task.createdBy.GUID))
            mentions.push(task.createdBy);

        if (_.isObject(task.assignedTo) && _.isString(task.assignedTo.GUID))
            mentions.push(task.assignedTo);

        mentions = _.filter(taskMetadata.users,
            function (availableUser) {
                var user = _.find(mentions,
                    function (sharedUser) {
                        return (sharedUser.GUID.toUpperCase() === availableUser.GUID.toUpperCase());
                    });

                return _.isObject(user);
            });
            
        mentions = _.uniq(mentions);

        const toolbarItems =[
            'undo', 'redo', 'separator',
            {
                formatName: 'header',
                formatValues: [false, 1, 2, 3, 4, 5]
            }, 'separator',
            'bold', 'italic', 'strike', 'underline', 'separator',
            'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator'
        ];

        const mentionsView = [
            {
                dataSource: mentions,
                searchExpr: 'Name',
                displayExpr: 'Name',
                valueExpr: 'GUID'
            }
        ];

        return value;

    };

    const statusOptions = [
        {id: constants.tasks.statuses.notStarted, name: tasksModule.getTasksStatusName(constants.tasks.statuses.notStarted)},
        {id: constants.tasks.statuses.inProgress, name: tasksModule.getTasksStatusName(constants.tasks.statuses.inProgress)},
        {id: constants.tasks.statuses.waiting, name: tasksModule.getTasksStatusName(constants.tasks.statuses.waiting)},
        {id: constants.tasks.statuses.completed, name: tasksModule.getTasksStatusName(constants.tasks.statuses.completed)},
        {id: constants.tasks.statuses.deferred, name: tasksModule.getTasksStatusName(constants.tasks.statuses.deferred)}
    ];

    const priorityOptions = [
        {id: constants.tasks.priorities.low, name: tasksModule.getTaskPriorityName(constants.tasks.priorities.low)},
        {id: constants.tasks.priorities.medium, name: tasksModule.getTaskPriorityName(constants.tasks.priorities.medium)},
        {id: constants.tasks.priorities.high, name: tasksModule.getTaskPriorityName(constants.tasks.priorities.high)},
        {id: constants.tasks.priorities.critical, name: tasksModule.getTaskPriorityName(constants.tasks.priorities.critical)}
    ];

    const actionOptions = [
        {id: constants.tasks.actions.none, name: tasksModule.getTaskActionName(constants.tasks.actions.none)},
        {id: constants.tasks.actions.openInfobox, name: tasksModule.getTaskActionName(constants.tasks.actions.openInfobox)},
        {id: constants.tasks.actions.openEditForm, name: tasksModule.getTaskActionName(constants.tasks.actions.openEditForm)},
        {id: constants.tasks.actions.loadCustomQuery, name: tasksModule.getTaskActionName(constants.tasks.actions.loadCustomQuery)}
    ];

    const getSourceInformation = () => {
        var text = translate("application");

        if (_.isObject(editTask.sourceInformation) &&
            _.isNumber(editTask.sourceInformation.sourceObjectType) &&
            editTask.sourceInformation.sourceObjectType !== constants.tasks.sourceObjectTypes.application)
            text = editTask.sourceInformation.label;

        return text;
    };

    return editTask == null ? '' : 
    <>
        <Loader loaded={!generating} theme='generate' />
        <div className='app-task-detail'>
            {
                showHeader ? <div className='app-task-detail-header'>
                    <div className='app-task-detail-header-title'>{task != null ? translate("task_details") : translate('new_task')}</div>
                    <div className='app-task-detail-header-actions'>
                        <Button theme='secondary' size='small' icon={icons.x} 
                            onClick={() => { cancelTask(); }} />
                        <Button theme='primary' size='small' icon={icons.save} 
                            disabled={
                                        (!editTask?.title || editTask?.title.trim() === '') || 
                                        editTask?.dueDate === null || 
                                        editTask?.assignedTo.GUID === null ||
                                        (!isEdit && helpers.isDateBefore(editTask?.dueDate, helpers.getDate())) || 
                                        (!isEdit && editTask?.reminderDate !== null && helpers.isDateBefore(editTask?.reminderDate, editTask?.dueDate)) 
                                    }
                            onClick={() => { saveTask(); }} />
                    </div>
                </div> : ''
            }
            <div className='app-task-detail-content'>
                <div className='app-task-detail-content-row'>
                    <DropDown className='app-task-detail-content-row-item' label={translate('status')} items={statusOptions} selected={editTask.status}  valueProperty={'id'} display={'name'} onChange={(e) => {setEditTask(editTask => ({ ...editTask, status: e.value })); }} />
                    <DropDown className='app-task-detail-content-row-item' label={translate('priority')} items={priorityOptions} selected={editTask.priority}  valueProperty={'id'} display={'name'} onChange={(e) => {setEditTask(editTask => ({ ...editTask, priority: e.value })); }} />
                </div>
                <div className='app-task-detail-content-row'>
                    <TextBox className='app-task-detail-content-row-item' label={translate('title')} value={editTask.title} valueChangeEvent='keyup' isRequired={true}
                                validator={
                                    {
                                        ref: titleValidatorRef,
                                        rules: [
                                            {
                                                type: validatorTypes.requiredRule,
                                                message: translate('required')
                                            }
                                        ]
                                    }
                                }
                                onChange={(e) => { setEditTask(editTask => ({ ...editTask, title: e.value })); }}
                                 />               
                </div>
                <div className='app-task-detail-content-row'>
                    <TextArea className='app-task-detail-content-row-item' label={translate('description')} minHeight={'55px'} maxHeight={'75px'} value={editTask.description} 
                        onChange={(e) => { setEditTask(editTask => ({ ...editTask, description: e.value })); }} />
                </div>
                <div className='app-task-detail-content-row'>
                    <DropDown className='app-task-detail-content-row-item' label={translate('assigned_to')} searchEnabled={true} searchExpr={"Name"} items={taskMetadata.users} selected={editTask.assignedTo.GUID} valueProperty={'GUID'} display={'Name'} isRequired={true}                    
                                validator={
                                {
                                    ref: assignedToValidatorRef,
                                    rules: [
                                        {
                                            type: validatorTypes.requiredRule,
                                            message: translate('required')
                                        }
                                    ]
                                }
                            }
                    onChange={(e) => {setEditTask(editTask => ({ ...editTask, assignedTo: {...editTask.assignedTo, GUID: e.value} }));}} />
                </div>
                {
                    isEdit ? 
                    <div className='app-task-detail-content-row'> 
                        <div className='app-task-detail-content-row-item'>
                            {`${translate('associated_with')}: ${getSourceInformation()}`}
                        </div>                                
                        <div className='app-task-detail-content-row-item'>
                            <div>
                                <div className='app-task-detail-label'>{`${translate('created_by')}:`}</div>
                                <img className='app-tasks-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: editTask.createdBy.GUID, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} />
                                <div className='app-task-detail-created-by'>{editTask.createdBy.Name}</div>
                            </div>
                        </div>

                    </div> : ''
                }
                <div className='app-task-detail-content-row'>
                    <DateBox 
                        className='app-task-detail-content-row-item' 
                        type='datetime' 
                        label={translate('due_date')} 
                        dateOutOfRangeMessage={translate('due_date_must_be_in_the_future')}
                        min={!isEdit ? helpers.getDate() : null}
                        value={editTask.dueDate == null ? null : helpers.convertJSONDate(editTask.dueDate)} 
                        isRequired={true}
                        validator={
                            {
                                ref: dueDateValidatorRef,
                                rules: [
                                    {
                                        type: validatorTypes.requiredRule,
                                        message: translate('required')
                                    }
                                ]
                            }
                        }
                        onChange={(e) => { setEditTask(editTask => ({ ...editTask, dueDate: e.value })); }} 
                />
                    <DateBox 
                        className='app-task-detail-content-row-item' 
                        type='datetime' 
                        label={translate('reminder_date')} 
                        disabled={editTask.dueDate == null}
                        showClearButton={true}
                        dateOutOfRangeMessage={translate('reminder_date_must_be_after_due_date')}
                        min={editTask.dueDate == null ? helpers.getDate() : helpers.convertJSONDate(editTask.dueDate)}
                        value={editTask.reminderDate == null ? null : helpers.convertJSONDate(editTask.reminderDate)}                   
                        onChange={(e) => { setEditTask(editTask => ({ ...editTask, reminderDate: e.value })); }} />
                </div>
                {
                    isEdit ? 
                    <div className='app-task-detail-content-row'> 
                        <div className='app-task-detail-content-row-item'>
                            {`${translate('associated_with')}: ${tasksModule.getTaskActionName(editTask.action)}`}
                        </div>                                
                    </div> : ''
                }                
                {
                    task.entity != null ? <div className='app-task-detail-content-row'>
                        <DropDown className='app-task-detail-content-row-item' label={translate('action')} items={actionOptions} selected={editTask.action} valueProperty={'id'} display={'name'} 
                           onChange={(e) => {setEditTask(editTask => ({ ...editTask, action: e.value }));}} />
                    </div> 
                    : ""
                }
                
                <div className='app-task-detail-content-row'>
                    <TextEditor 
                        height='100px' 
                        className='app-task-detail-content-row-item' 
                        minHeight={75} label={translate('notes')} 
                        value={notesData(editTask)}
                        toolbarItems={['undo', 'redo', 'separator', 'bold', 'italic', 'strike', 'underline', 'separator','alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator']}
                        onChange={(o) => {
                                        if (o.userChanged !== true)
                                            return;
                                        handleNoteTextChange(o.value);
                                    }}
                    />                                    
                </div>
            </div>
        </div>
    </>
}