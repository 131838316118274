// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Memo } from '../../base/memo/memo';
import { Clickable } from '../../base/clickable/clickable';
import { Button } from '../../base/button/button';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { icons } from '../../base/icon/icon';
import { projects } from '../projects/projects';
import { navigation } from '../navigation/navigation';
import { tools } from '../tools/tools';
import { reports } from '../reports/reports';
import { trip2Trade as trip2TradeModule } from '../../../modules/trip2Trade';
import { header } from '../header/header';

var _ = require("lodash");

export function Notification({notification, markNotification}) {    
    
    const [memo, setMemo] = useState([]);
    const [noticeMetadata, setNoticeMetadata] = useState([]);
    const [expandMessage, setExpandMessage] = useState(false);

    const getJobStatus = useCallback ( (o)=>{
        switch (noticeMetadata.status) {
            default:
                return translate('n_a');
            case constants.jobs.statuses.pending:
                return translate('pending');
            case constants.jobs.statuses.processing:
                return translate('processing');
            case constants.jobs.statuses.completed:
                return translate('completed');
            case constants.jobs.statuses.completedWithErrors:
                return translate('completed_with_errors');
            case constants.jobs.statuses.failed:
                return translate('failed');
        }
    }, [noticeMetadata]);

    const getJobType = useCallback ( (o)=>{

        switch (noticeMetadata.taskType) {
            default:
                return !_.isEmpty(noticeMetadata.taskTypeName) ? noticeMetadata.taskTypeName : '';
            case constants.jobs.types.demographics:
                return translate('bulk_demographics')
            case constants.jobs.types.closestStore:
                return translate('bulk_closest_stores')
            case constants.jobs.types.mapBook:
                return translate('map_books')
            case constants.jobs.types.custom:
                switch (noticeMetadata.customTaskType) {
                    default:
                        return !_.isEmpty(noticeMetadata.taskTypeName) ? noticeMetadata.taskTypeName : '';
                    case constants.jobs.types.customTypes.managementReport:
                        return translate('management_report')
                    case constants.jobs.types.customTypes.voidAnalysis:
                        return translate('void_analysis')
                    case constants.jobs.types.customTypes.mobility:
                        return translate('mobility_report')
                    case constants.jobs.types.customTypes.demographicReport:
                        return translate('demographic_report')
                    case constants.jobs.types.customTypes.importExport:
                        return translate('import_export')
                    case constants.jobs.types.customTypes.adHocDataSource:
                        return translate('ad_hoc_data_source')
                    case constants.jobs.types.customTypes.pointReports:
                        return translate('point_detail_reports')
                    case constants.jobs.types.customTypes.submitOfflinePackage:
                        return translate('submit_offline_package')
                    case constants.jobs.types.customTypes.analyticsProjection:
                        return translate('analytics_projection')
                    case constants.jobs.types.customTypes.createOfflinePackage:
                        return translate('create_offline_package')
                    case constants.jobs.types.customTypes.trip2Trade:
                        return translate('trip_2_trade')
                    case constants.jobs.types.customTypes.ciDashboardReport:
                        return translate('ci_dashboard_report')
                    case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                        return translate('bulk_analytics_projection');
                    case constants.jobs.types.customTypes.ciCannibalization:
                        return translate('ci_cannibalization')
                    case constants.jobs.types.customTypes.externalAnalyticsProjection:
                        return translate('external_analytics_projection')
                    case constants.jobs.types.customTypes.ciCustomChannels:
                        return translate('ci_custom_channels')
                    case constants.jobs.types.customTypes.analyticsRelocation:
                        return translate('analytics_relocation')
                    case constants.jobs.types.customTypes.ciKeyInsightsReport:
                        return translate('ci_key_insights_report')
                    }
        }
    }, [noticeMetadata]);

    const getIcon = useCallback ( (o)=>{

        switch (o.type) {
            default:
                return null;
            case constants.notifications.types.adHocDataSource:
                return icons.folderArrowUp;
            case constants.notifications.types.map:
                return icons.map;
            case constants.notifications.types.savedReport:
                return icons.fileBarChart;                
            case constants.notifications.types.task:
                return icons.task;
            case constants.notifications.types.message:
                return null;    // messages will use the corporate image logo
            case constants.notifications.types.job:
        
                switch (noticeMetadata.taskType) {
                    default:
                        return icons.gear;
                    case constants.jobs.types.demographics:
                        return icons.people;
                    case constants.jobs.types.closestStore:
                        return icons.store;
                    case constants.jobs.types.mapBook:
                        return icons.bookBookmark;
                    case constants.jobs.types.custom:

                        switch (noticeMetadata.customTaskType) {
                            default:
                                return icons.gear;
                            case constants.jobs.types.customTypes.managementReport:
                                return icons.people;
                            case constants.jobs.types.customTypes.voidAnalysis:
                                return icons.empty;
                            case constants.jobs.types.customTypes.mobility:
                                return icons.mobile;
                            case constants.jobs.types.customTypes.demographicReport:
                                return icons.fileLines;
                            case constants.jobs.types.customTypes.importExport:
                                return icons.folderArrowUp;
                            case constants.jobs.types.customTypes.adHocDataSource:
                                return icons.folderArrowUp;
                            case constants.jobs.types.customTypes.pointReports:
                                return icons.braille;
                            case constants.jobs.types.customTypes.submitOfflinePackage:
                                return icons.wifiSlash;
                            case constants.jobs.types.customTypes.analyticsProjection:
                                return icons.chartMixed;
                            case constants.jobs.types.customTypes.createOfflinePackage:
                                return icons.wifiSlash;
                            case constants.jobs.types.customTypes.trip2Trade:
                                return icons.suitcase;
                            case constants.jobs.types.customTypes.ciDashboardReport:
                                return icons.pieChart;
                            case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                                return icons.chartMixed;
                            case constants.jobs.types.customTypes.ciCannibalization:
                                return icons.circleThreeQuarters;
                            case constants.jobs.types.customTypes.externalAnalyticsProjection:
                                return icons.external;
                            case constants.jobs.types.customTypes.ciCustomChannels:
                                return icons.store;
                            case constants.jobs.types.customTypes.analyticsRelocation:
                                return icons.truckRampBox;
                            case constants.jobs.types.customTypes.ciKeyInsightsReport:
                                return icons.binoculars;
                            }
                }
        }
    }, [noticeMetadata]);

    const getMessage = useCallback ( (o)=>{

        switch (o.type) {
            default:
                return expandMessage ? o.message : `<div class='app-notification-announcement'>${o.message}</div>`;
            case constants.notifications.types.map:
                return `<b>${o.owner.Name}</b> ${translate('shared_the_map')} <b>${o.title}</b>`;
            case constants.notifications.types.savedReport:
                return `<b>${o.owner.Name}</b> ${translate('shared_the_report')} <b>${o.title}</b>`;              
            case constants.notifications.types.adHocDataSource:
                return `<b>${o.owner.Name}</b> ${translate('shared_the_adhoc')} <b>${o.title}</b>`;
            case constants.notifications.types.task:
                return o.message;
            case constants.notifications.types.job:
                if (noticeMetadata.taskType === constants.jobs.types.custom && noticeMetadata.customTaskType === constants.jobs.types.customTypes.adHocDataSource)
                    return `<i>${getJobType(o)}</i> <b>${o.title}</b> ${translate('was_shared_with_you')}`;
                else
                    return `<i>${getJobType(o)}</i> ${translate('job')} <b>${o.title}</b> ${translate('finished_processing')} <b>${getJobStatus(o)}</b>`;
        }
    }, [expandMessage, noticeMetadata, getJobType, getJobStatus]);

    const getTypeName = useCallback ( (o)=>{

        switch (o.type) {
            default:
                return o.message
            case constants.notifications.types.message:
                return translate('message');
            case constants.notifications.types.job:
                return translate('job');
            case constants.notifications.types.map:
                return translate('map');
            case constants.notifications.types.task:
                return translate('task');
            case constants.notifications.types.adHocDataSource:
                return translate('ad_hoc_data_source');
            case constants.notifications.types.savedReport:
                return translate('saved_report');
        }

    }, []);

    const renderViewed = (o)=>{        
        if (o.isViewed)
            return <Button className='app-job-management-mark-read' theme='simple' icon={icons.circle} 
                tooltip={translate('mark_as_unread')} 
                onClick={() => { 
                    markNotification({ id: o.id, type: o.type, isViewed: false });
                }} 
            />
        else
            return <Button className='app-job-management-mark-unread' theme='simple' icon={icons.circle} 
                tooltip={translate('mark_as_read')} 
                onClick={() => { 
                    markNotification({ id: o.id, type: o.type, isViewed: true });
                }} 
            />
    };

    const viewNotification = (o)=>{

        switch (o.type) {
            default:
                // unknow notification type
                break;
            case constants.notifications.types.message:
                setExpandMessage(!expandMessage);
                break;
            case constants.notifications.types.job:

                switch (noticeMetadata.taskType) {
                    default:
                        break;
                    case constants.jobs.types.demographics:
                    case constants.jobs.types.closestStore:
                        navigation.forceExpand({ section: constants.navigation.sections.jobs });
                        break;
                    case constants.jobs.types.custom:
                        switch (noticeMetadata.customTaskType) {
                            // TBD: where should these custom job types navigate to?
                            default:
                            case constants.jobs.types.customTypes.voidAnalysis:
                            case constants.jobs.types.customTypes.importExport:
                            case constants.jobs.types.customTypes.submitOfflinePackage:
                            case constants.jobs.types.customTypes.analyticsProjection:
                            case constants.jobs.types.customTypes.createOfflinePackage:
                            case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                            case constants.jobs.types.customTypes.ciCannibalization:
                            case constants.jobs.types.customTypes.externalAnalyticsProjection:
                            case constants.jobs.types.customTypes.ciCustomChannels:
                            case constants.jobs.types.customTypes.analyticsRelocation:
                                break;
                            case constants.jobs.types.customTypes.managementReport:
                            case constants.jobs.types.customTypes.mobility:
                            case constants.jobs.types.customTypes.demographicReport:
                            case constants.jobs.types.customTypes.pointReports:
                            case constants.jobs.types.customTypes.ciDashboardReport:
                            case constants.jobs.types.customTypes.ciKeyInsightsReport:
                                navigation.forceExpand({ section: constants.navigation.sections.jobs });
                                break;
                            case constants.jobs.types.customTypes.trip2Trade:
                                // TBD: T2T shouldn't be under jobs, but in its own category (below)
                                /*
                                if (noticeMetadata.status === constants.jobs.statuses.completed)
                                    trip2TradeModule.showLayers([o.id]);
                                */
                                break;
                            case constants.jobs.types.customTypes.adHocDataSource:
                                tools.openAdhocDatasources();
                                break;
                        }
                        break;
                }            
                break;
            case constants.notifications.types.map:
                projects.openAvailableMaps({ tab: 1, map: o.objectId });
                break;
            case constants.notifications.types.savedReport:
                reports.openReportManager();
                break;                
            case constants.notifications.types.task:
                header.toggleTasks();
                break;
            case constants.notifications.types.offlinePackage:
                // TBD
                break;
            case constants.notifications.types.geoFeeds:
                // TBD
                break;
            case constants.notifications.types.siteTours:
                // TBD
                break;
            case constants.notifications.types.trip2Trade:
                // TBD: T2T category should have status in metadata - load renderer using the id
                /*
                if (noticeMetadata.status === constants.jobs.statuses.completed)
                    trip2TradeModule.showLayers([o.id]);
                */
                break;
            case constants.notifications.types.adHocDataSource:
                tools.openAdhocDatasources();
                break;
        }
    };

    useEffect(() =>{

        var taskTypeValue = -1;
        var taskTypeNameValue = '';
        var customTaskTypeValue = -1;
        var statusValue = -1;

        if (_.isObject(notification.metaData)) {
            const taskType = notification.metaData.find(item => item.Key === "taskType");
            if (_.isObject(taskType)) 
                taskTypeValue = taskType.Value;

            const taskTypeName = notification.metaData.find(item => item.Key === "taskTypeName");
            if (_.isObject(taskTypeName)) 
                taskTypeNameValue = taskTypeName.Value;

            const customTaskType = notification.metaData.find(item => item.Key === "customTaskType");
            if (_.isObject(customTaskType)) 
                customTaskTypeValue = customTaskType.Value;

            const status = notification.metaData.find(item => item.Key === "status");
            if (_.isObject(status)) 
                statusValue = status.Value;
        }

        setNoticeMetadata({
            taskType: taskTypeValue,
            taskTypeName: taskTypeNameValue,
            customTaskType: customTaskTypeValue,
            status: statusValue
        });

    }, [notification]);

    useEffect(() =>{

        setMemo({
            id: notification.id,
            date: notification.date,
            title: notification.type === constants.notifications.types.task ? notification.title : null,
            message: getMessage(notification),
            isLinkify: notification.type === constants.notifications.types.task || (notification.type === constants.notifications.types.message && expandMessage),
            user: notification.type !== constants.notifications.types.message && notification.type !== constants.notifications.types.job && _.isObject(notification.owner) ? notification.owner : null,
            icon: getIcon(notification),
            iconTitle: getTypeName(notification),
            imageSrc: notification.type === constants.notifications.types.message ? 'https://cdn.tradeareasystems.net/Images/Rebrand/KalibrateHeader.png' : null,
            imageAlt: notification.type === constants.notifications.types.message ? constants.company : null
        });

    }, [notification, getMessage, getIcon, getTypeName]);
    
    var classes = ['app-notification-container'];
    if (notification.type === constants.notifications.types.message && expandMessage)
        classes.push('app-notification-container-expand');    

    return <>
        <div className={classes.join(' ')}>
            <Clickable
                className={`app-map-notification ${!notification.isViewed ? 'app-notification-new' : ''}`} onClick={() => { viewNotification(notification); }}
            >
                <Memo key={memo.id} memo={memo} />
            </Clickable>
            <div className='app-notification-viewed-container'>
                {renderViewed(notification)}
            </div>
        </div>
    </>
}
