// App imports
import { icons, Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';
import { Tabs } from '../../../base/tabs/tabs';
import { DataList } from '../../../base/dataList/dataList';

const _ = require("lodash");

export function CIChannelFilter({ baseChannels, updateBaseChannels, customChannels, updateCustomChannels }) {
	
	const rendererDataListRow = (item) =>{
        return <div className='app-competitive-insights-channel-filter-row' key={item.id}>
			{item.name}
			{ item.type === constants.competitiveInsights.channelTypes.user ? 
				<Icon className='app-competitive-insights-channel-filter-icon' icon={icons.user} />
			: item.type === constants.competitiveInsights.channelTypes.admin ? 
				<Icon className='app-competitive-insights-channel-filter-icon' icon={icons.people} /> 
			: '' }	
		</div>
    };     

	const tabs = [{
		id: 1,
		text: `${translate('base')} (${baseChannels.length})`,
		icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.locationDot.icon[0]} ${icons.locationDot.icon[1]}"><path fill="currentColor" d="${icons.locationDot.icon[4]}"></svg>`,
		component: <div className='app-competitive-insights-channel-list'>
			<DataList
				keyExpr={'id'}
				dataSource={baseChannels}
				height={'auto'}
				itemRender={rendererDataListRow}
				selectedItemsChange={(o)=> {
					var items = baseChannels;
					items.forEach(channel =>{
						channel.isSelected = o.indexOf(channel.id) > -1;
					});
					updateBaseChannels(items);
				}}
				selectedItems={baseChannels.filter(channel => channel.isSelected).map(channel => { return channel.id; })}
				selectByClick={true}
			/>
		</div>
	},
	{
		id: 2,
		text: `${translate('custom')} (${customChannels.length})`,
		icon: `<svg data-prefix="fas" class="svg-inline--fa" viewBox="0 0 ${icons.arrowRightArrowLeft.icon[0]} ${icons.arrowRightArrowLeft.icon[1]}"><path fill="currentColor" d="${icons.arrowRightArrowLeft.icon[4]}"></svg>`,
		component: <div className='app-competitive-insights-channel-list'>
			<DataList
				keyExpr={'id'}
				dataSource={customChannels}
				height={'auto'}
				itemRender={rendererDataListRow}
				selectedItemsChange={(o)=> {
					var items = customChannels;
					items.forEach(channel =>{
						channel.isSelected = o.indexOf(channel.id) > -1;
					});
					updateCustomChannels(items);
				}}
				selectedItems={customChannels.filter(channel => channel.isSelected).map(channel => { return channel.id; })}
				selectByClick={true}
			/>
		</div>
	}];

	return <>
		{ !_.isArray(baseChannels) || ! _.isArray(customChannels) ? '' :
			<Tabs tabs={tabs} />
		}	
	</>
}