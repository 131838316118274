// App imports
import { map } from '../components/app/map/map';
import { customerMaps } from './customerMaps';
import { sources } from './sources';
import { thematics } from './thematics';
import { tradeAreas } from './tradeAreas';
import { mapBooks } from './mapbooks';
import { trip2Trade } from './trip2Trade';
import { geofences } from './geofences';
import { geoFeeds } from './geoFeeds';
import { competitiveInsights } from './competitiveInsights';
import { cosmetic } from './cosmetic';
import { directionsManager } from '../components/app/drivingDirections/drivingDirections';
import { layers } from '../components/app/layers/layers'
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { mapControl } from '../components/app/map/mapControl/mapControl';
import { userPreferences } from '../components/app/app';
import { icons } from '../components/base/icon/icon';
import { zIndexes } from '../utils/zIndex';
import { filtersModule } from './filters';

const _ = require("lodash");

export class MapCosmetic {
    constructor() {
        this.IsVisible = true;
        this.Shapes = [];
    }
};

export class MapDirections {
    constructor() {
        this.Id = null;
        this.Shapes = [];
        this.Waypoints = [];
        this.IsVisible = true;
    }
};

export class MapShape {
    constructor() {
        this.Id = null;
        this.Type = null;
        this.Title = null;
        this.Description = null;
        this.ImageUrl = null;
        this.Style = null;
        this.Radius = -1;
        this.FillColor = { R: null, G: null, B: null, A: null };
        this.LineColor = { R: null, G: null, B: null, A: null };
        this.LineWidth = -1;
        this.LineStyle = null;
        this.Draggable = null;
        this.EncodedString = null;
        this.GeoLevelId = null;
        this.GeographyIds = null;
        this.VintageId = -1;
        this.Sort = -1;
        this.Anchor = { x: 0, y: 0 };
        this.ParentId = null;
        this.CentroidLat = 0;
        this.CentroidLon = 0;
        this.LabelLat = 0;
        this.LabelLon = 0;
    }
};

export class MapWaypoint {
    constructor() {
        this.Id = null;
        this.Label = "";
        this.Latitude = null;
        this.Longitude = null;
        this.Sort = -1;
        this.CustomQueryId = "";
        this.PointId = "";
        this.IsVia = null;
    }
};

export class MapLabel {
    constructor() {
        this.id = helpers.emptyGuid();
        this.pointId = null;
        this.title = null;
        this.style = null;
        this.labelPoint = { lat: null, lon: null };
        this.parentPoint = { lat: null, lon: null };
        this.showLine = null;
        this.draggable = null;
        this.anchor = { x: 0, y: 0 };
    }
};

export class MapModelThematicRange {
    constructor() {
        this.Alpha = 153;
        this.Red = 0;
        this.Green = 0;
        this.Blue = 0;
        this.LegendText = null;
        this.Sequence = null;
    }
};

export class MapCustomQuery {
    constructor() {
        this.Id = null;
        this.LayerText = null;
        this.MaxZoom = null;
        this.MaxLabelZoom = null;
        this.BingSearch = null;
        this.HiddenGroups = [];
        this.LabeledGroups = [];
        this.IsVisible = true;
        this.UpdateOnPan = true;
        this.AutoLabel = false;
        this.LabelStyle = {
            color: { r: 255, g: 255, b: 255, a: 1 },
            background: { r: 0, g: 0, b: 0, a: 1 },
            fontSize: 10,
            textAlign: 1,
            bold: false,
            italic: false,
            underline: false
        };
        this.DirtyLabels = [];
        this.ZIndex = null;
        this.LabelPosition = 2;
        this.LabelShowLine = false;
        this.LabelDraggable = false;
        this.FilterPoints = [];
        this.FilterPointLabels = [];
        this.IsAllSelected = true;
        this.VisibleGroups = [];
        this.HiddenLabels = [];
        this.IsCompetitiveInsights = false;
        this.CompetitiveInsightsFilteredChannels = [];
        this.CompetitiveInsightsGradeVisual = constants.competitiveInsights.visuals.chain;
        this.PointDataSourceFilterList = [];
    }
};

export class MapThematic {
    constructor() {
        this.Id = null;
        this.LayerText = null;
        this.IsVisible = null;
        this.ZIndex = null;
        this.IsLabeled = null;
        this.PinPointCount = null;
        this.HiddenGroups = [];
    }
};

export class MapCustomerMap {
    constructor() {
        this.Id = null;
        this.Type = null;
        this.LayerText = null;
        this.PointIds = [];
        this.IsVisible = null;
        this.CustomQueryIds = [];
        this.AdditionalAttributes = [];
    }
};

export class MapGeoFeed {
    constructor() {
        this.Id = null;
        this.LayerText = null;
        this.IsVisible = null;
        this.Shapes = [];
        this.AutoLabel = null;
        this.LabelStyle = {
            color: null,
            background: null,
            fontSize: null,
            textAlign: null,
            bold: null,
            italic: null,
            underline: null
        };
        this.DirtyLabels = [];
        this.ZIndex = null;
        this.LabelPosition = 2;
        this.LabelShowLine = false;
        this.LabelDraggable = false;
        this.HiddenLabels = [];
    }
};

export class MapTrip2Trade {
    constructor() {
        this.Id = null;
        this.LayerText = null;
        this.IsVisible = null;
        this.UpdateOnPan = true;
        this.Shapes = [];
        this.AutoLabel = null;
        this.LabelStyle = {
            color: null,
            background: null,
            fontSize: null,
            textAlign: null,
            bold: null,
            italic: null,
            underline: null
        };
        this.DirtyLabels = [];
        this.ZIndex = null;
        this.LabelPosition = null;
        this.LabelShowLine = null;
        this.LabelDraggable = null;
        this.HiddenLabels = [];
        this.HiddenGroups = [];
    }
};

export class MapGeoFence {
    constructor() {
        this.Id = null;
        this.CustomQueryId = null;
        this.PointTitle = null;
        this.Name = null;
        this.IsVisible = null;
        this.FillColor_A = null;
        this.FillColor_R = null;
        this.FillColor_G = null;
        this.FillColor_B = null;
        this.LineColor_A = null;
        this.LineColor_R = null;
        this.LineColor_G = null;
        this.LineColor_B = null;
        this.LineWidth = null;
        this.LineStyle = null;
    }
};

export class MapStreetSide {
    constructor() {
        this.CameraLat = 0;
        this.CameraLon = 0;
        this.Pitch = 0;
        this.Heading = 0;
        this.Zoom = 0;
    }
};

export class MapModelTradeArea {
    constructor() {
        this.Id = null;
        this.LayerText = null;
        this.SubTitle = null;
        this.IsVisible = null;
        this.Shapes = [];
        this.Ranges = [];
        this.ZIndex = null;
    }
};

export class MapCompetitiveInsightsTradeArea {
    constructor() {
        this.Type = null;
        this.PointIds = [];
        this.Percentage = null;
        this.IsVisible = null;
        this.ZIndex = null;
    }
};

export class MapAnalyticsModel {
    constructor() {
        this.customQueryId = helpers.emptyGuid();
        this.dataSourceId = helpers.emptyGuid();
        this.latitude = 0;
        this.longitude = 0;
        this.pointId = null;
        this.projectionId = helpers.emptyGuid();
        this.currentModelId = helpers.emptyGuid();
        this.demoReportId = 0;
        this.scenarioId = helpers.emptyGuid();
        this.showTheme = false;
        this.themeOutputId = helpers.emptyGuid();
        this.expandLegend = false;
        this.showOverlay = false;
        this.showSum = false;
        this.collapseGrid = false;
        this.currentSisterSite = -1;
        this.currentSiteId = null;
    }
};

export class MapLayer {
    constructor() {
        this.data = {
            isAdhoc: undefined,
            isCompetitiveInsights: undefined,
            competitiveInsightsFilteredChannels: [],
            competitiveInsightsGradeVisual: undefined,
            isCustomerMap: undefined,
            isDynamic: undefined,
            isTile: undefined,
            pointDataSourceFilterList: []
        }
        this.id = null;
        this.selected = true;
        this.subType = 0;
        this.text = "";
        this.type = 2;
        this.visible = true;
        this.labeled = false;
        this.pinPointCount = null;
        this.oppositeVisibilityEntities = [];
        this.dirtyLabels = [];
    }
};

export const projects = {
    initMapMetadata: () => {
        return {
            Information:
            {
                Id: null,
                Name: null,
                Description: null,
                ContactInformation: null,
                Center:
                {
                    lat: map.center.lat,
                    lon: map.center.lon
                },
                Zoom: map.zoom,
                Type: projects.getMapTypeMS(map.type),
                IsTraffic: false,   // TBD
                IsRoadLabeled: true,   // TBD
                IsFixed: false,
                IsCreator: true
            },
            PrintSettings: projects.getDefaultPrintSettings(),
            Cosmetic: new MapCosmetic(),
            Directions: new MapDirections(),
            CustomQueries: [],
            CustomerMaps: [],
            Trip2TradeLayers: [],
            GeoFeedLayers: [],
            Thematics: [],
            TradeAreaLibraries: [],
            GeoFences: [],
            StreetSideInformation: new MapStreetSide(),
            ModelTradeAreaLayers: [],
            AnalyticsModel: new MapAnalyticsModel(),
            CompetitiveInsightsTradeAreas: []
        }            
    },
    getMapMetadata: (o) => {

        if (o == null) o = { isPrint: false };

        var metaData = projects.initMapMetadata();

        var getEntityVisibility = (layer, groupId) =>{
            return layer.oppositeVisibilityEntities.indexOf(groupId) > -1 ? !layer.visible : layer.visible;
        };

        map.layers.forEach(layer => {

            switch (layer.type) {
                default:
                    break;

                case constants.layers.types.cosmetic:

                    metaData.Cosmetic.IsVisible = layer.visible;

                    layer.entities.forEach(entity => {
                        var myShape = new MapShape();
                        var myColor;

                        myShape.Id = entity.id;
                        myShape.Type = projects.getEntityTypeName(entity.type);
                        myShape.Title = entity.text;
                        myShape.Draggable = entity.draggable;

                        var validShape = false;

                        switch (entity.type) {
                            default:
                                break;
                            case constants.entities.pushpin:
                                if (entity.image != null) {
                                    const symbolData = helpers.getDataFromSymbolUrl(entity.image);
                                    myShape.ImageUrl = symbolData.name;
                                }

                                myShape.LineColor = null;
                                myShape.FillColor = null;
                                myShape.Description = entity.description;
                                myShape.EncodedString = helpers.encodedLocations([entity.location]);

                                validShape= true;

                                break;
                            case constants.entities.label:
                                // TBD: labels not yet available in KLI 2.0
                                /*
                                //myShape.Style = entity.style;

                                myShape.LineColor = null;
                                myShape.FillColor = null;
                                myShape.EncodedString = helpers.encodedLocations([entity.location]);

                                myShape.Anchor = { x: entity.anchor.x, y: entity.anchor.y };

                                // TBD:
                                //if (entity.properties.parent != null && entity.properties.parent.id != null)
                                //    myShape.ParentId = entity.properties.parent.id;
                                */

                                break;
                            case constants.entities.polyline:
                                myColor = entity.strokeColor;

                                myShape.LineColor.R = myColor.r;
                                myShape.LineColor.G = myColor.g;
                                myShape.LineColor.B = myColor.b;
                                myShape.LineColor.A = myColor.a;

                                myShape.FillColor = null;
                                
                                myShape.LineWidth = Math.round(entity.strokeWidth);
                                myShape.LineStyle = entity.strokeStyle;
                                myShape.Description = entity.description;
                                myShape.EncodedString = helpers.encodedLocations(entity.paths);

                                validShape= true;

                                break;
                            case constants.entities.polygon:
                            case constants.entities.rectangle:
                                myColor = entity.strokeColor;

                                myShape.LineColor.R = myColor.r;
                                myShape.LineColor.G = myColor.g;
                                myShape.LineColor.B = myColor.b;
                                myShape.LineColor.A = myColor.a;

                                myColor = entity.fillColor;

                                myShape.FillColor.R = myColor.r;
                                myShape.FillColor.G = myColor.g;
                                myShape.FillColor.B = myColor.b;
                                myShape.FillColor.A = myColor.a;

                                myShape.LineWidth = Math.round(entity.strokeWidth);
                                myShape.LineStyle = entity.strokeStyle;
                                myShape.Description = entity.description;

                                if (entity.hasMultiDimensionalPaths) {
                                    // TBD: handle Geography shapes which require geography data to be saved
                                    if (entity.metaData?.geoLevelId != null && entity.metaData?.vintageId != null) {
                                        myShape.GeoLevelId = entity.metaData.geoLevelId;
                                        myShape.GeographyIds = entity.metaData.geographyIds;
                                        myShape.VintageId = entity.metaData.vintageId;

                                        //validShape= true;
                                    }
                                }
                                else {
                                    myShape.EncodedString = helpers.encodedLocations(entity.paths);
                                    validShape= true;
                                }

                                break;
                            case constants.entities.standardGeography:
                                if (entity.metaData?.geoLevelId != null && entity.metaData?.vintageId != null && entity.metaData?.geographyIds != null && entity.metaData?.geographyIds.length > 0) {

                                    myColor = entity.strokeColor;

                                    myShape.LineColor.R = myColor.r;
                                    myShape.LineColor.G = myColor.g;
                                    myShape.LineColor.B = myColor.b;
                                    myShape.LineColor.A = myColor.a;

                                    myColor = entity.fillColor;

                                    myShape.FillColor.R = myColor.r;
                                    myShape.FillColor.G = myColor.g;
                                    myShape.FillColor.B = myColor.b;
                                    myShape.FillColor.A = myColor.a;

                                    myShape.LineWidth = Math.round(entity.strokeWidth);
                                    myShape.LineStyle = entity.strokeStyle;
                                    myShape.Description = entity.description;

                                    myShape.GeoLevelId = entity.metaData.geoLevelId;
                                    myShape.GeographyIds = entity.metaData.geographyIds;
                                    myShape.VintageId = entity.metaData.vintageId;

                                    myShape.EncodedString = '';

                                    validShape= true;
                                }

                                break;
                            case constants.entities.circle:
                                myColor = entity.strokeColor;

                                myShape.LineColor.R = myColor.r;
                                myShape.LineColor.G = myColor.g;
                                myShape.LineColor.B = myColor.b;
                                myShape.LineColor.A = myColor.a;

                                myColor = entity.fillColor;

                                myShape.FillColor.R = myColor.r;
                                myShape.FillColor.G = myColor.g;
                                myShape.FillColor.B = myColor.b;
                                myShape.FillColor.A = myColor.a;

                                myShape.LineWidth = Math.round(entity.strokeWidth);
                                myShape.LineStyle = entity.strokeStyle;

                                // convert radius from meters to miles because KLI 1.0 defaults to miles while 2.0 defaults to meters,
                                // and we do not pass measurment units when saving a map
                                myShape.Radius = helpers.convertLength(parseFloat(entity.radius), constants.lengthMeasurements.meters, constants.lengthMeasurements.miles);

                                myShape.Description = entity.description;
                                myShape.EncodedString = helpers.encodedLocations([entity.location]);

                                validShape= true;

                                break;
                        }

                        if (validShape)
                            metaData.Cosmetic.Shapes.push(myShape);
                    });

                    break;

                case constants.layers.types.directions:

                    var myWaypoints = directionsManager.getAllWaypoints();

                    myWaypoints.forEach((waypoint, i) => {   
                        var myWaypoint = new MapWaypoint();

                        if (waypoint.pointId != null && waypoint.pointId.length > 0) {
                            myWaypoint.PointId = waypoint.pointId;
                            // TBD: set myWaypoint.CustomQueryId
                        }

                        if (waypoint.location != null) {
                            myWaypoint.Latitude = waypoint.location.lat;
                            myWaypoint.Longitude = waypoint.location.lon;
                        }
                        else {
                            myWaypoint.Latitude = 0;
                            myWaypoint.Longitude = 0;
                        }

                        myWaypoint.Id = waypoint.id;
                        myWaypoint.Label = waypoint.value;
                        myWaypoint.Sort = i;
                        myWaypoint.IsVia = false;

                        metaData.Directions.Waypoints.push(myWaypoint);
                    });

                    metaData.Directions.IsVisible = layer.visible;

                    break;

                case constants.layers.types.point:
                    var myCustomQuery = new MapCustomQuery();
                    
                    myCustomQuery.Id = layer.id.toUpperCase();
                    myCustomQuery.LayerText = layer.text;
                    myCustomQuery.MaxZoom = layer.metaData.serviceAttributes.MaxZoom;
                    myCustomQuery.MaxLabelZoom = layer.metaData.serviceAttributes.MaxLabelZoom;
                    myCustomQuery.BingSearch = layer.metaData.serviceAttributes.SearchFilter;     // TDB: is this needed?
                    myCustomQuery.LabeledGroups = [];    // TBD
                    myCustomQuery.IsVisible = layer.active;
                    myCustomQuery.UpdateOnPan = layer.visible;
                    myCustomQuery.AutoLabel = layer.labeled;
                    myCustomQuery.ZIndex = layer.zIndex;
                    myCustomQuery.IsAllSelected = true;   // is this needed?
                    myCustomQuery.HiddenLabels = [];    // TBD

                    myCustomQuery.VisibleGroups = [];
                    myCustomQuery.HiddenGroups = [];

                    if (layer.data != null && layer.data.pointDataSourceFilterList != null)
                        layer.data.pointDataSourceFilterList.map(item => {myCustomQuery.PointDataSourceFilterList.push(item.id)});

                    if (layer.visible) {
                        layer.legend.forEach(item => {
                            myCustomQuery.VisibleGroups.push(`${myCustomQuery.Id}_${item.groupId}`);
                        });
                    }
                    else {
                        layer.legend.forEach(item => {
                            myCustomQuery.HiddenGroups.push(`${myCustomQuery.Id}_${item.groupId}`);
                        });
                    }

                    if (_.isArray(layer.oppositeVisibilityEntities) && layer.oppositeVisibilityEntities.length > 0) {
                        layer.oppositeVisibilityEntities.forEach(entityId => {
                            if (layer.visible) {
                                myCustomQuery.HiddenGroups.push(`${myCustomQuery.Id}_${entityId}`);
                                myCustomQuery.VisibleGroups = myCustomQuery.VisibleGroups.filter(group => group !== `${myCustomQuery.Id}_${entityId}`);
                            }
                            else {
                                myCustomQuery.VisibleGroups.push(`${myCustomQuery.Id}_${entityId}`);
                                myCustomQuery.HiddenGroups = myCustomQuery.HiddenGroups.filter(group => group !== `${myCustomQuery.Id}_${entityId}`);
                            }
                        });
                    }

                    myCustomQuery.IsCompetitiveInsights = layer.data.isCompetitiveInsights;
                    if (myCustomQuery.IsCompetitiveInsights) {
                        myCustomQuery.CompetitiveInsightsFilteredChannels = layer.metaData.channels.filter(channel => channel.isSelected).map(channel => { return channel.id; });
                        myCustomQuery.CompetitiveInsightsGradeVisual = layer.metaData.currentIndex;
                    }

                    if (_.isObject(layer.metaData.serviceAttributes.MapLabelStyle)) {
                        if (_.isObject(layer.metaData.serviceAttributes.MapLabelStyle.Color)) {
                            if (layer.metaData.serviceAttributes.MapLabelStyle.Color.Alpha == null)
                                layer.metaData.serviceAttributes.MapLabelStyle.Color.Alpha = 1;

                            myCustomQuery.LabelStyle.color = {
                                a: layer.metaData.serviceAttributes.MapLabelStyle.Color.Alpha,
                                b: layer.metaData.serviceAttributes.MapLabelStyle.Color.Blue,
                                g: layer.metaData.serviceAttributes.MapLabelStyle.Color.Green,
                                r: layer.metaData.serviceAttributes.MapLabelStyle.Color.Red
                            }

                        } else {
                            myCustomQuery.LabelStyle.color = null;
                        }

                        if (_.isObject(layer.metaData.serviceAttributes.MapLabelStyle.Fill)) {
                            if (layer.metaData.serviceAttributes.MapLabelStyle.Fill.Alpha == null)
                                layer.metaData.serviceAttributes.MapLabelStyle.Fill.Alpha = 1;

                            myCustomQuery.LabelStyle.background = {
                                a: layer.metaData.serviceAttributes.MapLabelStyle.Fill.Alpha,
                                b: layer.metaData.serviceAttributes.MapLabelStyle.Fill.Blue,
                                g: layer.metaData.serviceAttributes.MapLabelStyle.Fill.Green,
                                r: layer.metaData.serviceAttributes.MapLabelStyle.Fill.Red
                            }

                        } else {
                            myCustomQuery.LabelStyle.background = null;
                        }

                        myCustomQuery.LabelStyle.fontSize = layer.metaData.serviceAttributes.MapLabelStyle.Size;
                        myCustomQuery.LabelStyle.textAlign = layer.metaData.serviceAttributes.MapLabelStyle.Alignment;
                        myCustomQuery.LabelStyle.bold = layer.metaData.serviceAttributes.MapLabelStyle.Bold;
                        myCustomQuery.LabelStyle.italic = layer.metaData.serviceAttributes.MapLabelStyle.Italic;
                        myCustomQuery.LabelStyle.underline = layer.metaData.serviceAttributes.MapLabelStyle.Underline;
                        myCustomQuery.LabelShowLine = layer.metaData.serviceAttributes.MapLabelStyle.ShowLine;
                        myCustomQuery.LabelDraggable = layer.metaData.serviceAttributes.MapLabelStyle.Draggable;
                        myCustomQuery.LabelPosition = layer.metaData.serviceAttributes.MapLabel.position || constants.labelPosition.topMiddle;
                    }

                    if (_.isArray(layer.dirtyLabels))
                        layer.dirtyLabels.forEach(dirtyLabel => {        
                            const entity = layer.entities.find(entity => entity.id === dirtyLabel.entityId);
                            const label = layer.labels.find(label => label.id === dirtyLabel.id);

                            if (_.isObject(entity) && _.isObject(label)) {
                                var myLabel = new MapLabel();

                                myLabel.id = dirtyLabel.id;
                                myLabel.pointId = dirtyLabel.entityId;
                                myLabel.title = entity.label;
                                myLabel.style = _.isObject(layer.metaData.serviceAttributes.MapLabel) ? layer.metaData.serviceAttributes.MapLabel.style : null;
                                myLabel.labelPoint.lat = dirtyLabel.location.lat;
                                myLabel.labelPoint.lon = dirtyLabel.location.lon;
                                myLabel.parentPoint.lat = entity.location.lat;
                                myLabel.parentPoint.lon = entity.location.lon;
                                myLabel.showLine = true;
                                myLabel.draggable = label.draggable;
                                myLabel.anchor = { x: label.anchor.x, y: label.anchor.y };

                                myCustomQuery.DirtyLabels.push(myLabel);
                            }
                        });

                    metaData.CustomQueries.push(myCustomQuery);
                    metaData.CustomQueries = _.sortBy(metaData.CustomQueries, "ZIndex").reverse();

                    break;

                case constants.layers.types.thematics.parcel:
                case constants.layers.types.thematics.trafficMetrix:
                case constants.layers.types.thematics.standardGeography:
                case constants.layers.types.thematics.sqlDataLayer:
                            
                    switch (layer.subType) {
                        default:
                            break;
                        case constants.layers.types.thematics.subTypes.range:
                        case constants.layers.types.thematics.subTypes.dotDensity:
                        case constants.layers.types.thematics.subTypes.polygon:

                            var myThematic = new MapThematic();

                            myThematic.Id = layer.id;
                            myThematic.LayerText = layer.text;
                            myThematic.IsVisible = layer.visible;
                            myThematic.ZIndex = layer.zIndex;
                            myThematic.IsLabeled = layer.labeled;
                            myThematic.Type = layer.type;
                            myThematic.PinPointCount = layer.metaData.pinPointCount ? layer.metaData.pinPointCount : -1;

                            // TBD: save to HiddenGroups, but this wasn't supported in KLI 1.0 - need to update SOA to return this when loading a saved map
                            myThematic.HiddenGroups = [];
                            if (_.isArray(layer.oppositeVisibilityEntities) && layer.oppositeVisibilityEntities.length > 0) {
                                layer.oppositeVisibilityEntities.forEach(entityId => {
                                    myThematic.HiddenGroups.push(`${layer.id}_${entityId}`);
                                });
                            }

                            metaData.Thematics.push(myThematic);
                            metaData.Thematics = _.sortBy(metaData.Thematics, "ZIndex").reverse();

                            break;
                    }

                    break;

                case constants.layers.types.customerMaps.pin:
                case constants.layers.types.customerMaps.desireLine:
                case constants.layers.types.customerMaps.marketShare:
                case constants.layers.types.customerMaps.heat:

                    var myCustomerMap = new MapCustomerMap();
                    var mySubtext = "";

                    if (layer.type === constants.layers.types.customerMaps.desireLine && layer.metaData.isUber) {
                        mySubtext = layer.subText;
                    }

                    myCustomerMap.Id = layer.metaData.isUber || layer.metaData.isDynamic ? layer.metaData.themeId : layer.id.replace(`${layer.type}_`, '');
                    myCustomerMap.Type = customerMaps.getRendererType({ type: layer.type });
                    myCustomerMap.LayerText = layer.text;
                    myCustomerMap.IsVisible = layer.visible;
                    myCustomerMap.PointIds = [...layer.metaData.points, ...layer.oppositeVisibilityEntities];
                    myCustomerMap.IsChain = false;  // TBD
                    myCustomerMap.CustomQueryIds = _.isArray(layer.metaData.customQueryList) && layer.metaData.customQueryList.length > 0 && !_.isUndefined(layer.metaData.customQueryList[0]) ? layer.metaData.customQueryList : "";

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "isUber",
                        Value: (layer.metaData.isUber) ? layer.metaData.isUber : "false"
                    });

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "timeType",
                        Value: (layer.metaData.isUber) ? layer.metaData.timeType : "-1"
                    });

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "dataType",
                        Value: (layer.metaData.isUber) ? layer.metaData.dataType : "-1"
                    });

                    myCustomerMap.AdditionalAttributes.push({ 
                        Key: "subtext", 
                        Value: mySubtext 
                    });

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "isDynamic",
                        Value: (layer.metaData.isDynamic) ? layer.metaData.isDynamic : "false"
                    });

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "isTile",
                        Value: (layer.metaData.isTile) ? layer.metaData.isTile : "false"
                    });

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "isCompetitiveInsights",
                        Value: (layer.metaData.isCompetitiveInsights) ? layer.metaData.isCompetitiveInsights : "false"
                    });

                    myCustomerMap.AdditionalAttributes.push({
                        Key: "pinPointCount",
                        Value: (layer.metaData.pinPointCount) ? layer.metaData.pinPointCount : "-1"
                    });

                    metaData.CustomerMaps.push(myCustomerMap);
        
                    break;

                case constants.layers.types.geoFeeds:
                    var myGeoFeed = new MapGeoFeed();

                    myGeoFeed.Id = layer.id;
                    myGeoFeed.LayerText = layer.text;
                    myGeoFeed.IsVisible = layer.visible;
                    myGeoFeed.AutoLabel = layer.labeled;
                    myGeoFeed.ZIndex = layer.zIndex;

                    // TBD: labels not yet available in KLI 2.0, set default values:
                    myGeoFeed.LabelStyle = {
                        color: { r: 255, g: 255, b: 255, a: 1 },
                        background: { r: 0, g: 0, b: 0, a: 1 },
                        fontSize: 10,
                        textAlign: 1,
                        bold: true,
                        italic: false,
                        underline: false
                    }

                    // TBD: the geofeed layer does not have any entities - how to save shapes?
                    myGeoFeed.Shapes = [];

                    metaData.GeoFeedLayers.push(myGeoFeed);
                    metaData.GeoFeedLayers = _.sortBy(metaData.GeoFeedLayers, "ZIndex").reverse();

                    break;

                case constants.layers.types.tradeArea:

                    var tradeAreaLibrary = mapBooks.getNewTradeAreaLibraryForMapBook(layer.tradeAreas);         
                    tradeAreaLibrary.allowShapeDelete = layer.metaData.allowShapeDelete;
                    tradeAreaLibrary.allowShapeEdit = layer.metaData.allowShapeEdit;
                    tradeAreaLibrary.allowShapeView = layer.metaData.allowShapeView;
                    tradeAreaLibrary.center = {
                        lat: layer.metaData.center.lat,
                        lon: layer.metaData.center.lon,
                        tag: null
                    };
                    tradeAreaLibrary.customQueryId = layer.metaData.customQueryId;
                    tradeAreaLibrary.dataSourceGUID = layer.metaData.dataSourceGUID;
                    tradeAreaLibrary.dataSourceId = layer.metaData.dataSourceId;
                    tradeAreaLibrary.generated = true;
                    tradeAreaLibrary.id = helpers.newGuid();
                    tradeAreaLibrary.isVisible = layer.visible;
                    tradeAreaLibrary.name = layer.text;
                    tradeAreaLibrary.pointId = layer.metaData.pointId;
                    tradeAreaLibrary.tradeAreaTemplateId = helpers.emptyGuid();
                    tradeAreaLibrary.zIndex = layer.zIndex;
                    tradeAreaLibrary.zoomToLayer = true;
                                
                    metaData.TradeAreaLibraries.push(tradeAreaLibrary);
                    metaData.TradeAreaLibraries = _.sortBy(metaData.TradeAreaLibraries, "ZIndex").reverse();

                    break;

                case constants.layers.types.geoFence:

                    layer.entities.forEach(geoFence => {
                        var myGeoFence = new MapGeoFence();

                        myGeoFence.Id = geoFence.id;

                        if (_.isObject(geoFence.metaData.pointTitle) && _.isString(geoFence.metaData.pointTitle.text))
                            myGeoFence.PointTitle = geoFence.metaData.pointTitle.text;
                        else if (_.isString(geoFence.metaData.pointTitle))
                            myGeoFence.PointTitle = geoFence.metaData.pointTitle;
                        
                        myGeoFence.Name = geoFence.text;
                        myGeoFence.IsVisible = geoFence.visible;
                        myGeoFence.CustomQueryId = geoFence.metaData.dataSourceId;

                        var myGeoFenceFillColor = geoFence.fillColor;
                        var myGeoFenceLineColor = geoFence.strokeColor;

                        myGeoFence.FillColor_A = myGeoFenceFillColor.a;
                        myGeoFence.FillColor_R = myGeoFenceFillColor.r;
                        myGeoFence.FillColor_G = myGeoFenceFillColor.g;
                        myGeoFence.FillColor_B = myGeoFenceFillColor.b;

                        myGeoFence.LineColor_A = myGeoFenceLineColor.a;
                        myGeoFence.LineColor_R = myGeoFenceLineColor.r;
                        myGeoFence.LineColor_G = myGeoFenceLineColor.g;
                        myGeoFence.LineColor_B = myGeoFenceLineColor.b;
                        
                        myGeoFence.LineWidth = Math.round(geoFence.strokeWidth);
                        myGeoFence.LineStyle = geoFence.strokeStyle;

                        metaData.GeoFences.push(myGeoFence);
                    });
    
                    break;

                case constants.layers.types.modelTradeArea:
                    // TBD
                    /*
                    var myModelTA = new MapModelTradeArea();

                    myModelTA.Id = layer.id;
                    myModelTA.LayerText = layer.text;
                    myModelTA.SubTitle = layer.subtext;
                    myModelTA.IsVisible = layer.visible;
                    myModelTA.ZIndex = _.isNaN(layer.zIndex) ? 1 : layer.zIndex;

                    if (_.isObject(layer.theme))
                        _.each(layer.theme.Ranges, function (range) {
                            var modelRange = new MapModelThematicRange();

                            modelRange.Alpha = range.Alpha;
                            modelRange.Red = range.Red;
                            modelRange.Green = range.Green;
                            modelRange.Blue = range.Blue;
                            modelRange.LegendText = range.LegendText;
                            modelRange.Sequence = range.Sequence;

                            myModelTA.Ranges.push(modelRange);
                        });

                    if (myModelTA.IsVisible) {

                        layer.entities.forEach(entity => {
                            if (!_.isObject(entity.properties))
                                return;

                            var modelShape = new MapShape();

                            modelShape.Id = helpers.newGuid();
                            modelShape.Type = entity.type;
                            modelShape.Title = entity.text;
                            modelShape.ParentId = entity.id;
                            modelShape.MapObjectId = layer.id;
                            modelShape.Draggable = false;

                            switch (entity.type) {
                                case constants.entities.polygon:
                                    var myColor = helpers.parseCssStyleColor(entity.strokeColor);

                                    modelShape.LineColor.R = myColor.r;
                                    modelShape.LineColor.G = myColor.g;
                                    modelShape.LineColor.B = myColor.b;
                                    modelShape.LineColor.A = myColor.a;

                                    myColor = helpers.parseCssStyleColor(entity.fillColor);

                                    modelShape.FillColor.R = myColor.r;
                                    modelShape.FillColor.G = myColor.g;
                                    modelShape.FillColor.B = myColor.b;
                                    modelShape.FillColor.A = myColor.a;

                                    modelShape.LineWidth = Math.round(entity.strokeWidth);
                                    modelShape.LineStyle = entity.strokeStyle;

                                    modelShape.Description = entity.description;

                                    // TBD:
                                    //modelShape.EncodedString = Microsoft.Maps.WellKnownText.write(entity);

                                    if (entity.centroid != null) {
                                        modelShape.CentroidLat = entity.centroid.latitude;
                                        modelShape.CentroidLon = entity.centroid.longitude;
                                        modelShape.LabelLat = entity.label.anchorLocation.latitude;
                                        modelShape.LabelLon = entity.label.anchorLocation.longitude;
                                    }
                                    break;
                                default:
                                    break;
                            }

                            myModelTA.Shapes.push(modelShape);
                        });
                    }

                    metaData.ModelTradeAreaLayers.push(myModelTA);
                    */

                    break;

                case constants.layers.types.competitiveInsightsTradeArea:

                    var myCompetitiveInsightsTradeArea = new MapCompetitiveInsightsTradeArea();

                    myCompetitiveInsightsTradeArea.Type = layer.metaData.tradeAreaType;
                    myCompetitiveInsightsTradeArea.Name = layer.text;
                    myCompetitiveInsightsTradeArea.SubTitle = _.isString(layer.subText) ? layer.subText : "";
                    myCompetitiveInsightsTradeArea.PointIds = layer.metaData.points;
                    myCompetitiveInsightsTradeArea.Percentage = _.isNumber(layer.metaData.percentage) ? layer.metaData.percentage : -1;
                    myCompetitiveInsightsTradeArea.IsVisible = layer.visible;
                    myCompetitiveInsightsTradeArea.ZIndex = layer.zIndex;

                    if (_.isObject(layer.metaData.center))
                        myCompetitiveInsightsTradeArea.Center = { lat: layer.metaData.center.lat, lon: layer.metaData.center.lon };
                    else
                        myCompetitiveInsightsTradeArea.Center = { lat: 0, lon: 0 };

                    metaData.CompetitiveInsightsTradeAreas.push(myCompetitiveInsightsTradeArea);

                    break;

                case constants.layers.types.data:

                    switch (layer.group) {
                        default:
                            break;

                        case constants.layers.groups.trip2Trade:

                            var myTrip2Trade = new MapTrip2Trade();
        
                            myTrip2Trade.Id = layer.id.toUpperCase();
                            myTrip2Trade.LayerText = layer.text;
                            myTrip2Trade.IsVisible = layer.active;
                            myTrip2Trade.UpdateOnPan = layer.visible;
                            myTrip2Trade.AutoLabel = layer.labeled;
                            myTrip2Trade.ZIndex = _.isNaN(layer.zIndex) ? zIndexes({ type: constants.layers.types.trip2Trade, subType: 0 }) : layer.zIndex;
                            myTrip2Trade.HiddenLabels = [];
        
                            // TBD: we don't have a label button on trip2trade layers in KLI 2.0, so set default values:
                            myTrip2Trade.LabelStyle = {
                                color: { r: 255, g: 255, b: 255, a: 1 },
                                background: { r: 0, g: 0, b: 0, a: 1 },
                                fontSize: 10,
                                textAlign: 1,
                                bold: true,
                                italic: false,
                                underline: false
                            }
                            myTrip2Trade.LabelShowLine = false;
                            myTrip2Trade.LabelDraggable = false;
                            myTrip2Trade.LabelPosition = 2;

                            myTrip2Trade.HiddenGroups = [];
                            if (_.isArray(layer.oppositeVisibilityEntities) && layer.oppositeVisibilityEntities.length > 0) {
                                layer.oppositeVisibilityEntities.forEach(groupId => {
                                    // legend group ids are newly generated each time, so save the legend item index because items will be in the same order
                                    const index = layer.legend.findIndex(legend => legend.groupId === groupId);
                                    if (index > -1)
                                        myTrip2Trade.HiddenGroups.push(`${myTrip2Trade.Id}_${index}`);
                                });
                            }
        
                            // save shapes for print map, but don't need them when loading saved maps
                            myTrip2Trade.Shapes = [];
                            if (myTrip2Trade.IsVisible) {

                                // entities within a hidden group may still need to be displayed, but with the next group's color,
                                // so determine the correct color and visibility for each entity
                                var myEntities = _.cloneDeep(layer.entities);

                                if (layer.oppositeVisibilityEntities.length > 0) {
                                    var indexVisibilities = layer.legend.map((legend, index) => { 

                                        var color = legend.color;
                                        var visible = getEntityVisibility(layer, legend.groupId);
        
                                        for (var i = index; i < layer.legend.length; i++)
                                        {
                                            var indexVisibility = getEntityVisibility(layer, layer.legend[i].groupId);                                        
                                            if (visible === false && indexVisibility)
                                            {
                                                color = layer.legend[i].color;
                                                visible = indexVisibility;
                                            }
                                        }
        
                                        return { groupId: legend.groupId, visible: visible, color: color }; 
                                    });
        
                                    myEntities.filter(x => _.isString(x.groupId)).forEach(x => {
                                        var group = indexVisibilities.find(index => index.groupId === x.groupId);                                
                                        x.fillColor = { r: group.color.r, g: group.color.g, b: group.color.b, a: x.fillColor.a };
                                        x.strokeColor = { r: group.color.r, g: group.color.g, b: group.color.b, a: x.strokeColor.a };
                                        x.visible = group.visible;
                                    });
                                }

                                const bounds = helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})

                                myEntities.forEach(entity => {

                                    if (_.isObject(entity.data) && _.isObject(entity.data.geometry) && _.isArray(entity.data.geometry.coordinates)) {

                                        var hideEntity = !entity.visible;

                                        var locations = [];
                                        entity.data.geometry.coordinates.forEach(coord => { 
                                            locations = [...locations, ...coord.map(c => { return { lat: c[1], lon: c[0] } })];
                                        });

                                        hideEntity = hideEntity || !map.polygonIntersectsPolygon(locations, bounds);

                                        if (!hideEntity) {
                                            var myColor;
                                            var trip2TradeShape = new MapShape();

                                            delete trip2TradeShape.GeoLevelId;
                                            delete trip2TradeShape.GeographyIds;
                                            delete trip2TradeShape.VintageId;

                                            trip2TradeShape.Id = helpers.newGuid();
                                            trip2TradeShape.Draggable = entity.draggable;
                                            trip2TradeShape.ParentId = entity.id;
                                            trip2TradeShape.MapObjectId = layer.id;

                                            trip2TradeShape.Type = entity.data.geometry.type;

                                            if (_.isObject(entity.data.properties))
                                                trip2TradeShape.Title = entity.data.properties.name;

                                            const entityType = projects.getEntityTypeId(trip2TradeShape.Type)
                                            switch (entityType) {
                                                default:
                                                    break;
                                
                                                case constants.entities.pushpin:
                                        
                                                    if (entity.image != null) {
                                                        const symbolData = helpers.getDataFromSymbolUrl(entity.image);
                                                        trip2TradeShape.ImageUrl = symbolData.name;
                                                    }
                    
                                                    trip2TradeShape.LineColor = null;
                                                    trip2TradeShape.FillColor = null;
                                                    trip2TradeShape.Description = entity.description;
                                                    trip2TradeShape.EncodedString = helpers.encodedLocations([entity.location]);
                                                
                                                    break;
                                
                                                case constants.entities.label:
                                                    // TBD
                                                    break;
                                
                                                case constants.entities.polyline:
                                
                                                    myColor = entity.strokeColor;

                                                    trip2TradeShape.LineColor.R = myColor.r;
                                                    trip2TradeShape.LineColor.G = myColor.g;
                                                    trip2TradeShape.LineColor.B = myColor.b;
                                                    trip2TradeShape.LineColor.A = myColor.a;

                                                    trip2TradeShape.FillColor = null;
                    
                                                    trip2TradeShape.LineWidth = Math.round(entity.strokeWidth);
                                                    trip2TradeShape.LineStyle = "Solid";
                                                    trip2TradeShape.Description = entity.description;
        
                                                    trip2TradeShape.EncodedString = helpers.encodedLocations(locations);
                                
                                                    break;
                                
                                                case constants.entities.polygon:
                                                case constants.entities.rectangle:
                                                
                                                    myColor = entity.strokeColor;

                                                    trip2TradeShape.LineColor.R = myColor.r;
                                                    trip2TradeShape.LineColor.G = myColor.g;
                                                    trip2TradeShape.LineColor.B = myColor.b;
                                                    trip2TradeShape.LineColor.A = myColor.a;
                    
                                                    myColor = entity.fillColor;
                    
                                                    trip2TradeShape.FillColor.R = myColor.r;
                                                    trip2TradeShape.FillColor.G = myColor.g;
                                                    trip2TradeShape.FillColor.B = myColor.b;
                                                    trip2TradeShape.FillColor.A = myColor.a;
                    
                                                    trip2TradeShape.LineWidth = Math.round(entity.strokeWidth);
                                                    trip2TradeShape.LineStyle = "Solid";
                                                    trip2TradeShape.Description = entity.description;
        
                                                    trip2TradeShape.EncodedString = helpers.encodedLocations(locations);
                                    
                                                    break;
                                            }
                                
                                            myTrip2Trade.Shapes.push(trip2TradeShape);
                                        }
                                    }
                                });
                            }
        
                            metaData.Trip2TradeLayers.push(myTrip2Trade);
                            metaData.Trip2TradeLayers = _.sortBy(metaData.Trip2TradeLayers, "ZIndex").reverse();
        
                            break;
                                        
                    }

                    break;
            }
        });

        // TBD: if streetside get its zoom
        
        // TBD: metaData.AnalyticsModel = projectionModule.getAnalyticsModelOptions();

        return metaData;
    },
    loadMap: async (o) => {      
    
        var newMap = null;
        const mapPromise = new Promise(async (resolve) => {
            newMap = await legacyEndpoints.service({
                name: 'GetMap',
                suppressError: true,
                parameters: {
                    Id: o.mapId,
                    trackUsage: true
                }
            });
            resolve();
        });

        var pointServices = [];
        const pointPromise = new Promise(async (resolve) => {
            pointServices = await legacyEndpoints.service({
                name: 'GetPointServices',
                suppressError: true
            });

            if (!_.isArray(pointServices))
                pointServices = [];

            resolve();
        });

        var thematicServices = [];
        const thematicPromise = new Promise(async (resolve) => {
            thematicServices = await legacyEndpoints.service({
                name: 'GetThemeServices',
                suppressError: true
            });

            if (!_.isArray(thematicServices))
                thematicServices = [];

            resolve();
        });

        var customerMapServices = [];
        const customerMapPromise = new Promise(async (resolve) => {
            customerMapServices = await legacyEndpoints.service({
                name: 'GetCustomerMapsForUser',
                suppressError: true
            });

            if (!_.isArray(customerMapServices))
                customerMapServices = [];

            resolve();
        });
        
        await Promise.all([mapPromise, pointPromise, thematicPromise, customerMapPromise]);

        newMap.CustomQueries = newMap.CustomQueries.filter(customQuery =>_.isObject(pointServices.find(service => service.Id.toLowerCase() == customQuery.Id.toLowerCase())));
        newMap.Thematics = newMap.Thematics.filter(thematic =>_.isObject(thematicServices.find(service => service.Id == thematic.Id)));
        newMap.CustomerMaps = newMap.CustomerMaps.filter(customerMap =>_.isObject(customerMapServices.find(service => service.MapID.toString().toLowerCase() == customerMap.Id.toString().toLowerCase() && service.Type == customerMap.Type)));

        if (!_.isObject(newMap) || newMap.Information?.Id == null) return false;

        mapControl.setMap({
            map: newMap
        });
        
        map.clear();

        if (_.isObject(newMap.Information)) {
            var center = newMap.Information.Center;
            var zoom = newMap.Information.Zoom;
            var type = newMap.Information.Type;

            if (_.isNumber(o.latitude) && _.isNumber(o.longitude))
                center = { lat: o.latitude, lon: o.longitude };

            if (_.isNumber(o.zoom))
                zoom = o.zoom;

            if (_.isString(o.type))
                type = o.type;

            map.center = center;
            map.zoom = zoom;

            if (type == constants.map.legacyTypes.street) 
                type = constants.map.legacyTypes.road;
            else if (type == constants.map.legacyTypes.hybrid)
                type = constants.map.legacyTypes.aerial;

            map.type = projects.getMapTypeId(type);

            /* TBD
            if (newMap.Information.IsTraffic)
                mapControl.buttons.traffic.click();

            if (newMap.Information.IsRoadLabeled != mapControl.buttons.labelToggle.checked)
                mapControl.buttons.labelToggle.click();
            */
        }

       await helpers.wait({
            stop: () => { return map.isReady() },
            sleep: 1000,
            maxWait: 30000
        });
               
        if (helpers.isViewer() && newMap?.Information?.IsFixed)
        {
            map.disableZooming();
            map.disablePanning();
        }

        [
            { id: 'cosmetic', action: () => {projects.loadCosmetic(newMap.Cosmetic)} },
            { id: 'custom queries', action: () => {projects.loadCustomQueries(newMap.CustomQueries, newMap.TradeAreaLibraries)} },
            { id: 'thematics', action: () => {projects.loadThematics(newMap.Thematics)} },
            { id: 'customer maps', action: () => {projects.loadCustomerMaps(newMap.CustomerMaps)} },
            { id: 'trip 2 trade', action: () => {projects.loadTrip2Trade(newMap.Trip2TradeLayers)} },
            { id: 'geofeeds', action: () => {projects.loadGeoFeeds(newMap.GeoFeedLayers)} },
            { id: 'geofences', action: () => {projects.loadGeofences(newMap.GeoFences)} },
            { id: 'competitive insights', action: () => {projects.loadCompetitiveInsightsTradeAreas(newMap.CompetitiveInsightsTradeAreas)}},
            { id: 'driving directions', action: () => {projects.loadDrivingDirections(newMap.Directions)} }
        ].forEach(item =>{
            try{
                item.action();
            }
            catch(e){
                console.error(item.id, e);
            }
        });

        return newMap;
    },
    loadCosmetic: async (cosmeticList) => {

        var myShapes = [];

        for (const shape of cosmeticList.Shapes) {

            var validShape = false;

            var myShape = {
                id: shape.Id,
                name: shape.Title,
                description: shape.Description,
                type: projects.getEntityTypeId(shape.Type)
            };

            var myLocations = helpers.decodeLocations(shape.EncodedString);

            switch (myShape.type) {
                default:
                    break;

                case constants.entities.pushpin:

                    myShape.location = myLocations.shift();

                    if (shape.ImageUrl != null && shape.ImageUrl != "") {
                        myShape.symbol = legacyEndpoints.handlers.getSymbolUrl({ imageUrl: shape.ImageUrl });
                        validShape = true;
                    }

                    break;

                case constants.entities.label:
                    // TBD
                    break;

                case constants.entities.polyline:

                    myShape.locations = myLocations;

                    myShape.strokeColor = { r: shape.LineColor.R, g: shape.LineColor.G, b: shape.LineColor.B, a: shape.LineColor.A }
                    myShape.strokeWidth = shape.LineWidth;
                    myShape.strokeStyle = shape.LineStyle;

                    validShape = true;

                    break;

                case constants.entities.polygon:
                case constants.entities.rectangle:
                
                    if (myLocations.length > 2) {
                        myShape.locations = myLocations;
                        myShape.fillColor = { r: shape.FillColor.R, g: shape.FillColor.G, b: shape.FillColor.B, a: shape.FillColor.A }

                        myShape.strokeColor = { r: shape.LineColor.R, g: shape.LineColor.G, b: shape.LineColor.B, a: shape.LineColor.A }
                        myShape.strokeWidth = shape.LineWidth;
                        myShape.strokeStyle = shape.LineStyle;
    
                        validShape = true;
                    }

                    break;

                case constants.entities.standardGeography:
                    if (shape.GeoLevelId != null && shape.VintageId != null && shape.GeographyIds != null && shape.GeographyIds.length > 0) {
            
                        var results = await legacyEndpoints.service({
                            name: 'GetStandardGeographyShapes',
                            parameters: {
                                vintageId: shape.VintageId,
                                geoLevelId: shape.GeoLevelId,
                                reportId: -1,
                                selectionType: constants.selections.types.append,
                                selectionBehavior: constants.selections.behaviors.single, 
                                polygonSelection: { polygonString: helpers.encodedLocations([{ lat: 0, lon: 0}]), polygonType: constants.encodedString.google },
                                selectedGeographies: shape.GeographyIds,
                                skip: 0, 
                                take: 0, 
                                filters: [], 
                                sorts: [],
                                data: null,
                                modelOutput: null,
                                showTheme: false
                            }
                        });
            
                        myShape.geoLevelId = shape.GeoLevelId;
                        myShape.vintageId = shape.VintageId;
                        myShape.geographyIds = shape.GeographyIds;
                        myShape.wkt = `GEOMETRYCOLLECTION(${results.shapes.map(x => x.polygon.polygonString).join(',')})`;
            
                        myShape.fillColor = { r: shape.FillColor.R, g: shape.FillColor.G, b: shape.FillColor.B, a: shape.FillColor.A }
            
                        myShape.strokeColor = { r: shape.LineColor.R, g: shape.LineColor.G, b: shape.LineColor.B, a: shape.LineColor.A }
                        myShape.strokeWidth = shape.LineWidth;
                        myShape.strokeStyle = shape.LineStyle;
            
                        validShape = true;
                    }
            
                    break;

                case constants.entities.circle:

                    // convert radius from miles to meters because KLI 1.0 defaults to miles while 2.0 defaults to meters,
                    // and we do not pass measurment units when saving a map
                    myShape.locations = helpers.createCircle({ location: myLocations[0], radius: helpers.convertLength(shape.Radius, constants.lengthMeasurements.miles, constants.lengthMeasurements.meters) });

                    myShape.fillColor = { r: shape.FillColor.R, g: shape.FillColor.G, b: shape.FillColor.B, a: shape.FillColor.A }

                    myShape.strokeColor = { r: shape.LineColor.R, g: shape.LineColor.G, b: shape.LineColor.B, a: shape.LineColor.A }
                    myShape.strokeWidth = shape.LineWidth;
                    myShape.strokeStyle = shape.LineStyle;

                    validShape = true;

                    break;
            }

            if (validShape)
                myShapes.push(myShape);
            
        }

        if (myShapes.length > 0) {
            cosmetic.refresh({
                isVisible: cosmeticList.IsVisible,
                shapes: myShapes
            });            
        }
    },
    loadCustomQueries: async (customQueryList, taLibraryList) => {
        var mapLayers = [];

        if (_.isArray(customQueryList)) {
            const sortedCustomQueries = _.sortBy(customQueryList, "ZIndex").reverse();
            
            for (const customQuery of sortedCustomQueries){
                var mapLayer = new MapLayer();
                mapLayer.data.isAdhoc = customQuery.IsAdhoc;
                mapLayer.data.isCustomerMap = customQuery.IsCustomerMap;
                mapLayer.data.isDynamic = customQuery.IsDynamic;
                mapLayer.data.isTile = customQuery.IsTile;
                mapLayer.data.isCompetitiveInsights = customQuery.IsCompetitiveInsights;
                mapLayer.data.competitiveInsightsFilteredChannels = customQuery.CompetitiveInsightsFilteredChannels;
                mapLayer.data.competitiveInsightsGradeVisual = customQuery.CompetitiveInsightsGradeVisual;
                
                if (customQuery.PointDataSourceFilterList.length > 0)
                {
                    var filters = await filtersModule.getPointDataSourceFiltersForCustomQuery ({
                        customQueryId: customQuery.Id
                    });

                    _.forEach(customQuery.PointDataSourceFilterList, function (pointDataSourceFilter) {
                        var filter = _.find(filters, { id: pointDataSourceFilter})

                        mapLayer.data.pointDataSourceFilterList.push({
                            id: pointDataSourceFilter.toUpperCase(),
                            name: filter ? filter.name : null
                        });
                    });
                }
                
                mapLayer.id = customQuery.Id.toUpperCase();
                mapLayer.text = customQuery.LayerText;
                mapLayer.active = customQuery.IsVisible;
                mapLayer.visible = customQuery.UpdateOnPan;
                mapLayer.labeled = customQuery.AutoLabel;
                mapLayer.type = constants.layers.types.point;
                mapLayer.subType = customQuery.DataType;

                mapLayer.oppositeVisibilityEntities = [];
                if (customQuery.UpdateOnPan) {
                    if (_.isArray(customQuery.HiddenGroups) && customQuery.HiddenGroups.length > 0) {
                        customQuery.HiddenGroups.forEach(groupId => {
                            mapLayer.oppositeVisibilityEntities.push(groupId.replace(`${mapLayer.id}_`, ''));
                        });
                    }
                }
                else {
                    if (_.isArray(customQuery.VisibleGroups) && customQuery.VisibleGroups.length > 0) {
                        customQuery.VisibleGroups.forEach(groupId => {
                            mapLayer.oppositeVisibilityEntities.push(groupId.replace(`${mapLayer.id}_`, ''));
                        });
                    }
                }

                mapLayer.dirtyLabels = [];
                if (_.isArray(customQuery.DirtyLabels) && customQuery.DirtyLabels.length > 0)
                    mapLayer.dirtyLabels = customQuery.DirtyLabels.map(dirtyLabel => {
                        return {
                            id: dirtyLabel.id,
                            entityId: dirtyLabel.pointId,
                            location: dirtyLabel.labelPoint,
                            anchor: dirtyLabel.anchor
                        }
                    });

                mapLayers.push(mapLayer);
            }
        }

        if (mapLayers.length > 0) {
            sources.refresh({
                layers: mapLayers,
                onRefresh: (o)=>{ 
                    layers.refreshDataLayers(o); 
                },
                onComplete: ()=>{ 
                    projects.loadTradeAreaLibraries(taLibraryList); 
                }
            });            
        }
        else {
            projects.loadTradeAreaLibraries(taLibraryList); 
        }
    },
    loadTradeAreaLibraries: (taLibraryList) => {

        if (_.isArray(taLibraryList) && taLibraryList.length > 0) {
            _.sortBy(taLibraryList, "ZIndex").reverse().forEach(taLibrary => {

                var layer = null;
                var entity = null;

                // check if this is a pushpin trade area that should be linked to the cosmetic layer
                if (_.isString(taLibrary.customQueryId) && taLibrary.customQueryId === helpers.emptyGuid()) {
                    const cosmetic = map.layers.find(layer => layer.type === constants.layers.types.cosmetic);
                    if (_.isObject(cosmetic))
                        taLibrary.customQueryId = cosmetic.id;
                }

                if (taLibrary.customQueryId != null)
                    layer = map.layers.find(layer => layer.id === taLibrary.customQueryId);

                if (_.isObject(layer) && taLibrary.pointId != null)
                    entity = layer.entities.find(x => x.id === taLibrary.pointId);

                if (!_.isObject(entity)) {
                    entity = {
                        id: taLibrary.pointId,
                        type: constants.entities.point,
                        text: taLibrary.name,
                        location: taLibrary.center,
                        layer: {
                            id: taLibrary.customQueryId,
                            parentId: taLibrary.dataSourceId,
                            metaData: {
                                serviceAttributes: {
                                    AllowShapeDelete: taLibrary.allowShapeDelete,
                                    AllowShapeEdit: taLibrary.allowShapeEdit,
                                    AllowShapeView: taLibrary.allowShapeView,
                                    ID: taLibrary.customQueryId,
                                    DataSourceGUID: taLibrary.dataSourceGUID,
                                    DataSourceId: taLibrary.dataSourceId
                                }
                            }
                        }
                    }
                }

                projects.loadTradeAreaLibrary(taLibrary, entity);
            });
        }
    },
    loadTradeAreaLibrary: async (taLibrary, entity) => {

        const tradeAreaDefaults = await tradeAreas.getDefaults({
            dataSourceId: entity.layer.parentId,
            customQueryId: entity.layer.id,
            pointId: entity.id,
            latitude: entity.location.lat,
            longitude: entity.location.lon,
            filteredTypes: null
        });

        tradeAreas.generate({
            visible: taLibrary.isVisible,
            entity: entity,
            tradeAreas: taLibrary.tradeAreas,
            standardGeographies: tradeAreaDefaults.standardGeographies
        });
    },
    loadThematics: (thematicList) => {

        var mapLayers = [];

        if (_.isArray(thematicList)) {
            thematicList.forEach(thematic => {
                var mapLayer = new MapLayer();

                mapLayer.id = thematic.Id;
                mapLayer.text = thematic.LayerText;
                mapLayer.type = thematic.Type;
                mapLayer.subType = thematic.SubType;
                mapLayer.visible = thematic.IsVisible;
                mapLayer.labeled = thematic.IsLabeled;
                mapLayer.pinPointCount = thematic.PinPointCount;

                // TBD: thematics don't contain HiddenGroups because it wasn't supported in KLI 1.0
                mapLayer.oppositeVisibilityEntities = [];
                if (_.isArray(thematic.HiddenGroups) && thematic.HiddenGroups.length > 0) {
                    thematic.HiddenGroups.forEach(groupId => {
                        mapLayer.oppositeVisibilityEntities.push(groupId.replace(`${thematic.Id}_`, ''));
                    });
                }

                mapLayers.push(mapLayer);
            });
        }

        if (mapLayers.length > 0) {
            // sort by visibility (reveresed) so that the visible thematic is displayed in the map
            // (because only one thematic is displayed at a time and it will be the last one sent to refresh)
            thematics.refresh({
                layers: _.sortBy(mapLayers, [function(o) { return o.visible; }]).reverse(),
                onRefresh: (o)=>{ layers.refreshThematicLayers(o); }
            });            
        }

    },
    loadCustomerMaps: (customerMapsList) => {

        if (_.isArray(customerMapsList)) {
            customerMapsList.forEach(customerMap => {

                var myIsCompetitiveInsightsAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "isCompetitiveInsights"; });
                var myIsCompetitiveInsightsFlag = (myIsCompetitiveInsightsAttribute == null) ? false : myIsCompetitiveInsightsAttribute.Value == "true";

                var myIsUberAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "isUber"; });
                var myIsUberFlag = (myIsUberAttribute == null) ? false : myIsUberAttribute.Value == "true";

                var myIsDynamicAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "isDynamic"; });
                var myIsDynamicFlag = (myIsDynamicAttribute == null) ? false : myIsDynamicAttribute.Value == "true";

                var myIsTileAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "isTile"; });
                var myIsTileFlag = (myIsTileAttribute == null) ? false : myIsTileAttribute.Value == "true";

                var myTimeTypeAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "timeType"; });
                var myTimeTypeValue = (myTimeTypeAttribute == null) ? -1 : Number(myTimeTypeAttribute.Value);

                var myDataTypeAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "dataType"; });
                var myDataTypeValue = (myDataTypeAttribute == null) ? -1 : Number(myDataTypeAttribute.Value);

                var myPinPointCountAttribute = _.find(customerMap.AdditionalAttributes, function (attribute) { return attribute.Key == "pinPointCount"; });
                var myPinPointCountValue = (myPinPointCountAttribute == null) ? -1 : Number(myPinPointCountAttribute.Value);
            
                customerMaps.refresh({
                    id: myIsDynamicFlag ? `${customerMap.Id}_bulk` : customerMap.Id,
                    type: customerMap.Type,
                    name: customerMap.LayerText,
                    pointId: customerMap.PointIds, 
                    isCompetitiveInsights: myIsCompetitiveInsightsFlag,
                    isUber: myIsUberFlag,
                    isDynamic: myIsDynamicFlag,
                    isTile: myIsTileFlag,
                    timeType: myTimeTypeValue,
                    dataType: myDataTypeValue,
                    themeId: customerMap.Id,
                    visible: customerMap.IsVisible,
                    pinPointCount: myPinPointCountValue,
                    outOfRange: customerMap.Type !== constants.customerDataRenderers.heat && map.zoom < 10	// TBD: handle heat map out of range
                });

            });
        }
    },
    loadTrip2Trade: (trip2TradeList) => {

        if (_.isArray(trip2TradeList)) {
            const renderers = _.sortBy(trip2TradeList, "ZIndex").reverse().map(trip2Trade => { return trip2Trade.Id.toUpperCase(); });

            const options = trip2TradeList.map(trip2Trade => { 
                return { 
                    id: trip2Trade.Id.toUpperCase(), 
                    active: trip2Trade.IsVisible,
                    visible: trip2Trade.UpdateOnPan,
                    oppositeVisibilityEntities: _.isArray(trip2Trade.HiddenGroups) ? trip2Trade.HiddenGroups.map(groupId => groupId.replace(`${trip2Trade.Id.toUpperCase()}_`, '')) : []
                }
            });

            if (renderers.length > 0)
                trip2Trade.showLayers(renderers, options); 
        }
    },
    loadGeoFeeds: (geoFeedsList) => {

        if (_.isArray(geoFeedsList)) {
            _.sortBy(geoFeedsList, "ZIndex").reverse().forEach(geoFeed => {
                geoFeeds.loadGeoFeed({ id: geoFeed.Id, visible: geoFeed.IsVisible, zoomToLayer: false }); 
            });
        }
    },
    loadGeofences: (geofenceList) => {

        var myGeofences = [];

        if (_.isArray(geofenceList)) {
            geofenceList.forEach(geofence => {

                var myGeofence = {
                    id: geofence.Id,
                    name: geofence.Name,
                    visible: geofence.IsVisible,
                    polygonString: geofence.PolygonString,
                    polygonType: geofence.PolygonType,
                    fillColor: { r: geofence.FillColor_R, g: geofence.FillColor_G, b: geofence.FillColor_B, a: geofence.FillColor_A },
                    lineColor: { r: geofence.LineColor_R, g: geofence.LineColor_G, b: geofence.LineColor_B, a: geofence.LineColor_A },
                    lineWidth: geofence.LineWidth,
                    lineStyle: geofence.LineStyle,
                    dataSourceId: geofence.CustomQueryId,
                    pointTitle: geofence.PointTitle,
                    centroid: { lon: geofence.SourceLongitude, lat: geofence.SourceLatitude }   // TBD: is this how we should set centroid?
                }

                myGeofences.push(myGeofence);

            });
        }

        if (myGeofences.length > 0) {
            geofences.refresh({
                geofences: myGeofences,
                locateGeofence: false
            });            
        }

    },
    loadCompetitiveInsightsTradeAreas: (ciTradeAreasList) => {

        if (_.isArray(ciTradeAreasList)) {
            ciTradeAreasList.forEach(tradeArea => {

                var item = {
                    setDefault: false,
                    text: tradeArea.Name,
                    percent: tradeArea.Percentage,
                    type: tradeArea.Type,
                    entity: { location: tradeArea.Center, text: tradeArea.Name },
                    pointIds: tradeArea.PointIds,
                    icon: icons.grid,
                    await: true,
                    visible: tradeArea.IsVisible,
                    zoomToLayer: false
                };

                competitiveInsights.showLayer(item); 
            });
        }
    },
    loadDrivingDirections: (directionsData) => {

        if (_.isObject(directionsData) && _.isArray(directionsData.Waypoints) && directionsData.Waypoints.length > 0) {
            
            var destinations = [];

            directionsData.Waypoints.forEach(waypoint => {
                if (!waypoint.IsVia) {
                    if (waypoint.PointId != null && waypoint.PointId.length > 0) {
                        destinations.push({
                            id: waypoint.Id,
                            value: waypoint.Label,
                            mapValue: `${waypoint.Latitude},${waypoint.Longitude}`,
                            location: { lat: waypoint.Latitude, lon: waypoint.Longitude },
                            pointId: waypoint.PointId
                        });
                    }
                    else {
                        destinations.push({
                            id: waypoint.Id,
                            value: waypoint.Label,
                            pointId: null
                        });
                    }
                }   
            });

            directionsManager.loadDestinations({ destinations: destinations });

        }   
    },
    updateDefaultMap: async (o) => {      

        if (o.isDefault) {
            const result = await projects.saveDefaultMap({id:o.mapId});

            if (result)
                userPreferences.DefaultMap = o.mapId;
        }
        else if (userPreferences.DefaultMap === o.mapId && o.isDefault === false) {
            userPreferences.DefaultMap = '';
            const result = await projects.saveDefaultMap({id: ''});
        }
    },    
    saveMap: async (o) => {      

        var metadata = projects.getMapMetadata();
        
        if (o.id !== null)
            metadata.Information.Id = o.id;

        metadata.Information.Name = o.name;
        metadata.Information.Description = o.description;
        metadata.Information.ContactInformation = o.contact;
        metadata.Information.IsFixed = o.fixed;

        const mapId = await legacyEndpoints.service({
            name: 'SaveMap',
            parameters: {
                Map: metadata, 
                FolderId: o.folderId
            }
        });

        legacyEndpoints.service({
            name: 'SaveMapImage',
            parameters: {
                MapId: mapId
            }
        });    
        
        await projects.updateDefaultMap({mapId: mapId, isDefault: o.isDefault})

        return mapId;

    }, 
    getFilteredShareableObjectsForUser: async (o) => {

        return(await legacyEndpoints.service({
            name: 'GetFilteredShareableObjectsForUser',
            parameters: {
                Columns: 'status,permission,type,name',
                Page: 0,
                ResultCount: 100000,
                Filter: '',
                SortColumn: 1,
                SortDirection: 'asc',
                Type: o.sharingPeerType,
                ObjectType: o.objectType,
                ObjectId: o.objectId,
                Init: true,
                SharedObjects: ''                            
            }          
        }));

    },   
    shareObject: async (o) => {      

        const success = await legacyEndpoints.service({
            name: 'ShareObject',
            parameters: o
        });  
    
        return success;
    },                
    printCustomMap: async (o) => {      
        return await legacyEndpoints.service({
            name: 'SaveMapForPrint',
            parameters: {
                Map: o.metaData
            }
        });
    },
    printMap: async (o) => {      

        var metadata = projects.getMapMetadata({ isPrint: true });
        
        metadata.Information.Name = "Print Map";
        metadata.Information.Description = "Print Map";
        metadata.Information.ContactInformation = "Print Map";
        metadata.Information.IsFixed = false;

        if (_.isObject(o.mapOptions) && _.isObject(o.mapTitles)) {
            metadata.PrintSettings = projects.getPrintMapSettings(o.mapOptions, o.mapTitles);

            if (_.isNumber(o.mapOptions.mapType) && o.mapOptions.mapType >= 0)
                metadata.Information.Type = projects.getMapTypeMS(o.mapOptions.mapType);

            if (metadata.Information.Type === constants.map.legacyTypes.aerial && o.mapOptions.showLabels)
                metadata.Information.Type = constants.map.legacyTypes.hybrid;
        }

        if (_.isObject(o.quickReportOptions) && o.quickReportOptions.chartId !== -1) {
            const chartShapeId = await legacyEndpoints.service({
                name: 'SaveChartShape',
                parameters: {aShape:{
                    Name: o.quickReportOptions.tradeArea.combinedName ?? o.quickReportOptions.tradeArea.name,
                    Type: o.quickReportOptions.tradeArea.Type,
                    CenterLat: o.quickReportOptions.tradeArea.CenterLat,
                    CenterLon: o.quickReportOptions.tradeArea.CenterLon,
                    LengthMeasurement: o.quickReportOptions.tradeArea.LengthMeasurement,
                    Interval: o.quickReportOptions.tradeArea.Interval,
                    EncodedPoints: o.quickReportOptions.tradeArea.EncodedPoints,
                    PointFormat: o.quickReportOptions.tradeArea.PointFormat,
                    GeographyVintageId: o.quickReportOptions.tradeArea.GeographyVintage,
                    GeographyIds: o.quickReportOptions.tradeArea.GeographyIds,
                }}
            });

            metadata.PrintSettings.ChartId = o.quickReportOptions.chartId;
            metadata.PrintSettings.ChartShapeId = chartShapeId;
            metadata.PrintSettings.ChartLocation = o.quickReportOptions.chartLocation;
        }        

        const mapId = await legacyEndpoints.service({
            name: 'SaveMapForPrint',
            parameters: {
                Map: metadata
            }
        });

        return mapId;

    },
    deleteMap: async (o) => {      

        const result = await legacyEndpoints.service({
            name: 'DeleteMap',
            parameters: {
                Id: o.id
            }
        });

        if (result) {
            if(mapControl.getCurrentMapInformation()?.Id === o.id)
                mapControl.setMap({map: null});

            await projects.updateDefaultMap({mapId: o.id, isDefault: false});
        }

        return result;
    },    
    saveDefaultMap: async (o) => {      

        return(await legacyEndpoints.service({
            name: 'SetDefaultMap',
            parameters: {
                MapId: o.id
            }
        }));
     
    },     
    getMapTypeMS: (type) => {
		switch (type)
		{
			default:
            case constants.map.types.road:
                return constants.map.legacyTypes.road;
            case constants.map.types.terrain:
                return constants.map.legacyTypes.terrain;
            case constants.map.types.aerial:
                return constants.map.legacyTypes.aerial;
            case constants.map.types.dark:
                return constants.map.legacyTypes.dark;
            case constants.map.types.gray:
                return constants.map.legacyTypes.gray;
            case constants.map.types.light:
                return constants.map.legacyTypes.light;
            case constants.map.types.birdsEye:
                return constants.map.legacyTypes.birdsEye;
            }
    },
    getMapTypeId: (type) => {
		switch (type)
		{
			default:
            case constants.map.legacyTypes.road:
                return constants.map.types.road;
            case constants.map.legacyTypes.terrain:
                return constants.map.types.terrain;
            case constants.map.legacyTypes.aerial:
                return constants.map.types.aerial;
            case constants.map.legacyTypes.dark:
                return constants.map.types.dark;
            case constants.map.legacyTypes.gray:
                return constants.map.types.gray;
            case constants.map.legacyTypes.light:
                return constants.map.types.light;
            case constants.map.legacyTypes.birdsEye:
                return constants.map.types.birdsEye;
            }
    },
    getEntityTypeName: (type) => {
		switch (type)
		{
			default:
                return "";
            case constants.entities.activePoint:
                return "ActivePoint";
            case constants.entities.circle:
                return "Circle";
            case constants.entities.point:
                return "Point";
            case constants.entities.pushpin:
                return "Pushpin";
            case constants.entities.label:
                return "Label";
            case constants.entities.polygon:
            case constants.entities.rectangle:  // rectangle needs to be saved as polygon
                return "Polygon";
            case constants.entities.polyline:
                return "Polyline";
            case constants.entities.tradeArea:
                return "TradeArea";
            case constants.entities.selection:
                return "Selection";
            case constants.entities.geoJson:
                return "GeoJson";
            case constants.entities.wkt:
                return "WKT";
            case constants.entities.cluster:
                return "Cluster";
            case constants.entities.standardGeography:
                return "StandardGeography";
        }
    },
    getEntityTypeId: (type) => {
		switch (type)
		{
			default:
                return constants.entities.point;
            case "ActivePoint":
                return constants.entities.activePoint;
            case "Circle":
                return constants.entities.circle;
            case "Point":
                return constants.entities.point;
            case "Pushpin":
                return constants.entities.pushpin;
            case "Label":
                return constants.entities.label;
            case "Polygon":
                return constants.entities.polygon;
            case "Polyline":
                return constants.entities.polyline;
            case "TradeArea":
                return constants.entities.tradeArea;
            case "Selection":
                return constants.entities.selection;
            case "Rectangle":
                return constants.entities.rectangle;
            case "GeoJson":
                return constants.entities.geoJson;
            case "WKT":
                return constants.entities.wkt;
            case "Cluster":
                return constants.entities.cluster;
            case "StandardGeography":
                return constants.entities.standardGeography;
        }
    },
    getMaps: async () => {

        return(await legacyEndpoints.service({
            name: 'GetMyMaps'
        }));

    }, 
    getSharedMaps: async () => {

        return(await legacyEndpoints.service({
            name: 'GetSharedMaps'
        }));

    }, 
    getMapFolders: async () => {

        return(await legacyEndpoints.service({
            name: 'GetMapFolders'
        }));

    },       
    createMapFolder: async (o) => {

        return(await legacyEndpoints.service({
            name: 'CreateMapFolder',
            parameters: {
                Name: o.name
            }
        }));

    },  
    updateMapFolder: async (o) => {

        return(await legacyEndpoints.service({
            name: 'UpdateMapFolder',
            parameters: {
                Id: o.folderId,
                Name: o.folderName
            }
        }));

    },      
    moveMapToFolder: async (o) => {

        return(await legacyEndpoints.service({
            name: 'MoveMapToFolder',
            parameters: {
                FolderId: o.folderId,
                MapId: o.mapId
            }
        }));

    },      
    deleteMapFolder: async (o) => { 

        return(await legacyEndpoints.service({
            name: 'DeleteMapFolder',
            parameters: {
                Id: o.folderId
            }
        }));

    },          
    getFilteredSavedMapUsage: async (o) => {
        return(await legacyEndpoints.service({
            name: 'GetFilteredSavedMapUsage',
            parameters: {
                MapId: o.mapId,
                Columns: o.columns ?? 'Name,DateCreated,LastAccessDate,LoadCount,SharedCount,IsPublished',
                Page: 0,
                ResultCount: o.resultCount ?? 15,
                Filter: '',
                SortColumn: o.sortColumn ?? 1,
                SortDirection: o.sortDirection ?? 'asc',
                ShowMyUsage: o.showMyUsage                
            }            
        }));       
    },      
    getPrintOptions: async () => {
        return(await legacyEndpoints.service({
            name: 'GetPrintOptions'
        }));
    },
    getDefaultPrintSettings: () => {
        return {
            TopLeft: "",
            TopRight: "",
            BottomLeft: "",
            BottomMiddle: "",
            BottomRight: "",
            TopLeftStyle: { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "0344e882-f33d-f35b-971e-0fa1757f378f" },
            TopRightStyle: { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "ad9fd6ad-c217-4e92-f2d5-478de0c853fc" },
            BottomLeftStyle: { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "cb09524b-48ff-a259-726d-f35759d8ccfc" },
            BottomMiddleStyle: { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "dca524c2-9d60-edfb-252b-c3f65efbcb20" },
            BottomRightStyle: { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "b0eb3341-63d1-5161-595b-ad70a11b7711" },
            ChartId: -1,
            ChartLocation: constants.print.legendLocation.none,
            LegendLocation: constants.print.legendLocation.none,
            LegendBehavior: constants.print.legendBehavior.autofit,
            Orientation: constants.print.orientation.landscape,
            PrintTemplateSizeGUID: "5fcec4e8-050f-415d-8eef-83ebb91643b2",
            ScaleToMap: false,         
        };
    },
    getDefaultAdHocMap: () => {
        return {
            includeSelection: true,
            showSelectionLabel: true,
            selectionLabelColor: { r: 255, g: 255, b: 255, a: 1 },
            selectionLabelFillColor: { r: 0, g: 0, b: 0, a: 1 },
            zoom: 12,
            zoomToTradeAreas: true,
            mapType: constants.map.types.road,
            showLabels: true,
            orientation: constants.print.orientation.landscape,
            printTemplateGUID: null,
            legendLocation: constants.mapBooks.adHocMap.legendLocation.topLeft,
            legendBehavior: constants.mapBooks.adHocMap.legendBehavior.autofit,
            topLeftTitle: null,
            topRightTitle: null,
            bottomLeftTitle: null,
            bottomMiddleTitle: null,
            bottomRightTitle: null,
            rangeTheme: null,
            dotDensityTheme: null,
            polygonThemes: [],
            pointSources: [],
            dataMaps: []
        };
    },
    getTitleTypePrint: (type) => {
        switch (type) {
            case constants.mapBooks.adHocMap.titleTypes.aerialDate:
                return constants.print.contentTypes.aerialDate;
            case constants.mapBooks.adHocMap.titleTypes.date:
                return constants.print.contentTypes.currentDate;
            case constants.mapBooks.adHocMap.titleTypes.logo:
                return constants.print.contentTypes.companyLogo;
            case constants.mapBooks.adHocMap.titleTypes.text:
            default:
                return constants.print.contentTypes.customText;
        }
    },
    getTitleTypeAdHoc: (type) => {
        switch (type) {
            case constants.print.contentTypes.aerialDate:
                return constants.mapBooks.adHocMap.titleTypes.aerialDate;
            case constants.print.contentTypes.currentDate:
                return constants.mapBooks.adHocMap.titleTypes.date;
            case constants.print.contentTypes.companyLogo:
                return constants.mapBooks.adHocMap.titleTypes.logo;
            case constants.print.contentTypes.customText:
            default:
                return constants.mapBooks.adHocMap.titleTypes.text;
        }
    },
    getDefaultAdHocTitle: () => {
        return {
            type: constants.mapBooks.adHocMap.titleTypes.text,
            fontSize: 12,
            fontStyle: "Arial",
            dateFormat: 0,
            defaultText: {
                Id: helpers.newGuid(),
                Text: "",
                Words: []
            },
            phrases: []
        };
    },
    getPrintMapSettings: (mapOptions, mapTitles) => {

        var printSettings = projects.getDefaultPrintSettings();

        printSettings.TopLeft = mapTitles.topLeft;
        printSettings.TopLeftStyle = mapTitles.topLeftStyle;
        printSettings.TopRight = mapTitles.topRight;
        printSettings.TopRightStyle = mapTitles.topRightStyle;
        printSettings.BottomLeft = mapTitles.bottomLeft;
        printSettings.BottomLeftStyle = mapTitles.bottomLeftStyle;
        printSettings.BottomMiddle = mapTitles.bottomMiddle;
        printSettings.BottomMiddleStyle = mapTitles.bottomMiddleStyle;
        printSettings.BottomRight = mapTitles.bottomRight;
        printSettings.BottomRightStyle = mapTitles.bottomRightStyle;

        printSettings.LegendLocation = mapOptions.legendLocation;
        printSettings.LegendBehavior = mapOptions.legendBehavior;
        printSettings.Orientation = mapOptions.orientation;
        printSettings.PrintTemplateSizeGUID = mapOptions.printTemplateGUID;

        return printSettings;
    },
    getAdHocMapPrintSettings: (adHocMap) => {

        var printSettings = projects.getDefaultPrintSettings();
        var title;

        if (adHocMap.topLeftTitle === null) {
            printSettings.TopLeft = "";
            printSettings.TopLeftStyle = { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "0344e882-f33d-f35b-971e-0fa1757f378f" };
        }
        else {
            title = projects.getTitleTypePrint(adHocMap.topLeftTitle.type);
            printSettings.TopLeft = title === constants.print.contentTypes.customText ? adHocMap.topLeftTitle.defaultText.Text : title;
            printSettings.TopLeftStyle = { FontFamily: adHocMap.topLeftTitle.fontStyle, FontSize: adHocMap.topLeftTitle.fontSize, PrintStyleGUID: "" };
        }

        if (adHocMap.topRightTitle === null) {
            printSettings.TopRight = "";
            printSettings.TopRightStyle = { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "0344e882-f33d-f35b-971e-0fa1757f378f" };
        }
        else {
            title = projects.getTitleTypePrint(adHocMap.topRightTitle.type);
            printSettings.TopRight = title === constants.print.contentTypes.customText ? adHocMap.topRightTitle.defaultText.Text : title;
            printSettings.TopRightStyle = { FontFamily: adHocMap.topRightTitle.fontStyle, FontSize: adHocMap.topRightTitle.fontSize, PrintStyleGUID: "" };
        }

        if (adHocMap.bottomLeftTitle === null) {
            printSettings.BottomLeft = "";
            printSettings.BottomLeftStyle = { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "0344e882-f33d-f35b-971e-0fa1757f378f" };
        }
        else {
            title = projects.getTitleTypePrint(adHocMap.bottomLeftTitle.type);
            printSettings.BottomLeft = title === constants.print.contentTypes.customText ? adHocMap.bottomLeftTitle.defaultText.Text : title;
            printSettings.BottomLeftStyle = { FontFamily: adHocMap.bottomLeftTitle.fontStyle, FontSize: adHocMap.bottomLeftTitle.fontSize, PrintStyleGUID: "" };
        }

        if (adHocMap.bottomMiddleTitle === null) {
            printSettings.BottomMiddle = "";
            printSettings.BottomMiddleStyle = { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "0344e882-f33d-f35b-971e-0fa1757f378f" };
        }
        else {
            title = projects.getTitleTypePrint(adHocMap.bottomMiddleTitle.type);
            printSettings.BottomMiddle = title === constants.print.contentTypes.customText ? adHocMap.bottomMiddleTitle.defaultText.Text : title;
            printSettings.BottomMiddleStyle = { FontFamily: adHocMap.bottomMiddleTitle.fontStyle, FontSize: adHocMap.bottomMiddleTitle.fontSize, PrintStyleGUID: "" };
        }

        if (adHocMap.bottomRightTitle === null) {
            printSettings.BottomRight = "";
            printSettings.BottomRightStyle = { FontFamily: "Arial", FontSize: 10, PrintStyleGUID: "0344e882-f33d-f35b-971e-0fa1757f378f" };
        }
        else {
            title = projects.getTitleTypePrint(adHocMap.bottomRightTitle.type);
            printSettings.BottomRight = title === constants.print.contentTypes.customText ? adHocMap.bottomRightTitle.defaultText.Text : title;
            printSettings.BottomRightStyle = { FontFamily: adHocMap.bottomRightTitle.fontStyle, FontSize: adHocMap.bottomRightTitle.fontSize, PrintStyleGUID: "" };
        }

        switch (adHocMap.legendLocation) {
            default:
            case constants.mapBooks.adHocMap.legendLocation.none:
                printSettings.LegendLocation = constants.print.legendLocation.none;
                break;
            case constants.mapBooks.adHocMap.legendLocation.topLeft:
                printSettings.LegendLocation = constants.print.legendLocation.topLeft;
                break;
            case constants.mapBooks.adHocMap.legendLocation.topRight:
                printSettings.LegendLocation = constants.print.legendLocation.topRight;
                break;
            case constants.mapBooks.adHocMap.legendLocation.bottomLeft:
                printSettings.LegendLocation = constants.print.legendLocation.bottomLeft;
                break;
            case constants.mapBooks.adHocMap.legendLocation.bottomRight:
                printSettings.LegendLocation = constants.print.legendLocation.bottomRight;
                break;
        }

        switch (adHocMap.legendBehavior) {
            default: 
            case constants.mapBooks.adHocMap.legendBehavior.autofit:
                printSettings.LegendBehavior = constants.print.legendBehavior.autofit;
                break;
            case constants.mapBooks.adHocMap.legendBehavior.wrap:
                printSettings.LegendBehavior = constants.print.legendBehavior.wrap;
                break;
            case constants.mapBooks.adHocMap.legendBehavior.separate:
                printSettings.LegendBehavior = constants.print.legendBehavior.separate;
                break;
            case constants.mapBooks.adHocMap.legendBehavior.none:
                printSettings.LegendLocation = constants.print.legendLocation.none;
                printSettings.LegendBehavior = constants.print.legendBehavior.autofit;
                break;
        }
    
        printSettings.Orientation = adHocMap.orientation;

        if (adHocMap.printTemplateGUID !== null)
            printSettings.PrintTemplateSizeGUID  =  adHocMap.printTemplateGUID;

        return printSettings;
    },
    setAdHocMapPrintSettings: (adHocMap, mapOptions, mapTitles) => {

        if (_.isNumber(mapOptions.mapType) && mapOptions.mapType >= 0)
            adHocMap.mapType = projects.getMapTypeMS(mapOptions.mapType);

        if (adHocMap.mapType === constants.map.legacyTypes.aerial && mapOptions.showLabels)
            adHocMap.mapType = constants.map.legacyTypes.hybrid;
    
        adHocMap.showLabels = mapOptions.showLabels;
        adHocMap.zoom = mapOptions.zoom;
        adHocMap.zoomToTradeAreas = mapOptions.zoomToTradeAreas;

        adHocMap.printTemplateGUID = mapOptions.printTemplateGUID;
        adHocMap.orientation = mapOptions.orientation;

        switch (mapOptions.legendBehavior) {
            default: 
                adHocMap.legendBehavior = constants.mapBooks.adHocMap.legendBehavior.none;
                break;
            case constants.print.legendBehavior.autofit:
                adHocMap.legendBehavior = constants.mapBooks.adHocMap.legendBehavior.autofit;
                break;
            case constants.print.legendBehavior.wrap:
                adHocMap.legendBehavior = constants.mapBooks.adHocMap.legendBehavior.wrap;
                break;
            case constants.print.legendBehavior.separate:
                adHocMap.legendBehavior = constants.mapBooks.adHocMap.legendBehavior.separate;
                break;
        }

        switch (mapOptions.legendLocation) {
            default:
            case constants.print.legendLocation.none:
                adHocMap.legendBehavior = constants.mapBooks.adHocMap.legendBehavior.none;
                adHocMap.legendLocation = constants.mapBooks.adHocMap.legendLocation.topLeft;
                break;
            case constants.print.legendLocation.topLeft:
                adHocMap.legendLocation = constants.mapBooks.adHocMap.legendLocation.topLeft;
                break;
            case constants.print.legendLocation.topRight:
                adHocMap.legendLocation = constants.mapBooks.adHocMap.legendLocation.topRight;
                break;
            case constants.print.legendLocation.bottomLeft:
                adHocMap.legendLocation = constants.mapBooks.adHocMap.legendLocation.bottomLeft;
                break;
            case constants.print.legendLocation.bottomRight:
                adHocMap.legendLocation = constants.mapBooks.adHocMap.legendLocation.bottomRight;
                break;
        }
    
        if (_.isNull(mapTitles.topLeft) || _.isUndefined(mapTitles.topLeft) || mapTitles.topLeft.length === 0) {
            adHocMap.topLeftTitle = null;
        }
        else {
            adHocMap.topLeftTitle = projects.getDefaultAdHocTitle();
            adHocMap.topLeftTitle.type = projects.getTitleTypeAdHoc(mapTitles.topLeft);

            if (adHocMap.topLeftTitle.type === constants.mapBooks.adHocMap.titleTypes.text)
                // TBD: do we set the defaultText.Id to a new guid or a specific one?
                adHocMap.topLeftTitle.defaultText.Text = mapTitles.topLeft

            if (_.isObject(mapTitles.topLeftStyle)) {
                adHocMap.topLeftTitle.fontStyle = mapTitles.topLeftStyle.FontFamily;
                adHocMap.topLeftTitle.fontSize = mapTitles.topLeftStyle.FontSize;
            }
        }

        if (_.isNull(mapTitles.topRight) || _.isUndefined(mapTitles.topRight) || mapTitles.topRight.length === 0) {
            adHocMap.topRightTitle = null;
        }
        else {
            adHocMap.topRightTitle = projects.getDefaultAdHocTitle();
            adHocMap.topRightTitle.type = projects.getTitleTypeAdHoc(mapTitles.topRight);
            
            if (adHocMap.topRightTitle.type === constants.mapBooks.adHocMap.titleTypes.text)
                adHocMap.topRightTitle.defaultText.Text = mapTitles.topRight

            if (_.isObject(mapTitles.topRightStyle)) {
                adHocMap.topRightTitle.fontStyle = mapTitles.topRightStyle.FontFamily;
                adHocMap.topRightTitle.fontSize = mapTitles.topRightStyle.FontSize;
            }
        }

        if (_.isNull(mapTitles.bottomLeft) || _.isUndefined(mapTitles.bottomLeft) || mapTitles.bottomLeft.length === 0) {
            adHocMap.bottomLeftTitle = null;
        }
        else {
            adHocMap.bottomLeftTitle = projects.getDefaultAdHocTitle();
            adHocMap.bottomLeftTitle.type = projects.getTitleTypeAdHoc(mapTitles.bottomLeft);
            
            if (adHocMap.bottomLeftTitle.type === constants.mapBooks.adHocMap.titleTypes.text)
                adHocMap.bottomLeftTitle.defaultText.Text = mapTitles.bottomLeft

            if (_.isObject(mapTitles.bottomLeftStyle)) {
                adHocMap.bottomLeftTitle.fontStyle = mapTitles.bottomLeftStyle.FontFamily;
                adHocMap.bottomLeftTitle.fontSize = mapTitles.bottomLeftStyle.FontSize;
            }
        }

        if (_.isNull(mapTitles.bottomMiddle) || _.isUndefined(mapTitles.bottomMiddle) || mapTitles.bottomMiddle.length === 0) {
            adHocMap.bottomMiddleTitle = null;
        }
        else {
            adHocMap.bottomMiddleTitle = projects.getDefaultAdHocTitle();
            adHocMap.bottomMiddleTitle.type = projects.getTitleTypeAdHoc(mapTitles.bottomMiddle);
            
            if (adHocMap.bottomMiddleTitle.type === constants.mapBooks.adHocMap.titleTypes.text)
                adHocMap.bottomMiddleTitle.defaultText.Text = mapTitles.bottomMiddle

            if (_.isObject(mapTitles.bottomMiddleStyle)) {
                adHocMap.bottomMiddleTitle.fontStyle = mapTitles.bottomMiddleStyle.FontFamily;
                adHocMap.bottomMiddleTitle.fontSize = mapTitles.bottomMiddleStyle.FontSize;
            }
        }

        if (_.isNull(mapTitles.bottomRight) || _.isUndefined(mapTitles.bottomRight) || mapTitles.bottomRight.length === 0) {
            adHocMap.bottomRightTitle = null;
        }
        else {
            adHocMap.bottomRightTitle = projects.getDefaultAdHocTitle();
            adHocMap.bottomRightTitle.type = projects.getTitleTypeAdHoc(mapTitles.bottomRight);
            
            if (adHocMap.bottomRightTitle.type === constants.mapBooks.adHocMap.titleTypes.text)
                adHocMap.bottomRightTitle.defaultText.Text = mapTitles.bottomRight

            if (_.isObject(mapTitles.bottomRightStyle)) {
                adHocMap.bottomRightTitle.fontStyle = mapTitles.bottomRightStyle.FontFamily;
                adHocMap.bottomRightTitle.fontSize = mapTitles.bottomRightStyle.FontSize;
            }
        }
    },
	getGroupedItems: (o) => {
		var items = [];

		o.items.forEach((item) => {
			var parent = items.find((val) => val.id === item.ApplicationSymbology.Id);
			var child = {
				id: item.Id,
				text: item.Name,
				type: item.Type,
				subType: _.isNumber(item.SubType) ? item.SubType : item.DataType,
				data: {
					isCompetitiveInsights: item.IsCompetitiveInsights,
					isAdhoc: item.IsAdhoc,
					isCustomerMap: item.IsCustomerMap,
					isDynamic: item.IsDynamic,
					isTile: item.IsTile
				}
			};

			if (parent)
				parent.items.push(child);
			else
				items.push({
					id: item.ApplicationSymbology.Id,
					text: item.ApplicationSymbology.Title,
					icon: item.ApplicationSymbology.TASOnline,
					type: 0,
					items: [child]
				});
		});

		return items;
	},
	getItemsByType: (o) => {
		var items = [];
        var acceptedTypes = [];
        if (o.type != null)
            acceptedTypes.push(o.type);
        if (_.isArray(o.types) && o.types.length > 0)
            acceptedTypes = acceptedTypes.concat(o.types);

		o.items.forEach((group) => {

            items.push({
                disabled: true,
                text: group.text
            });

            var count = 0;

            group.items.forEach((item) => {
                if (!_.isNull(item.id) && !_.isUndefined(item.id) && !_.isNull(item.subType) && !_.isUndefined(item.subType)) {
                    if (_.includes(acceptedTypes, item.subType)) {

                        items.push({
                            id: item.id,
                            text: item.text,
                            type: item.subType
                        });

                        count++;
                    }
                }
            });

            if (count === 0) {
                items.pop();
            }

        });

		return items;
	}, 
    getAdHocMapPreview: async (o) => {      

        var newAdHocMap =  _.cloneDeep(o.adHocMap);

        if (_.isObject(o.mapOptions) && _.isObject(o.mapTitles))
            projects.setAdHocMapPrintSettings(newAdHocMap, o.mapOptions, o.mapTitles);

        var previewPoint = _.cloneDeep(o.previewPoint);

        if (_.isNull(previewPoint) || _.isUndefined(previewPoint)) {
            previewPoint = {
                Id: helpers.newGuid(),
                PointId: helpers.newGuid(),
                Type: 1,
                BaseImage: userPreferences.DefaultPushpinSymbol,
                BaseImageType: 0,
                Name: "Current View",
                Latitude: map.center.lat,
                Longitude: map.center.lon,
                ServiceId: "",
                ServiceName: "",
                Geocode: null,
                Keys: [],
                Selected: false
            };

            newAdHocMap.includeSelection = false;
            newAdHocMap.showSelectionLabel = false;
        }

        const data = await legacyEndpoints.service({
            name: 'GetAdHocMapPreview',
            parameters: {
                MapBookPoint: previewPoint,
                Map: newAdHocMap
            }
        });

        return _.isNull(data) || _.isUndefined(data) ? helpers.emptyGuid() : data.mapPreviewId;

    },
    publishMap: async ({ id, publish}) => {
        await legacyEndpoints.service({
            name: 'PublishMap',
            parameters: {
                Id: id, 
                Publish: publish
            }
        });
    },
    generatePublishMapLink: (id) => {
        if (!_.isString(id))
            return;
        
        var body = userPreferences.ViewerEmailMessage;
        var subject = userPreferences.ViewerEmailSubject;

        subject = _.replace(subject, /TAS Viewer/i, "KLI Viewer");

        body = _.replace(body, /http:\/\//i, "https://");
        body = _.replace(body, /www.tasviewer.com/i, "kli-viewer.kalibrate.com");
        body = _.replace(body, /tasviewer.com/i, "kli-viewer.kalibrate.com");
        body = _.replace(body, /#{queryFields}/i, `id=${id.toUpperCase()}`);
        body = _.replace(body, "?", "%3F");
        body = _.replace(body, "&", "%26");

        var windowInstance = window.open(`mailto:?subject=${subject}&body=${body}`);

        setTimeout(() => {
            windowInstance.close();
        }, 100);        
    }
}