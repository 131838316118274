// React imports
import { useEffect, useState } from 'react'

// App imports
import { Window } from '../../base/window/window';
import { Button } from '../../base/button/button';
import { MenuButton } from '../../base/menuButton/menuButton';
import { TradeAreas } from '../tradeAreas/tradeAreas';
import { tradeAreas as tradeAreaModule } from '../../../modules/tradeAreas';
import { geofences as geofenceModule } from '../../../modules/geofences';
import { Comments } from '../comments/comments';
import { Photos } from '../photos/photos';
import { Trip2Trade } from '../trip2Trade/trip2Trade';
import { Multimedia } from '../multimedia/multimedia';
import { Hideable } from '../../base/hideable/hideable';
import { Geofences } from '../geofences/geofences';
import { Loader }  from '../../base/loader/loader';
import { CIDashboards } from '../mobilityData/competitiveInsights/dashboards/ciDashboards';
import { SmallDemographicReports } from '../reports/smallDemographicReports/smallDemographicReports';
import { rightPanel } from '../../base/rightPanel/rightPanel';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { tools } from '../tools/tools';
import { map } from '../map/map';
import { helpers } from '../../../utils/helpers';
import { MapBookRunner } from '../mapBookRunner/mapBookRunner';
import { customReports } from '../../../modules/customReports';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { AnalogSelector } from '../analogs/analogSelector';
import { TaskDetail } from '../tasks/taskDetail/taskDetail';
import { selections } from '../../../modules/selections';
const _ = require("lodash");

export var activityHub;

export function ActivityHub() {

    const [activeEntityLayer, setActiveEntityLayer] = useState(null);
    const [activeTab, setActiveTab] = useState(helpers.emptyGuid());
    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [entity, setEntity] = useState(null);
    const [pixel, setPixel] = useState({ x: 0, y: 0 });
    const [title, setTitle] = useState('');    
    const [onClose, setOnClose] = useState({}); 

    useEffect(() =>{
        setActiveEntityLayer(
            map.addLayer({
                group: constants.layers.groups.activeEntity,
                type: constants.layers.types.activeEntity
            })
        );        
    },[]);

    const renderTabs = (tabs) => {
        return tabs.map((tab, i) =>{ 

            if (_.isArray(tab.items))
                return renderTabs(tab.items);

            if (_.isUndefined(tab.component))
                return;

            if (tab.key !== helpers.emptyGuid() && (!_.isBoolean(tab.loaded) || tab.loaded === false))
                return;

            return <Hideable className='app-activity-hub-body-content' key={tab.key} hide={activeTab !== tab.key}>{tab.component}</Hideable>
        });
    };

    const renderTabButtons = (tabs) =>{

        const onClick = async (item) =>{
            if (_.isFunction(item.onClick))
            {
                if (item.await)
                {
                    setGenerating(true);
                    await item.onClick();
                    setGenerating(false);
                }
                else
                    item.onClick();

                if (item.closeOnExecute)
                    setVisible(false);
            }
            else if (!_.isUndefined(item.component))
            {
                item.loaded = true;
                setTabs(tabs);
                setActiveTab(activeTab===item.key ? tabs[0].key : item.key);
            }
        };

        return tabs.map((tab) =>{
            return _.isArray(tab.items) ?
                <MenuButton 
                    key={tab.key}
                    icon={tab.icon}
                    className='app-activity-hub-footer-drown-down'
                    parentContainer='#activityHub'
                    scrollMenu={true}
                    items={
                        tab.items.map((item) => {
                            return { 
                                icon: item.icon ? item.icon : tab.icon, 
                                text: item.text, 
                                onClick: async () =>{ await onClick(item); } 
                            } 
                        })
                    } 
                />
            :
                tab.confirmButton ?
                <ConfirmButton 
                    key={tab.key}
                    className='app-activity-hub-footer-tab'
                    theme='navigation'
                    icon={tab.icon}
                    tooltip={tab.tooltip}
                    disabled={tab.disabled}
                    clearText={translate('delete')}
                    onConfirm={async () => { await onClick(tab); }}
                />
                : <Button
                    key={tab.key}
                    className='app-activity-hub-footer-tab'
                    theme='navigation'
                    icon={tab.icon}
                    tooltip={tab.tooltip}
                    active={activeTab===tab.key}
                    disabled={tab.disabled}
                    onClick={async () => { await onClick(tab); }}
                />
        })
    };

    activityHub = {
        open: async (o) =>
        {
            activeEntityLayer.clear();

            switch(o.type)
            {
                default:
                    break;
                case 'cluster':
                    break;
            }

            switch(o.entity.type){
                case constants.entities.point:
                case constants.entities.pushpin:
                case constants.entities.cluster:
                    activeEntityLayer.addEntity({ 
                        type: constants.entities.activePoint,
                        location: o.entity.location,
                        anchor: o.entity.anchor,
                        width: o.entity.width,
                        height: o.entity.height
                    });

                    break;
                case constants.entities.circle:
                case constants.entities.polygon:
                case constants.entities.rectangle:
                case constants.entities.polyline:
                    activeEntityLayer.addEntity({                        
                        type: constants.entities.activeShape,
                        paths: o.entity.paths
                    });
                    break
            }

            setTabs([{ id: constants.activityHub.actions.information, key: helpers.emptyGuid() }]);
            setTitle(o.entity.text);
            setPixel(map.latLonToXY(o.entity.location));
            setEntity(o.entity);
            setLoaded(false);
            setGenerating(false);
            setVisible(true);
            setActiveTab(helpers.emptyGuid());
            setOnClose(o.onClose ? { action: o.onClose }  : { });

            var tabs = _.sortBy((_.isFunction(o.renderContent) ? await o.renderContent() : []).filter(x => _.isString(x.id)).map((tab, i) =>{

                tab.key = helpers.newGuid();
                if (_.isArray(tab.items))
                    tab.items.forEach(x => x.key = helpers.newGuid());
                
                switch (tab.id)
                {
                    default:
                        tab.priority = 100;
                        tab.icon = icons.question;
                        tab.tooltip = tab.id;
                        break;
                    case constants.activityHub.actions.information:
                        tab.priority = 0;
                        tab.icon = icons.infoCircle;
                        tab.tooltip = translate('information');
                        tab.key = helpers.emptyGuid();
                        break;
                    case constants.activityHub.actions.tradeAreas:
                        tab.priority = 1;
                        tab.icon = icons.bullseye;
                        tab.tooltip = translate('trade_areas');
                        tab.component = <TradeAreas entity={o.entity} 
                            onSave={({tradeAreas, tradeAreaDefaults}) => {
                                activityHub.close();
                                
                                tradeAreaModule.generate({
                                    entity: o.entity,
                                    tradeAreas: tradeAreas,
                                    standardGeographies: tradeAreaDefaults.standardGeographies
                                });
                            }
                        } />;
                        break;
                    case constants.activityHub.actions.drivingDirections:
                        tab.priority = 2;
                        tab.icon = icons.car;
                        tab.tooltip = translate('driving_directions');
                        tab.onClick = () =>{
                            tools.openDrivingDirections({ entity: o.entity, add: true });
                            setVisible(false);
                        };
                        break;
                    case constants.activityHub.actions.edit:
                        tab.priority = 3;
                        tab.icon = icons.edit;
                        tab.tooltip = translate('edit');
                        break;
                    case constants.activityHub.actions.add:
                        tab.priority = 4;
                        tab.icon = icons.add;
                        tab.tooltip = translate('add_to_source');
                        break;
                    case constants.activityHub.actions.reshape:
                        tab.priority = 5;
                        tab.icon = icons.reshape;
                        tab.tooltip = translate('reshape');
                        break;
                    case constants.activityHub.actions.move:
                        tab.priority = 6;
                        tab.icon = icons.move;
                        tab.tooltip = translate('move');
                        break;
                    case constants.activityHub.actions.photos:
                        tab.priority = 7;
                        tab.icon = icons.picture;
                        tab.tooltip = translate('photos');
                        tab.component = <Photos entity={o.entity} />;
                        break;
                    case constants.activityHub.actions.comments:
                        tab.priority = 8;
                        tab.icon = icons.comments;
                        tab.tooltip = translate('comments');
                        tab.component = <Comments entity={o.entity} />;
                        break;
                    case constants.activityHub.actions.multimedia:
                        tab.priority = 9;
                        tab.icon = icons.photoFilmMusic;
                        tab.tooltip = translate('manage_multimedia');
                        tab.component = <Multimedia entity={o.entity} />;
                        break;
                    case constants.activityHub.actions.delete:
                        tab.priority = 10;
                        tab.icon = icons.trash;
                        tab.confirmButton = true;
                        tab.tooltip = translate('delete');
                        break;
                    case constants.activityHub.actions.mapBooks:
                        tab.priority = 11;
                        tab.icon = icons.bookBookmark;
                        tab.tooltip = translate('mapbook');
                        tab.component = <MapBookRunner entity={o.entity}/>;
                        break;  
                    case constants.activityHub.actions.select:
                        tab.priority = 12;
                        tab.icon = icons.lasso;
                        tab.tooltip = translate('select_contents');
                        tab.onClick = () =>{
                            selections.selectionFinish({ shape: { locations: o.entity.paths } });
                        };
                        break;                   
                    case constants.activityHub.actions.projections:
                        tab.priority = 13;
                        tab.icon = icons.chartMixedUpCircleDollar;
                        tab.tooltip = translate('projections');                        
                        break;
                    case constants.activityHub.actions.trip2Trade:
                        tab.priority = 14;
                        tab.icon = icons.suitcase;
                        tab.tooltip = translate('trip_2_trade');
                        tab.component = <Trip2Trade entity={o.entity} />;
                        break;
                    case constants.activityHub.actions.customerMaps:
                        tab.priority = 15;
                        tab.icon = icons.peopleGroup;
                        tab.tooltip = translate('customer_maps');
                        break;
                    case constants.activityHub.actions.customActionButton:
                        tab.priority = 16;
                        tab.icon = icons.fileLines;
                        tab.tooltip = tab.customButton.HoverText;

                        const getCustomAction = (item, tab) =>{
                            switch(item.Action){                         
                                default:
                                    return null;
                                case constants.activityHub.actions.customActions.cvsCompList:
                                    tab.icon = icons.list;

                                    return {
										text: item.Text,
										icon: icons.list,
                                        onClick: () =>{
                                            customReports.cvs.generateCompList({ entity: o.entity });
                                        }
                                    };
                                case constants.activityHub.actions.customActions.customReport:
                                    tab.icon = icons.fileLines;

                                    return {
										text: item.Text,
										icon: icons.fileLines,
                                        onClick: () =>{
                                            customReports.generateCustomReport({ id: item.Id, entity: o.entity });
                                        }
                                    };
                                case constants.activityHub.actions.customActions.analogs:
                                    tab.icon = icons.sliders;

                                    return {
                                        key: tab.key,
										text: item.Text,
										icon: icons.sliders,
                                        component: <AnalogSelector entity={o.entity} />,
                                        loaded: true,
                                        onClick: () =>{
                                            tab.component = <AnalogSelector entity={o.entity} />;
                                            tab.loaded = true;
                                            setTabs(tabs);
                                            setActiveTab(activeTab===tab.key ? tabs[0].key : tab.key);
                                        }
                                    };
                            }
                        };
                        
                        switch(tab.customButton.Type)
                        {
                            case constants.activityHub.actions.customActions.types.button:
                                
                                var customAction = getCustomAction(tab.customButton, tab);
                                if (customAction === null)
                                    tab = null;
                                else
                                    tab.onClick = customAction.onClick;

                                break;
                            case constants.activityHub.actions.customActions.types.dropDown:
                                
                                tab.items = tab.customButton.Items.map(item => {
                                    return getCustomAction(item, tab);
                                }).filter(x => x !== null);

                                break;
                        }                        
                        
                        break;
                    case constants.activityHub.actions.geofences:
                        tab.priority = 17;
                        tab.icon = icons.fence;
                        if(o.entity.layer.metaData.isCompetitiveInsights === true) {
                            tab.tooltip = translate('geofence');
                            tab.onClick = () =>{ 
                                    geofenceModule.loadCompetitiveInsightsGeoFence(o.entity);

                                setVisible(false);
                            };  
                        }
                        else {
                            tab.tooltip = translate('manage_geofences');
                            tab.component = <Geofences entity={o.entity} />;
                        }
                        break;
                    case constants.activityHub.actions.download:
                        tab.priority = 18;
                        tab.icon = icons.download;
                        tab.tooltip = translate('download');
                        break;   
                    case constants.activityHub.actions.demographics:
                        tab.priority = 19;
                        tab.icon = icons.fileBarChart;
                        tab.tooltip = translate('demographics');
                        tab.component = <SmallDemographicReports 
                                text={o.entity.text} 
                                reportCategory={constants.reportCategories.demographicSummary}
                                scrolling = {true}
                                tradeAreaLibrary={[{
                                    CenterLat: o.entity.location.lat,
                                    CenterLon: o.entity.location.lon,
                                    EncodedPoints: helpers.encodedLocations(o.entity.paths),
                                    GeographyIds: [],
                                    GeographyVintageId: -1,
                                    Interval: 0,
                                    LengthMeasureMent: 4,
                                    PointFormat: 0,
                                    Type: "P",
                                    isTradeArea: false,
                                    libraryName: o.entity.text,
                                    name: o.entity.text
                                }]}    
                            />
                        break;                    
                    case constants.activityHub.actions.competitiveInsightsVisitTradAreas:
                        tab.priority = 20;
                        tab.icon = icons.grid;
                        tab.tooltip = translate('visits_trade_area');
                        break;
                    case constants.activityHub.actions.competitiveInsightsPolygonTradeAreas:
                        tab.priority = 21;
                        tab.icon = icons.hexagon;
                        tab.tooltip = translate('visits_polygon');
                        break;   
                    case constants.activityHub.actions.competitiveInsightsDashboards:
                        tab.priority = 22;
                        tab.icon = icons.gaugeHigh;
                        tab.tooltip = translate('dashboards');
                        tab.onClick = ()=>{
                            rightPanel.setContent({ content: <CIDashboards key={'CIDashboards'} entity={o.entity} />, title: o.entity?.text, dragEnabled: false, className: 'app-ci-dashboards-wrapper' });
                            setVisible(false);
                        };
                        break;                                                     
                    case constants.activityHub.actions.reportIncorrectCompetitiveInsightsObject:
                        tab.priority = 23;
                        tab.icon = icons.bug;
                        tab.tooltip = translate('report_incorrect_point');
                        break;
                    case constants.activityHub.actions.task:
                        tab.priority = 24;
                        tab.icon = icons.task;
                        tab.tooltip = translate('task');
                        tab.component = <TaskDetail task={ {id: null, entity: o.entity} } showHeader={true} onSave={() => {setVisible(false);}}/>;
                        break;
                }                
                
                return tab;
            }).filter(x => x !== null), 'priority');

            if (helpers.isViewer())
                tabs = tabs.filter(x => x.id === constants.activityHub.actions.information);

            setTabs(tabs);

            setLoaded(true);
        },
        close: () =>{

            setVisible(false);
            setTabs([]);
            setEntity(null);

            if (_.isFunction(onClose.action))
                onClose.action();

            activeEntityLayer.clear();
        },
        title: (title) =>{ setTitle(title); },
        selectDefaultTab: () =>{ if (tabs.length > 0) setActiveTab(tabs[0].key); }
    };

    return <Window id='activityHub' container='#map' pixel={pixel} entity={entity} visible={visible} setVisible={setVisible} title={title} width='750px' height='560px' onClose={() =>{
            if (_.isFunction(onClose.action))
                onClose.action();

            activeEntityLayer.clear();
        }}>
        <div className='app-activity-hub-body'>
            <Loader className='app-form-loader' loaded={loaded} />
            <Loader theme='generate' className='app-form-loader' loaded={!generating} />
            { renderTabs(tabs) }
        </div>
        <div className='app-activity-hub-actions'>{ renderTabButtons(tabs) }</div>
    </Window>
}