import { useEffect, useState } from "react";
import { tasks as tasksModule } from "../../../../modules/tasks";
import { DataList } from "../../../base/dataList/dataList";
import { Button } from "../../../base/button/button";
import { icons } from "../../../base/icon/icon";
import { translate } from "../../../../utils/translation";
import { Loader }  from '../../../base/loader/loader';
import { successToast } from "../../../base/toast/toast";

const _ = require("lodash");

export default function TaskShare({ task, taskMetaData, onCancel }) {
    const [userList, setUserList] = useState([]);
    const [sharedUsers, setSharedUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (taskMetaData.users) {
            var users = [];
            taskMetaData.users.forEach((user) => {users.push({id: user.GUID, name: user.Name})});
            setUserList(users);
        }
        if (task.sharedPeers) {
            var sharedUsers = []; 
            task.sharedPeers.forEach((sharedPeer) => {sharedUsers.push(sharedPeer.GUID)});
            setSharedUsers(sharedUsers);
        }

        setLoaded(true);
    }, [task, taskMetaData]);

    const onShare = async () => {
        setLoaded(false);
        var peers = [];
        taskMetaData.users.forEach((user) => {
            if (sharedUsers.includes(user.GUID)) {
                peers.push(user);
            }
        });

        const result = await tasksModule.updateTaskSharedPeers({ id: task.id, userList: peers });

        if (result.sharedPeers) {
            var newSharedUsers = []; 
            result.sharedPeers.forEach((sharedPeer) => {newSharedUsers.push(sharedPeer.GUID)});
            setSharedUsers(newSharedUsers);
        }

        setLoaded(true);
        successToast(translate('success'))
    }

    const changeSelection = (selectedItems) => {
        setSharedUsers(selectedItems);
    }

    return <div className='app-task-share-container'>
        <Loader className='app-task-share-loader' loaded={loaded}/>
        <div className='app-task-share-header'>
            <div className='app-task-share-title'>{translate('task_share_title')}</div>
            <div className='app-task-share-buttons'>
                <Button className='app-task-share-close' theme='secondary' size='small' icon={icons.x} onClick={onCancel}/>
                <Button className='app-task-share-save' theme='primary' size='small' icon={icons.share} onClick={onShare}/>
            </div>
        </div>
        <div className='app-task-share-body'>
            <DataList dataSource={userList} selectedItems={sharedUsers} keyExpr={'id'} displayExpr={"name"} selectedItemsChange={changeSelection}/>
        </div>
    </div>
}