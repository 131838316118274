// React imports
import { useState, useEffect, useRef, useCallback } from 'react'

// App imports
import { errorToast, successToast } from '../../base/toast/toast';
import { Button } from '../../base/button/button';
import { Bar } from '../../base/bar/bar';
import { Icon } from '../../base/icon/icon';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { TextBox } from '../../base/textBox/textBox';
import { TextArea } from '../../base/textArea/textArea';
import { DropDown } from '../../base/dropDown/dropDown';
import { projects as projectsModule } from '../../../modules/projects';
import { userPreferences } from '../app';
import { mapControl } from '../map/mapControl/mapControl';

const _ = require("lodash");

export function SaveMap({onClose}) {    
                           
    const [project, setProject] = useState(null);
    const [mapFolders, setMapFolders] = useState([]);
    const holdName = useRef(null);

    const fetchMapFolders = useCallback(async () => {
        const folders = await projectsModule.getMapFolders();
        setMapFolders(folders.map(folder => ({ id: folder.Id, text: folder.Name, isDefault: folder.IsDefault })));
    }, []);

    useEffect(() => {
        fetchMapFolders();
    }, [fetchMapFolders]);    

    useEffect(() => {

        if (!_.isArray(mapFolders) || mapFolders.length === 0)
            return;

        var defaultFolder = mapFolders.find(f => f.isDefault === true);

        if (!_.isObject(defaultFolder))
            defaultFolder = mapFolders[0];

        setProject(prevProject => ({ ...prevProject, folderId: defaultFolder?.id }));

    }, [mapFolders]);    

    var getMapFolders = useCallback(
        async () =>{        
            var information = mapControl.getCurrentMapInformation();

            if (!information) {
                const defaultProject = {
                    id: null,
                    name: '', 
                    description: '', 
                    contact: userPreferences.Email,
                    fixed: false,
                    isDefault: false,
                    folderId: null
                };                 
                setProject(defaultProject);
            }
            else {
                holdName.current = information.Name;
    
                setProject(prevProject => ({
                    ...prevProject,
                    id: information.Id,
                    name: information.Name,
                    description: information.Description,
                    fixed: information.IsFixed,
                    contact: userPreferences.Email
                }));            
            }
        },
        []
    );    

    useEffect(() =>{
        getMapFolders();
    }, [getMapFolders]);    

    async function saveNew()  {
        var newProject = _.cloneDeep(project);

        save(newProject);
    };  

    async function saveAs()  {

        if (holdName.current === project.name) {
            errorToast(translate('save_map_as_duplicate_name_message'));
            return;
        }

        var saveAsProject = _.cloneDeep(project);
        saveAsProject.id = null;

        save(saveAsProject);
    };     

    async function save(project)  {
        close();
        const mapId = await projectsModule.saveMap(project);

        if (mapId) {

            if (mapControl.getCurrentMapInformation()?.Id !== mapId) {
                projectsModule.loadMap({mapId: mapId});
            }
            else {
                project.id = mapId;
                mapControl.updateCurrentMapInformation(project);
            }

            successToast(translate('successfully_saved'));
        }
        else {
            errorToast(translate('failed'));   
        }
    };  

    function close() {
        setProject(null);
        onClose();
    };

    return <>
    {
        project === null ? '' :
                <div>
                    <div className='app-save-map-labels'>{translate('map_details')}</div>
                    <div className='app-save-map-rows'>
                        <TextBox className='app-save-map-inputs' height='47px' label={translate('title')} value={project.name}
                            onChange={(o) => { setProject({ ...project, name: o.value }); }}  />                            
                    </div>
                    <div className='app-save-map-rows'>                            
                        <TextArea className='app-save-map-inputs' minHeight={150} label={translate('description')} value={project.description}
                            onChange={(o) => { setProject({ ...project, description: o.value }); }}  />                  
                    </div>          
                    <div className='app-save-map-rows'>
                        <DropDown
                            items={mapFolders} 
                            className='app-save-map-inputs'
                            height={'55'} 
                            display={'text'} 
                            valueProperty={'id'} 
                            selected={project.folderId}
                            onSelect={(o) => {
                                setProject({ ...project, folderId: o.value.id });
                            }}
                        />                                            
                    </div>                          
                    <div>
                        <div className='app-option-selector'>
                            {project.fixed ? 
                            <div className='app-option-selector-check'>
                                <Icon icon={icons.check} />
                            </div> : ''}
                            <Bar className={'app-save-map-bar-buttons app-save-map-bar-short'} 
                                icon={icons.map}
                                text={translate('fix_extent')} 
                                active={project.fixed} 
                                onClick={(o) => { setProject({ ...project, fixed: !project.fixed }); }}
                                disabled={false} 
                            /> 
                        </div>                        
                    </div>                                           
                    <div className='app-save-map-actions-rows'>
                        <Button className='app-save-map-button' theme='secondary' tooltip={translate('close')} size='medium' icon={icons.x} onClick={()=>{close() }} />	    
                        <Button className='app-save-map-button' theme='secondary' tooltip={translate('save_as')} size='medium'icon={icons.saveAs} disabled={project?.id === null} onClick={()=>{saveAs() }} />	                
                        <Button className='app-save-map-button' theme='primary' tooltip={translate('save')} size='medium'icon={icons.save} onClick={()=>{saveNew() }} />	
                    </div>
                </div>
    }
    </>
}