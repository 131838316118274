// Third party imports
import {DateBox as DxDateBox} from 'devextreme-react/date-box';

// App imports
import { validation } from '../validation/validation';
import { Icon, icons } from '../icon/icon';

const _ = require("lodash");

export function DateBox({ className, label, value, min, max, dateOutOfRangeMessage, disabled=false, height, onChange, actions, type='date', inputAttr = {}, validator, isRequired = false, showClearButton=false }) {

    var classes = ['app-date-box'];

    if (className)
        classes.push(className);

    const handleFocusOut = () => {
        if(validator) {
            validator.ref.current.instance.option('isDirty', true);
            validator.ref.current?.instance.validate()
        }
    };

    return <div className={classes.join(' ')}>
        <div className='app-date-box-container'>
            <DxDateBox 
                type={type}
                inputAttr={inputAttr}
                height={height? height : '30px'}
                value={value}
                min={min}
                max={max}
                dateOutOfRangeMessage={dateOutOfRangeMessage}
                label={label}
                disabled={disabled}
                onFocusOut={handleFocusOut}
                showClearButton={showClearButton}
                onValueChanged={(e)=>{
                    if (showClearButton && e.previousValue !== null && e.value === null) {
                        // continue
                    }                        
                    else if (e.value === null || e.value === e.previousValue)
                        return

                    if (onChange)
                        onChange({                        
                            value: e.value,
                            userChanged: e.event !== undefined
                        });
                }}>
                {validation.getValidator(validator)}
            </DxDateBox>
            {isRequired ? <span className='required'>{isRequired ? <Icon className={'app-form-required'} icon={icons.asterisk}/> : ''}</span> : ''}
        </div>
    </div>
}