// React imports
import { useEffect, useState, useMemo, useCallback, Fragment } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { translate } from '../../../../utils/translation';
import { icons } from '../../../base/icon/icon';
import { TextBox } from '../../../base/textBox/textBox';
import { SymbolManager } from '../../../base/symbolManager/symbolManager';
import { helpers } from '../../../../utils/helpers';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';

const _ = require("lodash");

export default function PushPinEditForm({ entity }){

    const [symbol, setSymbol] = useState(null);
    const [text, setText] = useState(null);
    const [description, setDescription] = useState(null);

    useEffect(() => {
        setText(entity.text);
        setDescription(entity.description);
        setSymbol(helpers.getDataFromSymbolUrl(entity.image).name);
    }, []);

    const onSave = () => {

        entity.text = text;
        entity.description = description;
        entity.image = legacyEndpoints.handlers.getSymbolUrl({ imageUrl: symbol });
        
        if(_.isFunction(entity.onEdit)){
            entity.onEdit({text: text, description: description, symbol: symbol});
        }
    }

    return <div className='app-push-pin-edit-form-container'>
        <div className='app-push-pin-edit-form-header'>
            <div className='app-push-pin-edit-form-header-title'></div>
            <Button theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={onSave}/>
        </div>
        <div className='app-push-pin-edit-form-body'>
            <TextBox className='app-push-pin-edit-form-field' label={translate('name')} value={text} onChange={(o) => {setText(o.value)}}/>
            <TextBox className='app-push-pin-edit-form-field' label={translate('description')} value={description} onChange={(o) => {setDescription(o.value)}}/>
            <SymbolManager symbol={symbol} onChange={(o) => {setSymbol(o)}}/>
        </div>
    </div>
}