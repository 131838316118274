// React imports
import { useEffect, useState } from 'react';

// App imports
import { Form } from '../forms';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";

const _ = require("lodash");

export function InfoForm({customQueryId, pointId, formId, form=null, fromTask=false}){

    const [_form, _setForm] = useState(form);

    useEffect(() =>{
        if (form === null)
        {
            if (_.isString(formId) && _.isString(pointId))
            {
                (async () =>{
                    _setForm(await legacyEndpoints.service({
                        name: 'GetFormInformation',
                        parameters: {
                            formId: formId,
                            pointId: pointId
                        }
                    }));
                })();
            }
            else
                return;
        }

       _setForm(form);

    }, [form, formId, pointId]);
    
    const onTabChange = async (tab)=>{
        
        if (tab.Pages.length > 0)
            return tab;
        
        if (fromTask)
            tab.Pages.push({ 
                Sections: await legacyEndpoints.service({
                    name: 'GetFormTabInformation',
                    parameters: {
                        formId: customQueryId,
                        tabId: tab.Id,
                        pointId: pointId
                    }
                }) 
            });
        else
            tab.Pages.push({ 
                Sections: await legacyEndpoints.service({
                    name: 'GetCustomQueryTabInformation',
                    parameters: {
                        customQueryId: customQueryId,
                        tabId: tab.Id,
                        pointId: pointId
                    }
                }) 
            });
        
        return tab;
    };

	return <Form 
        form={_form}
        onTabChange={onTabChange}
    />
}