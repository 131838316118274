import { useEffect, useState } from 'react';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { Bar } from '../../base/bar/bar';
import { icons } from '../../../components/base/icon/icon';
import { ModelWizard } from './modelWizard/modelWizard';
import { translate } from '../../../utils/translation';
import { app, userPreferences } from '../app';
import { projections } from '../../../modules/projections';
import { constants } from '../../../utils/constants';
import { Scenarios } from '../scenarios/scenarios';
import { HotSpotAnalysis } from './hotSpotAnalysis/hotSpotAnalysis';

const _ = require("lodash");

export function Analytics(){

	const [availableProjections, setAvailableProjections] = useState([]);
	const [hasScenarios, setHasScenarios] = useState(false);
	const [currentProjection, setCurrentProjection] = useState(null);
	const [showScenarios, setShowScenarios] = useState(false);
	const [showHotSpotAnalysis, setShowHotSpotAnalysis] = useState(false);

	useEffect(()=>{
        legacyEndpoints.service({
			name: 'GetAvailableProjections',
			success: function(result) {

				var relocations = result.filter(x => x.type === constants.projections.relocation);
				var recaptures = result.filter(x => x.hasRecaptureModel);
				var interactiveForecasts = result.filter(x => x.type === constants.projections.interactive && !x.hasRecaptureModel);
				var oneclickForecasts = result.filter(x => x.type === constants.projections.oneClick && !x.hasRecaptureModel);

				setAvailableProjections([...interactiveForecasts, ...oneclickForecasts, ...recaptures, ...relocations]);
				setHasScenarios([...interactiveForecasts, ...recaptures].filter(x => x.providerType === constants.projections.providers.intalytics).length > 0)
			}
		});
    }, []);
	
	// TBD: need user permission flag for market optimization, such as userPreferences.AllowMarketOptimization
	return <>
		<Bar text={translate('tools')} icon={icons.toolbox} opened={true}>
			<Bar className='app-legend-menu-item'text={translate('hot_spot_analysis')} icon={icons.fire} notLicensed={!userPreferences.AllowDemoReports} onClick={()=>{setShowHotSpotAnalysis(true)}} />
			{showHotSpotAnalysis === true ? <HotSpotAnalysis onClose={()=>{setShowHotSpotAnalysis(false)}} /> : ''}
			{true ? null : <Bar className='app-legend-menu-item'text={translate('market_optimization')} icon={icons.puzzle} notLicensed={!userPreferences.AllowDemoReports} onClick={()=>{app.toggleMarketOptimization()}} />}
		</Bar>
		{
			availableProjections.length > 0 ? <Bar text={translate('forecasting')} icon={icons.chartMixedUpCircleDollar} opened={true}>
				{hasScenarios ? <Bar className='app-legend-menu-item' text={translate('scenarios')} icon={icons.files} onClick={()=>{ setShowScenarios(true) }}/> : ''}
				{availableProjections.map((projection) =>{
					return <Bar className='app-legend-menu-item' key={projection.id} text={projection.name} icon={projections.getIcon(projection)} onClick={() => { setCurrentProjection(projection); }} />
				})}
				{showScenarios === true ? <Scenarios onClose={()=>{setShowScenarios(false)}} /> :''}
				{currentProjection !==null ? <ModelWizard projection={currentProjection} onClose={()=>{ setCurrentProjection(null); }}/> :''}			
			</Bar> : ''
		}		
	</>;
}