// React imports
import { useState, useEffect, useCallback } from 'react';

// Third party imports
import { FilterBuilder as DevExFilterBuilder } from 'devextreme-react/filter-builder';

const _ = require("lodash");

export function FilterBuilder({fields, filter, onValueChange}) {

	const onValueChangeOverride = (e) => {

		if (onValueChange)
			onValueChange(e);
	};

	return <DevExFilterBuilder
                fields={fields}
                value={filter}
                onValueChange={onValueChangeOverride}>
            </DevExFilterBuilder>;
}