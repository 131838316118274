// Third party imports
import moment from 'moment';

// React imports
import { useState, useRef, useEffect, useMemo, memo, forwardRef, useImperativeHandle} from 'react';

// App imports
import { TaskDetail } from '../taskDetail/taskDetail';
import { DataGrid } from '../../../base/dataGrid/dataGrid';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';
import { tasks as tasksModule } from '../../../../modules/tasks';
import { RadioButtonSet } from '../../../base/radioButtonSet/radioButtonSet';
import { Popover } from '../../../base/popover/popover';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';

const _ = require('lodash');

const TaskList = forwardRef(({ setTask, setTaskDetailActive, setTaskActionActive, setTaskHistoryActive, setTaskShareActive, setTaskSupportId}, ref) => {
    const [dueFilter, setDueFilter] = useState({id: null});
    const [assignmentFilter, setAssignmentFilter] = useState({id: null});
    const [refreshKey, setRefreshKey] = useState(0);
    const grid = useRef(null);
    const addWindow = useRef(null);

    useImperativeHandle(ref, () => ({
        refreshGrid: refreshGrid,
        clearSelection: clearSelection
    }));

    const assignmentFilters = useMemo(() => ({
        allTasks: { id: 1, filters: []},
        createdByMe: { id: 2, filters: [{ expression: constants.expressions.and, field: 'createdBy', operator: constants.operators.equals, value: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, filterBuilders: [] }]},
        assignedToMe: { id: 3, filters: [{ expression: constants.expressions.and, field: 'assignedTo', operator: constants.operators.equals, value: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, filterBuilders: [] }]},
        sharedToMe: { id: 4, filters: [{ expression: constants.expressions.and, field: 'sharedTo', operator: constants.operators.equals, value: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, filterBuilders: [] }] }
    }),[]);

    const dueFilters = useMemo(() => ({
        openTasks: { id:1, filters: [{ expression: constants.expressions.and,  field: 'status', operator: constants.operators.notEquals, value: constants.tasks.statuses.completed, filterBuilders: [] }]},
        dueToday: { id:2, filters: [
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.greaterThanEquals, value: moment().format('YYYY-MM-DD'), filterBuilders: [] },
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.lessThan, value: moment().add('1', 'days').format('YYYY-MM-DD'), filterBuilders: [] }
        ]},
        dueThisWeek: { id: 3, filters: [
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.greaterThanEquals, value: moment().startOf('week').format('YYYY-MM-DD'), filterBuilders: [] },
            { expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.lessThan, value: moment().endOf('week'), filterBuilders: [] }
        ]},
        overdue: { id: 4, filters: [{ expression: constants.expressions.and, field: 'dueDate', operator: constants.operators.lessThan, value: moment().format('YYYY-MM-DD'), filterBuilders: [] }]},
        completed: { id: 5, filters: [{ expression: constants.expressions.and, field: 'status', operator: constants.operators.equals, value: constants.tasks.statuses.completed, filterBuilders: [] }]}
    }),[]);

    useEffect(() => {
        setDueFilter(dueFilters.openTasks);
        setAssignmentFilter(assignmentFilters.allTasks);
    }, [assignmentFilters, dueFilters] );

    const handleExport = () => {
        if (grid.current) {
            var ids = [];
            grid.current.instance.getDataSource().items().forEach((item) => {
                ids.push(item.id);
            });

            tasksModule.exportTasks({ ids: ids });
        }
    };

    const handleSave = () => {
        addWindow.current.instance.hide();
        refreshGrid();
    };

    const handleCancel = () => {
        addWindow.current.instance.hide();
    }

    const createHeaderFilter = (options)=>{
        options.dataSource.useDefaultSearch = false;
        options.dataSource.load = async (o) => {
            var filters = helpers.getFilterBuilder({ filter: options.dataSource.filter });

            var result = await tasksModule.getColumnFilter({
                field: options.fieldName,
                skip: _.isNumber(o.skip) ? o.skip : 0,
                take: _.isNumber(o.take) ? o.take : 20,
                searchOperation: helpers.getFilterOperator(o.searchOperation),
                searchValue: o.searchValue,
                filters: filters
            });

            if (options.fieldName === 'dueDate') {
                result.data = _.map(result.data, (o) => {
                    return { ...o, text: moment(o.text).format('MM/DD/YYYY') };
                });
            }
           
            return {
                data: result.data,
                totalCount: result.total
            };
        }
    };

    const clearGridFilters = () => {
        setDueFilter(dueFilters.openTasks);
        setAssignmentFilter(assignmentFilters.allTasks);
        grid.current.instance.clearFilter();
    };

    const renderUser = ({userGuid, userName}) =>{ return <div><img className='app-tasks-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: userGuid, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} /> {userName}</div>};

    const renderActions = (o) =>{
        var actions = [];
        // Action
        if (o.action != null && o.action != constants.tasks.actions.none)
            actions.push(<Button theme='simple' icon={icons.personRuning} 
                tooltip={ o.action == constants.tasks.actions.openInfobox ? translate('open_info_form') :
                            o.action == constants.tasks.actions.openEditForm ? translate('open_edit_form') :
                            o.action == constants.tasks.actions.loadCustomQuery ? translate('view_map') :
                            translate('action')} 
                onClick={() => { 
                    setTask(o);
                    setTaskSupportId(o.id);
                    setTaskHistoryActive(false);
                    setTaskShareActive(false);
                    setTaskActionActive(true); 
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // History
        if (_.isArray(o.history) && o.history.length > 0)
            actions.push(<Button theme='simple' icon={icons.clock} 
                tooltip={translate('history')} 
                onClick={() => { 
                    setTask(o);
                    setTaskSupportId(o.id);
                    setTaskHistoryActive(true); 
                    setTaskActionActive(false);
                    setTaskShareActive(false);
                }} 
            />);
        else
            actions.push(<div style={{width: '20px'}}></div>);

        // Share
        actions.push(<Button theme='simple' icon={icons.shareAlt} 
            tooltip={translate('share')}
            onClick={() => {
                setTask(o);
                setTaskSupportId(o.id);
                setTaskShareActive(true);
                setTaskHistoryActive(false);
                setTaskActionActive(false);
            }}  
        />);

        // Delete
        actions.push(<ConfirmButton align='right' className='confirm-button' theme='simple' icon={icons.trash} 
            tooltip={translate('delete')}
            clearText={translate('delete')} 
            onConfirm={async () => { 
                await tasksModule.deleteTasks({ taskIdList: [o.id] });
                refreshGrid();
            }} 
        />);

        return <div className='app-task-list-action-container'>
            {
                actions.map((action ,i) => { 
                    return <div key={i} className='app-task-list-action'>
                        {action}
                    </div>
                })
            }
        </div>
    };

    const selectionChanged = (o) => {
        if (o.selectedRowKeys.length > 0) {
            setTask(o.selectedRowsData[0]);
            setTaskDetailActive(true);
        }
    };

    const refreshGrid = () => {
        setRefreshKey(refreshKey + 1);
    };

    const clearSelection = () => {
        setTask(null);
        setTaskDetailActive(false);
        setTaskActionActive(false);
        setTaskHistoryActive(false);
        setTaskShareActive(false);

        if (grid.current) {
            grid.current.instance.clearSelection();
            grid.current.instance.option('focusedRowIndex', -1);
        }
    };

    return <>
        <div className='app-task-list-header'>
            <div className='app-task-list-filters'>
                <RadioButtonSet>
                    <Button theme='map' text={translate('open_tasks')} active={dueFilter.id == dueFilters.openTasks.id} onClick={()=>{setDueFilter(dueFilters.openTasks)}}></Button>
                    <Button theme='map' text={translate('due_today')} active={dueFilter.id == dueFilters.dueToday.id} onClick={()=>{setDueFilter(dueFilters.dueToday)}}></Button>
                    <Button theme='map' text={translate('due_this_week')} active={dueFilter.id == dueFilters.dueThisWeek.id} onClick={()=>{setDueFilter(dueFilters.dueThisWeek)}}></Button>
                    <Button theme='map' text={translate('overdue')} active={dueFilter.id == dueFilters.overdue.id} onClick={()=>{setDueFilter(dueFilters.overdue)}}></Button>
                    <Button theme='map' text={translate('completed')} active={dueFilter.id == dueFilters.completed.id} onClick={()=>{setDueFilter(dueFilters.completed)}}></Button>
                </RadioButtonSet>
                <RadioButtonSet>
                    <Button theme='map' text={translate('all_tasks')} active={assignmentFilter.id == assignmentFilters.allTasks.id} onClick={()=>{setAssignmentFilter(assignmentFilters.allTasks)}}></Button>
                    <Button theme='map' text={translate('created_by_me')} active={assignmentFilter.id == assignmentFilters.createdByMe.id} onClick={()=>{setAssignmentFilter(assignmentFilters.createdByMe)}}></Button>
                    <Button theme='map' text={translate('assigned_to_me')} active={assignmentFilter.id == assignmentFilters.assignedToMe.id} onClick={()=>{setAssignmentFilter(assignmentFilters.assignedToMe)}}></Button>
                    <Button theme='map' text={translate('shared_to_me')} active={assignmentFilter.id == assignmentFilters.sharedToMe.id} onClick={()=>{setAssignmentFilter(assignmentFilters.sharedToMe)}}></Button>
                </RadioButtonSet>
            </div>
            <div className='app-task-list-button-container'>
                <Popover ref={addWindow}
		    		parentId={'#app-task-list-create-button'} 
		    		className={'app-task-list-create-popover'}
		    		showTitle={false}
		    		width={600}
		    		height={480}
		    	>		
                    <TaskDetail task={ {id: null, entity: null} } showHeader={true} onSave={handleSave} onCancel={() => {handleCancel();}}  resetOnSave={true}/>
		    	</Popover>
                <Button id='app-task-list-create-button' theme='primary' size='small' icon={icons.plus} tooltip={translate('new_task')}>
                </Button>
                <Button theme='primary' size='small' icon={icons.refresh} tooltip={translate('refresh')} onClick={refreshGrid}></Button>
                <Button theme='primary' size='small' icon={icons.eraser} tooltip={translate('clear')} onClick={clearGridFilters}></Button>
                <Button theme='primary' size='small' icon={icons.download} tooltip={translate('export')} onClick={handleExport}></Button>
            </div>
        </div>
        <DataGrid 
            ref={grid}
            keyExpr='id'
            className='app-task-list'
            selectionMode='single'
            onSelectionChanged={selectionChanged}
            defaultPageSize={10}
            remoteOperations={ {groupPaging: true, remoteOperations: true} }
            showHeaderFilter={true}
            showFilterRow={true}
            activeRefreshOnly={true}
            focusedRowEnabled={true}
            height={'100%'}
            columns={[
                {name: 'status', className: 'word-wrap', width: '100px', caption: translate('status'), onRender: (o) => { return tasksModule.getTasksStatusName(o.status); }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'status' }); }},
                {name: 'title', className: 'word-wrap', width: '200px', caption: translate('title'), headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'title' }); } },
                {name: 'type', className: 'word-wrap', width: '85px', caption: translate('type'), onRender: (o) => { return tasksModule.getTaskTypeName(o.type); }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'type' }); } },
                {name: 'priority', className: 'word-wrap', width:'105px', caption: translate('priority'), onRender: (o) => { return tasksModule.getTaskPriorityName(o.priority); }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'priority' }); } },
                {name: 'sourceInformation', className: 'word-wrap', width:'135px', caption: translate('associated_with'), allowFiltering: false, allowSorting: false, onRender: (o) => { return o.sourceInformation?.label; } },
                {name: 'dueDate', className: 'word-wrap', width:'160px', dataType: 'date', caption: translate('due_date'), onRender: (o) => { return helpers.formatDate({ date: o.dueDate, format: 'lll' }); }, allowHeaderFiltering: false },
                {name: 'assignedtoname', className: 'word-wrap', width:'200px', caption: translate('assigned_to'), onRender: (o) => { return renderUser({ userGuid: o.assignedTo.GUID, userName: o.assignedTo?.Name }) }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'assignedtoname' }); }},
                {name: 'createdbyname', className: 'word-wrap', width:'200px', caption: translate('created_by'), onRender: (o) => { return renderUser({ userGuid: o.createdBy.GUID, userName: o.createdBy?.Name }) }, headerFilterDataSource: (o) =>{ return createHeaderFilter({ ...o, fieldName: 'createdbyname' }); }},
                {name: 'actions', width:'105px', alignment:'center', caption: translate('actions'), allowFiltering: false, allowSorting: false, onRender: (o) => { return renderActions(o); }}
            ]}
            onLoad={async (o)=>{
                var filters = helpers.getFilterBuilder({ filter: o.filter });
                filters = filters.concat({expression: 1, filters: dueFilter.filters});
                
                if (assignmentFilter.id !== assignmentFilters.allTasks.id)
                    filters = filters.concat({expression: 1, filters: assignmentFilter.filters});

                var sorts = [];
                if (_.isArray(o.sort))
                    o.sort.forEach(sort => { 
                        sorts.push({ fieldId: o.sort[0].selector, sortOrder: sort.desc ? 1 : 0 });
                    });

                const data = await tasksModule.getFilteredTasks({ skip: o.skip, take: o.take, filters: filters, sorts: sorts });
                    
                return {
                    data: data.results,
                    totalCount: data.total
                };
            }}
        />
    </>
});

export default memo(TaskList);