const _ = require("lodash");

/**
 * @class GoogleBoundingBoxOverlay 
 * @classdesc The GoogleBoundingBoxOverlay class is a class that represents an image overlay on a Google map based on bounding box. 
 * It's an alternative to the GoogleImageOverlay class. It fixes issue with double-projection visible on small zoom 
 * levels when overlaying image with GroundOverlay used by GoogleImageOverlay class.
 */
export class GoogleBoundingBoxOverlay {

    constructor(o) {

        o.map._private._imageOverlays.push(this);

        this._map = o.map;
        this._googleMap = o.googleMap;
        this._overlay = null;
        this._visible = o.visible;
        this._zIndex = o.zIndex;
    
        this.refresh = async () =>{ 
                    
            if (this._overlay !== null) {
                this._overlay.setMap(null);
                this._overlay = null;
            }

            if (this._visible === false)
                return;

            const CustomOverlay = class extends window.google.maps.OverlayView {
                constructor(url, map) {
                  super();
                  this.map = map;
                  this._url = url;
                  this.layer = document.createElement('div');
                  this.img = document.createElement('img');
                  this.img.style.width = '100%';
                  this.img.style.height = '100%';
                  this.layer.style.borderStyle = 'none';
                  this.layer.style.borderWidth = '0px';
                  this.layer.style.position = 'absolute';
                  this.setMap(map);
                  this.zoom = map.getZoom();
                }
            
                onAdd() {
                  const panes = this.getPanes();
                  panes.overlayLayer.appendChild(this.layer);

                  const bounds = this.map.getBounds();
                  if (!bounds) return;
            
                  const northEast = bounds.getNorthEast();
                  const southWest = bounds.getSouthWest();
                  const projection = this.getProjection();
                  if (!projection) return;
                  const sw = projection.fromLatLngToDivPixel(southWest);
                  const ne = projection.fromLatLngToDivPixel(northEast);
            
                  // update location of the layer div
                  const div = this.layer;
                  div.style.left = sw.x + 'px';
                  div.style.top = ne.y + 'px';
                  div.style.width = (ne.x - sw.x) + 'px';
                  div.style.height = (sw.y - ne.y) + 'px';
            
                  this.img.src = this._url;
            
                  // append the image to the layer
                  div.innerHTML = '';
                  div.appendChild(this.img);
                }
            
                draw() {
                    const newZoom = this.map.getZoom();
                    if (this.zoom !== newZoom) {
                        this.zoom = newZoom;
                        this.layer.innerHTML = '';
                    }

                }
            
                onRemove() {
                  if (this.layer.parentElement) {
                    this.layer.parentElement.removeChild(this.layer);
                  }
                }
            };
            
            this._overlay = new CustomOverlay (
                await o.imageUrl({
                    bounds: this._map.bounds,
                    zoom: this._map.zoom,
                    layer: o.layer
                }),
                this._googleMap
            );
        };

        this.refresh();
         
        this._map._private._imageOverlays = _.sortBy(this._map._private._imageOverlays, '_zIndex');    
    }

    dispose() {

        if (this._overlay === null)
            return;

        this._overlay.setMap(null);
        this._overlay = null;

        this._map._private._imageOverlays = this._map._private._imageOverlays.filter(overlay => overlay != this);
    }

    setVisible(value){

        if (this._visible === value)
            return;

        this._visible = value;
            
        this._map._private._imageOverlays.forEach(layer => layer.refresh());
    }

    setZIndex(value){

        if (this._zIndex === value)
            return;

        this._zIndex = value;

        this._map._private._imageOverlays = _.sortBy(this._map._private._imageOverlays, '_zIndex');        
        this._map._private._imageOverlays.forEach(layer => layer.refresh());
    }
};