// React imports
import { useState, useCallback } from 'react';

// Third party imports
import {HtmlEditor as DxHtmlEditor, Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';

// App imports
import { helpers } from '../../../utils/helpers';

const headerValues = [false, 1, 2, 3, 4, 5];
const tabs = [
    { name: 'From This Device', value: ['file'] },
    { name: 'From the Web', value: ['url'] },
    { name: 'Both', value: ['file', 'url'] },
  ];
const tabLabel = { 'aria-label': 'Tab' };
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const fontSizeOptions = {
  inputAttr: {
    'aria-label': 'Font size',
  },
};
const fontFamilyOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};
const headerOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};

const _ = require("lodash");

export function TextEditor({ className, height, label, value, toolbarItems, placeholder, name, disabled, minHeight, maxHeight, onChange, valueChangeEvent, onEnter, textAreaRef, allowNull=true }) {

    var classes = ['app-text-editor'];

    if (className)
        classes.push(className); 
    
    const [isMultiline, setIsMultiline] = useState(false);
    // const [currentTab, setCurrentTab] = useState(tabs[2].value);
    // const multilineChanged = useCallback(
    //   (e) => {
    //     setIsMultiline(e.value);
    //   },
    //   [setIsMultiline],
    // );
    // const currentTabChanged = useCallback(
    //   (e) => {
    //     setCurrentTab(e.value);
    //   },
    //   [setCurrentTab],
    // );

    const getValue = (e) =>{
                
        if ((e.value === null && allowNull === false) || e.value === e.previousValue)
            return

        if (onChange)
            onChange({
                value: e.value,
                name: name,
                userChanged: e.event !== undefined
            });
    };

    var renderItem = (o) => {
        return <Item name={o} key={helpers.newGuid()} />
    };

    return <div className={classes.join(' ')} >  
      <DxHtmlEditor
        height={height}
        defaultValue={'<p>Notes...</p>'}
        value={value}
        valueType={'html'}
        onValueChanged={getValue}
      >
        <MediaResizing enabled={true} />
        <Toolbar multiline={isMultiline}>
        { 
        !_.isArray(toolbarItems) || toolbarItems.length === 0 ? '' : 
            toolbarItems.map((item) =>{
				return renderItem(item);
			})
		}
          <Item
            name="header"
            acceptedValues={headerValues}
            options={headerOptions}
          />        
        </Toolbar>
      </DxHtmlEditor>
    </div>   
}