import { GoogleEntity } from './googleEntity';
import { GoogleTileOverlay } from './googleTileOverlay';
import { GoogleImageOverlay } from './googleImageOverlay';
import { GoogleBoundingBoxOverlay } from './googleBoundingBoxOverlay';
import { GoogleHeatMap } from './googleHeatMap';
import { GoogleDirectionsRenderer } from './googleDirectionsRenderer';
import { GoogleDataOverlay } from './googleDataLayer';
import { GoogleKmlLayer } from './googleKmlLayer';
import { Layer } from '../layer';
import { constants } from '../../utils/constants';

const _ = require("lodash");

export class GoogleLayer extends Layer {

    constructor(map, o) {
        super(map, o);

        switch(o.type){
            default:
                this._private._layer = { 
                    setVisible: () => {},
                    setZIndex: () => {},
                    dispose: () => {}
                };
                break;
            case constants.layers.types.thematics.standardGeography:
            case constants.layers.types.thematics.customGrid:
            case constants.layers.types.thematics.fixedGrid:
            case constants.layers.types.thematics.parcel:
            case constants.layers.types.thematics.customGridNoDemos:
            case constants.layers.types.thematics.sqlDataLayer:
            case constants.layers.types.thematics.trafficMetrix:
                if (_.isFunction(this._private._tileUrl))
                    this._private._layer = new GoogleTileOverlay({
                        googleMap: this._private._map._private._map,
                        tileUrl: this._private._tileUrl,
                        visible: this._private._visible,
                        zIndex: this._private._zIndex,
                        layer: this
                    });
                else
                    this._private._layer = new GoogleBoundingBoxOverlay({
                        map: this._private._map,
                        googleMap: this._private._map._private._map,
                        imageUrl: this._private._imageUrl,
                        visible: this._private._visible,
                        zIndex: this._private._zIndex,
                        layer: this
                    });
                    /*
                    this._private._layer = new GoogleImageOverlay({
                        map: this._private._map,
                        googleMap: this._private._map._private._map,
                        imageUrl: this._private._imageUrl,
                        visible: this._private._visible,
                        zIndex: this._private._zIndex,
                        layer: this
                    });
                    */
                break;
            case constants.layers.types.customerMaps.pin:
            case constants.layers.types.customerMaps.desireLine:
            case constants.layers.types.customerMaps.marketShare:
                this._private._layer = new GoogleImageOverlay({
                    map: this._private._map,
                    googleMap: this._private._map._private._map,
                    imageUrl: this._private._imageUrl,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    layer: this
                });
                break;
            case constants.layers.types.customerMaps.heat:
                this._private._layer = new GoogleHeatMap({
                    map: this._private._map,
                    googleMap: this._private._map._private._map,
                    imageUrl: this._private._imageUrl,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    layer: this
                });
                break;
            case constants.layers.types.data:
                this._private._layer = new GoogleDataOverlay({
                    map: this._private._map,
                    googleMap: this._private._map._private._map,
                    data: this._private._data,
                    visible: this._private._visible,
                    zIndex: this._private._zIndex,
                    layer: this
                });
                break;
            case constants.layers.types.geoFeeds:
                    this._private._layer = new GoogleKmlLayer({
                        map: this._private._map,
                        googleMap: this._private._map._private._map,
                        data: this._private._data,
                        imageUrl: this._private._imageUrl,
                        visible: this._private._visible,
                        zIndex: this._private._zIndex,
                        layer: this
                    });
                    break;                
            case constants.layers.types.directions:
                this._private._layer = new GoogleDirectionsRenderer();
                break;
        }
    };

    get active(){ return super.active; }
    set active(value){
        super.active = value;
    };

    get visible(){ return super.visible; }
    set visible(value){
        this._private._layer.setVisible(value);
        super.visible = value;
    };

    get zIndex(){ return super.zIndex; }
    set zIndex(value){
        this._private._layer.setZIndex(value);
        super.zIndex = value;
    };

    addEntity(o){
        return super.addEntity({ entity: new GoogleEntity(this, o) });
    };

    clear(o){
        super.clear(o);
    }

    dispose(){        
        super.dispose();
    }
};