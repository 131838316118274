// React imports
import { useState, useEffect } from 'react';

// App imports
import { DataList } from '../../base/dataList/dataList';
import { Memo } from '../../base/memo/memo';
import { translate } from '../../../utils/translation';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';

const _ = require("lodash");

export function TaskHistory({ task, onCancel }) {

    const [memos, setMemos] = useState([]);

    useEffect(() =>{
        if (_.isArray(task.history)) {
            setMemos(task.history.map((item) => {
                return {
                    id: item.id,
                    date: item.date,
                    message: item.description.Text,
                    user: item.user
                }
            }));
        }

    }, [task]);

    return <>
        <div className='app-task-history-header'>
            <div className='app-task-history-title'>{translate("task_history")}</div>
            <div className='app-task-history-actions'>
                <Button theme='secondary' size='small' icon={icons.x} 
                    onClick={() => { 
                        if(_.isFunction(onCancel))
                            onCancel();
                    }} />
            </div>
        </div> 
        <div className='app-task-history-container'>
        
        { _.isArray(memos) && memos.length > 0 ?
            <DataList
                keyExpr={'id'}
                dataSource={memos}
                searchEnabled={false}
                selectionMode={'single'}
                showSelectionControls={false}
                selectedItemsChange={(o)=> {
                    // do nothing
                }}
                itemRender={(memo) =>{
                    return <Memo key={memo.id} memo={memo} />
                }}
            />          
        : '' }
    </div>
    </>  
}