// React imports
import { useEffect, useState } from 'react';

// App imports
import { Layers } from '../layers/layers';
import { Analytics } from '../analytics/analytics';
import { ArtificialIntelligence } from '../artificialIntelligence/artificialIntelligence';
import { Reports } from '../reports/reports';
import { Button }  from '../../base/button/button';
import { Panel } from '../../base/panel/panel';
import { QuickReports } from '../quickReports/quickReports';
import { Hideable } from '../../base/hideable/hideable';
import { Tools } from '../tools/tools';
import { icons } from '../../base/icon/icon';
import { translate } from "../../../utils/translation";
import { constants } from '../../../utils/constants';
import { MobilityData } from '../mobilityData/mobilityData';
import { Projects } from '../projects/projects';
import { Jobs } from '../jobs/jobs';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { userPreferences } from '../app';
import { msalConfig } from "../../../config/auth";
import { trip2Trade as trip2TradeModule } from '../../../modules/trip2Trade';
import { helpers } from '../../../utils/helpers';
import { ViewerInformation } from '../viewer/viewInformation';

export var navigation;
var expandListeners = [];
var notificationListeners = [];

export function Navigation({context, setNavigationLoaded}){

	const [collapsed, setCollapsed] = useState(false);
	const [activeSection, setActiveSection] = useState(null);	
	const [jobsRunning, setJobsRunning] = useState(0);
	const [jobsCompleted, setJobsCompleted] = useState(0);

	const getJobs = () =>{

		if (helpers.isViewer())
			return;

		legacyEndpoints.service({
			name: 'GetNewNotificationsForSession',
			suppressError: true,
			parameters: {
				SessionKey: legacyEndpoints.authenticationToken.TokenInformation.SessionKey
			},
			success: (result) => {			
				if (result.ForceSignOut)
				{
					legacyEndpoints.beforeUnloadController.abort();

					if (helpers.isViewer())
						context.instance.logoutRedirect();
					else
					{
						context.instance.logoutRedirect({
							account: context.instance.getAllAccounts()[0],
							postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
							authority: msalConfig.auth.authority
						});
					}
				}
				else {

					setJobsRunning(result.JobsNotFinished.filter(x => (x.Status === constants.jobs.statuses.pending || x.Status === constants.jobs.statuses.processing)).length);  
					setJobsCompleted(result.Jobs.filter(x => (x.Status === constants.jobs.statuses.completed || x.Status === constants.jobs.statuses.completedWithErrors || x.Status === constants.jobs.statuses.failed) && !x.IsViewed).length);

                    notificationListeners.forEach(n =>{
                        n.action(result);
                    });             
                    
                    const t2tNotifications = result.Jobs.filter(x => (x.Status === constants.jobs.statuses.completed && x.CustomTaskType === constants.jobs.types.customTypes.trip2Trade));
                    trip2TradeModule.loadNotification(t2tNotifications);
                }
			}
		});
	};
	
	navigation = {
		updateJobs: () =>{		
			getJobs();
		},
		onExpand: (o) =>{
			expandListeners.push(o.listener);
		},
		forceExpand: (o) =>{
			setActiveSection(o.section);
		},
        subscribeToNotifications: (o) =>{
            const exists = notificationListeners.some(x => x.id === o.id);
            if (!exists) 
			    notificationListeners.push(o);
		},
        unsubscribeFromNotifications: (o) =>{
            if (o)
			    notificationListeners = notificationListeners.filter(n => n.id !== o.id);
		},        
	};	

	useEffect(() =>{	
		
		getJobs();
		
		setInterval(() => {
			getJobs();
		}, 30000);
	
	}, []);

	useEffect(() =>{	

		setNavigationLoaded(true);
	
	}, [setNavigationLoaded]);

	useEffect(()=>{
		expandListeners.forEach(listener =>{
			listener({
				collapsed: collapsed,
				activeSection: activeSection !== null
			});
		});
	}, [collapsed, activeSection]);

	var items = [
		{ key: constants.navigation.sections.layers, text: translate('layers'), icon: icons.layers, className: 'app-nav-section app-nav-section-layers', children: <Layers /> },
		{ key: constants.navigation.sections.analytics, text: translate('analytics'), icon: icons.chartMixed, className: 'app-analytics-nav-section', children: <Analytics />  },
		{ key: constants.navigation.sections.ai, text: translate('ai'), panelText: translate('artificial_intelligence'), icon: icons.brainCircuit, className: 'app-nav-section', notLicensed: !userPreferences.AllowAI, children: <ArtificialIntelligence />},
		{ key: constants.navigation.sections.mobilityData, text: translate('mobility_data'), icon: icons.mobile, className: 'app-nav-section', notLicensed: !userPreferences.AllowCompetitiveInsights && !userPreferences.AllowMobilityData, children: <MobilityData /> },
		{ key: constants.navigation.sections.reports, text: translate('reports'), icon: icons.fileBarChart, className: 'app-nav-section', children: <Reports /> },
		{ key: constants.navigation.sections.quickReports, text: translate('quick_reports'), icon: icons.pieChart, className: 'app-nav-section', notLicensed: !userPreferences.AllowDemoReports, children: <QuickReports active={activeSection === constants.navigation.sections.quickReports}/> },		
		{ key: constants.navigation.sections.tools, text: translate('tools'), icon: icons.toolbox, className: 'app-nav-section', children: <Tools /> },
		{ key: constants.navigation.sections.projects, text: translate('maps'), icon: icons.map, className: 'app-nav-section', children: <Projects active={activeSection === constants.navigation.sections.projects} onClose={()=>{ setActiveSection(null);}} />, showButton: !helpers.isViewer() },
		{ key: constants.navigation.sections.jobs, text: translate('jobs'), icon: icons.gear, showNotification: jobsRunning  > 0 || jobsCompleted > 0, spinIcon: jobsRunning  > 0, spinIconSpeed: 'slow', className: 'app-nav-section', children: <Jobs onClose={()=>{ setActiveSection(null);}} /> }
	];

	if (helpers.isViewer())
		items = [
		...items.filter(x => x.key === constants.navigation.sections.layers || x.key === constants.navigation.sections.projects),
		{ key: constants.navigation.sections.viewer, text: translate('viewer_information'), icon: icons.infoCircle, className: 'app-nav-section', children: <ViewerInformation /> }];

	return <>
		<Hideable className='app-nav-container' hide={collapsed}>
			<div className='app-nav'>
			{
				items.filter(item => item.showButton !== false).map((item) => {
					return <Button key={item.key} className='app-navigation-button' theme='navigation' text={item.text} tooltip={item.panelText ?? item.text} icon={item.icon} spinIcon={item.spinIcon} spinIconSpeed={item.spinIconSpeed} active={activeSection===item.key} comingSoon={item.comingSoon} notLicensed={item.notLicensed} disabled={item.disabled} onClick={() => { setActiveSection(activeSection === item.key ? null : item.key); }}>
					{
						jobsRunning > 0 && item.showNotification ? <div className='app-navigation-button-badge app-navigation-button-badge-processing' tooltip={translate('processing')} >{jobsRunning}</div>: ''
					}
					{
						jobsCompleted > 0 && item.showNotification ? <div className='app-navigation-button-badge app-navigation-button-badge-complete' tooltip={translate('complete')}>{jobsCompleted}</div> : ''						
					}
					</Button>
				})
			}
			</div>
			{
				items.map((item) =>{
					return <Panel key={item.key} className={item.className} text={item.panelText ?? item.text} open={activeSection === item.key} onClose={()=>{ setActiveSection(null); }}>
						{item.children}
					</Panel>									
				})
			}
		</Hideable>
		<Button className='app-nav-collapser' theme='primary' icon={(collapsed ? icons.rightArrow : icons.leftArrow)} tooltip={(collapsed ? translate('expand') : translate('collapse'))} onClick={() => {setCollapsed(!collapsed)}} />
	</>
}