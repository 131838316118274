// React imports
import ReactDOM from "react-dom/client";
import { useContext } from 'react';
import { BrowserRouter, useNavigate } from "react-router-dom";

// Third party imports
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalContext } from '@azure/msal-react';
import 'devextreme/dist/css/dx.light.css';

// App imports
import { App } from "./components/app/app";
import { Loader } from './components/base/loader/loader';
import { SignIn } from './components/signIn/signIn';
import { reportWebVitals } from "./config/reportWebVitals";
import { msalConfig } from "./config/auth";
import { CustomNavigationClient } from "./config/navigationClient";
import { helpers } from "./utils/helpers";
import "./index.css";

const msalInstance = new PublicClientApplication(msalConfig);
const rootContainer = document.getElementById('root');
const root = ReactDOM.createRoot(rootContainer);
const _ = require("lodash");
const params = new URLSearchParams(window.location.search);
const queryParams =  {};

for (const key of params.keys()) {
    const keyLower = key.toLowerCase();
    const value = params.get(key);

    switch (keyLower) {
        case 'id':
        case 'mapid':
            queryParams.id = value;
            break;
        case 'taskid':
            queryParams.taskId = value;
            break;
        case 'taskgrid':
            queryParams.taskGrid = value;
            break;
        case 'latitude':
        case 'lat':
            queryParams.latitude = value;
            break;
        case 'longitude':
        case 'lon':
        case 'long':
            queryParams.longitude = value;
            break;
        case 'zoom':
            queryParams.zoom = value;
            break;
        case 'type':
            queryParams.type = value;
            break;
        default:
            break;
    }
}

const b2cQueryParams = {
    ui_locales: (navigator.language?.toLowerCase() ?? navigator.userLanguage.toLowerCase()) ?? 'en-us',
    ui_version: "v" + process.env.REACT_APP_VERSION,
    ui_branch: process.env.REACT_APP_VERSION_BRANCH
};

if (helpers.isViewer())
{
    document.title = "KLI Online Viewer";
    
    if (_.isString(queryParams.id))
    {
        const context = {
            instance: {
                logoutRedirect: (o) => {
                    window.location.href = "https://kalibrate.com/products/software/kalibrate-location-intelligence/";
                }
            },
            viewer: helpers.isViewer(),
            queryParams: queryParams
        };

        root.render(
            <BrowserRouter basename={window.location.pathname}>
                <App context={context}/>
            </BrowserRouter>
        );
    }
    else
    {
        window.location.href = "https://kalibrate.com/products/software/kalibrate-location-intelligence/";
        /*root.render(
            <div>Error could not load map.</div>
        );*/
    }
}
else
{    
    // Handle the redirect flows
    msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
        //console.log("tokenResponse: msal handleRedirectPromise", tokenResponse, tokenResponse.account);

        // Default to using the first account if no account is active on page load
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0)
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);

        // Optional - This will update account state if a user signs in from another tab or window
        msalInstance.enableAccountStorageEvents();

        msalInstance.addEventCallback((event) => {
            if (event.eventType !== EventType.LOGIN_SUCCESS || !event.payload.account)
            {
                if (event.eventType === EventType.SSO_SILENT_FAILURE && event.error?.errorCode === 'monitor_window_timeout')
                {
                    msalInstance.loginRedirect({ prompt: "login", extraQueryParameters: b2cQueryParams }).catch(error => {
                        console.log('loginRedirect', error);
                    });
                }
                else
                    return;
            }

            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
        });

        root.render(
            <BrowserRouter basename={window.location.pathname}>
                <MsalProvider instance={msalInstance}>
                    <AuthenticatedTemplate>
                        <App onLoad={(o) => {
                            var context = useContext(MsalContext);
                                
                            context.viewer = false;
                            context.queryParams = queryParams;

                            msalInstance.setNavigationClient(new CustomNavigationClient(useNavigate()));

                            return context;
                        }} />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <SignIn onSignIn={(o) => {
                            b2cQueryParams.ui_version = o.version;
                            b2cQueryParams.ui_branch = o.branch;
                            
                            msalInstance.loginRedirect({ prompt: "login", extraQueryParameters: b2cQueryParams }).catch(error => {
                                console.log('loginRedirect', error);
                            });
                        }} />
                    </UnauthenticatedTemplate>
                </MsalProvider>
            </BrowserRouter>
        );
    })
    .catch((error) => {
        console.log("error: msal handleRedirectPromise", error);

        try {
            msalInstance.loginRedirect({ prompt: "login", extraQueryParameters: b2cQueryParams }).catch(error => {
                console.log('loginRedirect', error);
            });
        }
        catch (e) {
            console.log(e);
        }
    });

    root.render(
        <Loader showLogo='true' theme='primary' size='large'/>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();