// React imports
import { useEffect, useState, useRef } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { inputBoxTypes, TextBox, validatorTypes } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { trip2Trade as trip2TradeModule } from '../../../../modules/trip2Trade';

const _ = require('lodash');

export function Trip2TradeJob({entity,trip2TradeJob,onSave,onClose}){

    const [formData, setFormData] = useState(trip2TradeModule.getDefaultJob(entity));
    const [isEdit, setIsEdit] = useState(false);
    const nameValidatorRef = useRef(null);
    const radiusValidatorRef = useRef(null);

    useEffect(() => {
        if (trip2TradeJob) {
            setFormData(trip2TradeJob);
            setIsEdit(true);
        }

    }, [trip2TradeJob])       

    function handleChange(e) {
        setFormData((formData) => ({ ...formData, [e.name]: e.value }));
    };

    var saveJob = () =>{ 
        onSave(formData);
    };       

    const validateRadius = (e) => {
        var value = e.value;

        switch (formData.radiusUnitType) {
        case 2:
                value *= 0.3048;
                break;
            case 8:
                value *= 1000;
                break;
            case 4:
                value *= 1609.34;
                break;
        }
        
        if (value > 907 || value < 10) {
            return false;
        }
        else
            return true;        
    }
    
	return <>
        <div className='app-trip-2-trade-job-header'>      
            <div className='app-trip-2-trade-job-actions'>
                <Button theme='secondary' size='small' className={'fa-flip-vertical'} icon={icons.arrowTurnDownLeft} tooltip={translate('go_back')} onClick={() =>{ onClose()  }}/>
                <Button theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={saveJob}
                    disabled={
                                (formData.name.trim() === '') ||
                                (radiusValidatorRef.current?.instance.validate().isValid === false)
                            }                 
                />
            </div>  
        </div>  
        <div className='app-trip-2-trade-body'>
            <div>
                <TextBox className='app-trip-2-trade-name' height='47px' label={translate('name')} name='name' value={formData.name} 
                    valueChangeEvent='keyup' isRequired={true}
                    validator={
                        {
                            ref: nameValidatorRef,
                            rules: [
                                {
                                    type: validatorTypes.requiredRule,
                                    message: translate('required')
                                }
                            ]
                        }
                    }                         
                    onChange={ handleChange }  
                />
                <TextArea className='app-trip-2-trade-description' minHeight='200px' maxHeight='200px' label={translate('description')} name='description' value={formData.description} valueChangeEvent='keyup' onChange={ handleChange } />                                             
            </div> 
            {isEdit ? '' : <div>               
                <TextBox 
                type={inputBoxTypes.numeric}
                 className='app-trip-2-trade-radius-number' height='47px' label={translate('radius')} name='radius' value={formData.radius} 
                            valueChangeEvent='keyup' isRequired={true}
                            validator={
                                {
                                    ref: radiusValidatorRef,
                                    rules: [
                                        {
                                            type: validatorTypes.requiredRule,
                                            message: translate('required')
                                        },
                                        {
                                            type: validatorTypes.customRule,
                                            message: translate('out_of_range'),
                                            callback: validateRadius 
                                        },
                                    ]
                                }
                            }                
                    onChange={ handleChange }  
                />  

                <DropDown className='app-trip-2-trade-radius-radius-units' 
                            height='47px' 
                            selected={formData.radiusUnitType} 
                            items={trip2TradeModule.getRadiusUnits()}
                            label={translate('radius')}  
                            display='name' 
                            valueProperty='id' 
                            onChange={(o) => { setFormData((formData) => ({ ...formData, 'radiusUnitType': o.value })); }}/>                    

                <DropDown className='app-trip-2-trade-trip-type' 
                            height='47px' 
                            items={trip2TradeModule.getTripTypes()} 
                            selected={formData.tripType} 
                            label={translate('trip_type')} 
                            display='name' 
                            valueProperty='id'
                            onChange={(o) => { setFormData((formData) => ({ ...formData, 'tripType': o.value })); }}/>                                               
            </div>}  
        </div>
    </>
}