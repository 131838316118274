// React imports
import { useEffect, useState, useCallback } from 'react';

// App imports
import { CIChannelFilter } from './ciChannelFilter';
import { Button } from '../../../base/button/button'
import { Panel } from '../../../base/panel/panel';
import { icons } from '../../../base/icon/icon';
import { constants } from '../../../../utils/constants';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { competitiveInsights } from '../../../../modules/competitiveInsights';
import { successToast } from '../../../base/toast/toast';

const _ = require("lodash");

export function CIChannelFilterPanel({ onClose }) {
	
	const [loaded, setLoaded] = useState(true);
	const [generated, setGenerated] = useState(true);	
	const [baseChannels, setBaseChannels] = useState([]);
	const [customChannels, setCustomChannels] = useState([]);

	const setChannels = useCallback(
        (channels)=>{
            const newChannels = _.cloneDeep(channels);
            setBaseChannels(newChannels.filter(channel => channel.type === constants.competitiveInsights.channelTypes.base));
            setCustomChannels(newChannels.filter(channel => channel.type === constants.competitiveInsights.channelTypes.user || channel.type === constants.competitiveInsights.channelTypes.admin));
        },
        []
    );

	const load = useCallback(
        ()=>{

            const currentFilters = competitiveInsights.getLayer()?.metaData.channels;		

            if (_.isArray(currentFilters))
                setChannels(currentFilters);
            else
            {
                setLoaded(false);
                legacyEndpoints.service({
                    name: 'GetCompetitiveInsightsUserChannelFilter',
                    success: (result) => {
                        setLoaded(true);
                        setChannels(result);
                    }
                });
            }			
        },
        [setChannels]
    );
	
	useEffect(()=>{
        load();
    }, [load]);

	const close = ()=>{
		onClose();
	};

	const saveFilters = (o) =>{
        o = _.isObject(o) ? o : {};
        setGenerated(false);

        legacyEndpoints.service({
            name: 'SaveCompetitiveInsightsUserChannelFilter',
            parameters: { channelIds: [...baseChannels.filter(channel => channel.isSelected).map(channel => { return channel.id; }), ...customChannels.filter(channel => channel.isSelected).map(channel => { return channel.id; })] },
            success: () => {
                
                setGenerated(true);
                successToast(translate('successfully_saved'));

                const ciLayer = competitiveInsights.getLayer();		
                if (_.isObject(ciLayer))
                    ciLayer.data.competitiveInsightsFilteredChannels = null;

                if (_.isFunction(o.callback))
                    o.callback();
            }
        });
	};

	return <>
		<Panel text={translate('channel_filter')} open={true} loaded={loaded} generated={generated} onReload={() => { load(); }} onClose={() => { close(); }}>
			<div className='app-competitive-insights-channel-filter-buttons'>
				<Button theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() => { close(); }} />
				<Button theme='secondary' size='small' icon={icons.save} tooltip={translate('save')} onClick={() => { 
					saveFilters({
						callback: ()=>{ 
							if (_.isObject(competitiveInsights.getLayer()))
								competitiveInsights.open(); 
						}
					}); 
				}} />
				<Button theme='primary' size='small' icon={icons.check} tooltip={translate('save_and_open')} onClick={() => {
					saveFilters({
						callback: ()=>{ competitiveInsights.open(); }
					});
				 }} />
			</div>			
			<div className='app-competitive-insights-channel-filter'>
                <CIChannelFilter baseChannels={baseChannels} updateBaseChannels={(items) => { setBaseChannels(items); }} 
                    customChannels={customChannels} updateCustomChannels={(items) => { setCustomChannels(items); }}
                />
			</div>			
		</Panel>
	</>
}