import { useEffect, useState } from 'react';

import { InputForm } from '../inputForm/inputForm';
import { Loader } from '../../../base/loader/loader';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { sources } from '../../../../modules/sources';
import { layers } from '../../layers/layers';
import { successToast, errorToast } from '../../../base/toast/toast';
import { translate } from '../../../../utils/translation';
import { forms as formModule} from '../../../../modules/forms';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';

const _ = require("lodash");

export function EditForm({ customQueryId, pointId, onClose, saveButtonSize='medium', fromTask=false }){

    const [form, setForm] = useState(null);
    const [generating, setGenerating] = useState(false);    
    const [updateForm, setUpdateForm] = useState(null);
    const [collapsedForm, setCollapsedForm] = useState(null);
    const [activeWarning, setActiveWarning] = useState(false);
    const [activeError, setActiveError] = useState(false);

    useEffect(() =>{        
        (async () =>{

            var data = null;

            if (fromTask)
                data = await legacyEndpoints.service({
                    name: 'GetEditFormV2ById',
                    parameters: {
                        formId: customQueryId,
                        pointId: pointId
                    }
                });
            else
                data = await legacyEndpoints.service({
                    name: 'GetEditFormV2',
                    parameters: {
                        aServiceID: customQueryId,
                        aPointID: pointId
                    }
                });

            setForm(data);
            setUpdateForm({
                Id: data?.Id,
                UpdateSections: []
            });
            setCollapsedForm(formModule.collapseForm({form: data}));

        })();

    }, [customQueryId, pointId, fromTask]);

    const save = async () =>{

        setGenerating(true);

        var validation = await formModule.saveEditForm({customQueryId: customQueryId, pointId: pointId, form: form, collapsedForm: collapsedForm, updateForm: updateForm, fromTask: fromTask});
        if (!validation.errors) {
            if (!fromTask)
                sources.refresh({
                    layers: [{
                        id: customQueryId,
                        text: translate('loading'),
                        subType: 0,
                        data: { isCompetitiveInsights: false }
                    }],
                    onRefresh: (o) =>{
                        layers.refreshDataLayers(o);
                    }
                });

            if (validation.updateCompleted)
                successToast(translate('success'));
        }
        else {
            setForm(_.cloneDeep(validation.form));
            errorToast(translate('form_save_error'));
        }
        
        setGenerating(false);
        
        if(_.isFunction(onClose)) 
            onClose();
    };
    
	return <>
        <div className='app-edit-form-header'>
            <div className='app-edit-form-header-title'>{fromTask ? translate('task_activity') : ''}</div>
            <div className='app-edit-form-header-actions'>
            {
                fromTask ? <Button theme='secondary' size='small' icon={icons.x} onClick={() => { if(_.isFunction(onClose)) onClose(); }} /> : ''
            }
            {
                (activeError === true) ? <Button theme='primary' size={saveButtonSize} icon={icons.check} disabled={true} /> 
                : (activeWarning === true) ? <ConfirmButton className='app-button-warning' theme='primary' size={saveButtonSize} align='right' icon={icons.check} clearText={translate('save')} headerText={translate('save_with_warnings')} onConfirm={()=>{save()}}/> 
                : <Button theme='primary' size={saveButtonSize} icon={icons.check} onClick={()=>{save()}}/> 
            }
            </div>
        </div>
        <Loader loaded={form !== null} />
        <Loader theme='generate' loaded={!generating} />
        <InputForm form={form} collapsedForm={collapsedForm} forceChange={false} onUpdate={(o) => { setUpdateForm(o.updateForm); 
                            setActiveError(o.activeError); 
                            setActiveWarning(o.activeWarning) }
                        } />      
    </>;
}