// App imports
import { TaskActionInfoBox } from './taskActionInfoBox';
import { EditForm } from '../../forms/editForm/editForm';
import { TaskActionCustomQuery } from './taskActionCustomQuery';
import { constants } from '../../../../utils/constants';
import { translate } from '../../../../utils/translation';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';

const _ = require("lodash");

export function TaskAction({ task, onCancel }) {

    return <>
        { task?.action !== constants.tasks.actions.openEditForm ? <div className='app-task-action-header'>
            <div className='app-task-action-header-title'>{translate("task_activity")}</div>
            <div className='app-task-action-header-actions'>
                <Button theme='secondary' size='small' icon={icons.x} 
                    onClick={() => { 
                        if(_.isFunction(onCancel))
                            onCancel();
                    }} />
            </div>
        </div> 
        : '' 
        }
        <div className={task?.action == constants.tasks.actions.openEditForm ? 'app-task-action-container-no-header' : 'app-task-action-container'}>
            { task?.action == constants.tasks.actions.openInfobox ?
                <TaskActionInfoBox task={task} />
            : task?.action == constants.tasks.actions.openEditForm ?
                <div className='app-task-action-form app-task-action-form-edit'>
                    <EditForm customQueryId={task.sourceInformation.parentObjectId} pointId={task.sourceInformation.objectId} saveButtonSize={'small'} fromTask={true} onClose={onCancel} />
                </div>
            : task?.action == constants.tasks.actions.loadCustomQuery ?
                <TaskActionCustomQuery task={task} />
            : '' }
        </div>
    </>
}