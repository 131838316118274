// React imports
import { useEffect, useRef, useState } from 'react';

//App imports
import { Loader } from '../../base/loader/loader';
import { PieChart } from '../../base/pieChart/pieCart';
import { BarChart } from '../../base/barChart/barChart'
import { DropDown } from '../../base/dropDown/dropDown'
import { SimpleTable } from '../../base/simpleTable/simpleTable'
import { map } from "../map/map";
import { constants } from '../../../utils/constants';
import { helpers } from "../../../utils/helpers";
import { translate } from "../../../utils/translation";
import { legacyEndpoints } from "../../../services/legacyEndpoints";

const _ = require('lodash');

export var quickReports = {
    update: () =>{}
};

export function QuickReports(active) {

    const container = useRef(null);
    const [data, setData] = useState(null);    
    const [loaded, setLoaded] = useState(true);
    const [report, setReport] = useState(null);
    const [reports, setReports] = useState([]);
    const [tradeArea, setTradeArea] = useState(null);
    const [tradeAreas, setTradeAreas] = useState([]);    
    const [targetTradeArea, setTargetTradeArea] = useState(null);

    quickReports = {
        update: (o) =>{
            
            var currentView = {
                id: 'current_view',
                group: translate('map'),
                Name: translate('current_view'),
                Type: constants.tradeAreas.types.userDrawn,
                CenterLat: 0,
                CenterLon: 0,
                EncodedPoints: null,
                GeographyIds: [],
                GeographyVintageId: -1,
                Interval: 0,
                LengthMeasurement: constants.lengthMeasurements.miles,
                PointFormat: constants.demographicShape.encodedString
            };
            
            var shapes = [currentView];

            map.layers.find(layer => layer.type === constants.layers.types.cosmetic).entities.filter(
                x => x.type === constants.entities.circle || 
                x.type === constants.entities.polygon || 
                x.type === constants.entities.rectangle
            ).forEach(entity => {
                shapes.push({
                    id: entity.id,
                    group: entity.layer.text,
                    Name: entity.text,
                    Type: constants.tradeAreas.types.userDrawn,
                    CenterLat: entity.location.lat,
                    CenterLon: entity.location.lon,
                    EncodedPoints: helpers.encodedLocations(entity.paths),
                    GeographyIds: [],
                    GeographyVintageId: -1,
                    Interval: 0,
                    LengthMeasurement: constants.lengthMeasurements.miles,
                    PointFormat: constants.demographicShape.encodedString
                });
            });

            map.layers.filter(layer => layer.type === constants.layers.types.tradeArea).forEach(layer => {
                shapes = shapes.concat(layer.entities.map(entity =>{

                    var type = entity.metaData.tradeAreaType === constants.tradeAreas.types.savedShape ? entity.metaData.savedTradeAreaType : entity.metaData.tradeAreaType;
                    
                    return {
                        id: entity.id,
                        group: layer.text,
                        Name: entity.text,
                        Type: type,
                        CenterLat: layer.metaData.center ? layer.metaData.center.lat : 0,
                        CenterLon: layer.metaData.center ? layer.metaData.center.lon : 0,
                        EncodedPoints: entity.metaData.wkt ? entity.metaData.wkt : helpers.encodedLocations(entity.paths),
                        GeographyIds: entity.metaData.geographyIds,
                        GeographyVintageId: entity.metaData.vintageId,
                        Interval: type === constants.tradeAreas.types.userDrawn ? 0 : entity.metaData.distance,
                        LengthMeasurement: entity.metaData.lengthMeasurement,
                        PointFormat: entity.metaData.wkt ? constants.demographicShape.wkt : constants.demographicShape.encodedString
                    }
                }));
            });

            setTradeAreas([...shapes]);            
            setTargetTradeArea(_.isObject(o) ? o.id : null);
        },
        handlePan: async (o) =>{  
            
            if (!report || tradeAreas.length === 0 || !tradeArea || !map.bounds)
                return;

            const viewChanged = _.isObject(o) ? o.viewChanged || false : false;

            var shape = tradeAreas.find(x => x.id === tradeArea);
            if (!shape)
                return;

            if (viewChanged && shape.id !== 'current_view')
                return;
    
            setLoaded(false);

            shape = _.cloneDeep(shape);
            if (shape.id === 'current_view' && shape.EncodedPoints === null)
                shape.EncodedPoints = helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest}));
            
            setData(await legacyEndpoints.service({
                name: 'GenerateChartReportTableForShape',
                parameters: {
                    aChartID: report.ID,
                    aShape: shape,
                    aZoom: map.zoom
                }
            }));

            setLoaded(true);
        }
    };

    useEffect(()=>{

        if (helpers.isOnScreen(container.current))
            quickReports.handlePan();

    }, [active]);

    useEffect(()=>{
        map.addListener({
            type: constants.listeners.viewChange,
			action: () => {
                if (helpers.isOnScreen(container.current))
                    quickReports.handlePan({ viewChanged: true });
			}
        });

        legacyEndpoints.service({
            name: 'GetAvailableChartReports',
            success: (r) => {
                setReports(r);
            }
        });
    }, []);

    useEffect(()=>{

        if (reports.length === 0)
            return;
        
        setReport(reports[0]);
        quickReports.update();

    }, [reports]);

    useEffect(()=>{

        if (tradeAreas.length === 0)
            return;
                
        // Retain the currently selected trade area if it still exists
        var setDefaultTA = true;
        if (_.isString(tradeArea))
            setDefaultTA = !_.isObject(tradeAreas.find(x => x.id === tradeArea));

        if (setDefaultTA)
            setTradeArea(tradeAreas[0].id);
        else if (helpers.isOnScreen(container.current) && tradeArea === targetTradeArea)
            quickReports.handlePan();

    }, [targetTradeArea, tradeArea, tradeAreas]);

    useEffect(()=>{

        if (helpers.isOnScreen(container.current))
            quickReports.handlePan();

    }, [report, tradeArea]);    

    return <div className='app-quick-reports'>
        <DropDown display='Name' items={reports} selected={report} label={translate('select_report')} onChange={(e)=>{
            setReport(e.value);
        }}/>
        <DropDown display='Name' valueProperty='id' items={tradeAreas} grouped={true} selected={tradeArea} label={translate('trade_area')} onChange={(e)=>{
            setTradeArea(e.value);
        }}/>
        <div ref={container} className='app-quick-reports-description'>{report?.Description}</div>
        <div className='app-quick-reports-source'>{report?.DemographicSource}</div>
        {(
            report === null || report.Type === constants.quickReports.types.table ? null :
            report.Type === constants.quickReports.types.pie ?
            <PieChart data={data?.Values.map((value,i)=>{return { field: data.Fields[i], value: parseFloat(value) };})} width={270} />
            : <BarChart data={data?.Values.map((value,i)=>{return { field: data.Fields[i], value: parseFloat(value) };})} width={270} />
        )}
        {(
            data === null ? '' : <SimpleTable headers={[data.FieldTitle, data.ValueTitle]} data={data.Values.map((value,i)=>{return([data.Fields[i],value]);})} />
        )}
        { loaded ? '' : <Loader /> }
    </div>
}