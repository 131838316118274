// App imports 
import { map } from '../components/app/map/map'; 
import { layerActions } from '../components/app/layers/layer/layer';
import { constants } from '../utils/constants';
import { activityHub } from '../components/app/activityHub/activityHub';
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { icons } from '../components/base/icon/icon';
import { competitiveInsights } from './competitiveInsights';
import { bulkInfo } from './bulkInfo';
import { Button } from '../components/base/button/button';
import { MenuButton } from "../components/base/menuButton/menuButton";
import { ciFilter } from '../components/app/mobilityData/mobilityData';
import { translate } from '../utils/translation';
import { ButtonGroup } from '../components/base/buttonGroup/buttonGroup';
import { InfoForm } from '../components/app/forms/infoForm/infoForm';
import { EditForm } from '../components/app/forms/editForm/editForm';
import { customerMaps } from './customerMaps';
import { projections } from './projections';
import { ShapeDownloader } from '../components/app/shapeDownloader/shapeDownloader';
import { selections } from './selections';
import { ProjectionEditForm } from '../components/app/projections/projectionEditForm';
import { userPreferences } from '../components/app/app';
import { modelWizard } from './modelWizard';
import { successToast, warningToast } from '../components/base/toast/toast';
import { filters } from '../components/app/filters/filters';
import { filtersModule } from './filters';
import { CompetitiveInsightsReportObject } from '../components/app/competitiveInsightsReportObject/competitiveInsightsReportObject';

const _ = require("lodash");

export const sources = {
	entityMouseInTimeout: null,
	entityMouseOutTimeout: null,
    get: async () => {
        return await legacyEndpoints.service({ name: 'GetPointServices' });
    },
	getPointInformation: async (o) =>{
		
		var fromTask = _.isBoolean(o.fromTask) && o.fromTask;

		if (o.entity.layer.metaData.isCompetitiveInsights === true)
			return await competitiveInsights.getCompetitiveInsightsInformation(o);              

		if (fromTask)
			return await legacyEndpoints.service({
				name:'GetFormInformation',
				parameters: {
					formId: o.entity.layer.id,
					pointId:  o.entity.id
				}
			});
		else
			return await legacyEndpoints.service({
			name:'GetCustomQueryInformation',
			parameters: {
				customQueryId: o.entity.layer.id,
				pointId:  o.entity.id
			}
		});

	},
	getLayers: () =>{
		return map.layers.filter(layer => layer.type === constants.layers.types.point);
	},
    getSelectedIds: () => {
        return sources.getLayers().map(layer => {return layer.id.toLowerCase();});
    },
	getActiveLayers: () =>{
		return sources.getLayers().filter(layer => layer.active);
	},
	mapLayerToService: (o) =>{
		return {
			CompetitiveInsightsChannels: _.isArray(o.layer.data.competitiveInsightsFilteredChannels) && o.layer.data.competitiveInsightsFilteredChannels.length > 0 ? 
				o.layer.data.competitiveInsightsFilteredChannels : null,
			Id: o.layer.id,
			IsCompetitiveInsights: o.layer.data.isCompetitiveInsights,
			MaxZoom: -1,
			Name: o.layer.text,
			DataType: o.layer.subType,
			PointDataSourceFilterList: _.map(o.layer.data.pointDataSourceFilterList, 'id') ?? [],
			Cluster: o.layer.metaData?.serviceAttributes?.Cluster ?? null,
			HiddenGroups: {
				idList: o.layer.oppositeVisibilityEntities,
				hide: o.layer.visible
			}
		};
	},
	createLabel: (layer, entity, style, draggable=true) =>{
		
		const id = helpers.newGuid();

		var svgLabel = helpers.createSvgLabel({
			text: entity.label,
			label: style ?? layer.metaData.serviceAttributes.MapLabel,
			parentWidth: entity.width,
			parentHeight: entity.height
		});

		var newLocation =  entity.location;
		layer.dirtyLabels = _.isArray(layer.dirtyLabels) ? layer.dirtyLabels : [];
		var savedLabel = layer.dirtyLabels.find(label => label.entityId === entity.id);
		if (_.isObject(savedLabel)) {
			savedLabel.id = id;
			
			var pixel = map.latLonToXY(savedLabel.location);
			pixel.y -= savedLabel.anchor.y / 2;
			newLocation = map.xyToLatLon(pixel);
		}

		var line = layer.addEntity({
			type: constants.entities.polyline,
			subType: constants.entities.label,
			paths: [newLocation, entity.location],
			visible: entity.visible,
			metaData: {
				parentId: entity.id
			}
		});

		layer.addEntity({
			id: id,
			type: constants.entities.label,
			location: savedLabel ? savedLabel.location : entity.location,
			image: svgLabel.icon,
			anchor: svgLabel.anchor,
			visible: entity.visible,
			draggable: draggable,
			dirty: savedLabel ? true : false,
			metaData: {
				parentId: entity.id
			},
			listeners: [{
				type: constants.listeners.drag,
				action: (e) =>{
					
					e.entity.dirty = true;
					var pixel = map.latLonToXY(e.entity.location);
					pixel.y -= e.entity.anchor.y / 2;
					var newLocation = map.xyToLatLon(pixel);

					var savedLabel = layer.dirtyLabels.find(label => label.entityId === entity.id);

					if (savedLabel) {
						savedLabel.location = e.entity.location;
						savedLabel.anchor = e.entity.anchor;
					}
					else {
						layer.dirtyLabels.push({ id: id, entityId: entity.id, location: e.entity.location, anchor: e.entity.anchor });
					}
					
					line.paths = [newLocation, entity.location];
				}
			}]
		});
	},
    refresh: (o) => {
		o = _.isObject(o) ? o : {};

        var refreshLayers = o.layers ? o.layers : sources.getActiveLayers().map((layer) =>{
            return{
                id: layer.id,
				text: layer.text,
				subType: layer.subType
            };
        });

		var openActivityHub = o.openActivityHub;

        if (refreshLayers.length === 0)
            return;

		var layersToLoad = [];
		var layersToCreate = refreshLayers.filter(x => sources.getSelectedIds().indexOf(x.id.toLowerCase()) === -1);

		if (layersToCreate.length === 0)
			layersToLoad = sources.getActiveLayers().filter(activeLayer => refreshLayers.map(refreshLayer => {return refreshLayer.id.toLowerCase();}).indexOf(activeLayer.id.toLowerCase()) !== -1);
		else
			_.reverse(layersToCreate).forEach(newLayer => {
				var layer = map.addLayer({
					id: newLayer.id,
					group: constants.layers.groups.point,
					type: constants.layers.types.point,
					text: newLayer.text,
					subType: newLayer.subType,
					active: !_.isUndefined(newLayer.active) ? newLayer.active : true,
					visible: !_.isUndefined(newLayer.visible) ? newLayer.visible : true,
					labeled: !_.isUndefined(newLayer.labeled) ? newLayer.labeled : false,
					oppositeVisibilityEntities: _.isArray(newLayer.oppositeVisibilityEntities) ? newLayer.oppositeVisibilityEntities : [],
					actions: [{
						id: layerActions.active,
						getActive: () => { return layer.active; },
						onClick: () => { layer.active = !layer.active; }
					},
					{
						id: layerActions.visible,
						getActive: () => { return layer.visible; },
						getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
						onClick: () => { 
							layer.visible = !layer.visible;

							var refresh = (layer.metaData.serviceAttributes.Cluster && layer.metaData.serviceAttributes.AllowClustering) || (layer.data.isCompetitiveInsights);
							if (refresh)
								sources.refresh({ onRefresh: o.onRefresh, layers:[layer] });
						}
					},
					{
						id: layerActions.label,
						getActive: () => { return layer.labeled; },
						getHalfActive: () => { return layer.oppositeLabeledEntities.length > 0; },
						onClick: () =>{							
							
							layer.labeled = !layer.labeled;
							layer.clearLabels();

							if (layer.labeled)
								layer.entities.forEach(entity => {
																
									if (entity.metaData.isHalo === true)
										return;

									sources.createLabel(layer, entity);
								});
						}
					},
					{
						id: layerActions.delete,
						onClick: () =>{
							layer.dispose();
							selections.refresh();
						}
					}],
					onChange: (c)=>{
						if (c?.layer)
							sources.refresh({ onRefresh: o.onRefresh, layers:[layer] });
						else
							o.onRefresh({ layers: sources.getLayers() });
					},
					data: newLayer.data
				});

				layer.dirtyLabels = _.isArray(newLayer.dirtyLabels) ? newLayer.dirtyLabels : [];

				layersToLoad.push(layer);
			});

		layersToLoad.forEach(x => x.loading = true);

		legacyEndpoints.service({
			name: 'GetServicePointDataList',
			suppressError: true,
			parameters: {
				customQueryList: layersToLoad.map(layer =>{
					return sources.mapLayerToService({ layer: layer });
				}),
				northWestLat: map.bounds.northWest.lat,
				northWestLon: map.bounds.northWest.lon,
				southEastLat: map.bounds.southEast.lat,
				southEastLon: map.bounds.southEast.lon,
				zoom: map.zoom
			},
			success: (serviceLayers) =>{

				var removedLayers = 0;

				serviceLayers.filter(x => x.Valid === false).forEach(serviceLayer => {

					// For some reason this isn't being returned anymore, just remove all invalid layers for now
					//if (serviceLayer.ErrorMessage.toLowerCase() !== "Permission not available for the custom query.".toLowerCase())
						//return;
					
					var layer = sources.getLayers().find(layer => { return layer.id.toLowerCase() === serviceLayer.Attributes.ID.toLowerCase() });					
					layer.dispose();

					removedLayers++;					
				});

				if (removedLayers > 0 && !helpers.isViewer())
					warningToast(translate('invalid_layers_removed'));

				serviceLayers.filter(x => x.Valid === true).forEach(serviceLayer => {
					var layer = sources.getLayers().find(layer => { return layer.id.toLowerCase() === serviceLayer.Attributes.ID.toLowerCase() });

					if (serviceLayer.IsCompetitiveInsights && serviceLayer.CompetitiveInsightsFilteredChannels === null)
					{
						layer.hasData = false;
						layer.loading = false;
						ciFilter.show();
						return;
					}

					layer.clear();

					var locations;

					if (!_.isNull(serviceLayer.EncodedPoints))
						locations = helpers.decodeLocations(serviceLayer.EncodedPoints);

					var imageWidth = 0;
					
					layer.metaData.serviceAttributes = serviceLayer.Attributes;

					const getEntityVisibility = (groupId, cluster) =>{ return (_.isBoolean(cluster) && cluster) ? true : (layer.oppositeVisibilityEntities.indexOf(groupId) > -1 ? !layer.visible : layer.visible); };

					const getEntityLabeled = (groupId) =>{ return layer.oppositeLabeledEntities.indexOf(groupId) > -1 ? !layer.labeled : layer.labeled; };

					const getCompetitiveInsightsVisibilty = (o) =>{

						switch(o.index)
						{
							case constants.competitiveInsights.indexGrade.chainIndexGrade:
								o.grade = o.metaData.chainIndexGrade;
								break;
							case constants.competitiveInsights.indexGrade.chainMarketIndexGrade:
								o.grade = o.metaData.chainMarketIndexGrade;
								break;
							case constants.competitiveInsights.indexGrade.channelIndexGrade:
								o.grade = o.metaData.channelIndexGrade;
								break;
							case constants.competitiveInsights.indexGrade.channelMarketIndexGrade:
								o.grade = o.metaData.channelMarketIndexGrade;
								break;
						}						

						return getEntityVisibility(o.groupId, o.cluster) && layer.secondaryOppositeVisibilityEntities.indexOf(o.grade) === -1;
					};

					var legendGroups = {};
					
					if (layer.active)
						serviceLayer.Points.forEach((point, i)=> {
						var pointName = _.isString(point.InfoboxTitle) && point.InfoboxTitle.length > 0 ? point.InfoboxTitle : point.Name;
						var entity;

							if(imageWidth < point.Width)
								imageWidth = point.Width;

							if (!legendGroups[point.GroupId])
								legendGroups[point.GroupId] = [];

						if (point.Polygon)
						{
							entity = layer.addEntity({
								groupId: point.GroupId,
								id: point.ID,
								text: pointName,
								label: point.Label,
								type: point.Polygon.polygonString.toLowerCase().startsWith('LINESTRING') || point.Polygon.polygonString.toLowerCase().startsWith('MULTILINESTRING') ? constants.entities.polyline : constants.entities.polygon,
								paths: helpers.parseWkt(point.Polygon.polygonString),
								fillColor: point.FillColor,
								strokeColor: point.StrokeColor,
								strokeWidth: _.isNumber(point.StrokeWidth) ? point.StrokeWidth : constants.polygons.defaults.strokeWidth,
								visible: getEntityVisibility(point.GroupId, point.IsCluster),
								labeled: getEntityLabeled(point.GroupId),
								onMove: (entity) => {sources.updateShape({serviceId: serviceLayer.Attributes.ID, shapeId: point.ID, encodedString: helpers.encodedLocations(entity.paths)})},
								onReshape: (entity) => {sources.updateShape({serviceId: serviceLayer.Attributes.ID, shapeId: point.ID, encodedString: helpers.encodedLocations(entity.locations)})},
								listeners: [{
									type: constants.listeners.click,
									action: (e) =>{ sources.showActivityHub({ data: e.entity, type: 'entity' }); }
								},
								{
									type: constants.listeners.mouseIn,
									action: (e) =>{
										sources.showHoverTooltip(e.entity);
									}
								},
								{
									type: constants.listeners.mouseOut,
									action: (e) =>{
	
										if (sources.entityMouseInTimeout !== null)
											clearTimeout(sources.entityMouseInTimeout);
	
										if (sources.entityMouseOutTimeout !== null)
											clearTimeout(sources.entityMouseOutTimeout);
	
										sources.entityMouseOutTimeout = setTimeout(() =>{
											map.hideEntityTooltip();
										}, 750);
									}
								}]
							})
						}
						else
						{
							var entityProperties = {
								groupId: point.GroupId,
								id: point.ID,
								text: pointName,
								type: point.IsCluster ? constants.entities.cluster : constants.entities.point,
								label: point.Label,
								location: null,
								image: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: point.SymbolURL, symbolType: point.SymbolType }),
								anchor: { x: point.Width / 2 , y: point.Height / 2 },
								width: point.Width,
								height: point.Height,
								visible: serviceLayer.IsCompetitiveInsights ? getCompetitiveInsightsVisibilty({ groupId: point.GroupId, index: layer.metaData.currentIndex, metaData: point.CompetitiveInsightsMetaData, cluster: point.IsCluster }) : getEntityVisibility(point.GroupId, point.IsCluster),
								labeled: getEntityLabeled(point.GroupId),
								onMove: (entity) => {sources.movePoint(entity)},
								metaData: {
									competitiveInsights: point.CompetitiveInsightsMetaData
								},
								listeners: [{
									type: constants.listeners.click,
									action: (e) =>
									{
										if (!point.IsCluster)
											sources.showActivityHub({ data: e.entity, type: 'entity' });
									}
								},
								{
									type: constants.listeners.mouseIn,
									action: (e) =>
									{
										if (!point.IsCluster)
											sources.showHoverTooltip(e.entity);
									}
								},
								{
									type: constants.listeners.mouseOut,
									action: (e) =>{
	
										if (sources.entityMouseInTimeout !== null)
											clearTimeout(sources.entityMouseInTimeout);
	
										if (sources.entityMouseOutTimeout !== null)
											clearTimeout(sources.entityMouseOutTimeout);
	
										sources.entityMouseOutTimeout = setTimeout(() =>{
											map.hideEntityTooltip();
										}, 750);
									}
								}]
							};

							entityProperties.location = locations[i];
							entity = layer.addEntity(entityProperties);
						}

						if (_.isObject(openActivityHub) && openActivityHub.layerId === entity.layer.id && openActivityHub.entityId === entity.id)
							sources.showActivityHub({ data: entity, type: 'entity' });				

						if (serviceLayer.IsCompetitiveInsights && _.isObject(entity))
						{			
							entity.metaData.halo = competitiveInsights.createHalo({
								groupId: point.GroupId,								
								location: locations[i],
								anchor: { x: point.Width / 2 , y: point.Height / 2 },
								layer: layer,
								zIndex: entity.zIndex - 1,
								size: point.Width,
								grade: competitiveInsights.getGradeByIndexGrade(layer.metaData.currentIndex, point.CompetitiveInsightsMetaData),
								visible: getCompetitiveInsightsVisibilty({ groupId: point.GroupId, index: layer.metaData.currentIndex, metaData: point.CompetitiveInsightsMetaData, cluster: point.IsCluster }),								
								metaData: {
									competitiveInsights: point.CompetitiveInsightsMetaData,
									isHalo: true
								}
							});
						}

							if (getEntityLabeled(point.GroupId))
								sources.createLabel(layer, entity);

							legendGroups[point.GroupId].push({
								text: pointName,
								onClick: () =>{		
									map.locate({ entity: entity, zoomTo: true });
								},
								actions: [{
									id: layerActions.zoom,
									onClick: () =>{									
										map.locate({ entity: entity });
									}
								}]
							});
						});

					if (serviceLayer.IsCompetitiveInsights)
					{
						const updateCompetitiveInsightsSubtext = () =>{
							if (!_.isNumber(layer.metaData.currentIndex))
								layer.metaData.currentIndex = constants.competitiveInsights.indexGrade.chainIndexGrade;

							switch(layer.metaData.currentIndex)
							{
								case constants.competitiveInsights.indexGrade.chainIndexGrade:
									layer.subText = translate('vs_entire_chain');
									break;
								case constants.competitiveInsights.indexGrade.chainMarketIndexGrade:
									layer.subText = translate('vs_chain_in_market');
									break;
								case constants.competitiveInsights.indexGrade.channelIndexGrade:
									layer.subText = translate('vs_entire_channel');
									break;
								case constants.competitiveInsights.indexGrade.channelMarketIndexGrade:
									layer.subText = translate('vs_channel_in_market');
									break;
							}
						};

						updateCompetitiveInsightsSubtext();

						layer.metaData.isCompetitiveInsights = true;
						layer.metaData.channels = serviceLayer.CompetitiveInsightsChannels;

						layer.toolbars = [{
							actions: ['A', 'B', 'C', 'D', 'F'].map((grade) =>{

								var buttonState = layer.secondaryOppositeVisibilityEntities.indexOf(grade) === -1 ? grade : 'inactive';

								return <Button key={grade} theme='simple' icon={icons.circle} text={grade} className={`app-legend-layer-toolbar-action app-legend-layer-competitive-insights-grade-${buttonState}`} onClick={(e, button)=>{
									
									if (layer.secondaryOppositeVisibilityEntities.indexOf(grade) > -1)
										layer.secondaryOppositeVisibilityEntities = layer.secondaryOppositeVisibilityEntities.filter(x => x !== grade);
									else
										layer.secondaryOppositeVisibilityEntities.push(grade);
									
									layer.entities.forEach(entity => entity.visible = getCompetitiveInsightsVisibilty({ groupId: entity.groupId, index: layer.metaData.currentIndex, metaData: entity.metaData.competitiveInsights }));

									button.setClass({
										className: `app-legend-layer-toolbar-action app-legend-layer-competitive-insights-grade-${layer.secondaryOppositeVisibilityEntities.indexOf(grade) === -1 ? grade : 'inactive'}`
									});
								}} />
							})
						},
						{
							actions: [
								<Button className='app-legend-layer-toolbar-action' theme='simple' icon={icons.filterList} disabled={!serviceLayer.IsCompetitiveInsights} onClick={()=>{ciFilter.show();}} />,
								<ButtonGroup buttons={[
										{index: constants.competitiveInsights.indexGrade.chainIndexGrade, name: translate('vs_entire_chain'), icon: icons.infoCircle, isDefault: constants.competitiveInsights.indexGrade.chainIndexGrade === layer.metaData.currentIndex },
										{index: constants.competitiveInsights.indexGrade.channelIndexGrade, name: translate('vs_entire_channel'), icon: icons.infoCircle, isDefault: constants.competitiveInsights.indexGrade.channelIndexGrade === layer.metaData.currentIndex},
										{index: constants.competitiveInsights.indexGrade.chainMarketIndexGrade, name: translate('vs_chain_in_market'), icon: icons.infoCircle, isDefault: constants.competitiveInsights.indexGrade.chainMarketIndexGrade === layer.metaData.currentIndex},
										{index: constants.competitiveInsights.indexGrade.channelMarketIndexGrade, name: translate('vs_channel_in_market'), icon: icons.infoCircle, isDefault: constants.competitiveInsights.indexGrade.channelMarketIndexGrade === layer.metaData.currentIndex}
									]}
									activeOnClick={true}
									theme={'simple'}
									onClick={(item) => {

											layer.metaData.currentIndex = item.index;

											updateCompetitiveInsightsSubtext();

											layer.entities.forEach(entity => {												
												entity.visible = getCompetitiveInsightsVisibilty({ groupId: entity.groupId, index: layer.metaData.currentIndex, metaData: entity.metaData.competitiveInsights });
												if (_.isObject(entity.metaData.halo))
													entity.metaData.halo.image = competitiveInsights.getHalo({
														size: entity.width,
														grade: competitiveInsights.getGradeByIndexGrade(item.index, entity.metaData.competitiveInsights)
													}).image;
											});
										}
									}
								 />
							]
						}];
					}
					else 
					{
						if (_.isArray(layer.data.pointDataSourceFilterList) && layer.data.pointDataSourceFilterList.length > 0)
							layer.subText = translate('filtered') + ': ' + _.map(layer.data.pointDataSourceFilterList, 'name').join(', ');
						else
							layer.subText = '';

						if (layer.metaData.serviceAttributes.Cluster && layer.metaData.serviceAttributes.AllowClustering)
							layer.subText = translate('clustered');

						layer.toolbars = [{
							actions: [
								<Button className='app-legend-layer-toolbar-action' theme='simple' icon={icons.halfCircle} disabled={true} onClick={()=>{}} />,
								<MenuButton className='app-legend-layer-toolbar-action' theme='simple' icon={icons.filterList} disabled={false} itemsDataSource={async() => {
									var result = await filtersModule.getPinnedPointDataSourceFilters ({
										dataSourceId: serviceLayer.Attributes.DataSourceId
									});
									var items = [];

									_.each(result?.filterList, function(databoundItem, i) {
										if (i === 0)
											items.push({header: true, text: translate('filters')});

										var item = _.filter(layer.data.pointDataSourceFilterList, function(f) { return f.id === databoundItem.id.toUpperCase(); });

										items.push({
											icon: icons.database, text: databoundItem.name, selected: (item.length > 0), onClick: async(e) =>
											{
												var item = _.filter(layer.data.pointDataSourceFilterList, function(f) { return f.id === databoundItem.id.toUpperCase(); });

												if (item.length > 0)
												{
													layer.data.pointDataSourceFilterList = _.without(layer.data.pointDataSourceFilterList, _.first(item));
													e.item.selected = false;
												}
												else
												{
													layer.data.pointDataSourceFilterList.push({ id: databoundItem.id.toUpperCase(), name: databoundItem.name });
													e.item.selected = true;
												}

												sources.refresh();
											}
										});

										if (layer.data.pointDataSourceFilterList.includes(databoundItem.id.toUpperCase()))
											databoundItem.selected = true;
									});

									if (result?.isFilterable)
									{
										// Add Create Filter
										if (items.length > 0)
											items.push({header: true, text: translate('manage')});

										items.push({
											icon: icons.gear, text: translate('manage_filters'), onClick: async(e) =>
											{
												filters.setContent({
													title: serviceLayer.Attributes.Name,
													dataSourceId: serviceLayer.Attributes.DataSourceId,
													customQueryId: serviceLayer.Attributes.ID
												});
											}
										});
									}
									else
										items.push({ text: translate('no_filters_available'), disabled: true });

									return items;
								}} />,
								<Button className='app-legend-layer-toolbar-action' theme='simple' icon={icons.folderOpen} disabled={true} onClick={()=>{}} />,
								<Button className='app-legend-layer-toolbar-action' theme='simple' icon={icons.pin} disabled={true} onClick={()=>{}} />,
								<Button className='app-legend-layer-toolbar-action' theme='simple' tooltip={translate('bulk_info')} icon={icons.rectangleList} disabled={!layer.metaData.serviceAttributes.AllowBulkInfo} onClick={()=>{									
									bulkInfo.generateBulkInfo({ title: layer.text, customQueryId: layer.id }); 
								}} />,
								<Button className='app-legend-layer-toolbar-action' theme='simple' tooltip={translate('bulk_edit')} icon={icons.edit} disabled={!layer.metaData.serviceAttributes.AllowBulkEdit} onClick={()=>{									
									bulkInfo.generateBulkEdit({ title: layer.text, customQueryId: layer.id }); 
								}} />,
								<Button className='app-legend-layer-toolbar-action' theme='simple' tooltip={translate('cluster_points')} icon={icons.gridRound} disabled={!layer.metaData.serviceAttributes.AllowClustering} onClick={()=>{
									layer.metaData.serviceAttributes.Cluster = !layer.metaData.serviceAttributes.Cluster;
									sources.refresh({ layers: [layer] });

									if (layer.metaData.serviceAttributes.Cluster)
										layer.subText = 'Clustered';
									else
										layer.subText = '';
								}} />
							]
						}];
					}

					layer.text = serviceLayer.Attributes.LayerName;
					layer.hasData = serviceLayer.PointsExist;
					layer.outOfRange = (serviceLayer.Points.length === 0 && serviceLayer.PointsExist && layer.visible);
					layer.parentId = serviceLayer.Attributes.DataSourceId;

					if (layer.active)
						layer.legend = serviceLayer.Legend.map(legend =>{
							return {
								groupId: legend.GroupId,
								text: legend.Name,
								icon: legend.SymbolHeight > 0 ? legacyEndpoints.handlers.getSymbolUrl({ imageUrl: legend.Symbol, symbolType: legend.SymbolType }) : null,
								color: legend.SymbolHeight === 0 ? legend.FillColor : null,
								iconWidth: imageWidth,
								items: legendGroups[legend.GroupId],
								actions: [{
									id: layerActions.visible,
									getActive: () => { return getEntityVisibility(legend.GroupId); },
									onClick: () =>{

										if (layer.oppositeVisibilityEntities.indexOf(legend.GroupId) > -1)
											layer.oppositeVisibilityEntities = layer.oppositeVisibilityEntities.filter(x => x !== legend.GroupId);
										else
											layer.oppositeVisibilityEntities.push(legend.GroupId);	

										layer.entities.filter(x => x.groupId === legend.GroupId).forEach(x => x.visible = serviceLayer.IsCompetitiveInsights ? getCompetitiveInsightsVisibilty({ groupId: x.groupId, index: layer.metaData.currentIndex, metaData: x.metaData.competitiveInsights }) : getEntityVisibility(x.groupId));

										sources.refresh();
									}
								},
								{
									id: layerActions.label,
									getActive: () => { return getEntityLabeled(legend.GroupId); },
									onClick: () =>{

										if (layer.oppositeLabeledEntities.indexOf(legend.GroupId) > -1)
											layer.oppositeLabeledEntities = layer.oppositeLabeledEntities.filter(x => x !== legend.GroupId);
										else
											layer.oppositeLabeledEntities.push(legend.GroupId);

										layer.entities.filter(x => x.groupId === legend.GroupId && x.visible).forEach(x => x.labeled = getEntityLabeled(legend.GroupId));

										sources.refresh();
									}
								}]
							};
						});

					layer.loading = false; 
				});
				
				if (_.isFunction(o.onComplete))
					o.onComplete();
			}
		});
    },
	showHoverTooltip: (entity) =>{

		if (selections.active)
			return;

		if (sources.entityMouseInTimeout !== null)
			clearTimeout(sources.entityMouseInTimeout);

		if (sources.entityMouseOutTimeout !== null)
			clearTimeout(sources.entityMouseOutTimeout);

		sources.entityMouseInTimeout = setTimeout(() =>{
			map.showEntityTooltip({
				pixel: map.latLonToXY(entity.location),
				title: entity.text,
				text: entity.label
			});
		}, 750);
	},
	showActivityHub: async (o) =>
	{
		const entity = o.data;
		var renderContent = async () =>{ return []; };

		switch(o.type)
		{
			default:
				if (selections.active){
					selections.selectEntity(entity);
					return;
				}
				else if (modelWizard.currentListener !== null)
				{
					modelWizard.currentListener(entity);
					return;
				}
		
				map.hideEntityTooltip();
		
				renderContent = async () => 
				{
					var information = await sources.getPointInformation({ entity: entity });				

					var tabs = [{ id: constants.activityHub.actions.information, component: <InfoForm customQueryId={entity.layer.id} pointId={entity.id} form={information.Form} /> }];
					
					if (entity.layer.metaData.serviceAttributes.AllowDelete)
						tabs = [...tabs,  { id: constants.activityHub.actions.delete, await: true, onClick: async () => {
	
								await legacyEndpoints.service({
									name: 'DeletePointV2',
									parameters: { aServiceID: entity.layer.id, aPointID: entity.id }
								});
	
								entity.dispose();
								activityHub.close();
								sources.refresh();
								successToast(translate('success'));
							}  
						}];
	
					if (entity.layer.metaData.serviceAttributes.AllowEdit)
						tabs = [...tabs,  { id: constants.activityHub.actions.edit, component: <EditForm customQueryId={entity.layer.id} pointId={entity.id} onClose={() => {activityHub.close();}}/> }];
							
					if (entity.layer.metaData.serviceAttributes.AllowMove)
						tabs = [...tabs, { id: constants.activityHub.actions.move, icon: icons.move, tooltip: translate('move'), onClick: ()=>{ entity.move(); activityHub.close();}}];

					if (entity.layer.metaData.serviceAttributes.AllowFileManagement)
						tabs = [...tabs,  { id: constants.activityHub.actions.multimedia }];
			
					if (entity.layer.metaData.serviceAttributes.AllowCommentView)
						tabs = [...tabs,  { id: constants.activityHub.actions.comments }];
			
					if (entity.layer.metaData.serviceAttributes.AllowPhotoView)
						tabs = [...tabs,  { id: constants.activityHub.actions.photos }];
			
					if (userPreferences.AllowMapBookManagement)	
						tabs = [...tabs, { id: constants.activityHub.actions.mapBooks, component: <div></div> }];
	
					if (entity.layer.metaData.serviceAttributes.AllowTasks)
						tabs = [...tabs,  { id: constants.activityHub.actions.task }];
						

					switch(entity.type)
					{
						case constants.entities.point:
	
							tabs = [...tabs,
								{ id: constants.activityHub.actions.tradeAreas },
								{ id: constants.activityHub.actions.drivingDirections }
							];
	
							if (entity.layer.metaData.serviceAttributes.AllowGeoFenceManagement || entity.layer.metaData.isCompetitiveInsights === true)
								tabs = [...tabs, { id: constants.activityHub.actions.geofences }];
	
							if (entity.layer.metaData.isCompetitiveInsights === true)
								tabs = [...tabs, { id: constants.activityHub.actions.reportIncorrectCompetitiveInsightsObject, component: <CompetitiveInsightsReportObject type={constants.competitiveInsights.reportedObjectTypes.point} entity={entity} onClose={()=>{activityHub.close();}} /> }];						
	
							if (entity.layer.metaData.serviceAttributes.AllowTrip2Trade)
								tabs = [...tabs,  { id: constants.activityHub.actions.trip2Trade }];
	
							const getCustomerMapIcon = (type) => {
								switch (type){
									default:
										return icons.peopleGroup;
									case constants.customerDataRenderers.pin:
										return icons.braille;
									case constants.customerDataRenderers.desireLine:
										return icons.bars;
									case constants.customerDataRenderers.range:
										return icons.grid;
									case constants.customerDataRenderers.heat:
										return icons.fire;
								}
							};
	
							if (information.AvailableCustomerMaps.length > 0)
								tabs = [...tabs, { 
									id: constants.activityHub.actions.customerMaps, 
									items: information.AvailableCustomerMaps.map(item => {
										return { 
											id: `${constants.activityHub.actions.customerMaps}_${item.Id}`, 
											text: item.Name,
											icon: getCustomerMapIcon(item.Type),
											onClick: () => { 
												customerMaps.refresh({ id: item.MapID, type: item.Type, name: item.Name, pointId: item.PointID, isTile: item.IsTile, icon: getCustomerMapIcon(item.Type) });
												activityHub.close();
											}
										}
									}) 
								}];
	
							if (information.InfoboxActionButtons.length > 0)
								tabs = [...tabs, ...(information.InfoboxActionButtons.map(action => { 
									return { 
										id: constants.activityHub.actions.customActionButton,
										customButton: action
									}
								}))];
	
							if (information.Projections.length > 0)
								tabs = [...tabs, { 
									id: constants.activityHub.actions.projections, 
									items: _.sortBy(information.Projections, ['hasRecaptureModel', 'type']).map(projection => {
	
										var item = { 
											id: `${constants.activityHub.actions.projections}_${projection.id}`, 
											text: projection.name,
											icon: projections.getIcon(projection)
										};
	
										switch (projection.type)
										{
											default:
												
												if (projection.layers.length === 0 || (projection.layers.length > 0 && projection.layers[0].editFormId === helpers.emptyGuid())
													|| projection.models.filter(x => x.type === constants.projections.models.disaggregateRecapture).length > 0)
													item.onClick = () => { 
														
														activityHub.close();
	
														projections.generate({
															id: projection.id,
															name: projection.name,
															type: projection.type,
															customQueryId: entity.layer.id,
															pointId: entity.id,
															location: entity.location,
															entity: entity
														});
													};
												else
													item.component = <ProjectionEditForm projection={projection} entity={entity} />;

												break;
											case constants.projections.relocation:
												item.onClick = () => { 																								
													projections.startRelocation({
														projection: projection,
														entity: entity
													});
													activityHub.close();
												};
												break;
										}
	
										
	
										return item;
									}) 
								}];

							if (entity.layer.metaData.isCompetitiveInsights === true)
								tabs = [...tabs, 
									{ id: constants.activityHub.actions.competitiveInsightsDashboards},
									{ id: constants.activityHub.actions.competitiveInsightsVisitTradAreas, items: competitiveInsights.getCompetitiveInsightsVisitsOptions({ entity: entity }) },
									{ id: constants.activityHub.actions.competitiveInsightsPolygonTradeAreas, items: competitiveInsights.getCompetitiveInsightsPolygonTradeAreaOptions({ entity: entity }) }
								];
			
							return tabs;
						case constants.entities.polygon:
	
							if (entity.hasMultiDimensionalPaths || entity.type === constants.entities.polyline)
								return tabs;
	
							if (entity.layer.metaData.serviceAttributes.AllowMove)
								tabs = [...tabs, { id: constants.activityHub.actions.reshape, icon: icons.reshape, tooltip: translate('reshape'), onClick:()=>{entity.reshape(); activityHub.close();}}];

							tabs = [...tabs, 
								{ id: constants.activityHub.actions.select },
								{ id: constants.activityHub.actions.demographics },
								{ id: constants.activityHub.actions.download, component: <ShapeDownloader entity={entity} /> }
							];
							
							return tabs;
						case constants.entities.cluster: // TEMP
							return tabs;
					}
				}
				break;
			case 'cluster':
				renderContent = async () => 
				{
					return [{ id: constants.activityHub.actions.information, component: <InfoForm form={o.information} /> }];
				};
				break;	
		}
		
		activityHub.open({
            entity: entity,
			type: o.type,
            renderContent: renderContent
		});
	},
	movePoint: async (entity) => {
		await legacyEndpoints.service({
			name: 'MovePointV2',
			parameters: {
				aServiceID: entity.layer.id,
				aPointID: entity.id,
				aNewLat: entity.location.lat.toFixed(6),
				aNewLon: entity.location.lon.toFixed(6)
			}
		});

		sources.refresh();
	},
	updateShape: async (o) => {
		await legacyEndpoints.service({
			name: 'UpdateShape',
			parameters: {
				serviceId: o.serviceId,
				shapeId: o.shapeId,
				shape: {
					polygonString: o.encodedString,
					polygonType: constants.encodedString.google
				}
			}
		});

		sources.refresh();
	},
	clusterClickHandler: (event, cluster, map) =>{
		var data = [];
	
		_.each(cluster.markers, function(marker) {
			var dataSource = _.find(data, { id: marker.parent.layer.id });

			if (!_.isObject(dataSource))
			{
				dataSource = { id: marker.parent.layer.id, text: marker.parent.layer.text, chains: [] };
				data.push(dataSource);
			}

			var chain = _.find(dataSource.chains, { id: marker.parent.groupId });

			if (!_.isObject(chain))
			{
				var legendItem = _.find(marker.parent.layer.legend, { groupId: marker.parent.groupId });

				chain = { id: marker.parent.groupId, text: legendItem.text, icon: legendItem.icon, iconWidth: legendItem.iconWidth, points: [] };
				dataSource.chains.push(chain);
			}

			chain.points.push({ id: marker.parent.id, label: marker.parent.label, text: marker.parent.text });
		});

		_.each(data, function(dataSource) {
			_.each(dataSource.chains, function(chain) {
				chain.points = _.sortBy(chain.points, ['label', 'text']);
			});

			dataSource.chains = _.sortBy(dataSource.chains, 'text');
		});

		data = _.sortBy(data, 'text');		

		var form = {
			Id: helpers.newGuid(),
			Type: 1,
			TabsActive: true,
			TabDisplay: 1,
			Tabs: [{
				Id: helpers.newGuid(),
				ImageId: null,
				ImageType: 0,
				Title: '',
				Pages: [{
					Id: helpers.newGuid(),
					Sections: []
				}],
				hidden: false
			}]
		};

		_.each(data, function(dataSource) {
			var section = {
				Id: helpers.newGuid(),
				Title: dataSource.text,
				Rows: [],
				hidden: false,
				isCustom: false
			}
			
			_.each(dataSource.chains, function(chain) {
				section.Rows.push({
					Id: helpers.newGuid(),
					Fields: [{
						Id: helpers.newGuid(),
						ValueStyle: {
							"Id": helpers.newGuid(),
							"Alignment": 0,
							"VerticalAlign": 4,
							"Bold": false,
							"Italic": false,
							"Underline": false,
							"Strikeout": false,
							"Font": "Sans-serif",
							"Size": 8,
							"Color": {
								"Id": helpers.newGuid(),
								"Red": 48,
								"Green": 48,
								"Blue": 48,
								"Alpha": 100
							},
							"Fill": {
								"Id": helpers.newGuid(),
								"Red": 229,
								"Green": 229,
								"Blue": 229,
								"Alpha": 100
							},
							"Border": {
								"Id": helpers.newGuid(),
								"Red": 204,
								"Green": 204,
								"Blue": 204,
								"Alpha": 100
							},
							"BorderStyle": 5
						},
						LabelStyle: {
							"Id": helpers.newGuid(),
							"Alignment": 0,
							"VerticalAlign": 4,
							"Bold": true,
							"Italic": false,
							"Underline": false,
							"Strikeout": false,
							"Font": "Sans-serif",
							"Size": 8,
							"Color": {
								"Id": helpers.newGuid(),
								"Red": 48,
								"Green": 48,
								"Blue": 48,
								"Alpha": 100
							},
							"Fill": null,
							"Border": null,
							"BorderStyle": 5
						},
						Merged: false,
						ShowLabel: true,
						Label: chain.text,
						Span: 1,
						hidden: false
					}]
				});

				_.each(chain.points, function(point) {
					var row = {
						Id: helpers.newGuid(),
						Fields: [{
							Id: helpers.newGuid(),
							ValueStyle: {
								"Id": helpers.newGuid(),
								"Alignment": 0,
								"VerticalAlign": 4,
								"Bold": false,
								"Italic": false,
								"Underline": false,
								"Strikeout": false,
								"Font": "Sans-serif",
								"Size": 8,
								"Color": {
									"Id": helpers.newGuid(),
									"Red": 48,
									"Green": 48,
									"Blue": 48,
									"Alpha": 100
								},
								"Fill": {
									"Id": helpers.newGuid(),
									"Red": 229,
									"Green": 229,
									"Blue": 229,
									"Alpha": 100
								},
								"Border": {
									"Id": helpers.newGuid(),
									"Red": 204,
									"Green": 204,
									"Blue": 204,
									"Alpha": 100
								},
								"BorderStyle": 5
							},
							LabelStyle: {
								"Id": helpers.newGuid(),
								"Alignment": 0,
								"VerticalAlign": 4,
								"Bold": true,
								"Italic": false,
								"Underline": false,
								"Strikeout": false,
								"Font": "Sans-serif",
								"Size": 8,
								"Color": {
									"Id": helpers.newGuid(),
									"Red": 48,
									"Green": 48,
									"Blue": 48,
									"Alpha": 100
								},
								"Fill": null,
								"Border": null,
								"BorderStyle": 5
							},
							RenderedValue: {
								fieldStyles: [],
								links: [],
								photos: [],
								value: point.text
							},
							Merged: false,
							ShowLabel: true,
							Label: point.label,
							Span: 1,
							hidden: false
						}]
					};

					section.Rows.push(row);
				});
			});

			form.Tabs[0].Pages[0].Sections.push(section);
		});

		var field = {
			Id: helpers.newGuid(),
			ValueStyle: {
				"Id": "c374e8b5-0e3c-4729-b1b2-2d38be2a8877",
				"Alignment": 0,
				"VerticalAlign": 4,
				"Bold": false,
				"Italic": false,
				"Underline": false,
				"Strikeout": false,
				"Font": "Sans-serif",
				"Size": 8,
				"Color": {
					"Id": "f5002dc9-98cc-466a-8a87-8a10464d96d9",
					"Red": 48,
					"Green": 48,
					"Blue": 48,
					"Alpha": 100
				},
				"Fill": {
					"Id": "9bcf6bde-260d-4c0b-8c8f-0d676f759dd5",
					"Red": 229,
					"Green": 229,
					"Blue": 229,
					"Alpha": 100
				},
				"Border": {
					"Id": "376d6f15-c429-4168-b18e-9b4578b4f171",
					"Red": 204,
					"Green": 204,
					"Blue": 204,
					"Alpha": 100
				},
				"BorderStyle": 5
			},
			LabelStyle: {
				"Id": "2be477b1-7199-406f-a871-c1b3515a2685",
				"Alignment": 0,
				"VerticalAlign": 4,
				"Bold": true,
				"Italic": false,
				"Underline": false,
				"Strikeout": false,
				"Font": "Sans-serif",
				"Size": 8,
				"Color": {
					"Id": "c0c68f32-4d00-4ea1-a81c-291bbf0df2d6",
					"Red": 48,
					"Green": 48,
					"Blue": 48,
					"Alpha": 100
				},
				"Fill": null,
				"Border": null,
				"BorderStyle": 5
			},
			RenderedValue: {
				fieldStyles: [],
				links: [],
				photos: [],
				value: ''
			},
			Merged: false,
			ShowLabel: true,
			Label: '',
			Span: 1,
			hidden: false
		};

		console.log(form);

		sources.showActivityHub({ 
			data:
			{
				text: cluster.marker.title,
				location: { lat: cluster.marker.position.lat(), lon: cluster.marker.position.lng() },
				type: constants.entities.point,
				anchor: cluster.marker.icon.anchor,
				width: cluster.marker.icon.size.width,
				height: cluster.marker.icon.size.height,
				paths: [],
			},
			type: 'cluster',
			information: form
		});
	}
};