// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { LargePanel } from '../../../base/largePanel/largePanel';
import { SelectableItems } from '../../../base/selectableItems/selectableItems';
import { CollapsibleSection } from '../../../base/collapsibleSection/collapsibleSection';
import { OptionSelector } from '../../../base/optionSelector/optionSelector';
import { TextBox } from '../../../base/textBox/textBox';
import { DropDown } from '../../../base/dropDown/dropDown';
import { TradeAreaDropDown } from '../../../base/tradeAreaDropDown/tradeAreaDropDown';
import { icons } from '../../../base/icon/icon';
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { translate } from "../../../../utils/translation";
import { helpers } from "../../../../utils/helpers";
import { constants } from '../../../../utils/constants';
import { reports as reportsModule } from '../../../../modules/reports';

const _ = require('lodash');

export function DemographicReports({text,reportCategory,onClose}) {

    const [items, setItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [tradeAreas, setTradeAreas] = useState([]);
    const [title, setTitle] = useState('');
    const [outputTypes, setOutputTypes] = useState([]);
    const [outputType, setOutputType] = useState(null); 
    const [tradeAreaTypes, setTradeAreaTypes] = useState([]);

    var getReportList = useCallback(
        async () =>{
            setLoaded(false);    
            
            var data = await reportsModule.getReportListForCategory({ reportCategory: reportCategory });

            var items = [];

            data.forEach((item) => {
                item.DemographicSource = translate("reports");

                var parent = items.find((val) => val.text.toLowerCase() === item.DemographicSource.toLowerCase());
                var child = {
                    id: item.ID,
                    text: item.Name,
                    type: item.Type
                };

                if (parent)
                    parent.items.push(child);
                else
                    items.push({
                        text: item.DemographicSource,
                        items: [child]
                    });
            });

            setItems([...items]);
            setLoaded(true);      
            
            if (reportCategory === constants.reportCategories.demographicComparison)                               
                setTitle(translate('demographic_comparison_report'));

            var outputTypesList= reportsModule.getOutputTypes();
            setOutputType(outputTypesList[1]);
            setOutputTypes([...outputTypesList]);        
            setTradeAreaTypes(reportCategory === constants.reportCategories.demographicQuadrant ? [constants.tradeAreas.types.ring] : null);
        },
        [reportCategory]
    );

    useEffect(() =>{
        getReportList();
    }, [reportCategory, getReportList]);

    const onSelect = (o) =>{
        o.item.selected = o.selected;
        setItems([...items]);        
    };

    const onSave = (o) =>{
         //todo have SelectableItems disable save 
         if (o.items.length === 0 || tradeAreas.length === 0) return;

        setGenerating(true);

        if (reportCategory === constants.reportCategories.demographicQuadrant) {
            o.items.forEach(item =>{          
                generateQuadrantDemoReport(item);
            });
        } 
        else {                
            reportsModule.generateDemoReports({items: o.items, title: title, tradeAreas: tradeAreas, format: outputType.id, callback: () => {setGenerating(false);}});  
        }     
    };

    const generateQuadrantDemoReport = (item) =>{
        tradeAreas.forEach(tradeArea => {                    
            legacyEndpoints.service({
                name: 'GenerateQuadrantDemoReport',
                parameters: {
                    aReportID: item.id,
                    aTitle: title,
                    aShape: tradeArea
                },
                success: (r) => {
                    helpers.navigateToUrl(legacyEndpoints.handlers.getReportUrl({
                        id: r.ReportID,
                        fileId: r.ReportFileID,
                        dictionaryId: r.ReportDictionaryID,
                        type: 'Demographics',
                        output: outputType.id === constants.reports.output.pdf ? "PDF" : "Excel"
                    }));
                    setGenerating(false);
                }
            });
        });            
    };

    const updateTitle = (o) =>{
        const uniqueNames = _.uniqBy(o, (obj) => obj.libraryName);

        setTitle(uniqueNames.map(u => u.libraryName).join(' '));

    };    
            
    const close = () =>{
        onClose();
    };

    return  <LargePanel text={text} loaded={loaded} generating={generating} onClose={() =>{ close(); }}>
        <SelectableItems
            items={items}
            selectedItems={items.map((item) => { return item.items.filter(x => x.selected) }).filter(item => item.length > 0).flat()}
            onSelect={(o) => { setItems([...o.items]); }}
            onRender={(o) => {
                return <div className='app-demographic-report-container'>
                {
                    o.items.map((group, i) => {
                        return <CollapsibleSection key={i} text={group.text} count={group.items.length} selectedCount={group.items.filter(x => x.selected).length}>
                        {
                            group.items.map((item, i) =>{
                                return <OptionSelector key={i} text={item.text} icon={icons.people} active={item.selected} onClick={() =>{ onSelect({ item: item, selected: !item.selected }); }}/>
                            })
                        }
                        </CollapsibleSection>
                    })
                }
                </div>
            }}
            onRenderFooter={(o) =>{
                return <>
                    <TradeAreaDropDown 
                        className='app-demographic-report-trade-area' 
                        maxSelections={5}
                        showCurentView={reportCategory === constants.reportCategories.demographicSummary} 
                        types={tradeAreaTypes} 
                        height='47px'
                        onChange={(o) => {
                            setTradeAreas(o)
                            updateTitle(o);
                    }}
                    />
                    <TextBox className='app-demographic-report-title' height='47px' label={translate('title')} value={title} onChange={(o) => {
                        setTitle(o.value);
                    }}/>
                    <DropDown className='app-demographic-report-output'
                        height='47px' 
                        items={outputTypes}
                        selected={outputType}
                        label={translate('format')} 
                        display='name' 
                        onSelect={(o) => {
                            setOutputType(o.value);
                        }}                       
                    />                        
                </>
            }}
            onSave={(o) =>{
                onSave(o);
            }}
            onClose={() =>{
                close();
            }}
        />
    </LargePanel>
}