// React imports
import { useEffect } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { Button } from '../../../base/button/button';
import { TextBox, inputBoxTypes } from '../../../base/textBox/textBox';
import { DropDown } from '../../../base/dropDown/dropDown';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { helpers } from '../../../../utils/helpers';

export function OptimizationCriteria({ protectionBuffer, updateProtectionBuffer, cannibalizationThreshold, updateCannibalizationThreshold, salesThreshold, updateSalesThreshold, resetOptimizationCriteria, onReadyNext }) {

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(protectionBuffer.rural != null && protectionBuffer.suburban != null && protectionBuffer.urban != null && 
                cannibalizationThreshold != null && salesThreshold.rural != null && salesThreshold.suburban != null && salesThreshold.urban != null);
                 
    }, [onReadyNext, protectionBuffer, cannibalizationThreshold, salesThreshold])

    return <>
        <div className='app-market-opt-wizard-header'>
            <Button theme='secondary' size='small' icon={icons.clockRotateLeft} tooltip={translate('reset')} 
                onClick={()=>{ resetOptimizationCriteria(); }} 
            />
        </div>
        <div className='app-market-opt-wizard-body'>
            <Bar icon={icons.protection} text={translate('protection_buffer')} opened={true}>
                <div className='app-market-opt-wizard-row'>
                    <div className='app-market-opt-label'>{`${translate('rural')}:`}</div>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox app-market-opt-distance'}
                        value={protectionBuffer.rural} 
                        type={inputBoxTypes.numeric}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateProtectionBuffer({ ...protectionBuffer, rural: o.value }); }} 
                    />
                    <DropDown                
                        className='app-market-opt-measurement'
                        selected={protectionBuffer.ruralMeasurement}
                        items={protectionBuffer.measurements}
                        display='abbreviation'
                        valueProperty="key"
                        height={'35px'}
                        onSelect={(o) => { updateProtectionBuffer({ ...protectionBuffer, ruralMeasurement: o.value.key }); }}  
                    />
                </div>
                <div className='app-market-opt-wizard-row'>
                    <div className='app-market-opt-label'>{`${translate('suburban')}:`}</div>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox app-market-opt-distance'}
                        value={protectionBuffer.suburban} 
                        type={inputBoxTypes.numeric}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateProtectionBuffer({ ...protectionBuffer, suburban: o.value }); }} 
                    />
                    <DropDown                
                        className='app-market-opt-measurement'
                        selected={protectionBuffer.suburbanMeasurement}
                        items={protectionBuffer.measurements}
                        display='abbreviation'
                        valueProperty="key"
                        height={'35px'}
                        onSelect={(o) => { updateProtectionBuffer({ ...protectionBuffer, suburbanMeasurement: o.value.key }); }}  
                    />
                </div>
                <div className='app-market-opt-wizard-row'>
                    <div className='app-market-opt-label'>{`${translate('urban')}:`}</div>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox app-market-opt-distance'}
                        value={protectionBuffer.urban} 
                        type={inputBoxTypes.numeric}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateProtectionBuffer({ ...protectionBuffer, urban: o.value }); }} 
                    />
                    <DropDown                
                        className='app-market-opt-measurement'
                        selected={protectionBuffer.urbanMeasurement}
                        items={protectionBuffer.measurements}
                        display='abbreviation'
                        valueProperty="key"
                        height={'35px'}
                        onSelect={(o) => { updateProtectionBuffer({ ...protectionBuffer, urbanMeasurement: o.value.key }); }}  
                    />
                </div>
            </Bar>
            <Bar icon={icons.cannibalization} text={translate('cannibalization_threshold')} opened={true}>
                <div className='app-market-opt-wizard-row'>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox'}
                        value={cannibalizationThreshold * 100} 
                        type={inputBoxTypes.percent}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateCannibalizationThreshold(o.value / 100); }} 
                    />
                </div>
            </Bar>
            <Bar icon={icons.sales} text={translate('sales_threshold')} opened={true}>
                <div className='app-market-opt-wizard-row'>
                    <div className='app-market-opt-label'>{`${translate('rural')}:`}</div>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox'}
                        value={salesThreshold.rural} 
                        type={inputBoxTypes.currency}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateSalesThreshold({ ...salesThreshold, rural: o.value }); }} 
                    />
                </div>
                <div className='app-market-opt-wizard-row'>
                    <div className='app-market-opt-label'>{`${translate('suburban')}:`}</div>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox'}
                        value={salesThreshold.suburban} 
                        type={inputBoxTypes.currency}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateSalesThreshold({ ...salesThreshold, suburban: o.value }); }} 
                    />
                </div>
                <div className='app-market-opt-wizard-row'>
                    <div className='app-market-opt-label'>{`${translate('urban')}:`}</div>
                    <TextBox
                        key={helpers.newGuid()}
                        className={'app-market-opt-textbox'}
                        value={salesThreshold.urban} 
                        type={inputBoxTypes.currency}
                        allowNegative={false}
                        allowDecimals={true}
                        height={'35px'}
                        onChange={(o) => { updateSalesThreshold({ ...salesThreshold, urban: o.value }); }} 
                    />
                </div>
            </Bar>
        </div>
    </>
}
