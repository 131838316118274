// React imports
import { useState, useEffect } from 'react';

// App imports
import { Bar } from '../../../base/bar/bar';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Description } from '../../../base/description/description';
import { RadioButtonSet } from '../../../base/radioButtonSet/radioButtonSet';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { constants } from '../../../../utils/constants';
import { helpers } from '../../../../utils/helpers';

const _ = require("lodash");

export function DataSelection({seedPointsFiles, seedPointsFile, updateSeedPointsFile, standardGeographies, standardGeography, updateStandardGeography, viewStandardGeography, drawnGeographies, drawnGeography, updateDrawnGeography, viewDrawnGeography, geographyMode, updateGeographyMode, seedPointCountExceeded, clearDataSelection, onReadyNext}) {

    const [geographyVintage, setGeographyVintage] = useState(null);
    const [geographyLevel, setGeographyLevel] = useState(null);

    useEffect(() => {

        if (onReadyNext)
            onReadyNext(!seedPointCountExceeded && seedPointsFile != null && 
                ((geographyMode === constants.marketOptimization.geographyModes.standard && standardGeography != null) ||
                (geographyMode === constants.marketOptimization.geographyModes.handdrawn && drawnGeography != null)))
                 
    }, [onReadyNext, seedPointsFile, standardGeography, drawnGeography, geographyMode])

    useEffect(() => {

        if (seedPointsFile == null)
            return;

        const seedFile = seedPointsFiles.find(file => file.id === seedPointsFile);
        if (_.isObject(seedFile)) {
            setGeographyVintage(seedFile.geoVintage);
            setGeographyLevel(seedFile.geoLevel);
        }
        
    }, [seedPointsFiles, seedPointsFile])

    return <>
        <div className='app-market-opt-wizard-header'>
            <Button theme='secondary' size='small' icon={icons.eraser} tooltip={translate('clear')} 
                onClick={()=>{ clearDataSelection(); }} 
            />
        </div>
        <div className='app-market-opt-wizard-body'>
            <Bar icon={icons.locationDot} text={translate('data')} opened={true}>
                <div className='app-market-opt-label'>{`${translate('seed_points_file')}:`}</div>
                <div className='app-market-opt-dropdowns'>
                    <DropDown
                        key={helpers.newGuid()}
                        items={seedPointsFiles} 
                        height={'35px'}
                        valueProperty={'id'}
                        display={'text'} 
                        selected={seedPointsFile}
                        onChange={(o) => { 
                            if (o.userChanged)
                                updateSeedPointsFile(o.value); 
                        }} 
                    />
                </div>
                <Description className='app-market-opt-inputs' description={translate('market_geography_description')} />
                <RadioButtonSet className='app-market-opt-inputs'>
                    <Button theme='secondary' size='medium' text={translate('geography')} active={geographyMode === constants.marketOptimization.geographyModes.standard} icon={icons.globe} onClick={()=>{updateGeographyMode(constants.marketOptimization.geographyModes.standard);}} />
                    <Button theme='secondary' size='medium' text={translate('hand_drawn')} active={geographyMode === constants.marketOptimization.geographyModes.handdrawn} icon={icons.reshape} onClick={()=>{updateGeographyMode(constants.marketOptimization.geographyModes.handdrawn);}} />
                </RadioButtonSet>
                { geographyMode === constants.marketOptimization.geographyModes.standard ? 
                    <>
                        <div className='app-market-opt-label'>{`${translate('standard_geography')}:`}</div>
                        <div className='app-market-opt-dropdowns'>
                            <DropDown
                                key={helpers.newGuid()} 
                                items={standardGeographies} 
                                placeholder={translate('select_standard_geography')}
                                height={'35px'}
                                valueProperty={'id'}
                                display={'text'} 
                                selected={standardGeography}
                                onChange={(o) => { 
                                    if (o.userChanged)
                                        updateStandardGeography(o.value); 
                                }} 
                                onClick={(o) => { 
                                    if (o.value.id === standardGeography)
                                        viewStandardGeography(o.value.id); 
                                }} 
                            />
                        </div>
                        { geographyVintage != null && geographyLevel != null ?
                            <Description className='app-market-opt-inputs' description={`${translate('geography_vintage')}: ${geographyVintage.name}\n${translate('geography_level')}: ${geographyLevel.name}`} />
                        : ''}
                    </>
                : geographyMode === constants.marketOptimization.geographyModes.handdrawn ?
                    <>
                        <div className='app-market-opt-label'>{`${translate('hand_drawn_shape')}:`}</div>
                        <div className='app-market-opt-dropdowns'>
                            <DropDown
                                key={helpers.newGuid()} 
                                items={drawnGeographies} 
                                placeholder={translate('select_hand_drawn_shape')}
                                height={'35px'}
                                valueProperty={'id'}
                                display={'Name'} 
                                selected={drawnGeography}
                                onChange={(o) => { 
                                    if (o.userChanged)
                                        updateDrawnGeography(o.value); 
                                }} 
                                onClick={(o) => { 
                                    if (o.value.id === drawnGeography)
                                        viewDrawnGeography(); 
                                }} 
                            />
                        </div>
                        <Description className='app-market-opt-inputs' description={translate('hand_drawn_description')} />
                    </>
                : ''}
            </Bar>
            { !seedPointCountExceeded ? '' :
                <Description className='app-decription-error app-market-opt-error' description={'The drawn polygon has exceeded the maximum seed point threshold. Please reduce the size of the polygon or use a standard geography.'} />
            }
        </div>
    </>
}
