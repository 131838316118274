// React imports
import React, { useRef, useCallback } from 'react'

// Third party imports
import { DataGrid as DevExDataGrid, Column, Sorting, Paging, Pager, FilterRow, HeaderFilter, Scrolling, LoadPanel, MasterDetail, Selection, SearchPanel, Export } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

// App imports
import { translate } from "../../../utils/translation";
import { helpers } from '../../../utils/helpers';

const _ = require("lodash");

const onRowExpanding = (e) => {
	e.component.collapseAll(-1);
};

export const DataGrid = React.forwardRef(({
		className,columns,onLoad,onSaving,showFilterRow,showHeaderFilter,showScrollbar,height,keyExpr,noDataText,remoteOperations,
		enableMasterDetail,detailTemplate,expandOneRowOnly,disableLoadPanel,selectionMode,onSelectionChanged,selectedItems,showSearchPanel,onCellPrepared,onToolbarPreparing,disablePaging,
		hidePager,editing,defaultPageSize = 12,
		allowExport, activeRefreshOnly = false, focusedRowEnabled = false
	}, ref) => {

	const grid = useRef(null);
	const container = useRef(null);
	
    React.useImperativeHandle(ref, () => {
        return grid.current
    }, []);

	var classes = ['app-data-grid'];

    if (className)
        classes.push(className);

	const emptyResults = useCallback(async () => {
		return await new Promise((resolve, reject) => {
			return resolve({ data: [], totalCount: 0 });
		});
	}, []);

	const store = new CustomStore({		
        key: keyExpr ? keyExpr : null,
		load(o) {

			if (!helpers.isOnScreen(container.current) && activeRefreshOnly)
				return emptyResults();
			else
				return onLoad(o);
		}
	});

  	const allowedPageSizes = [10, 25, 50, 100];

	const defaultEditing = {
		mode: "row",
		allowUpdating: false,
		allowDeleting: false,
		allowAdding: false
	};

	var renderColumn = (o) => {
		var classes = ['app-data-grid-column'];

		if (!o.column.allowSorting)
			classes.push('app-data-grid-column-no-link');

		if (o.column.className)
			classes.push(o.column.className);
		
		return <Column 			
			key={o.key} 
			dataField={o.column.name} 
			allowFiltering={o.column.allowFiltering}
			allowSorting={o.column.allowSorting}
			allowEditing={o.column.allowEditing}			
			dataType={o.column.dataType ? o.column.dataType : 'string'}
			width={o.column.width ? o.column.width : undefined}
			cssClass= {classes.join(' ')}					
			alignment={o.column.alignment}
			caption={o.column.caption}
			format={o.column.format ? o.column.format : ''}
			visible={typeof o.column.visible === 'undefined' ? true : o.column.visible}
			fixed={typeof o.column.fixed === 'undefined' ? false : o.column.fixed}
			fixedPosition={o.column.fixedPosition ? o.column.fixedPosition : 'left'}
			lookup={o.column.lookup ? o.column.lookup : undefined}
			validationRules={o.column.validationRules ? o.column.validationRules : []}
			cellRender={o.column.onRender ? (p) => { return o.column.onRender(p.data); } : null}
			headerCellRender={o.column.onHeaderRender ? (data) => { return o.column.onHeaderRender(data); } : null}
			editCellRender={o.column.onEditRender ? (data) => { return o.column.onEditRender(data); } : null}			
			calculateSortValue={o.column.calculateSortValue ? (data) => { return o.column.calculateSortValue(data); } : null}
			allowHeaderFiltering={o.column.allowHeaderFiltering != null ? o.column.allowHeaderFiltering : o.column.allowFiltering}
		>
		<HeaderFilter dataSource={o.column.headerFilterDataSource} allowSearch={o.column.headerFilterSearch} />
		{ !_.isArray(o.column.columns) || o.column.columns.length === 0 ? '' : 
			o.column.columns.map((column, i) =>{
				return renderColumn({ column: column, key: i });
			})
		}
		</Column>
	}

	const onSelectionChangedOverride = (e) => {
		if (enableMasterDetail)
		{
			e.component.collapseAll(-1);
			e.component.expandRow(e.selectedRowKeys[0]);
		}

		if (onSelectionChanged)
			onSelectionChanged(e);
	};

	const onExporting = (e) => {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(translate('data'));
		exportDataGrid({
		  component: e.component,
		  worksheet: worksheet,
		  autoFilterEnabled: true,
		}).then(() => {
		  workbook.xlsx.writeBuffer().then((buffer) => {
			saveAs(new Blob([buffer], { type: 'application/octet-stream' }), helpers.newGuid() + '.xlsx');
		  });
		});
	  };

    return <div className='app-datagrid-container' ref={container}>
		<DevExDataGrid
			ref={grid}
			className={classes.join(' ')}
			//keyExpr={keyExpr ? keyExpr : 'id'}
			height= {height ? height : '100%'}
			dataSource={store}
			showBorders={true}
			noDataText={noDataText ? noDataText : translate('no_data')}
			remoteOperations={remoteOperations ? remoteOperations : 'true'}      
			showRowLines={true}
			rowAlternationEnabled={true}
			columnAutoWidth={true}		
			showFilterRow={showFilterRow}
			onRowExpanding={expandOneRowOnly ? onRowExpanding : null}
			selectedRowKeys={selectedItems}
			onSelectionChanged={selectionMode !== 'none' ? onSelectionChangedOverride : null}
			onCellPrepared={onCellPrepared ? onCellPrepared : null}
			onToolbarPreparing={onToolbarPreparing ? onToolbarPreparing : null}
			onSaving={onSaving ? onSaving : null}
			onExporting={onExporting}
			editing={ editing ? editing : defaultEditing }
			syncLookupFilterValues={false}
			focusedRowEnabled={focusedRowEnabled}
		>
			<SearchPanel visible={showSearchPanel} highlightCaseSensitive={true} />
			<HeaderFilter visible={showHeaderFilter} />
			<FilterRow visible={showFilterRow}  />
			{
				columns.map((column, i) =>{
					return renderColumn({ column: column, key: i });
				})
			}
			<Scrolling showScrollbar={showScrollbar ? showScrollbar : 'never'} />
			<Sorting mode="multiple" />
			<Paging enabled={!disablePaging} defaultPageSize={defaultPageSize} /> 
			<Pager visible={!hidePager} showPageSizeSelector={true} showInfo={true} allowedPageSizes={allowedPageSizes}/>
			<LoadPanel enabled={!disableLoadPanel} />
			<MasterDetail enabled={enableMasterDetail} component={enableMasterDetail ? detailTemplate : null} />
			<Selection mode={selectionMode ? selectionMode : 'none'} />
			<Export enabled={typeof allowExport === 'undefined' ? false : allowExport}/>
		</DevExDataGrid>
	</div>
})