import { useState } from 'react';

// Third party imports
import {TextBox as DxTextBox, Button as TextBoxButton} from 'devextreme-react/text-box';
import {NumberBox as DxNumberBox} from 'devextreme-react/number-box';
import { iconHelpers } from '../icon/icon';

// App imports
import { validation } from '../validation/validation';
import { Icon, icons } from '../icon/icon';

export const inputBoxTypes = {
    text: 0,
    numeric: 1,
    currency: 2,
    percent: 3    
};

export const validatorTypes = validation.validatorTypes;

const _ = require("lodash");

export function TextBox({className, label, value, name, placeholder, actions, validator, isRequired = false, type = inputBoxTypes.text, mode = 'text', disabled=false, height='30px', maxLength=null, onChange=()=>{}, valueChangeEvent,
    allowNegative=true, allowDecimals=true, allowCommas=true, allowNull=true, readOnly=false, visible=true, elementAttr={}, inputAttr={}, storedAsDecimal = false, maxDecimals = 2, maxDigits = 16 }) {

    const [allowLeadingZeros, setAllowLeadingZeros] = useState(true);

    var classes = ['app-text-box'];

    if (className)
        classes.push(className);    

    const getValue = (e) =>{
                
        if ((e.value === null && allowNull === false) || e.value === e.previousValue)
            return

        if (onChange)
            onChange({
                value: e.value,
                name: name,
                userChanged: e.event !== undefined
            });
    };

    const getActions = () => {
        return actions?.map((action,i) => {
            action.type = 'textBoxButton';

            if (_.isObject(action.icon))
                action.icon = iconHelpers.toSVG(action.icon);

            return <TextBoxButton key={i} name={crypto.randomUUID()} location='after' options={action} />
        });
    };

    const getNumberValue = (value) =>{
        value = parseFloat(value);
        return _.isNaN(value) ? null : value;
    };

    const getFormatString = () =>{
        let format = allowLeadingZeros ? '0' : '#';
        const decimals = '#'.repeat(maxDecimals);
        
        format = allowCommas ? '#,##' + format : format;
        if (allowDecimals && maxDecimals > 0) {
            format += `.${decimals}`;
        }

        switch(type){
            case inputBoxTypes.currency:
                format = "$" + format;
                break;
            case inputBoxTypes.percent:
                storedAsDecimal ? format += '%' : format += `'%'`;
                break;
        }

        return format;
    }

    const handleFocusOut = () => {
        if(validator) {
            validator.ref?.current?.instance.option('isDirty', true);
            validator.ref?.current?.instance.validate()
        }
    };

    const getRequiredFieldIndicator = () => {
        return isRequired ?                     
            <span className='required'>{isRequired ? <Icon className={'app-form-required'} icon={icons.asterisk}/> : ''}</span> : '';
    }

    const renderComponent = () => {
        switch(type){
            default:
            case inputBoxTypes.text:
                return <div className='app-text-box-container'>
                    <DxTextBox 
                        elementAttr={elementAttr}
                        inputAttr={inputAttr}
                        label={label}
                        value={value?.toString()}
                        placeholder={placeholder}
                        mode={mode}
                        disabled={disabled}
                        readOnly={readOnly}
                        visible={visible}
                        stylingMode='outlined'
                        height={height}
                        maxLength={maxLength} 
                        onValueChanged={getValue}
                        valueChangeEvent={valueChangeEvent? valueChangeEvent : 'change'}
                        onFocusOut={handleFocusOut}
                        >
                        {getActions()}
                        {validation.getValidator(validator)}
                    </DxTextBox>                    
                    {getRequiredFieldIndicator()} 
                </div>
                
            case inputBoxTypes.numeric:
            case inputBoxTypes.currency:
            case inputBoxTypes.percent:
                let format = getFormatString();
                                
                return <div className='app-text-box-container'>
                <DxNumberBox
                    elementAttr={elementAttr}
                    inputAttr={inputAttr}
                    min={allowNegative ? null : 0}
                    label={label}
                    value={getNumberValue(value)}
                    disabled={disabled}
                    readOnly={readOnly}
                    visible={visible}
                    stylingMode='outlined'
                    height={height}
                    maxLength={maxLength}
                    format={format}
                    valueChangeEvent={valueChangeEvent? valueChangeEvent : 'change'}
                    onValueChanged={getValue}
                    onFocusOut={handleFocusOut}
                    onKeyDown={(e) => {
                        const inputElement = e.event.target;
                        const cursorPosition = inputElement.selectionStart;
                        const currentValue = inputElement.value;

                        if (e.event.key === 'Backspace' || e.event.key === 'Delete') {
                            

                            const newValue = currentValue.slice(0, cursorPosition - 1) + currentValue.slice(cursorPosition);
                            if (newValue === '' || newValue === '$' || newValue === '%') {
                                getValue({ value: null, event: e.event });
                                setAllowLeadingZeros(false);
                            }
                        }
                        else if (e.event.key === '0') {
                            const newValue = currentValue.slice(0, cursorPosition) + '0' + currentValue.slice(cursorPosition);
                            if (parseFloat(newValue) === 0) {
                                setAllowLeadingZeros(true);
                            }
                        }
                    }}>
                        
                    {getActions()}      
                    {validation.getValidator(validator)}              
                </DxNumberBox>
                {getRequiredFieldIndicator()} 
                </div>
                
        }
    };

    return <div className={classes.join(' ')}>{renderComponent()}</div>    
}