// React imports
import { useState, useEffect, useCallback } from 'react'

// App imports
import { Loader } from '../../../base/loader/loader';
import { InfoForm } from '../../forms/infoForm/infoForm';
import { helpers } from '../../../../utils/helpers';
import { sources } from '../../../../modules/sources';

const _ = require("lodash");

export function TaskActionInfoBox({ task }) {

    const [generating, setGenerating] = useState(false);
    const [form, setForm] = useState(null);

    const getPointInformation = useCallback( async () => {

        if (task?.sourceInformation?.parentObjectId == helpers.emptyGuid() || task?.sourceInformation?.objectId == helpers.emptyGuid())
            return;

        setGenerating(true);

        /* TBD: KLI 1.0 calls GetFormPointPageInformation first - not clear what we need from the results?
        const results = await legacyEndpoints.service({
            name: 'GetFormPointPageInformation',
            parameters: {
                formId: task.sourceInformation.parentObjectId,
                pageId: helpers.emptyGuid(),
                pointId: task.sourceInformation.objectId
            }
        });
        */

        const info = await sources.getPointInformation({
            fromTask: true,
            entity: {
                id: task.sourceInformation.objectId,
                layer: {
                    id: task.sourceInformation.parentObjectId,
                    metaData: {
                        isCompetitiveInsights: false
                    }
                }
            }
        });

        setForm(info.Form);
        setGenerating(false);

    }, [task]);

    useEffect(() => {
        getPointInformation();
    }, [getPointInformation]);

    return <>
        <Loader theme={'generate'} loaded={!generating} />
        <div className='app-task-action-form'>
            { form == null ? '' : <InfoForm customQueryId={task.sourceInformation.parentObjectId} pointId={task.sourceInformation.objectId} form={form} fromTask={true} /> }
        </div>
    </>
}