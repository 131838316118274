import { legacyEndpoints } from '../services/legacyEndpoints';
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';
import { translate } from '../utils/translation';

const _ = require("lodash");

export const tasks = {
    getFilteredTasks: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetFilteredTasks',
            parameters: {
                skip: o.skip,
                take: o.take,
                filters: o.filters,
                sorts: o.sorts
            }
        });
    },
    getColumnFilter: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetTasksColumnFilter',
            parameters: {
                field: o.field,
                filters: o.filters,
                searchOperation: o.searchOperation,
                searchValue: o.searchValue,
                skip: o.skip,
                take: o.take
            }
        });
    },
    getTasksStatusName: (status) =>{
        switch(status){
            default:
                return status;
            case constants.tasks.statuses.completed:
                return translate('completed');
            case constants.tasks.statuses.deferred:
                return translate('deferred');
            case constants.tasks.statuses.inProgress:
                return translate('in_progress');
            case constants.tasks.statuses.notStarted:
                return translate('not_started');
            case constants.tasks.statuses.waiting:
                return translate('waiting');
        }
    },
    getTaskStatusIdFromName: (status) =>{
        switch(status){
            default:
                return status;
            case "Completed":
                return constants.tasks.statuses.completed;
            case "Deferred":
                return constants.tasks.statuses.deferred;
            case "In Progress":
                return constants.tasks.statuses.inProgress;
            case "Not Started":
                return constants.tasks.statuses.notStarted;
            case "Waiting":
                return constants.tasks.statuses.waiting;
        }
    },
    getTaskTypeName: (type) =>{
        switch(type){
            default:
                return type;
            case constants.tasks.types.system:
                return translate('system');
            case constants.tasks.types.user:
                return translate('user');
        }
    },
    getTaskTypeNameFromId: (type) =>{
        switch(type){
            default:
                return type;
            case "System":
                return constants.tasks.types.system;
            case "User":
                return constants.tasks.types.user;
        }
    },
    getTaskPriorityName: (priority) =>{
        switch(priority){
            default:
                return priority;
            case constants.tasks.priorities.low:
                return translate('low');
            case constants.tasks.priorities.medium:
                return translate('normal');
            case constants.tasks.priorities.high:
                return translate('high');
            case constants.tasks.priorities.critical:
                return translate('critical');
        }
    },
    getTaskPriorityIdFromName: (priority) =>{
        switch(priority){
            default:
                return priority;
            case "Low":
                return constants.tasks.priorities.low;
            case "Normal":
                return constants.tasks.priorities.medium;
            case "High":
                return constants.tasks.priorities.high;
            case "Critical":
                return constants.tasks.priorities.critical;
        }
    },
    getTaskActionName: (action) =>{
        switch(action){
            default:
                return action;
            case constants.tasks.actions.none:
                return translate('none');
            case constants.tasks.actions.loadCustomQuery:
                return translate('load_custom_query');
            case constants.tasks.actions.openInfobox:
                return translate('open_info_form');
            case constants.tasks.actions.openEditForm:
                return translate('open_edit_form');
        }
    },
    getTask: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetTask',
            parameters: {
                id: o.id
            }
        });
    },    
    getTaskMetadata: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetTaskMetadata',
        });
    },
    getDefaultTask: function(o) {
        const sourceInformation = {
            label: o.entity != null ? o.entity.text : '',
            objectId: o.entity != null ? o.entity.id : helpers.emptyGuid(),
            parentObjectId: o.entity != null ? o.entity.layer.id : helpers.emptyGuid(),
            sourceObjectId: o.entity != null ? o.entity.layer.metaData.serviceAttributes.DataSourceGUID : helpers.emptyGuid(),
            objectType: o.entity != null ? constants.tasks.objectTypes.point : constants.tasks.objectTypes.application,
            sourceObjectType: o.entity != null ? constants.tasks.sourceObjectTypes.dataSource : constants.tasks.sourceObjectTypes.application
        };

        return {
            action: constants.tasks.actions.none,
            assignedTo: {id: null, GUID: null, name: null},
            createdBy: {id: legacyEndpoints.authenticationToken.TokenInformation.UserGUID, name: legacyEndpoints.authenticationToken.UserInformation.Name},
            description: null,
            dueDate: null,
            notes: [],
            priority: constants.tasks.priorities.medium,
            reminderDate: null,
            sourceInformation: sourceInformation,
            status: constants.tasks.statuses.notStarted,
            title: null,
            type: constants.tasks.types.user
        };
    },  
    updateTask: async (o) =>{
        return await legacyEndpoints.service({
            name: o.id == null ? 'CreateTask' : 'UpdateTask',
            parameters: {task: o}
        });
    },
    exportTasks: async (o) =>{
        var fileId = await legacyEndpoints.service({
            name: 'ExportTasks',
            parameters: {
                taskIdList: o.ids
            }
        });

        helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({id: fileId, isOneTime: true}));
    },
    deleteTasks: async (o) =>{
        return await legacyEndpoints.service({
            name: 'DeleteTasks',
            parameters: {
                taskIdList: o.taskIdList
            }
        });
    },
    updateTaskSharedPeers(o){
        return legacyEndpoints.service({
            name: 'UpdateTaskSharedPeers',
            parameters: {
                id: o.id,
                userList: o.userList
            }
        });
    }
};