import { useEffect, useState } from 'react';

import { InputForm } from '../forms/inputForm/inputForm';
import { Loader } from '../../base/loader/loader';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { constants } from '../../../utils/constants';
import { projections } from '../../../modules/projections';
import { activityHub } from '../activityHub/activityHub';
import { helpers } from '../../../utils/helpers';
import { translate } from '../../../utils/translation';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { forms as formModule } from '../../../modules/forms';

const _ = require("lodash");

export function ProjectionEditForm({ saveButtonSize='medium', projection, entity, relocation, onGenerate }){

    const [form, setForm] = useState(null);
    const [collapsedForm, setCollapsedForm] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [updateForm, setUpdateForm] = useState(null);
    const [jobName] = useState(`${(_.isString(projection.pointName) ? `${projection.pointName}-` : '')}${projection.name} ${helpers.formatDate({ date: new Date() })}`);
    const [activeWarning, setActiveWarning] = useState(false);
    const [activeError, setActiveError] = useState(false);
    
    useEffect(() =>{
		(async ()=>{
            var formData;
            if (_.isObject(entity))
            {
                formData = await legacyEndpoints.service({
                    name: 'GetAnalyticsProjectionEditForm',
                    parameters: {
                        projectionId: projection.id,
                        customQueryId: entity.layer.id, 
                        pointId: entity.id
                    }
                });
                setForm(formData);
            }      
            else
            {
                formData = await legacyEndpoints.service({
                    name: 'GetAnalyticsProjectionLayerEditForm',
                    parameters: {
                        projectionResults:{
                            id: projection.id,
                            pointId: projection.pointId,
                            layer: projection.layer,
                            data: projection.data
                        }
                    }
                });
                setForm(formData);
                setCollapsedForm(formModule.collapseForm({form: formData}));
            }    
		})();
    }, [projection, entity]);

    const generate = async () =>{

        activityHub.close();

        setGenerating(true);

        if (_.isObject(entity))
            if (projection.type === constants.projections.relocation)
                projections.generateRelocation({
                    id: projection.id,
                    customQueryId: entity.layer.id,
                    pointId: entity.id,
                    location: entity.location,
                    relocation: relocation,
                    updateForm: updateForm,
                    jobName: jobName
                });
            else
                switch (projection.providerType)
                {
                    case constants.projections.providers.generic:
                    case constants.projections.providers.intalytics:
                        
                        projections.generate({
                            id: projection.id,
                            name: projection.name,
                            type: projection.providerType === constants.projections.providers.generic ? constants.projections.oneClick : projection.type,
                            customQueryId: entity.layer.id,
                            pointId: entity.id,
                            location: entity.location,
                            editForm: form,
                            updateForm: updateForm,
                            entity: entity
                        });

                        break;
                }

        setGenerating(false);
        
        if (_.isFunction(onGenerate))
            onGenerate({
                updateForm: updateForm
            });
    };

	return <>
        <div className='app-proposed-site-header'>
            {
                (activeError === true) ? <Button theme='primary' size={saveButtonSize} icon={icons.check} disabled={true} /> 
                : (activeWarning === true) ? <ConfirmButton className='app-button-warning' theme='primary' size={saveButtonSize} align='right' icon={icons.check} clearText={translate('save')} headerText={translate('save_with_warnings')} onConfirm={()=>{generate()}}/> 
                : <Button theme='primary' size={saveButtonSize} icon={icons.check} onClick={()=>{generate()}}/> 
            }
        </div>
        <Loader loaded={form !== null} />
        <Loader theme='generate' loaded={!generating} />
        <InputForm form={form} collapsedForm={collapsedForm} forceChange={true} onUpdate={(o) => { setUpdateForm(o.updateForm); 
            setActiveError(o.activeError); 
            setActiveWarning(o.activeWarning) }
        } />    
    </>;
}