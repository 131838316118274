// Third party imports
import axios from "axios";

// App imports
import { constants } from "../utils/constants";
import { language, translate } from '../utils/translation';
import { errorToast, toast } from "../components/base/toast/toast";
import { msalConfig } from "../config/auth";

var _ = require("lodash");
var cryptLib = require("cryptlib");
var sha1 = require('sha1');
var isViewer = _.startsWith(window.location.hostname.toLowerCase(), 'kli-viewer');

axios.defaults.headers.common = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "Accept-Language": language
};

export const legacyEndpoints = {
    serviceBaseUrl: `${process.env.REACT_APP_LEGACY_SERVICE_BASE_URL}`,
    applicationId: (isViewer ? parseInt(`${process.env.REACT_APP_VIEWER_APPLICATION_VERSION}`) : parseInt(`${process.env.REACT_APP_APPLICATION_VERSION}`)),
    authenticationToken: null,
    sessionKey: null,
    context: null,
    beforeUnloadController: null,
    errorCount: 0,
    queryParams: null,
    GetImageryMetadata: function(o, args) {
        args = args || {};
        return `https://dev.virtualearth.net/REST/v1/Imagery/BasicMetadata/${args.imagerySet}/${args.lat},${args.lon
            }?zl=${args.zoom}&dir=${args.orientation}&key=${args.key}`;
    },
    ReverseGeocode: function(o, args) {
        args = args || {};
        return `https://dev.virtualearth.net/REST/v1/Locations/${args.lat},${args.lon}?key=${args.key}`;
    },
    handlers:
    {
        getLogoUrl: function (params) {
            params = _.isObject(params) ? params : {};

            if (params.id == null)
                params.id = legacyEndpoints.authenticationToken.UserPreferences.LogoId;
            else
                params.id = sha1(params.id.toLowerCase());

            if (params.type == null)
                params.type = 1;

            if (params.refresh == null)
                params.refresh = true;

            return this.baseUrl() + "Logos.ashx?Id=" + params.id + "&Type=" + params.type + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getPhotoUrl: function (params) {
            params = _.isObject(params) ? params : {};

            if (params.isMap == null)
                params.isMap = false;

            if (params.isUserPhoto == null)
                params.isUserPhoto = false;

            if (params.userId == null)
                params.userId = legacyEndpoints.authenticationToken.UserPreferences.UserGUID;

            if (params.preventCache == null)
                params.preventCache = false;

            if (params.isUserPhoto) {
                if (params.userId != null)
                    return this.baseUrl() + "Photos.ashx?Id=" + sha1(params.userId.toLowerCase()) + (_.isNumber(params.width) ? "&Width=" + params.width : "") + (_.isNumber(params.height) ? "&Height=" + params.height : "") + "&IsUserPhoto=" + params.isUserPhoto + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken + (params.preventCache ? "&Random=" + Math.random() : "");
            }
            else
                return this.baseUrl() + "Photos.ashx?Id=" + params.id + (_.isNumber(params.width) ? "&Width=" + params.width : "") + (_.isNumber(params.height) ? "&Height=" + params.height : "") + "&IsMap=" + params.isMap + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken + (params.preventCache ? "&Random=" + Math.random() : "");
        },
        getSymbolUrl: function (params) {
            params = _.isObject(params) ? params : {};
            params.symbolType = params.symbolType || 0;

            var mySymbol = "";

            if ((_.isNull(params.imageUrl) || _.isUndefined(params.imageUrl) || params.imageUrl.length === 0)) {
                if (params.isInfobox === true)
                    mySymbol = "https://cdn.tradeareasystems.net/Images/TASMobile/Misc/info.png";
                else if (params.isEditbox === true)
                    mySymbol = "https://cdn.tradeareasystems.net/Images/TASMobile/Misc/Edit.png";
            }
            else {
                switch (params.symbolType) {
                    default:
                        break;
                    case 0:
                        mySymbol = this.baseUrl() + "Symbols.ashx?Symbol=" + encodeURIComponent(params.imageUrl.replace(/\//gi, "%2f"));
                        break;
                    case 1:
                        mySymbol = this.baseUrl() + "Files.ashx?Id=" + params.imageUrl + "&IsCustomImage=true&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
                        break;
                    case 2:
                        break;
                    case 3:
                        mySymbol = params.imageUrl;
                        break;
                }
            }

            return mySymbol;
        },
        getTileUrl: function (params) {

            params = _.isObject(params) ? params : {};

            var hiddenRanges = '';

            if (_.isArray(params.hiddenRanges))
                hiddenRanges = `&HiddenRanges=${params.hiddenRanges.join(',')}`;            

            if (params.quadkey == null)
                return this.tileBaseUrl() + "Tiles.ashx?Id=" + params.id + "&Quadkey={quadkey}" + + hiddenRanges + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
            else
                return this.tileBaseUrl() + "Tiles.ashx?Id=" + params.id + "&Quadkey=" + params.quadkey + hiddenRanges +"&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getTileBoundingBoxUrl: function (params) {
            params = _.isObject(params) ? params : {};

            var requestType = _.isBoolean(params.allowLabels) ? "&RequestType=" + (params.allowLabels ? "2" : "1") : "&RequestType=2";

            var hiddenRanges = '';

            if (_.isArray(params.hiddenRanges))
                hiddenRanges = `&HiddenRanges=${params.hiddenRanges.join(',')}`;   

            if (params.pinPointCount == null)   
                params.pinPointCount = -1;

            return this.tileBaseUrl() + "Tiles.ashx?Id=" + params.id + "&Left=" + params.upperLeftLon + "&Right=" + params.lowerRightLon + "&Top=" + params.upperLeftLat + "&Bottom=" + params.lowerRightLat + "&Zoom=" + params.zoom + requestType + hiddenRanges + "&PinPointCount=" + params.pinPointCount + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getCustomerTilesBoundingBox: function (params) {
            params = _.isObject(params) ? params : {};

            if (params.isDesireLine == null)
                params.isDesireLine = false;

            if (params.isChain == null)
                params.isChain = false;

            if (params.isUber == null)
                params.isUber = false;

            if (params.timeType == null)
                params.timeType = -1;

            if (params.dataType == null)
                params.dataType = -1;

            if (params.customQueryId == null)
                params.customQueryId = -1;

            if (params.sourceId == null)
                params.sourceId = -1;

            if (params.pinPointCount == null)   
                params.pinPointCount = -1;

            params.isCompetitiveInsights = _.isBoolean(params.isCompetitiveInsights) && params.isCompetitiveInsights;

            return this.tileBaseUrl() + "CustomerTilesBoundingBox.ashx?Id=" + params.id + "&Data=" + params.data.join(",") + "&UpperLeftLat=" + params.upperLeftLat + "&UpperLeftLon=" + params.upperLeftLon + "&LowerRightLat=" + params.lowerRightLat + "&LowerRightLon=" + params.lowerRightLon + "&Zoom=" + params.zoom + "&IsChain=" + params.isChain + "&IsDesireLine=" + params.isDesireLine + "&IsUber=" + params.isUber + "&TimeType=" + params.timeType + "&DataType=" + params.dataType + "&SourceId=" + params.sourceId + "&CustomQueryId=" + params.customQueryId + "&IsCompetitiveInsights=" + params.isCompetitiveInsights + "&PinPointCount=" + params.pinPointCount + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getCustomerTileUrl: function (params) {
            params = _.isObject(params) ? params : {};

            if (params.isChain == null)
                params.isChain = false;

            return this.tileBaseUrl() + "CustomerTiles.ashx?Id=" + params.id + "&Quadkey={quadkey}&Data=" + params.data.join(",") + "&IsChain=" + params.isChain + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getChartUrl: function (params) {
            params = _.isObject(params) ? params : {};
            params.shapeId = params.shapeId || "";

            return this.baseUrl() + "Charts.ashx?Id=" + params.id + "&Width=" + params.width + "&Height=" + params.height + "&UpperLeftLatitude=" + params.upperLeftLatitude + "&UpperLeftLongitude=" + params.upperLeftLongitude + "&LowerRightLatitude=" + params.lowerRightLatitude + "&LowerRightLongitude=" + params.lowerRightLongitude + "&Zoom=" + params.zoom + "&ShapeId=" + params.shapeId + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getFileUrl: function (params) {
            params = _.isObject(params) ? params : {};
            params.isOneTime = (params.isOneTime != null) ? "&isOneTime=" + params.isOneTime : "";

            return this.baseUrl() + "Files.ashx?Id=" + params.id + params.isOneTime + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getGeoFeedUrl: function (params) {
            params = _.isObject(params) ? params : {};
            return this.baseUrl() + "GeoFeeds.ashx?Id=" + params.id + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getCORSProxyUrl: function (params) {
            params = _.isObject(params) ? params : {};
            return this.baseUrl() + "CORSProxy.ashx?Url=" + params.url + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getUploadUrl: function (params) {
            params = _.isObject(params) ? params : {};
            return this.baseUrl() + "Uploads.ashx?Task=" + params.task + "&Id=" + params.fileId + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getMultimediaUrl: function (params) {
            params = _.isObject(params) ? params : {};
            return this.baseUrl() + "Multimedia.ashx?Id=" + params.id + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getReportUrl: function (params) {
            params = _.isObject(params) ? params : {};
            params.fileId = (params.fileId != null) ? "&FileId=" + params.fileId : "";
            params.dictionaryId = (params.dictionaryId != null) ? "&DictionaryId=" + params.dictionaryId : "";

            return this.baseUrl() + "Reports.ashx?Id=" + params.id + params.fileId + params.dictionaryId + "&Type=" + params.type + "&Output=" + params.output + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        },
        getPrintUrl: function (params) {
            params = _.isObject(params) ? params : {};

            params.isHtml = _.isBoolean(params.isHtml) ? params.isHtml : false;
            params.isPrintPreview = _.isBoolean(params.isPrintPreview) ? params.isPrintPreview : false;
            params.outputType = _.isNumber(params.outputType) ? params.outputType : 2;

            return this.baseUrl() + "Print.ashx?Id=" + params.id + "&IsHtml=" + params.isHtml + "&IsPrintPreview=" + params.isPrintPreview + "&OutputType=" + params.outputType + "&Integrity=" + legacyEndpoints.authenticationToken.TokenInformation.HandlerToken;
        }  
    },
    init: function (o) {
        let instance = this;

        instance.context = o.context;
        instance.beforeUnloadController = o.beforeUnloadController;
        instance.queryParams = o.context.queryParams;

        if (o.context.viewer)
        {
            axios.post(instance.serviceBaseUrl + "TASUtils.svc/JSON/GetViewerAuthenticationToken",{
                duration: parseInt(`${process.env.REACT_APP_LEGACY_SERVICE_TOKEN_DURATION}`),
                id: o.context.queryParams.id
            }).then(result => {
                instance.authenticationToken = result.data.d;
                
                axios.defaults.headers.common.Authorization = instance.authenticationToken.TokenInformation.Token;

                axios.post(instance.serviceBaseUrl + "TASUtils.svc/JSON/GetServiceEndpoints", {
                    Application: instance.applicationId,
                    UserName: legacyEndpoints.encrypt(instance.authenticationToken.TokenInformation.UserId),
                    Format: constants.textFormats.json, // JSON
                    IsDebug: false
                }).then(result => {
                    instance["baseURL"] = function () {
                        return result.data.d.BaseURL;
                    };

                    instance.handlers["baseUrl"] = function () {
                        return result.data.d.BaseURL + "Handlers/";
                    };

                    instance.handlers["tileBaseUrl"] = function () {
                        return result.data.d.BaseURL.replace("v2.0", "Tiles") + "Handlers/";
                    };

                    _.forEach(result.data.d.Endpoints, function (endpoint) {
                        if (!_.isObject(instance[endpoint.Name]))
                            instance[endpoint.Name] = endpoint;
                    });

                    if (_.isFunction(o.callback))
                        o.callback();
                });
            }).catch(error => {
                instance.beforeUnloadController.abort();
                instance.context.instance.logoutRedirect();
            });
        }
        else
        {
            const account = o.context.accounts[0];
            if (!account) return;

            o.context.instance.acquireTokenSilent({
                scopes: [`${process.env.REACT_APP_AZURE_B2C_SCOPE}`],
                account: account
            }).then((response) => {

                if (!response)
                    return;

                axios.post(instance.serviceBaseUrl + "TASUtils.svc/JSON/GetAuthenticationToken",{
                    Duration: parseInt(`${process.env.REACT_APP_LEGACY_SERVICE_TOKEN_DURATION}`),
                    SessionKey: response.accessToken,
                    Application: instance.applicationId
                }).then(result => {
                    var claims = account.idTokenClaims;
                    
                    instance.authenticationToken = result.data.d;
                    
                    axios.defaults.headers.common.Authorization = legacyEndpoints.authenticationToken.TokenInformation.Token;

                    axios.post(instance.serviceBaseUrl + "TASUtils.svc/JSON/GetServiceEndpoints", {
                        Application: instance.applicationId,
                        UserName: legacyEndpoints.encrypt(claims.preferred_username),
                        Format: constants.textFormats.json, // JSON
                        IsDebug: false
                    }).then(result => {
                        instance["baseURL"] = function () {
                            return result.data.d.BaseURL;
                        };

                        instance.handlers["baseUrl"] = function () {
                            return result.data.d.BaseURL + "Handlers/";
                        };

                        instance.handlers["tileBaseUrl"] = function () {
                            return result.data.d.BaseURL.replace("v2.0", "Tiles") + "Handlers/";
                        };

                        _.forEach(result.data.d.Endpoints, function (endpoint) {
                            if (!_.isObject(instance[endpoint.Name]))
                                instance[endpoint.Name] = endpoint;
                        });

                        if (_.isFunction(o.callback))
                            o.callback();
                    });
                });            
            });
        }
    },
    service: async function(o) {
        var instance = this;
        var service = instance[o.name];

        if (!_.isBoolean(o.suppressError))
            o.suppressError = false;

        if (!_.isObject(service))
        {
            console.log('Endpoint not found: ', instance, o, service);
            return;
        }

        var handleResponse = (response) => {
            switch(response.status)
            {
                default:
                    response.data = response.data || {};

                    switch(response.data.Code)
                    {
                        case constants.exceptions.tokenExpired: // Token expired
                            instance.beforeUnloadController.abort();
                            instance.context.instance.logoutRedirect({
                                account: instance.context.instance.getAllAccounts()[0],
                                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
                                authority: msalConfig.auth.authority
                            });
                            break;
                        default:
                            if (_.isFunction(o.error))
                                o.error({statusText: response.statusText, data: response.data});
                            break;
                    }

                    if (!o.suppressError)
                    {
                        switch(response.data.Code){
                            default:
                                errorToast(response.data.Message);
                                break;
                            case 5000000:
                                errorToast(translate('service_error'));
                                break;
                        }
                    }

                    break;
                case 202:
                    break;
                case 200:
                    if (_.isFunction(o.success))
                        o.success(response.data.d);

                    instance.errorCount = 0;
                    return response.data.d;
            }
        };
        
        // 10 consecutive errors will force a logout
        var handleConsecutiveErrors = (error) => {
            if (++instance.errorCount >= 10)
            {
                instance.beforeUnloadController.abort();

                if (isViewer)
                    instance.context.instance.logoutRedirect();
                else
                    instance.context.instance.logoutRedirect({
                        account: instance.context.instance.getAllAccounts()[0],
                        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
                        authority: msalConfig.auth.authority
                    });
            }
        }

        try
        {
            switch(service.Method)
            {
                default:
                    break;
                case "GET":
                    return await axios
                        .get(service.Url, {params: o.parameters})
                        .then(response => handleResponse(response))
                        .catch(error => {
                            handleConsecutiveErrors(error);

                            if (_.isObject(error.response))
                                handleResponse(error.response);
                        });
                case "POST":
                    return await axios
                        .post(service.Url, o.parameters)
                        .then(response => handleResponse(response))
                        .catch(error => {
                            handleConsecutiveErrors(error);

                            if (_.isObject(error.response))
                                handleResponse(error.response);
                        });
            }   
        }
        catch(e)
        {
            if (!o.suppressError)
                errorToast(translate('service_error')); 
        }
         
    },
    dataService: async function(o) {
        var handleResponse = (response) =>{          
       
            switch(response.status)
            {
                default:
                    if (!o.error)
                        o.error(response.statusText);

                    errorToast(translate('service_error'));

                    break;
                case 200:
                    if (o.success)
                        o.success(response.data);
                    return response.data;
            }
        };
        
        try{
            return await axios
                .get(o, {transformRequest: (data, headers) => {
                    delete headers['Access-Control-Allow-Origin'];
                    delete headers['Authorization'];
                    delete headers['Time-Zone'];
                    delete headers['Accept-Language'];
                    delete headers['Content-Type'];                    
                    return data;
                }
                })          
                .then(response => handleResponse(response)); 
        }
        catch (e)
        {
            errorToast(translate('service_error')); 
        }
    },     
    uploadFileChunk: async function(formData, o) { 
        var instance = this;

        var handleResponse = (response) =>{            
            switch(response.status)
            {
                default:
                    if (o.error)
                        o.error(response.statusText);

                    errorToast(translate('service_error'));
                    break;
                case 200:
                    if (o.success)
                        o.success(response.data.d);
                    return response.data.d;
            }
        };
        return await axios
        .post(instance.serviceBaseUrl + 'TASUtils.svc/JSON/UploadFileChunk', formData,{headers: o.parameters})
        .then(response => handleResponse(response));   
    },
    encrypt: function(value) {
        var salt = `${process.env.REACT_APP_ENCRYPT_LEGACY_SALT}`;
        var iv = `${process.env.REACT_APP_ENCRYPT_LEGACY_IV}`;

        // Bug in env.local
        if (process.env.NODE_ENV === 'development')
            iv = _.trim(iv, '_');
        
        return cryptLib.encrypt(value, salt, iv);
    }
};